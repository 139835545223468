import React, { useCallback, useEffect } from 'react'
import * as Yup from 'yup'
import { Field, Formik } from 'formik'
import {
  Box,
  Button,
  makeStyles,
  Card,
  Grid,
} from '@material-ui/core'
import { isValidNumber, mapDispatch } from '../../../../utils/utils'
import { actions as socketActions } from '../../../socket/socketSlice'
import { actions as singleActions } from '../../../single/singleSlice'
import connect from 'react-redux/es/connect/connect'
import PropTypes from 'prop-types'
import lodash from 'lodash'
import FormRadio from '../../../../components/form/FormRadio'
import FormInterval from '../../../../components/form/FormInterval'
import FormInput from '../../../../components/form/FormInput'
import {
  BUY_SELL_OPTIONS,
  CMD_DEX_TAKER_PREVIEW_TAKER,
  CMD_DEX_TAKER_EXECUTE_TAKER,
  CMD_DEX_TAKER_STOP_TAKER,
  SIDE_BUY,
  SIDE_SELL,
  CMD_DEX_TAKER_APPROVE_SPENDING,
} from '../../../../protocolConstants'
import PiTwoColRowGrid from '../../../../components/PiTwoColRowGrid'
import { useRefFields, useRefFieldsPrevious, useRefFieldsPrevValueChanged, useStateReq } from '../../../../utils/hooks'
import {
  dexTakerDialogPropType,
  paramDexTakerPropType,
} from '../../../../utils/propTypesUtils'
import TakerAlgoFormDialog from './dexTakerAlgoForm/DexTakerAlgoFormDialog'
import ColorButton from '../../../../components/ColorButton'
import PiThreeColRowGrid from '../../../../components/PiThreeColRowGrid'
import PiCardContent from '../../../../components/PiCardContent'
import { PiFormContextProvider } from '../../../../utils/piFormContext'
import PiGridItem from '../../../../components/PiGridItem'
import FormCheckbox from '../../../../components/form/FormCheckbox'
import PiInputAdornment from '../../../../components/PiInputAdornment'
import logger from '../../../../utils/logger'

const useStyles = makeStyles((theme) => ({
  formBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  button: {
    height: "100%"
  }
}))

const AUTO_START_PX_WRT_STOP_PCT = 0.005


const DexTakerAlgoForm = ({ sessionId, dialogData, showToast, setCurPageData, paramDexTaker, generalInfo }) => {
  const classes = useStyles()

  const {
    isSending,
    isSuccessful,
    sendReq,
    sentData,
    receivedData,
    setStateField,
    isError,
    clearRId,
  } = useStateReq()

  const showPreviewTakerDialog = useCallback((previewDataInput, previewSentDataInput) => {
    const dialogOpen = true
    const dialogType = 'executeDexTaker'
    setCurPageData({
      dialogData: {
        ...dialogData,
        dialogOpen,
        dialogType,
        previewData: previewDataInput,
        previewSentData: previewSentDataInput
      }
    })
  }, [setCurPageData, dialogData])

  const onPreviewDexTaker = (values, { setErrors }) => {
    setStateField('valueErrors', undefined)
    // set the function into the ref
    setRefField('setErrors', setErrors)
    sendReq({
      isRelay: true,
      cmd: CMD_DEX_TAKER_PREVIEW_TAKER,
      data: {
        sessionId,
        params: values
      }
    })
  }

  const [setRefField, getRefField] = useRefFields()
  const prevIsSending  = useRefFieldsPrevious('isSending', isSending)

  const onStopTaker = () => {
    const dialogOpen = true
    const dialogType = 'stopDexTaker'
    setCurPageData({
      dialogData: {
        ...dialogData,
        dialogOpen,
        dialogType
      }
    })
  }
  useEffect(() => {
    if (prevIsSending && !isSending) {
      const cmd = lodash.get(sentData, 'cmd')
      if(cmd === CMD_DEX_TAKER_PREVIEW_TAKER) {
        if (isSuccessful) {
          const previewData = lodash.get(receivedData, 'data')
          showPreviewTakerDialog(previewData, sentData)
        } else if (isError) {
          const valueErrors = lodash.get(receivedData, ['data', 'valueErrors'])
          const formErrors = lodash.get(receivedData, ['data', 'formErrors'])
          setStateField('valueErrors', valueErrors)
          const setErrors = getRefField('setErrors')
          if (!lodash.isEmpty(formErrors) && lodash.isFunction(setErrors)) {
            setErrors(formErrors)
          }
        }
      } else if (cmd === CMD_DEX_TAKER_STOP_TAKER) {
        if (isSuccessful) {
          showToast("Taker stopped successfully", 'success')
          const active = false
          setCurPageData({
            // params from props, hence immutable
            paramDexTaker: {
              ...paramDexTaker,
              active
            }
          })
        }
      } else if (cmd === CMD_DEX_TAKER_EXECUTE_TAKER) {
        if (isSuccessful) {
          showToast("Taker execution successfully", 'success')
          const active = true
          setCurPageData({
            paramDexTaker: {
              ...paramDexTaker,
              active
            }
          })
        }
      } else if (cmd === CMD_DEX_TAKER_APPROVE_SPENDING) {
        if (isSuccessful) {
          showToast('Approving Tx(s) are sent. Tx(s) are still pending. Please check in Tx history or Explorer', 'info')
        }
        clearRId()
      } else {
        logger.info(`DexTakerAlgoForm unsupported cmd ${cmd}`)
      }
    }
  }, [prevIsSending, paramDexTaker, setCurPageData, getRefField, isSending, isSuccessful, isError, receivedData, sentData, setStateField, showPreviewTakerDialog, clearRId, showToast])
  if (!paramDexTaker) {
    return null
  }
  const { active } = paramDexTaker
  return (
    <Box className={classes.formBox}>
      <Formik
        initialValues={{
          ...paramDexTaker
        }}
        validationSchema={props =>  Yup.lazy(values => {
          return Yup.object().shape({
            // TODO:  further handle the taker algo form validation
            orderQty: Yup.number().required('Order Qty is required').moreThan(0, "Must be positive"),
            remainingQty: Yup.number().required('Remaining Qty is required'),
            startPx: Yup.number().required('Start Price is required').moreThan(0, "Must be positive"),
            stopPx: Yup.number().required('Stop Price is required').moreThan(0, 'Must be positive'),
            buySell: Yup.string().required('Buy and sell is required'),
            itvl: Yup.number().required('Interval is required'),
            manuallySetStartPx: Yup.bool(),
          })
        })}
        onSubmit={onPreviewDexTaker}
      >

        {(formProps) => {
          return (
            <PiFormContextProvider
              disabledAllFields={active}
            >
              <TakerAlgoFormInner
                formProps={formProps}
                active={active}
                isSending={isSending}
                generalInfo={generalInfo}
                onStopTaker={onStopTaker}
              />
            </PiFormContextProvider>
          )
        }}
      </Formik>

      <TakerAlgoFormDialog
        sessionId={sessionId}
        sendReq={sendReq}
        isSending={isSending}
      />
    </Box>
  )
}


DexTakerAlgoForm.propTypes = {
  sessionId: PropTypes.number.isRequired,
  dialogData: dexTakerDialogPropType,
  paramDexTaker: paramDexTakerPropType, // it is server's status
  generalInfo: PropTypes.object.isRequired,
  showToast: PropTypes.func.isRequired,
}

DexTakerAlgoForm.defaultProps = {
  algorithms: []
}

const mapStateToProps = (state, ownProps)=> {
  const dialogData = lodash.get(state, ['single', 'curPageData', 'dialogData'])
  const paramDexTaker = lodash.get(state, ['single', 'curPageData', 'paramDexTaker'])
  const generalInfo = lodash.get(state, ['single', 'curPageData', 'generalInfo'], {})
  return {
    dialogData,
    paramDexTaker,
    generalInfo,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return mapDispatch(dispatch)({ ...socketActions, ...singleActions})
}

export default connect(mapStateToProps, mapDispatchToProps)(DexTakerAlgoForm)

const autoCalStartPx = (stopPx, buySell) => {
  stopPx = parseFloat(stopPx)
  if (!isValidNumber(stopPx)) {
    return 0
  }
  if (buySell === SIDE_BUY) {
    return stopPx * (1 - AUTO_START_PX_WRT_STOP_PCT)
  } else if (buySell === SIDE_SELL) {
    return stopPx * (1 + AUTO_START_PX_WRT_STOP_PCT)
  } else {
    return 0
  }
}

const TakerAlgoFormInner = ({ formProps, isSending, active, onStopTaker, generalInfo }) => {
  const { values, handleSubmit, setFieldValue } = formProps
  const classes = useStyles()
  const { buySell,manuallySetStartPx, stopPx } = values
  const assetTokenA = lodash.get(generalInfo, ['tokenA', 'asset'], 'tokenA')
  const assetTokenB = lodash.get(generalInfo, ['tokenB', 'asset'], 'tokenB')

  let remainingAssetLabel = ''
  if (buySell === SIDE_BUY) {
    remainingAssetLabel = assetTokenB
  } else if (buySell === SIDE_SELL){
    remainingAssetLabel = assetTokenA
  }

  const stopPxChanged = useRefFieldsPrevValueChanged('stopPx', stopPx)
  const buySellChanged = useRefFieldsPrevValueChanged('buySell', buySell)
  const manuallySetStartPxChanged = useRefFieldsPrevValueChanged('manuallySetStartPx', manuallySetStartPx)
  useEffect(() => {
    if (!manuallySetStartPx) {
      if (stopPxChanged || buySellChanged || manuallySetStartPxChanged) {
        const newStartPx = autoCalStartPx(stopPx, buySell)
        setFieldValue('startPx', newStartPx)
      }
    }
  }, [stopPxChanged, buySellChanged, buySell, manuallySetStartPx, stopPx, setFieldValue, manuallySetStartPxChanged])

  let startStopButton
  if (active) {
    startStopButton = <Button
      className={classes.button}
      disabled={isSending}
      fullWidth
      size="small"
      onClick={onStopTaker}
      variant="contained"
    >
      Stop
    </Button>
  } else {
    let buttonLabel
    let colorStyle
    if (buySell === SIDE_BUY) {
      buttonLabel = "Preview & Buy"
      colorStyle = "green"
    } else if (buySell === SIDE_SELL) {
      buttonLabel = "Preview & Sell"
      colorStyle = "red"
    } else {
      buttonLabel = "Start"
    }
    startStopButton = <ColorButton
      className={classes.button}
      disabled={isSending}
      fullWidth
      size="small"
      type="submit"
      colorStyle={colorStyle}
    >
      {buttonLabel}
    </ColorButton>
  }

  return (
    <form onSubmit={handleSubmit}>
      <Card>
        <PiCardContent>
          <Grid container>
            <PiTwoColRowGrid>
              <Field
                name="orderQty"
                label="Order Quantity"
                InputProps={{
                  endAdornment: <PiInputAdornment position="end" label={assetTokenA}/>
                }}
                inputProps={{
                  type: 'number',
                  style: {
                    textAlign: 'right'
                  }
                }}
                component={FormInput}
              />
            </PiTwoColRowGrid>

            <PiTwoColRowGrid>
              <Field
                name="remainingQty"
                label="Remaining Asset"
                InputProps={{
                  endAdornment: <PiInputAdornment position="end" label={remainingAssetLabel}/>
                }}
                inputProps={{
                  type: 'number',
                  style: {
                    textAlign: 'right'
                  }
                }}
                component={FormInput}
              />
            </PiTwoColRowGrid>

            <PiGridItem
              xl={8}
              lg={8}
              md={8}
              sm={8}
              xs={8}
            >
              <Field
                name="startPx"
                label="Start Price"
                disabled={!manuallySetStartPx}
                inputProps={{
                  type: 'number',
                  style: {
                    textAlign: 'right'
                  }
                }}
                component={FormInput}
              />
            </PiGridItem>

            <PiGridItem
              xl={4}
              lg={4}
              md={4}
              sm={4}
              xs={4}
            >
              <Field
                name="manuallySetStartPx"
                label="Man. Set Start Px"
                component={FormCheckbox}
              />
            </PiGridItem>

            <PiTwoColRowGrid
              xl={8}
              lg={8}
              md={8}
              sm={8}
              xs={8}
            >
              <Field
                name="stopPx"
                label="Stop Price"
                // prec={pxPrec}
                inputProps={{
                  type: 'number',
                  style: {
                    textAlign: 'right'
                  }
                }}
                component={FormInput}
              />
            </PiTwoColRowGrid>

            <PiGridItem
              xl={4}
              lg={4}
              md={4}
              sm={4}
              xs={4}
            >
              {/* Empty grid just to ensure start and stop px align */}
            </PiGridItem>

            <PiTwoColRowGrid
              xs={12}
            >
              <Field
                name="buySell"
                label=""
                options={BUY_SELL_OPTIONS}
                component={FormRadio}
              />
            </PiTwoColRowGrid>

            <PiTwoColRowGrid
              xs={12}
            >
              <Field
                name="itvl"
                label="Interval"
                component={FormInterval}
              />
            </PiTwoColRowGrid>
          </Grid>

          <Box mt={2}>
            <Grid display="flex" container spacing={3}>
              <PiThreeColRowGrid>
                {startStopButton}
              </PiThreeColRowGrid>
            </Grid>
          </Box>
        </PiCardContent>
      </Card>
    </form>
  )
}
