import React from 'react'
import {
  BORROW_REPAY_OPTIONS,
  DEFAULT_ASSET_QTY_PRECISION, OPTION_BORROW, OPTION_REPAY,
} from '../../../protocolConstants'
import lodash from 'lodash'
import { Box, Button, Card, Grid } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert/Alert'
import PiCardContent from '../../../components/PiCardContent'
import { Field } from 'formik'
import FormInput from '../../../components/form/FormInput'
import PiInputAdornment from '../../../components/PiInputAdornment'
import FormRadio from '../../../components/form/FormRadio'
import FormSelect from '../../../components/form/FormSelect'

const DefaultGridItem = ({ children }) => {
  return (
    <Grid
      item
      xl={6}
      lg={6}
      sm={6}
      xs={6}
    >
      { children }
    </Grid>
  )
}

export const BorrowRepayFormInner = ({isSending, formProps, accNameAssetOptions, accNameAssetBorrowRepayLmt, accNameOptions }) => {
  const {values, handleSubmit } = formProps
  const {borrowRepay, accName, asset, qty} = values
  let buttonLabel = 'Submit'

  let borrowRepayLmt = null
  if (borrowRepay === OPTION_BORROW) {
    buttonLabel = 'Borrow Now'
    borrowRepayLmt = lodash.get(accNameAssetBorrowRepayLmt, [accName, asset, 'borrowLmt'], null)
  } else if (borrowRepay === OPTION_REPAY) {
    buttonLabel = 'Repay Now'
    borrowRepayLmt = lodash.get(accNameAssetBorrowRepayLmt, [accName, asset, 'repayLmt'], null)
  }
  const accNameAssetOptionFound = lodash.find(accNameAssetOptions, (ana) => {
    const { accName: curAccName } = ana
    return curAccName === accName
  })
  let assetOptions = []
  if (accNameAssetOptionFound) {
    assetOptions = lodash.get(accNameAssetOptionFound, 'assetOptions', [])
  }

  let warningMsg = ''
  const qtyFloat = parseFloat(qty)
  if (lodash.isNumber(borrowRepayLmt) && lodash.isNumber(qtyFloat) && borrowRepayLmt < qtyFloat){
    warningMsg = `Qty ${qtyFloat} is larger than limit ${borrowRepayLmt}. But feel free to proceed.`
  }

  return (
    <form onSubmit={handleSubmit}>
      <Box>
        <Card>
          <PiCardContent>
            <Box mx={2}>
              <Grid container spacing={3}>
                <DefaultGridItem>
                  <Field
                    name="accName"
                    label="Acc Name"
                    options={accNameOptions}
                    component={FormSelect}
                  />
                </DefaultGridItem>
                <DefaultGridItem>
                  <Field
                    name="asset"
                    label="Asset"
                    options={assetOptions}
                    component={FormSelect}
                  />
                </DefaultGridItem>

                <DefaultGridItem>
                  <Field
                    name="qty"
                    label="Quantity"
                    prec={DEFAULT_ASSET_QTY_PRECISION}
                    InputProps={{
                      endAdornment: <PiInputAdornment position="end" label={asset}/>,
                    }}
                    inputProps={{
                      type: 'number',
                      style: {
                        textAlign: 'right'
                      }
                    }}
                    component={FormInput}
                  />
                </DefaultGridItem>
                <DefaultGridItem>
                  {warningMsg === ''
                    ? null
                    : <Alert
                      icon={false}
                      variant="outlined"
                      severity="warning"
                    >
                      {warningMsg}
                    </Alert>
                  }
                </DefaultGridItem>

                <DefaultGridItem>
                  <Field
                    name="borrowRepay"
                    label=""
                    options={BORROW_REPAY_OPTIONS}
                    component={FormRadio}
                  />
                </DefaultGridItem>

                <DefaultGridItem>
                  <Button
                    fullWidth
                    size="small"
                    disabled={isSending}
                    color="primary"
                    variant="contained"
                    type="submit">
                    {buttonLabel}
                  </Button>
                </DefaultGridItem>
              </Grid>
            </Box>
          </PiCardContent>
        </Card>
      </Box>
    </form>
  )
}
