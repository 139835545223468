import PropTypes from 'prop-types'
import { ORDER_STATUS_CLOSED, ORDER_STATUS_OPEN, SIDE_BUY, SIDE_SELL } from '../protocolConstants'

/**
 * common propTypes
 */

const createFieldPropTypes = (extraFields = {}) => {
  return PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    name: PropTypes.string.isRequired,
    multiple: PropTypes.bool,
    checked: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    ...extraFields
  })
}
const formPropTypes = PropTypes.shape({
  touched: PropTypes.object,
  setFieldValue: PropTypes.func,
  errors: PropTypes.object, // TODO: double check the errors
  values: PropTypes.object
})

const metaPropTypes = PropTypes.shape({
  touched: PropTypes.bool,
  error: PropTypes.string,
})

const projectPropType = PropTypes.shape({
  exchangeAccounts: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    cat: PropTypes.string.isRequired,
    exchangeId: PropTypes.number.isRequired,
    pairs: PropTypes.arrayOf(PropTypes.string.isRequired) // may not need in dex
  })),
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired
})

const exchangePropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  chain: PropTypes.string,
  fee: PropTypes.arrayOf(PropTypes.number)
})

const optionPropType = PropTypes.shape({
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  label: PropTypes.string
})

const algorithmPropTypes = PropTypes.shape({
  id: PropTypes.number.isRequired,
  key: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
})

const accountPropTypes = PropTypes.shape({
  accId: PropTypes.number.isRequired,
  pairs: PropTypes.arrayOf(PropTypes.string)
})

const optionArrayPropType = PropTypes.arrayOf(optionPropType)

const projectArrayPropType = PropTypes.arrayOf(projectPropType)

const exchangeArrayPropType = PropTypes.arrayOf(exchangePropType)

const algorithmArrayPropType = PropTypes.arrayOf(algorithmPropTypes)

const sessionPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  algorithmId: PropTypes.number.isRequired,
  closed: PropTypes.bool.isRequired,
  data: PropTypes.any,
  instancerName: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  projectId: PropTypes.number.isRequired,
  status: PropTypes.string, // sometimes, server did not return this value
  userId: PropTypes.number.isRequired,

  // below are local info
  serverName: PropTypes.string.isRequired
})

const sessionArrayPropType = PropTypes.arrayOf(sessionPropType)

const orderbookItemPropType = PropTypes.shape({
  px: PropTypes.string.isRequired,
  qty: PropTypes.string.isRequired,
  cumQty: PropTypes.string,
  myOpenQty: PropTypes.string,
  myTotalQty: PropTypes.string,
  openInQuote: PropTypes.string
})

const orderbookPropType = PropTypes.shape({
  asks: PropTypes.arrayOf(orderbookItemPropType),
  bids: PropTypes.arrayOf(orderbookItemPropType)
})

const pairInfoPropTypes = PropTypes.shape({
  minNotional: PropTypes.string.isRequired,
  minOrderQty: PropTypes.string.isRequired,
  pair: PropTypes.string.isRequired,
  pxPrec: PropTypes.string.isRequired,
  qtyPrec: PropTypes.string.isRequired,
})

const longShortAccCfgPropTypes = PropTypes.shape({
  accCat: PropTypes.string.isRequired,
  accName: PropTypes.string.isRequired,
  ename: PropTypes.string.isRequired,
  pairs: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  tradablePairs: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  pairInfos: PropTypes.objectOf(pairInfoPropTypes).isRequired,
})

const icebergBasicInfoPropType = PropTypes.shape({
  serverTime: PropTypes.number.isRequired,
  // decimal
  avlbBase: PropTypes.string.isRequired,
  avlbQuote: PropTypes.string.isRequired,
  lockedBase: PropTypes.string.isRequired,
  lockedQuote: PropTypes.string.isRequired,
  bid1: PropTypes.string.isRequired,
  ask1: PropTypes.string.isRequired,
  spread: PropTypes.string.isRequired,
})

const icebergOrderPropType = PropTypes.shape({
  hiddenEid: PropTypes.string,
  hiddenOrderIdx: PropTypes.number.isRequired,
  numHiddenOrders: PropTypes.number.isRequired,
  perOrderHiddenQty: PropTypes.string.isRequired,
  px: PropTypes.string.isRequired,
  qty: PropTypes.string.isRequired,
  qtyOpen: PropTypes.string.isRequired,
  side: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  totalHiddenQty: PropTypes.string.isRequired,
  tpEid: PropTypes.string,
  tpQty: PropTypes.string.isRequired,
  tpPx: PropTypes.string,
  visibleEid: PropTypes.string,
  visibleQty: PropTypes.string.isRequired,
})

const icebergOrderArrayPropTypes = PropTypes.arrayOf(icebergOrderPropType)


const icebergDialogPropType = PropTypes.shape({
  dialogOpen: PropTypes.bool.isRequired,
  dialogType: PropTypes.oneOf(['executeIceberg', 'stopIceberg', 'showActiveOrders']),

  // data for executeIceberg
  previewData: PropTypes.object,
  previewSentData: PropTypes.object,
})

const longShortDialogPropType = PropTypes.shape({
  dialogOpen: PropTypes.bool.isRequired,
  dialogType: PropTypes.oneOf(['executeLongShort', 'stopLongShort']),

  // data for executeIceberg
  previewData: PropTypes.object,
  previewSentData: PropTypes.object,
})

const makerTakerDialogPropType = PropTypes.shape({
  dialogOpen: PropTypes.bool.isRequired,
  dialogType: PropTypes.oneOf(['executeTaker', 'stopTaker']),

  // data for executeIceberg
  previewData: PropTypes.object,
  previewSentData: PropTypes.object,
})

const tokenBuySellDialogPropType = PropTypes.shape({
  dialogOpen: PropTypes.bool.isRequired,
  dialogType: PropTypes.oneOf(['executeTokenBuySellAlgo', 'stopTokenBuySellAlgo']),
  // data for execute token buy sell algo
  previewData: PropTypes.object,
  previewSentData: PropTypes.object,
})

const spoofSniperDialogPropType = PropTypes.shape({
  dialogOpen: PropTypes.bool.isRequired,
  dialogType: PropTypes.oneOf(['executeSpoofSniperAlgo', 'stopSpoofSniperAlgo']),
  // data for execute token buy sell algo
  previewData: PropTypes.object,
  previewSentData: PropTypes.object,
})

const paramTakerPropType = PropTypes.shape({
  active: PropTypes.bool.isRequired,
  buySell: PropTypes.string.isRequired,
  itvl: PropTypes.number.isRequired,
  remainingQty: PropTypes.string.isRequired,
  orderQty: PropTypes.string.isRequired,
  startPx: PropTypes.string.isRequired,
  stopPx: PropTypes.string.isRequired,
})

const paramDexTakerPropType = PropTypes.shape({
  active: PropTypes.bool.isRequired,
  buySell: PropTypes.string.isRequired,
  itvl: PropTypes.number.isRequired,
  remainingQty: PropTypes.string.isRequired,
  orderQty: PropTypes.string.isRequired,
  startPx: PropTypes.string.isRequired,
  stopPx: PropTypes.string.isRequired,
})

const dexTakerDialogPropType = PropTypes.shape({
  dialogOpen: PropTypes.bool.isRequired,
  dialogType: PropTypes.oneOf(['executeDexTaker', 'stopDexTaker', 'showSpendingDetail', 'showBasicInfoDetail']),

  // data for executeIceberg
  previewData: PropTypes.object,
  previewSentData: PropTypes.object,
})

const paramTokenBuySellPropType = PropTypes.shape({
  active: PropTypes.bool.isRequired,
  orderLmtPx: PropTypes.string.isRequired,
  orderQty: PropTypes.string.isRequired,
  layerNum: PropTypes.number.isRequired,
  layerGapBasePoint: PropTypes.number.isRequired,
  buySell: PropTypes.string.isRequired,
  itvlMs: PropTypes.number.isRequired,
  remainingQty: PropTypes.string.isRequired,
  minSpread: PropTypes.string.isRequired,
})

const paramSpoofSniperPropType = PropTypes.shape({
  active: PropTypes.bool.isRequired,
})

const orderPropType = PropTypes.shape({
  accName: PropTypes.string.isRequired,
  cid: PropTypes.string.isRequired,
  eid: PropTypes.string.isRequired,
  ename: PropTypes.string.isRequired,
  pair: PropTypes.string.isRequired,
  px: PropTypes.string.isRequired, // decimal
  qty: PropTypes.string.isRequired, // decimal
  qtyFilled: PropTypes.string.isRequired, //decimal
  side: PropTypes.oneOf([SIDE_BUY, SIDE_SELL]),
  status: PropTypes.oneOf([ORDER_STATUS_OPEN, ORDER_STATUS_CLOSED]),
  tif: PropTypes.string.isRequired,
  time: PropTypes.number.isRequired,
})

const dexTxPropType = PropTypes.shape({
  accName: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  time: PropTypes.number.isRequired,
  txId: PropTypes.string.isRequired,
  isPending: PropTypes.bool.isRequired,
})

const orderArrayPropTypes = PropTypes.arrayOf(orderPropType)

const dexTxArrayPropTypes = PropTypes.arrayOf(dexTxPropType)

export {
  createFieldPropTypes,
  metaPropTypes,
  formPropTypes,
  projectPropType,
  projectArrayPropType,
  exchangePropType,
  exchangeArrayPropType,
  optionPropType,
  optionArrayPropType,
  sessionPropType,
  sessionArrayPropType,
  algorithmPropTypes,
  algorithmArrayPropType,
  orderbookItemPropType,
  orderbookPropType,
  pairInfoPropTypes,
  longShortDialogPropType,
  longShortAccCfgPropTypes,
  icebergOrderPropType,
  icebergOrderArrayPropTypes,
  icebergBasicInfoPropType,
  icebergDialogPropType,
  accountPropTypes,
  orderPropType,
  orderArrayPropTypes,
  dexTxPropType,
  dexTxArrayPropTypes,
  dexTakerDialogPropType,
  makerTakerDialogPropType,
  tokenBuySellDialogPropType,
  spoofSniperDialogPropType,
  paramTakerPropType,
  paramDexTakerPropType,
  paramTokenBuySellPropType,
  paramSpoofSniperPropType
}
