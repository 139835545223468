import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'


const useStyles = makeStyles((theme) => ({
  rowGrid: {
    paddingRight: theme.spacing(1) / 2,
    paddingLeft: theme.spacing(1) / 2
  }
}))


const PiThreeColRowGrid = ({children, ...rest}) => {
  const classes = useStyles()
  return (
    <Grid
      item
      xl={4}
      lg={4}
      md={4}
      sm={4}
      xs={4}
      className={classes.rowGrid}
      {...rest}
    >
      {children}
    </Grid>
  )
}


export default PiThreeColRowGrid
