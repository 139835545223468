import React from 'react'
import clsx from 'clsx'
import {
  Card,
  TableBody,
  TableRow,
  makeStyles,
} from '@material-ui/core'
import lodash from 'lodash'
import { getDexExplorerAddrUrl, mapDispatch, trimTxId } from '../../../../utils/utils'
import { actions as singleActions } from '../../../single/singleSlice'
import connect from 'react-redux/es/connect/connect'
import XscrollTable from '../../../ui/XscrollTable'
import PiTableCell from '../../../../components/PiTableCell'
import PiCardContent from '../../../../components/PiCardContent'
import { feeMtNumToLabel } from '../../../../protocolUtils'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  cardContent: {
    height: '100%'
  },
  tableCell: {
    borderBottom: "none"
  },
  refreshButton: {
    marginLeft: theme.spacing(2),
  }
}))

const SmallTableCell = ({children}) => {
  const classes = useStyles()
  return <PiTableCell
    className={classes.tableCell}
  >
    {children}
  </PiTableCell>
}

const DexTakerGeneralInfoView = ({ generalInfo }) => {
  const classes = useStyles()
  if (!generalInfo) {
    return null
  }
  const { accInfos = [], pathNodes = [], chain, ename, tokenGas = {} } = generalInfo

  const { asset: gasTokenAsset, addr: gasTokenAddr } = tokenGas

  const gasTokenNode = (
    <a href={getDexExplorerAddrUrl(chain, gasTokenAddr)}
       target='_blank'
       rel='noreferrer'
    >
          <pre>
            {`${gasTokenAsset} <${trimTxId(gasTokenAddr)}>`}
          </pre>
    </a>
  )

  const accInfoNodes = accInfos.map((acc) => {
    const { accAddr='', accName } = acc
    const key = `accinfo_${accName}`
    if (accAddr === '') {
      return <pre key={key}>
        {accName}
      </pre>
    } else {
      return  <a href={getDexExplorerAddrUrl(chain, accAddr)}
                 key={key}
                 target='_blank'
                 rel='noreferrer'
      >
          <pre>
            {`${accName} <${trimTxId(accAddr)}>`}
          </pre>
      </a>
    }
  })

  let pathInfoNodes
  if (pathNodes.length < 2) {
    pathInfoNodes = 'Error, address path length smaller than 2'
  } else {
    pathInfoNodes = []
    lodash.forEach(pathNodes, (pathNode, idx) => {
      const { addr, asset, feeMillionth } = pathNode
      pathInfoNodes.push(
        <a href={getDexExplorerAddrUrl(chain, addr)}
           key={`c_${chain}_addr_${addr}`}
           target='_blank'
           rel='noreferrer'
        >
          <pre>
            {`${asset} <${trimTxId(addr)}>`}
          </pre>
        </a>
      )
      if (idx !== pathNodes.length - 1) {
        pathInfoNodes.push(
          <u key={`fee_${idx}`}>
            <sup>
              {feeMtNumToLabel(feeMillionth)}
            </sup>
          </u>
        )
      }
    })
  }

  return (
    <Card className={clsx(classes.root)}
    >
      <PiCardContent className={classes.cardContent}>
        <XscrollTable>
          <TableBody>
            <TableRow
              key='chain'
              hover
            >
              <SmallTableCell>
                Chain
              </SmallTableCell>
              <SmallTableCell>
                {chain}
              </SmallTableCell>
            </TableRow>

            <TableRow key='token_gas'>
              <SmallTableCell>
                Token Gas
              </SmallTableCell>
              <SmallTableCell>
                {gasTokenNode}
              </SmallTableCell>
            </TableRow>

            <TableRow
              key='exchange'
              hover
            >
              <SmallTableCell>
                Exchange
              </SmallTableCell>
              <SmallTableCell>
                {ename}
              </SmallTableCell>
            </TableRow>

            <TableRow
              hover
              key='accounts'
            >
              <SmallTableCell>
                Accounts
              </SmallTableCell>
              <SmallTableCell>
                {accInfoNodes}
              </SmallTableCell>
            </TableRow>

            <TableRow
              hover
              key='path'
            >
              <SmallTableCell>
                Path
              </SmallTableCell>
              <SmallTableCell>
                {pathInfoNodes}
              </SmallTableCell>
            </TableRow>
          </TableBody>
        </XscrollTable>
      </PiCardContent>
    </Card>
  )
}

DexTakerGeneralInfoView.propTypes = {

}

DexTakerGeneralInfoView.defaultProps= {
}

const mapStateToProps = (state, ownProps)=> {
  const generalInfo = lodash.get(state, ['single', 'curPageData', 'generalInfo'])
  return {
    generalInfo
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return mapDispatch(dispatch)({...singleActions})
}

export default connect(mapStateToProps, mapDispatchToProps)(DexTakerGeneralInfoView)

