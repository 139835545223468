import PropTypes from 'prop-types'
import lodash from 'lodash'
import { mapDispatch } from '../../../../utils/utils'
import connect from 'react-redux/es/connect/connect'
import LoggerView from '../../../ui/LoggerView'


const LongShortLoggerView = ({logs}) => {
  return (
    <LoggerView logs={logs}/>
  )
}


LongShortLoggerView.propTypes = {
  logs: PropTypes.arrayOf(PropTypes.shape({
    method: PropTypes.string.isRequired,
    data: PropTypes.array.isRequired
  }))
}

LongShortLoggerView.defaultProps= {

}

const mapStateToProps = (state, ownProps)=> {
  const logs = lodash.get(state, ['single', 'curPageData', 'logs'])
  return {
    logs
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return mapDispatch(dispatch)({})
}

export default connect(mapStateToProps, mapDispatchToProps)(LongShortLoggerView)
