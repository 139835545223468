import React from 'react'
import {makeStyles, Tooltip, Typography} from '@material-ui/core'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import lodash from "lodash"
import PropTypes from "prop-types"

const useStyles = makeStyles((theme) => ({
  tooltipBase: {
    paddingRight: theme.spacing(1) / 2,
    paddingLeft: theme.spacing(1) / 2
  }
}))

const PiInfoTip = ({children, title, ...rest}) => {
  const classes = useStyles()

  let titleNode
  if (lodash.isString(title)) {
    titleNode = <Typography>
      {title}
    </Typography>
  } else {
    titleNode = title
  }
  return (
    <Tooltip
      arrow
      title={titleNode}
      className={classes.rowGrid}
      {...rest}
    >
      <InfoOutlinedIcon />
    </Tooltip>
  )
}

PiInfoTip.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.node.isRequired,
    PropTypes.arrayOf(PropTypes.node.isRequired)
  ]).isRequired
}

export default PiInfoTip
