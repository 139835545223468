import React from 'react'
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core'
import Page from '../../../components/Page'
import AccountListResults from './AccountListResults'
import AccountListToolbar from './AccountListToolbar'
import lodash from 'lodash'
import PropTypes from 'prop-types'
import {isUserAdmin, mapDispatch} from '../../../utils/utils'
import { actions as socketActions } from '../../socket/socketSlice'
import {actions as singleActions} from '../../single/singleSlice'
import connect from 'react-redux/es/connect/connect'
import FullscreenDialog from '../../../components/FullscreenDialog'
import { useRunOnce } from '../../../utils/hooks'
import EditAccountListForm from "./EditAccountListForm"
import {PATH_404} from "../../../constants"
import {Navigate} from "react-router-dom"

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}))

const AccountListView = ({ roles, clearCurPageData, selectedAccountIdMap, setCurPageData, showToast, editAccountOpen } ) => {
  const classes = useStyles()
  useRunOnce(() => {
    clearCurPageData()
  })
  const isAdm = isUserAdmin(roles)
  if (!isAdm) {
    return <Navigate to={PATH_404}/>
  }

  const handleNewSessionDialogClose = () => {
    const editAccountOpen = false
    setCurPageData({
      editAccountOpen
    })
  }

  const handleEditAccountsDialogOpen = () => {
    const selectedOneAccAtLeast = lodash.some(selectedAccountIdMap, (val) => {
      if (val === true) {
        return true
      }
    })
    if (!selectedOneAccAtLeast) {
      showToast('Please select at least one account to edit...', 'warning')
      return
    }
    const editAccountOpen = true
    setCurPageData({
      editAccountOpen
    })
  }

  const setAccountSearchText = (accountSearchText) => {
    setCurPageData({
      accountSearchText
    })
  }

  const setFilteredProjectId = (filteredProjectId) => {
    setCurPageData({
      filteredProjectId
    })
  }

  const setFilteredExchangeId = (filteredExchangeId) => {
    setCurPageData({
      filteredExchangeId
    })
  }

  const setShowSelectedChecked = (showSelectedChecked) => {
    setCurPageData({
      showSelectedChecked
    })
  }

  return (
    <Page
      className={classes.root}
      title="Accounts"
    >
      <Container maxWidth={false}>
        <AccountListToolbar
          setAccountSearchText= {setAccountSearchText}
          setFilteredProjectId={setFilteredProjectId}
          setFilteredExchangeId={setFilteredExchangeId}
          setShowSelectedChecked={setShowSelectedChecked}
          onAddSession={handleEditAccountsDialogOpen}
        />
        <Box mt={3}>
          <AccountListResults
          />
        </Box>
        <FullscreenDialog
          dialogOpen={editAccountOpen}
          dialogTitle='Edit Accounts'
          dialogOnCancel={handleNewSessionDialogClose}
        >
          <EditAccountListForm
          />
        </FullscreenDialog>
      </Container>
    </Page>
  )
}


AccountListView.propTypes = {
  clearCurPageData: PropTypes.func.isRequired,
  setCurPageData: PropTypes.func.isRequired,
  editAccountOpen: PropTypes.bool.isRequired,
  selectedAccountIdMap: PropTypes.object.isRequired,
  showToast: PropTypes.func.isRequired,
}

AccountListView.defaultProps = {
  editAccountOpen: false,
  selectedAccountIdMap: {}
}

const mapStateToProps = (state, ownProps)=> {
  const roles = lodash.get(state, ['single', 'profile', 'roles'])
  const editAccountOpen = lodash.get(state, ['single', 'curPageData', 'editAccountOpen'], false)
  const selectedAccountIdMap = lodash.get(state, ['single', 'curPageData', 'selectedAccountIdMap'], {})
  return {
    roles,
    editAccountOpen,
    selectedAccountIdMap
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return mapDispatch(dispatch)({...socketActions, ...singleActions})
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountListView)
