import React, { useCallback, useEffect } from 'react'
import MsgAlertView from '../../ui/MsgAlertView'
import lodash from 'lodash'
import MakerTakerAlgoView from './makerTakerAlgoView'
import DexTakerAlgoView from './dexTakerAlgoView'
import IcebergAlgoView from './icebergAlgoView'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'
import {
  accountPropTypes,
  algorithmArrayPropType,
  projectPropType
} from '../../../utils/propTypesUtils'
import { useRefFieldsPrevious, useStateReqView } from '../../../utils/hooks'
import {
  ALGO_KEY_LONG_SHORT,
  ALGO_KEY_ICEBERG,
  ALGO_KEY_DEX_TAKER,
  ALGO_KEY_MAKER_TAKER,
  ALGO_KEY_SPOOF_SNIPER,
  ALGO_KEY_TOKEN_BUY_SELL,
  CMD_SUBSCRIBE_TO_SESSION,
} from '../../../protocolConstants'
import { mapDispatch } from '../../../utils/utils'
import connect from 'react-redux/es/connect/connect'
import logger from '../../../utils/logger'
import PiBackdrop from '../../../components/PiBackdrop'
import { makeStyles } from '@material-ui/core'
import TokenBuySellAlgoView from './tokenBuySellAlgoView'
import SpoofSniperAlgoView from './spoofSniperAlgoView'
import LongShortAlgoView from './longShortAlgoView'


const useStyles = makeStyles((theme) => ({
  outDiv: {
    height: '100%'
  },
}))

/**
 * The view will subscribe to an algo and then show the content
 */
const SubscribeAlgoView = ({ algorithm, socketStatus, sessionId, project, sessionAccounts }) => {
  const classes = useStyles()
  const genData = useCallback(() => ({
      cmd: CMD_SUBSCRIBE_TO_SESSION,
      data: {
        unsubscribeFromOtherSessions: true,
        sessionId
      }
  }), [sessionId])

  let {
    loadingView,
    getStateField,
    setStateField,
    isSuccessful,
    sendReq,
  } = useStateReqView(genData())
  const prevSocketStatus = useRefFieldsPrevious('socketStatus', socketStatus)
  const wasPrevSuccessful = getStateField('wasPrevSuccessful', false)
  useEffect(() => {
    // if was successfully
    if (!wasPrevSuccessful) {
      if (isSuccessful) {
        setStateField('wasPrevSuccessful', true)
      }
    }

    // check socket status
    // the status change from failure to connected, sent request to subscribe again
    if (socketStatus === WebSocket.OPEN && prevSocketStatus !== WebSocket.OPEN) {
      // just connected, need to send the request
      logger.debug('SubscribeAlgoView subscribe now')
      sendReq(genData())
    }
  }, [wasPrevSuccessful, setStateField, isSuccessful, sendReq, prevSocketStatus, socketStatus, genData])

  if(loadingView && !wasPrevSuccessful) {
    // never successful, load the loading view directly
    logger.debug('SubscribeAlgoView Showing loading view only')
    return loadingView
  }

  let contentView = null
  const algorithmKey = lodash.get(algorithm, 'key')
  if(algorithmKey === ALGO_KEY_MAKER_TAKER) {
    contentView = <MakerTakerAlgoView
      sessionAccounts={sessionAccounts}
      algorithm={algorithm}
      sessionId={sessionId}
      project={project}
    />
  } else if (algorithmKey === ALGO_KEY_TOKEN_BUY_SELL) {
    contentView = <TokenBuySellAlgoView
      sessionAccounts={sessionAccounts}
      algorithm={algorithm}
      sessionId={sessionId}
      project={project}
    />
  } else if (algorithmKey === ALGO_KEY_ICEBERG) {
    contentView = <IcebergAlgoView
      sessionAccounts={sessionAccounts}
      sessionId={sessionId}
      algorithm={algorithm}
      project={project}
    />
  } else if (algorithmKey === ALGO_KEY_DEX_TAKER) {
    contentView = <DexTakerAlgoView
      sessionAccounts={sessionAccounts}
      sessionId={sessionId}
      algorithm={algorithm}
      project={project}
    />
  } else if (algorithmKey === ALGO_KEY_SPOOF_SNIPER) {
    contentView = <SpoofSniperAlgoView
      sessionAccounts={sessionAccounts}
      sessionId={sessionId}
      algorithm={algorithm}
      project={project}
    />
  } else if (algorithmKey === ALGO_KEY_LONG_SHORT) {
    contentView = <LongShortAlgoView
      sessionAccounts={sessionAccounts}
      sessionId={sessionId}
      algorithm={algorithm}
      project={project}
    />
  } else {
    // some of algo type, like asset_list, do not need to subscribe,
    // hence won't be rendered in this view
    contentView = <MsgAlertView
      msg={`Unknown Algorithm Type ${algorithmKey}`}
    />
  }

  const showLoadingView = Boolean(loadingView)
  logger.debug(`subscribeAlgoView showLoadingView ${showLoadingView}`)
  return <Box className={classes.outDiv}>
    {contentView}
    <PiBackdrop open={showLoadingView}>
      {loadingView}
    </PiBackdrop>
  </Box>
}

SubscribeAlgoView.propTypes = {
  algorithms: algorithmArrayPropType,
  sessionId: PropTypes.number.isRequired,
  sessionAccounts: PropTypes.arrayOf(accountPropTypes),
  project: projectPropType.isRequired,
  socketStatus: PropTypes.number.isRequired,
}

SubscribeAlgoView.defaultProps = {
  algorithms: []
}

const mapStateToProps = (state, ownProps)=> {
  const socketStatus = lodash.get(state, ['socket', 'socketStatus'])
  return {
    socketStatus
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return mapDispatch(dispatch)({})
}

export default connect(mapStateToProps, mapDispatchToProps)(SubscribeAlgoView)


