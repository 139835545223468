import React from 'react'
import clsx from 'clsx'
import lodash from 'lodash'
import PropTypes from 'prop-types'
import {
  makeStyles,
  Box
} from '@material-ui/core'
import DexAccountFields from './DexAccountFields'
import { exchangeArrayPropType, projectArrayPropType } from '../../../utils/propTypesUtils'
import { mapDispatch } from '../../../utils/utils'
import connect from 'react-redux/es/connect/connect'
import { ACCOUNT_CAT_DEX } from '../../../protocolConstants'
import { feeMtNumToLabel } from '../../../protocolUtils'


const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(3),
  }
}))

/**
 * A simple trading account, used by several algos, such as iceberg, and maker
 */
const DexSimpleTradingAccountForm = ({ className, exchanges, dexAssets, fieldNamePrefix, accountLabel, projectId, projects, setFieldValue, values }) => {
  const classes = useStyles()
  const exchangeIdFieldName = `${fieldNamePrefix}.exId` // it is a string
  const accountIdsFieldName = `${fieldNamePrefix}.accIds` // it is a list
  const pathFieldName = `${fieldNamePrefix}.dexRawPath`

  const project = lodash.find(projects, (p) => {
    return p['id'] === projectId
  })

  if(project === undefined) {
    return null
  }

  let { exchangeAccounts = [] } = project

  exchangeAccounts = lodash.filter(exchangeAccounts, (ea) => {
    const { cat } = ea
    return cat === ACCOUNT_CAT_DEX
  })
  const availableExchangeIds = new Set()
  lodash.forEach(exchangeAccounts, (exAcc) => {
    const { exchangeId } = exAcc
    availableExchangeIds.add(exchangeId)
  })

  // only show the exchanges
  exchanges = lodash.filter(exchanges, (ex) => {
    const { id } = ex
    return availableExchangeIds.has(id)
  })

  const exchangeIdOptions = lodash.map(exchanges, (ex) => {
    const { id, name } = ex
    return {
      value: id,
      label: name,
    }
  })

  const selectedExchangeId = lodash.get(values, exchangeIdFieldName)
  const selectedExchange = lodash.find(exchanges, (ex) => {
    const { id } = ex
    return selectedExchangeId === id
  })
  let assOptions = []
  let feeOptions = []
  let accountIdOptions = []
  if (selectedExchange !== undefined) {
    const { chain, feesMillionth, id } = selectedExchange
    const chainAssets = lodash.get(dexAssets, chain)
    assOptions = lodash.map(chainAssets, (ass, addr) => {
      return {
        value: addr,
        label : `${ass} on ${chain} <${addr}>`
      }
    })
    feeOptions = lodash.map(feesMillionth, (feeMt) => {
      return {
        value: feeMt,
        label: feeMtNumToLabel(feeMt),
      }
    })

    exchangeAccounts = lodash.filter(exchangeAccounts, (ea) => {
      const { exchangeId } = ea
      return exchangeId === id
    })
    accountIdOptions = lodash.map(exchangeAccounts, (ex) => {
      return {
        value: ex['id'],
        label: ex['name']
      }
    })
  }
  // }
  return (
    <Box mt={3} className={clsx(classes.root, className)}>
      <DexAccountFields
        accountLabel={accountLabel}
        exchangeIdFieldName={exchangeIdFieldName}
        accountIdsFieldName={accountIdsFieldName}
        pathFieldName={pathFieldName}
        accountIdOptions={accountIdOptions}
        exchangeIdOptions={exchangeIdOptions}
        feeOptions={feeOptions}
        assOptions={assOptions}
        values={values}
        setFieldValue={setFieldValue}
      />
    </Box>
  )
}

DexSimpleTradingAccountForm.propTypes = {
  projectId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.oneOf([''])
  ]),
  className: PropTypes.string,
  values: PropTypes.object.isRequired,
  projects: projectArrayPropType,
  exchanges: exchangeArrayPropType,
  dexAssets: PropTypes.object.isRequired,
  fieldNamePrefix: PropTypes.string.isRequired,
  accountLabel: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
}

DexSimpleTradingAccountForm.defaultProps = {
  accountLabel: 'Dex Trading Account'
}

const mapStateToProps = (state, ownProps)=> {
  const projects = lodash.get(state, ['single', 'profile', 'projects'])
  const exchanges = lodash.get(state, ['single', 'profile', 'exchanges'])
  const dexAssets = lodash.get(state, ['single', 'profile', 'dexAssets'])
  return {
    projects,
    exchanges,
    dexAssets,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return mapDispatch(dispatch)({})
}

export default connect(mapStateToProps, mapDispatchToProps)(DexSimpleTradingAccountForm)

