import lodash from 'lodash'
import { Typography } from '@material-ui/core'
import React from 'react'

const ValueErrorText = ({valueErrors}) => {
  if (lodash.isEmpty(valueErrors)) {
    return null
  }
  return (
    <div>
      {lodash.map(valueErrors, (val, key) => {
        return (
          <Typography color='error' key={key}>
            {`${key}: ${val}`}
          </Typography>
        )
      })}
    </div>
  )
}

export default ValueErrorText
