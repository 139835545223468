import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles, Typography
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import Logo from '../../components/Logo'
import { mapDispatch } from '../../utils/utils'
import connect from 'react-redux/es/connect/connect'
import lodash from 'lodash'

const useStyles = makeStyles((theme) => ({
  root: {},
  logo: {
    marginRight: theme.spacing(10),
    marginLeft: theme.spacing(3),
  },
  title: {
    'wordWrap': 'break-word'
  }
}))

const TopBar = (props) => {
  const { className, onMobileNavOpen, toolbarTitle, ...rest } = props
  const classes = useStyles()

  return (
    <AppBar
      className={clsx(classes.root, className)}
      elevation={0}
      {...rest}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          onClick={onMobileNavOpen}
        >
          <MenuIcon />
        </IconButton>
        <RouterLink to="/" className={classes.logo}>
          <Logo />
        </RouterLink>
        <Typography noWrap variant="h6" className={classes.title}>
          {toolbarTitle}
        </Typography>
        <Box flexGrow={1} />
        <Hidden mdDown>
          <IconButton color="inherit">

          </IconButton>
        </Hidden>

        <IconButton color="inherit">

        </IconButton>
      </Toolbar>
    </AppBar>
  )
}

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
  toolbarTitle: PropTypes.string.isRequired
}

TopBar.defaultProps = {
  toolbarTitle: ''
}

const mapStateToProps = (state, ownProps)=> {
  const toolbarTitle = lodash.get(state, ['single', 'curPageData', 'toolbarTitle'])
  return {
    toolbarTitle,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return mapDispatch(dispatch)({})
}

export default connect(mapStateToProps, mapDispatchToProps)(TopBar)

