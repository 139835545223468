import React, { useCallback, useEffect } from 'react'
import * as Yup from 'yup'
import { Field, Formik, useFormikContext } from 'formik'
import {
  Box,
  makeStyles,
  Card,
  Grid
} from '@material-ui/core'
import { isValidNumber, mapDispatch } from '../../../../utils/utils'
import { actions as socketActions } from '../../../socket/socketSlice'
import { actions as singleActions } from '../../../single/singleSlice'
import connect from 'react-redux/es/connect/connect'
import PropTypes from 'prop-types'
import lodash from 'lodash'
import FormInput from '../../../../components/form/FormInput'
import ColorButton from '../../../../components/ColorButton'
import { useDebounceCall, useRefFieldsPrevious, useStateReq } from '../../../../utils/hooks'
import {
  BUY_SELL_OPTIONS,
  CMD_DEX_TAKER_GET_AMOUNT_B,
  CMD_DEX_TAKER_PLACE_ORDER,
  SIDE_BUY,
  SIDE_SELL
} from '../../../../protocolConstants'
import FormRadio from '../../../../components/form/FormRadio'
import PiInputAdornment from '../../../../components/PiInputAdornment'
import PiCardContent from '../../../../components/PiCardContent'
import logger from '../../../../utils/logger'
import Button from '@material-ui/core/Button'
import FormSelect from '../../../../components/form/FormSelect'
import Typography from '@material-ui/core/Typography'
import { Decimal } from 'decimal.js'


const useStyles = makeStyles((theme) => ({
  formBox: {
  },
  bidAskButton: {
    color: "#000000",
    height: 'auto',
  },
  button: {
    height: "100%"
  }
}))

const SUBMIT_TYPE_UPDATE_TOTAL = 'updateTotal'
const SUBMIT_TYPE_PLACE_ORDER = 'placeOrder'


const DexPlaceOrderForm = ({ sessionId, generalInfo, showToast }) => {
  const classes = useStyles()
  const {
    sendReq,
    isSending,
    isSuccessful,
    receivedData,
    isError,
    clearRId,
  } = useStateReq()

  const accInfos = lodash.get(generalInfo, 'accInfos', [])
  const tokenAAsset = lodash.get(generalInfo, ['tokenA', 'asset'], 'tokenA')
  const tokenBAsset = lodash.get(generalInfo, ['tokenB', 'asset'], 'tokenB')
  const accountOptions = lodash.map(accInfos, (acInfo) => {
    const { accName } = acInfo
    return {
      value: accName,
      label: accName,
    }
  })
  const onHandleSubmit = (values) => {
    const { submitType } = values
    if (submitType === SUBMIT_TYPE_UPDATE_TOTAL) {
      sendReq({
        cmd: CMD_DEX_TAKER_GET_AMOUNT_B,
        isRelay: true,
        data: {
          sessionId,
          params: {
            ...values
          }
        }
      })
    } else if (submitType === SUBMIT_TYPE_PLACE_ORDER) {
      sendReq({
        cmd: CMD_DEX_TAKER_PLACE_ORDER,
        isRelay: true,
        data: {
          sessionId,
          params: {
            ...values
          }
        }
      })
    } else {
      logger.error(`submitType is invalid ${submitType}`)
    }
  }
  return (
    <Box
      className={classes.formBox}
      display="flex"
      flexDirection="column"
      justifyContent="center"
    >
      <Formik
        initialValues={{
          "buySell": SIDE_BUY,
          'submitType': ''
        }}
        validationSchema={props =>  Yup.lazy(values => {
          const { submitType } = values
          return Yup.object().shape({
            qty: Yup.number().required('Qty is required').moreThan(0, "Must be positive"),
            total: submitType === SUBMIT_TYPE_UPDATE_TOTAL ? Yup.number() : Yup.number().required('Total is required').moreThan(0, "Must be positive"),
            buySell: Yup.string().required('Buy sell is required'),
            accName: Yup.string().required('Account Name is required')
          })
        })}
        onSubmit={onHandleSubmit}
      >
        {(formProps) => {
          return (
            <DexPlaceOrderFormInner
              showToast={showToast}
              isSending={isSending}
              isError={isError}
              isSuccessful={isSuccessful}
              receivedData={receivedData}
              accountOptions={accountOptions}
              clearRId={clearRId}
              tokenAAsset={tokenAAsset}
              tokenBAsset={tokenBAsset}
              formProps={formProps}
            />
          )
        }}
      </Formik>
    </Box>
  )
}


DexPlaceOrderForm.propTypes = {
  sessionId: PropTypes.number.isRequired,
  generalInfo: PropTypes.shape({
    accInfos: PropTypes.arrayOf(PropTypes.shape({
      accAddr: PropTypes.string.isRequired,
      accName: PropTypes.string.isRequired,
    }))
  }),
  showToast: PropTypes.func.isRequired
}

DexPlaceOrderForm.defaultProps = {
  algorithms: []
}

const mapStateToProps = (state, ownProps)=> {
  const generalInfo = lodash.get(state, ['single', 'curPageData', 'generalInfo'])
  return {
    generalInfo
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return mapDispatch(dispatch)({...socketActions, ...singleActions})
}

export default connect(mapStateToProps, mapDispatchToProps)(DexPlaceOrderForm)


const DefaultGridItem = ({ children, ...rest }) => {
  return (
    <Grid
      item
      xl={6}
      lg={6}
      sm={6}
      xs={6}
      {...rest}
    >
      { children }
    </Grid>
  )
}

const DexPlaceOrderFormInner = ({ isSending, showToast, accountOptions, formProps, tokenAAsset, tokenBAsset, isError, isSuccessful, receivedData, clearRId }) => {
  const classes = useStyles()
  const { values, handleSubmit, setFieldValue, setErrors } = formProps
  const { buySell } = values
  const { submitForm } = useFormikContext()
  let buttonLabel = 'Place Now'
  let colorStyle = ''
  if (buySell === SIDE_BUY) {
    colorStyle = 'green'
    buttonLabel = 'Buy Now'
  } else if (buySell === SIDE_SELL) {
    colorStyle = 'red'
    buttonLabel = 'Sell Now'
  }

  const updateTotalNow = useDebounceCall(() => {
    setFieldValue('total', '') // clear the total
    setFieldValue('submitType', SUBMIT_TYPE_UPDATE_TOTAL)
    submitForm()
  }, 1500)
  //
  /**
   * either qty or total changed,
   * we need to update 1) ExactQty or Exact Total, 2) set the other one to be empty
   */
  const postChangeField = useCallback((fieldName, fieldNewValue) => {
    // may need to calculate accordingly
    const newValues = {
      ...values,
      [fieldName]: fieldNewValue
    }
    let { qty } = newValues
    switch (fieldName) {
      case 'qty':
      case 'buySell':
        qty = parseFloat(qty)
        if(isValidNumber(qty)){
          updateTotalNow()
        }
        break
      default:
        logger.error(`Unrecognized field name ${fieldName}`)
    }
  }, [values, updateTotalNow])

  const prevIsSending  = useRefFieldsPrevious('isSending', isSending)

  useEffect(() => {
    if (prevIsSending && !isSending && isSuccessful) {
      const cmd = lodash.get(receivedData, 'cmd')
      const data = lodash.get(receivedData, 'data')
      if (cmd === CMD_DEX_TAKER_GET_AMOUNT_B) {
        if (isSuccessful){
          lodash.map(data, (v, k) => {
            const curVal = lodash.get(values, k)
            if (curVal !== v) {
              logger.info(`setFieldValue ${k}, ${v}`)
              setFieldValue(k, v)
            }
          })
        } else if (isError) {
          const formErrors = lodash.get(receivedData, ['data', 'formErrors'])
          setErrors(formErrors)
        }
        clearRId()
      } else if (cmd === CMD_DEX_TAKER_PLACE_ORDER) {
        if (isSuccessful) {
          showToast('Order is placed. Tx is still pending. Please check in Tx history or Explorer', 'info')
        }
        clearRId()
      }
    }
  }, [clearRId, isError, isSending, setFieldValue, values, isSuccessful, prevIsSending, receivedData, setErrors, showToast])

  let pxInfo = ''
  try {
    const qty = new Decimal(lodash.get(values, 'qty'))
    const total = new Decimal(lodash.get(values, 'total'))
    const zero = new Decimal(0)
    if (!qty.eq(zero) && !total.eq(zero)) {
      const px = total.div(qty)
      pxInfo = `1 ${tokenAAsset} = ${px} ${tokenBAsset}`
    }
  } catch (e) {}


  return (
    <form onSubmit={handleSubmit}>
      <Box>
        <Card>
          <PiCardContent>
            <Box mx={2}>
              <Grid container spacing={3}>
                <DefaultGridItem
                  xl={12}
                  lg={12}
                  sm={12}
                  xs={12}
                >
                  <Field
                    name="accName"
                    label="Account Name"
                    options={accountOptions}
                    component={FormSelect}
                  />
                </DefaultGridItem>
                <DefaultGridItem>
                  <Field
                    name="qty"
                    label="Quantity"
                    postChange={postChangeField}
                    InputProps={{
                      endAdornment: <PiInputAdornment position="end" label={tokenAAsset}/>,
                    }}
                    inputProps = {{
                      type: "number",
                      style: {
                        textAlign: 'right'
                      }
                    }}
                    component={FormInput}
                  />
                </DefaultGridItem>

                <DefaultGridItem>
                  <Field
                    name="total"
                    label="Total"
                    InputProps={{
                      endAdornment: <PiInputAdornment position="end" label={tokenBAsset}/>,
                    }}
                    inputProps = {{
                      type: "number",
                      style: {
                        textAlign: 'right'
                      }
                    }}
                    component={FormInput}
                  />
                </DefaultGridItem>

                <DefaultGridItem>
                  <Field
                    name="buySell"
                    label=""
                    postChange={postChangeField}
                    options={BUY_SELL_OPTIONS}
                    component={FormRadio}
                  />
                </DefaultGridItem>

                <DefaultGridItem>
                  <Typography>
                    {pxInfo}
                  </Typography>
                </DefaultGridItem>

                <DefaultGridItem>
                  <Button
                    className={classes.button}
                    disabled={isSending}
                    fullWidth
                    size='small'
                    variant='contained'
                    type='submit'
                    onClick={() => { setFieldValue('submitType', SUBMIT_TYPE_UPDATE_TOTAL)}}
                  >
                    Update Total
                  </Button>
                </DefaultGridItem>

                <DefaultGridItem>
                  <ColorButton
                    fullWidth
                    size="small"
                    disabled={isSending}
                    onClick={() => { setFieldValue('submitType', SUBMIT_TYPE_PLACE_ORDER )}}
                    type="submit"
                    colorStyle={colorStyle}>
                    {buttonLabel}
                  </ColorButton>
                </DefaultGridItem>
              </Grid>
            </Box>
          </PiCardContent>
        </Card>
      </Box>
    </form>
  )
}
