import PiTwoColRowGrid from '../../../../../components/PiTwoColRowGrid'
import React from 'react'

const TargetItem = ({gridSize = 5, children, rest}) => {
  const keys = [
    'xl', 'lg', 'md', 'sm', 'xs'
  ]
  const rows = {}
  // if it is lg => multiple by 2
  keys.forEach((k) => {
    if (k === 'lg' || k === 'sm' || k === 'xs') {
      rows[k] = gridSize * 2
    } else {
      rows[k] = gridSize
    }
  })
  return (
    <PiTwoColRowGrid
      item
      {...rows}
      {...rest}
    >
      {children}
    </PiTwoColRowGrid>
  )
}

export default TargetItem
