import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import Slide from '@material-ui/core/Slide'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const FullScreenDialog = (props) =>  {
  const classes = useStyles()

  const { dialogOpen, dialogOnCancel, dialogTitle, children } = props

  const handleClose = () => {
    dialogOnCancel()
  }

  return (
    <div>
      <Dialog fullScreen open={dialogOpen} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {dialogTitle}
            </Typography>
          </Toolbar>
        </AppBar>
        {children}
      </Dialog>
    </div>
  )
}


FullScreenDialog.propTypes = {
  dialogOpen: PropTypes.bool.isRequired,
  dialogOnCancel: PropTypes.func.isRequired,
  dialogTitle: PropTypes.string
}

FullScreenDialog.defaultProps = {
  dialogOpen: false,
  dialogTitle: ''
}

export default FullScreenDialog
