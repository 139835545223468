import React, {useCallback, useContext} from 'react'
import lodash from 'lodash'
import {
  Box,
  Button,
  Card,
  makeStyles,
  TableBody,
  TableHead,
  TableRow, Typography
} from '@material-ui/core'
import PiCardContent from '../../../components/PiCardContent'
import { Field } from 'formik'
import FormInput from '../../../components/form/FormInput'
import XscrollTable from "../../ui/XscrollTable"
import {PiContext} from "../../../utils/piContext"
import PiTableCell from "../../../components/PiTableCell"
import clsx from "clsx"
import logger from "../../../utils/logger"

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(3),
  },
  cellMobile: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  cell: {

  },
  pairCell: {
    width: '100%',
  },
  mergePairsButton: {
    marginBottom: theme.spacing(1)
  },
  greenSpan: {
    color: 'green'
  },
  greySpan: {
    color: 'grey'
  },
  redSpan: {
    color: 'red'
  }
}))

const AccountCell = ({ children, isPairCell, className, ...rest }) => {
  const { isMobile } = useContext(PiContext)
  const classes = useStyles()

  const cellClasses = []
  if (isMobile) {
    cellClasses.push(classes.cellMobile)
  } else {
    cellClasses.push(classes.cell)
  }

  if (isPairCell) {
    cellClasses.push(classes.pairCell)
  }
  cellClasses.push(className)
  return (
    <PiTableCell
      className={clsx(...cellClasses)}
      size="medium"
      disablePiStyle
      {...rest}
    >
      {children}
    </PiTableCell>
  )
}

const PairsUpdateInfo = ({newPairsString, initPairsString}) => {
  const classes = useStyles()
  const newPairs = new Set(lodash.split(newPairsString.replaceAll(' ', ''), ','))
  const initPairs = new Set(lodash.split(initPairsString.replaceAll(' ', ''), ','))
  const addedPairs = []
  const removedPairs = []
  newPairs.forEach((v) => {
    if (v === '') {
      return
    }
    if (!initPairs.has(v)) {
      addedPairs.push(v)
    }
  })

  initPairs.forEach((v) => {
    if (v === '') {
      return
    }
    if (!newPairs.has(v)) {
      removedPairs.push(v)
    }
  })

  const added = addedPairs.length !== 0
  const removed = removedPairs.length !== 0

  return <Typography
    className={classes.greySpan}
    variant="body1"
  >
    {added ? 'Added': ''}
    {added ? <span className={classes.greenSpan}> {addedPairs.join(', ')}</span> : ''}
    {added ? '. ': ''}
    {removed ? 'Removed': ''}
    {removed ? <span className={classes.redSpan}> {removedPairs.join(', ')}</span> : ''}
  </Typography>
}


const EditAccountListFormInner = ({isSending, formProps, accIdToExId = {} }) => {
  const classes = useStyles()
  const {values, handleSubmit, initialValues, setFieldValue} = formProps
  const { exchangeAccounts } = values
  const { exchangeAccounts: exchangeAccountsInit } = initialValues



  // disable the enter key,
  // i.e. disable it from submit form by hit enter, also disable to input a line breaker
  const onKeyDown = (keyEvent) => {
    if (keyEvent.key === 'Enter') {
      keyEvent.preventDefault()
    }
  }

  const onMergePairs = useCallback((e) => {
    const exIdToPairsMap = {}
    lodash.forEach(exchangeAccounts, (ea) => {
      const { pairsString = '', id: accId } = ea

      const exId =  lodash.get(accIdToExId, accId, null)
      if (exId === null) {
        return
      }
      const pairs = lodash.split(pairsString, ',')
      const pairsMap = lodash.get(exIdToPairsMap, exId, {})
      lodash.forEach(pairs, (p) => {
        pairsMap[p] = true
      })
      lodash.set(exIdToPairsMap, exId, pairsMap)
    })
    const exIdToPairsStr = {}
    lodash.forEach(exIdToPairsMap, (pairsMap, exId) => {
      exIdToPairsStr[exId] = lodash.join(Object.keys(pairsMap), ',')
    })
    const newExchangeAccounts = []
    for (let i = 0; i < exchangeAccounts.length; i ++) {
      const ea = exchangeAccounts[i]
      const { id: accId } = ea
      const exId =  lodash.get(accIdToExId, accId, null)
      if (exId === null) {
        logger.error(`ex id is null, for accId: ${accId}, idx: ${i}`)
        return
      }
      const pairsStr = lodash.get(exIdToPairsStr, exId, null)
      if (pairsStr === null) {
        logger.error(`pairs Str is not found for the exchange accounts, accId: ${accId}, idx: ${i}`)
        return
      } else {
        newExchangeAccounts.push({
          ...ea,
          pairsString: pairsStr,
        })
      }
    }
    setFieldValue('exchangeAccounts', newExchangeAccounts)
  }, [setFieldValue, exchangeAccounts, accIdToExId])



  return (
    <form
      onSubmit={handleSubmit}
      autoComplete='off'
    >
      <Box>
        <Card>
          <PiCardContent>
            <XscrollTable>
              <TableHead>
                <TableRow>
                  <AccountCell
                  >
                    Id
                  </AccountCell>
                  <AccountCell
                  >
                    Acc Name
                  </AccountCell>

                  <AccountCell
                    isPairCell
                  >
                    Pairs
                  </AccountCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {lodash.map(exchangeAccounts, (exchangeAccount, idx) => {
                  const { id, name, pairsString: newPairsString } = exchangeAccount
                  const initPairsString = lodash.get(exchangeAccountsInit, [idx, 'pairsString'], null)
                  return (
                    <TableRow hover key={`acc_row_${id}`}>
                      <AccountCell>
                        {id}
                      </AccountCell>
                      <AccountCell>
                        {name}
                      </AccountCell>
                      <AccountCell
                        isPairCell
                      >
                        <Field
                          onKeyDown={onKeyDown}
                          name={`exchangeAccounts.${idx}.pairsString`}
                          label=""
                          multiline
                          placeholder='Please separate pairs with comma (,)'
                          component={FormInput}
                        />
                        <PairsUpdateInfo
                          newPairsString={newPairsString}
                          initPairsString={initPairsString}
                          />
                      </AccountCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </XscrollTable>

            <Button
              fullWidth
              className={classes.mergePairsButton}
              disabled={isSending}
              color="primary"
              variant="outlined"
              onClick={onMergePairs}
            >
              Merge Same Exchange Pairs
            </Button>
            <Button
              fullWidth
              disabled={isSending}
              color="primary"
              variant="contained"
              type="submit">
              Submit
            </Button>
          </PiCardContent>
        </Card>
      </Box>
    </form>
  )
}

export default EditAccountListFormInner
