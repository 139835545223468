import PropTypes from 'prop-types'
import { optionArrayPropType } from '../../../utils/propTypesUtils'


const accNameAssetOptionArrayPropTypes = PropTypes.arrayOf(PropTypes.shape({
  accName: PropTypes.string.isRequired,
  assetOptions: optionArrayPropType,
}))

// map from accName => asset => { borrowLmt, repayLmt}
const accBorrowRepayLmtPropTypes = PropTypes.objectOf(PropTypes.objectOf(PropTypes.shape({
  borrowLmt: PropTypes.number.isRequired,
  repayLmt: PropTypes.number.isRequired,
})))

export {
  accNameAssetOptionArrayPropTypes,
  accBorrowRepayLmtPropTypes,
}
