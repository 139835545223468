import React, { useEffect } from 'react'
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core'
import Page from '../../../components/Page'
import SessionListResults from './SessionListResults'
import SessionListToolbar from './SessionListToolbar'
import lodash from 'lodash'
import PropTypes from 'prop-types'
import {getInstancerServerName, mapDispatch} from '../../../utils/utils'
import { actions as socketActions } from '../../socket/socketSlice'
import { actions as singleActions } from '../../single/singleSlice'
import connect from 'react-redux/es/connect/connect'
import NewSessionView from '../newSessionView'
import FullscreenDialog from '../../../components/FullscreenDialog'
import { algorithmArrayPropType, projectArrayPropType } from '../../../utils/propTypesUtils'
import { useRefFieldsPrevious, useRunOnce, useStateReqView } from '../../../utils/hooks'
import { CMD_GET_SESSIONS } from '../../../protocolConstants'
import logger from '../../../utils/logger'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}))

const SessionListView = ({ algorithms, projects, clearCurPageData, setCurPageData, newSessionOpen } ) => {
  const classes = useStyles()
  useRunOnce(() => {
    clearCurPageData()
  })
  const genReqData = () => {
    return {
      cmd: CMD_GET_SESSIONS
    }
  }
  let {
    receivedData,
    loadingView,
    sendReq,
    isSuccessful
  } = useStateReqView(genReqData)

  const prevIsSuccessful = useRefFieldsPrevious('isSuccessful', isSuccessful)
  useEffect(() => {
    if (!prevIsSuccessful && isSuccessful) {
      let sessionList = lodash.get(receivedData, 'data', [])
      const sessionListNew = lodash.map(sessionList, (s) => {
        const { instancerName } = s
        const serverName = getInstancerServerName(instancerName)
        return {
          serverName,
          ...s
        }
      })
      setCurPageData({
        sessionList: sessionListNew
      })
    }
  }, [prevIsSuccessful, isSuccessful, receivedData, setCurPageData])
  const onSessionCreated = () => {
    logger.debug('on session created, reload the session list')
    sendReq(genReqData())
  }

  const handleNewSessionDialogClose = () => {
    const newSessionOpen = false
    setCurPageData({
      newSessionOpen
    })
  }

  const handleNewSessionDialogOpen = () => {
    const newSessionOpen = true
    setCurPageData({
      newSessionOpen
    })
  }

  const setSessionSearchText = (sessionSearchText) => {
    setCurPageData({
      sessionSearchText
    })
  }

  const setFilteredProjectId = (filteredProjectId) => {
    setCurPageData({
      filteredProjectId
    })
  }

  if(!loadingView) {
    loadingView = <SessionListResults
      algorithms={algorithms}
      projects={projects}
    />
  }

  return (
    <Page
      className={classes.root}
      title="Sessions"
    >
      <Container maxWidth={false}>
        <SessionListToolbar
          setSessionSearchText= {setSessionSearchText}
          setFilteredProjectId={setFilteredProjectId}
          onAddSession={handleNewSessionDialogOpen}
        />
        <Box mt={3}>
          {loadingView}
        </Box>
        <FullscreenDialog
          dialogOpen={newSessionOpen}
          dialogTitle='New Session'
          dialogOnCancel={handleNewSessionDialogClose}
        >
          <NewSessionView
            onSessionCreated={onSessionCreated}
          />
        </FullscreenDialog>
      </Container>
    </Page>
  )
}


SessionListView.propTypes = {
  projects: projectArrayPropType,
  algorithms: algorithmArrayPropType,
  clearCurPageData: PropTypes.func.isRequired,
  setCurPageData: PropTypes.func.isRequired,
}

SessionListView.defaultProps = {
}

const mapStateToProps = (state, ownProps)=> {
  const reqInfoMap = lodash.get(state, ['socket', 'reqInfoMap'], {})
  const projects = lodash.get(state, ['single', 'profile', 'projects'])
  const algorithms = lodash.get(state, ['single', 'profile', 'algorithms'])
  const roles = lodash.get(state, ['single', 'profile', 'roles'])

  const newSessionOpen = lodash.get(state, ['single', 'curPageData', 'newSessionOpen'], false)
  return {
    reqInfoMap,
    algorithms,
    projects,
    roles,
    newSessionOpen
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return mapDispatch(dispatch)({...socketActions, ...singleActions})
}

export default connect(mapStateToProps, mapDispatchToProps)(SessionListView)
