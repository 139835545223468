import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  makeStyles,
  Box,
  Grid
} from '@material-ui/core'
import { getMidPxOfOrderbook, mapDispatch } from '../../../../utils/utils'
import connect from 'react-redux/es/connect/connect'
import IcebergBasicInfoView from './IcebergBasicInfoView'
import IcebergLoggerView from './IcebergLoggerView'
import IcebergAlgoForm from './IcebergAlgoForm'
import IcebergOrderbook from './IcebergOrderbook'
import { useRefFieldsPrevious, useRunOnce, useStateReqView } from '../../../../utils/hooks'
import { actions as singleActions } from '../../../single/singleSlice'
import logger from '../../../../utils/logger'
import {
  accountPropTypes,
  algorithmPropTypes,
  projectPropType
} from '../../../../utils/propTypesUtils'
import {
  LOG_LINES_START_TO_TRIM,
  LOG_LINES_TRIM_TO
} from '../../../../constants'
import lodash from 'lodash'
import { addEventListener, removeEventListener } from '../../../../utils/eventBus'
import SmallThemeProvider from '../../../../smallThemeProvider'
import { processLogs } from '../../../ui/LoggerView'
import {
  CMD_BROADCAST_ICEBERG_ACTIVE_ORDER_TABLE, CMD_BROADCAST_ICEBERG_BASIC_INFO, CMD_BROADCAST_ICEBERG_ORDERBOOK,
  CMD_BROADCAST_ICEBERG_STARTED,
  CMD_BROADCAST_ICEBERG_STOPPED, CMD_GET_FULL_STATE, CMD_LOG_UPDATE
} from '../../../../protocolConstants'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  leftRoot: {
    padding: theme.spacing(1)
  },
  leftTop: {
    width: '100%',
    padding: theme.spacing(1)
  },
  leftBottom: {
    width: '100%',
    padding: theme.spacing(1)
  },
  rightRoot: {
    padding: theme.spacing(1)
  },
  rightTop: {
    width: '100%',
    padding: theme.spacing(1)
  },
  rightBottom: {
    width: '100%',
    height: '300px', // it is actually the min height to be 300
    padding: theme.spacing(1),
    "overflow": "auto"
  },
}))


const IcebergAlgoView = ({ algorithm, sessionId, params: reduxParams, clearCurPageData, setCurPageData, logs, initMidPx, setToolbarTitle, sessionAccounts, project }) => {
  const classes = useStyles()
  useRunOnce(() => {
    clearCurPageData()
    if (!lodash.isEmpty(sessionAccounts)) {
      const sessionAccount = sessionAccounts[0]
      const { accId, pairs } = sessionAccount
      const exchangeAccounts = lodash.get(project, 'exchangeAccounts', [])
      const account = lodash.find(exchangeAccounts, (ea) => {
        return ea['id'] === accId
      })
      if (account) {
        let pair = ''
        if (!lodash.isEmpty(pairs)) {
          pair = pairs[0]
        }
        const toolbarTitle = `${algorithm.name} - ${account.name} - ${pair}`
        setToolbarTitle(toolbarTitle)
      }
    }
  })
  const genFullState = () => {
    return {
      cmd: CMD_GET_FULL_STATE,
      isRelay: true,
      data: {
        sessionId,
      }
    }
  }

  let { isSuccessful, receivedData, loadingView } = useStateReqView(genFullState)

  const onIcebergBasicInfoUpdate = (event) => {
    const { data: basicInfoData, sessionId: eventSessionId } = lodash.get(event, 'data')
    if (eventSessionId !== sessionId) {
      return
    }
    setCurPageData({
      basicInfo: basicInfoData
    })
  }

  const onOrderbookUpdate = (event) => {
    const { data: orderbookData, sessionId: eventSessionId } =lodash.get(event, 'data')
    if(eventSessionId !== sessionId) {
      return
    }
    const curPageData = {
      orderbook: orderbookData,
    }
    if (initMidPx === undefined) {
      curPageData['initMidPx'] = getMidPxOfOrderbook(orderbookData)
    }
    setCurPageData(curPageData)
  }

  const onIcebergStarted = (event) => {
    const { sessionId: eventSessionId } =lodash.get(event, 'data')
    if (eventSessionId !== sessionId) {
      return
    }
    const active = true
    setCurPageData({
      params: {
        ...reduxParams,
        active
      }
    })
  }

  const onIcebergStopped = (event) => {
    const { sessionId: eventSessionId } =lodash.get(event, 'data')
    if (eventSessionId !== sessionId) {
      return
    }
    const active = false
    setCurPageData({
      params: {
        ...reduxParams,
        active
      }
    })
  }

  const onActiveOrder = (event) => {
    const { data: activeOrdersData, sessionId: eventSessionId } =lodash.get(event, 'data')
    if(eventSessionId !== sessionId) {
      return
    }
    setCurPageData({
      activeOrders: activeOrdersData
    })
  }

  const onLogfileUpdate = (event) => {
    const { data: logfileData = [], sessionId: eventSessionId } = lodash.get(event, 'data')
    if (eventSessionId !== sessionId) {
      return
    }
    const addLogs = processLogs(logfileData)
    let newLogs = lodash.concat(logs, addLogs)
    if (newLogs.length > LOG_LINES_START_TO_TRIM) {
      newLogs = newLogs.slice(-LOG_LINES_TRIM_TO, newLogs.length)
    }
    newLogs = newLogs.slice(-LOG_LINES_START_TO_TRIM, newLogs.length)
    setCurPageData({
      logs: newLogs
    })
  }

  useEffect(() => {
    const basicInfoCallbackId = addEventListener(CMD_BROADCAST_ICEBERG_BASIC_INFO, onIcebergBasicInfoUpdate)
    const orderbookCallbackId = addEventListener(CMD_BROADCAST_ICEBERG_ORDERBOOK, onOrderbookUpdate)
    const icebergStarted = addEventListener(CMD_BROADCAST_ICEBERG_STARTED, onIcebergStarted)
    const icebergStopped = addEventListener(CMD_BROADCAST_ICEBERG_STOPPED, onIcebergStopped)
    const activeOrderCallbackId = addEventListener(CMD_BROADCAST_ICEBERG_ACTIVE_ORDER_TABLE, onActiveOrder)
    const logfileUpdateId = addEventListener(CMD_LOG_UPDATE, onLogfileUpdate)
    return () => {
      removeEventListener(CMD_BROADCAST_ICEBERG_BASIC_INFO, basicInfoCallbackId)
      removeEventListener(CMD_BROADCAST_ICEBERG_ORDERBOOK, orderbookCallbackId)
      removeEventListener(CMD_BROADCAST_ICEBERG_STARTED, icebergStarted)
      removeEventListener(CMD_BROADCAST_ICEBERG_STOPPED, icebergStopped)
      removeEventListener(CMD_BROADCAST_ICEBERG_ACTIVE_ORDER_TABLE, activeOrderCallbackId)
      removeEventListener(CMD_LOG_UPDATE, logfileUpdateId)
    }
  })

  const prevIsSuccessful  = useRefFieldsPrevious('isSuccessful', isSuccessful)
  useEffect(() => {
    if ((!prevIsSuccessful) && isSuccessful) {
      const logLines = lodash.get(receivedData, ['data', 'shared', 'logLines'])
      const logs = processLogs(logLines)
      const activeOrders = lodash.get(receivedData, ['data', 'specific', 'activeOrders'])
      const basicInfo = lodash.get(receivedData, ['data', 'specific', 'basicInfo'])
      const orderbook = lodash.get(receivedData, ['data', 'specific', 'orderbook'])
      const params = lodash.get(receivedData, ['data', 'specific', 'params'])
      const pairInfo = lodash.get(receivedData, ['data', 'specific', 'pairInfo'])
      let initMidPx = 0
      try {
        initMidPx = getMidPxOfOrderbook(orderbook)
      } catch (e) {
        logger.error(e)
      }
      setCurPageData({
        logs,
        activeOrders,
        basicInfo,
        orderbook,
        params,
        pairInfo,
        initMidPx
      })
    }
  })

  if(loadingView) {
    return <Box className={classes.root}>
      {loadingView}
    </Box>
  }

  return (
    <Grid
      className={classes.root}
      container
    >
      <Grid
        item
        className={classes.leftRoot}
        xl={5}
        lg={5}
        sm={12}
        xs={12}>
        <Box height="100%"
             width="100%"
             display="flex"
             flexDirection="column"
        >
          <Box className={classes.leftTop}>
            <IcebergOrderbook />
          </Box>
          <Box className={classes.leftBottom} flexGrow={1}>
            <IcebergBasicInfoView/>
          </Box>
        </Box>
      </Grid>

      <Grid
        item
        className={classes.rightRoot}
        xl={7}
        lg={7}
        sm={12}
        xs={12}
      >
        <Box
          height="100%"
          width="100%"
          display="flex"
          flexDirection="column"
        >
          <Box className={classes.rightTop}>
            <SmallThemeProvider>
              <IcebergAlgoForm
                sessionId={sessionId}
              />
            </SmallThemeProvider>
          </Box>

          <Box className={classes.rightBottom} flexGrow={1}>
            <IcebergLoggerView/>
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}

IcebergAlgoView.propTypes = {
  algorithm: algorithmPropTypes, // the selected algorithm
  sessionId: PropTypes.number.isRequired,
  clearCurPageData: PropTypes.func.isRequired,
  setCurPageData: PropTypes.func.isRequired,
  setToolbarTitle: PropTypes.func.isRequired,
  logs: PropTypes.array.isRequired,
  sessionAccounts: PropTypes.arrayOf(accountPropTypes),
  project: projectPropType.isRequired,
}

IcebergAlgoView.defaultProps = {
  logs: [],
  sessionAccounts: [],
}

const mapStateToProps = (state, ownProps)=> {
  const logs = lodash.get(state, ['single', 'curPageData', 'logs'], [])
  const params = lodash.get(state, ['single', 'curPageData', 'params'])
  const initMidPx = lodash.get(state, ['single', 'curPageData', 'initMidPx'])
  return {
    logs,
    initMidPx,
    params
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return mapDispatch(dispatch)({...singleActions})
}

export default connect(mapStateToProps, mapDispatchToProps)(IcebergAlgoView)

