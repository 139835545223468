import { withStyles } from '@material-ui/core'
import { Component } from 'react'
import PiConsole from '../../components/PiConsole'
import React from 'react'
import PropTypes from 'prop-types'
import logger from '../../utils/logger'

const withStyle = withStyles((theme) => ({
  console: {
    "overflow-y": "scroll",
    height: '100%',
  }
}))

class InnerLoggerView extends Component {

  constructor (props) {
    super(props)
    // it does not belongs to the state
    // just need to calculate every time before rendering
    this.atBottom = false
  }

  setConsoleDiv = (consoleDiv) => {
    this.consoleDiv = consoleDiv
  }

  autoScroll = () => {
    if (this.consoleDiv) {
      this.consoleDiv.scrollTop = this.consoleDiv.scrollHeight - this.consoleDiv.offsetHeight
    }
  }

  scrollToBottom = () => {
    if (this.consoleDiv) {
      this.consoleDiv.scrollTop = this.consoleDiv.scrollHeight
    }
  }

  calIfAtBottom = () => {
    if (this.consoleDiv) {
      const scrollTop = this.consoleDiv.scrollTop
      const offsetHeight = this.consoleDiv.offsetHeight
      const scrollHeight = this.consoleDiv.scrollHeight
      return scrollTop + offsetHeight === scrollHeight
    } else {
      return false
    }
  }

  componentDidMount() {
    logger.debug('LoggerInnerView componentDidMount,scroll to bottom')
    this.scrollToBottom()
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (this.atBottom) {
      logger.debug('at bottom, hence scroll now')
      this.scrollToBottom()
    }
  }

  render() {
    const { classes, logs } = this.props
    this.atBottom = this.calIfAtBottom()
    return (
      <div className={classes.console} ref={this.setConsoleDiv}>
        <PiConsole
          variant='light'
          styles={{
            LOG_ERROR_BORDER: '#ffffff',
            LOG_INFO_COLOR: '#242424',
            LOG_ERROR_BACKGROUND: '',
          }}
          logs={logs}
        />
      </div>
    )
  }
}

InnerLoggerView.propTypes = {
  logs: PropTypes.arrayOf(PropTypes.shape({
    method: PropTypes.string.isRequired,
    data: PropTypes.array.isRequired
  }))
}

InnerLoggerView.defaultProps= {
}

export default withStyle(InnerLoggerView)
