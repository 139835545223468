import React from 'react'
import clsx from 'clsx'
import lodash from 'lodash'
import PropTypes from 'prop-types'
import {
  makeStyles,
  Box
} from '@material-ui/core'
import AccountFields from './AccountFields'
import { projectArrayPropType } from '../../../utils/propTypesUtils'
import { mapDispatch } from '../../../utils/utils'
import connect from 'react-redux/es/connect/connect'


const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(3),
  }
}))

/**
 * A simple trading account, used by several algos, such as iceberg, and maker
 */
const SimpleTradingAccountForm = ({ className, pairCountMin, postAccPairsChange, pairCountMax, fieldNamePrefix, accountLabel, projectId, projects, values, supportedAccountCats }) => {
  const classes = useStyles()
  const accountIdFieldName = `${fieldNamePrefix}.accId`
  const pairsFieldName = `${fieldNamePrefix}.pairs`

  const project = lodash.find(projects, (p) => {
    return p['id'] === projectId
  })

  if(project === undefined) {
    return null
  }

  let { exchangeAccounts = [] } = project
  exchangeAccounts = lodash.filter(exchangeAccounts, (ea) => {
    const { cat } = ea
    return lodash.includes(supportedAccountCats, cat)
  })

  const accountIdOptions = lodash.map(exchangeAccounts, (ex) => {
    return {
      value: ex['id'],
      label: ex['name']
    }
  })

  const selectedAccountId = lodash.get(values, accountIdFieldName)
  const selectedAxAccount = lodash.find(exchangeAccounts, (ex) => {
    return selectedAccountId === ex['id']
  })
  let pairsOptions = []
  if (selectedAxAccount !== undefined) {
    pairsOptions = lodash.map(lodash.get(selectedAxAccount, 'pairs', []), (p) => {
      return {
        value: p,
        label: p
      }
    })
  }

  return (
    <Box mt={3} className={clsx(classes.root, className)}>
      <AccountFields
        pairCountMin={pairCountMin}
        pairCountMax={pairCountMax}
        accountLabel={accountLabel}
        postAccPairsChange={postAccPairsChange}
        accountIdFieldName={accountIdFieldName}
        pairsFieldName={pairsFieldName}
        accountIdOptions={accountIdOptions}
        pairsOptions={pairsOptions}
      />
    </Box>
  )
}

SimpleTradingAccountForm.propTypes = {
  projectId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.oneOf([''])
  ]),
  className: PropTypes.string,
  values: PropTypes.object.isRequired,
  pairCountMin: PropTypes.number,
  pairCountMax: PropTypes.number,
  projects: projectArrayPropType,
  // filter the account category
  supportedAccountCats: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  fieldNamePrefix: PropTypes.string.isRequired,
  accountLabel: PropTypes.string.isRequired,
  postAccPairsChange: PropTypes.func,
}

SimpleTradingAccountForm.defaultProps = {
  accountLabel: 'Trading Account',
}

const mapStateToProps = (state, ownProps)=> {
  const projects = lodash.get(state, ['single', 'profile', 'projects'])
  return {
    projects,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return mapDispatch(dispatch)({})
}

export default connect(mapStateToProps, mapDispatchToProps)(SimpleTradingAccountForm)

