import { baseQuote } from '../../../../utils/utils'
import { BUY_SELL_OPTIONS, SIDE_BUY, SIDE_SELL } from '../../../../protocolConstants'
import React, { useCallback, useEffect } from 'react'
import { Box, Button, Card, Grid, makeStyles, Divider } from '@material-ui/core'
import ColorButton from '../../../../components/ColorButton'
import PiCardContent from '../../../../components/PiCardContent'
import PiTwoColRowGrid from '../../../../components/PiTwoColRowGrid'
import FormCheckbox from '../../../../components/form/FormCheckbox'
import { Field } from 'formik'
import PiInputAdornment from '../../../../components/PiInputAdornment'
import FormInput from '../../../../components/form/FormInput'
import FormRadio from '../../../../components/form/FormRadio'
import FormInterval from '../../../../components/form/FormInterval'
import FormSlider from '../../../../components/form/FormSlider'
import lodash from 'lodash'


const useStyles = makeStyles((theme) => ({
  button: {
    height: "100%"
  },
  divider: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(3),
  }
}))

const SpoofSniperAlgoFormInner = ({ formProps, pairInfo, active, onStopTaker, setCurPageData, spoofSniperAlgoFormUpdatingData }) => {
  const { values, handleSubmit, setFieldValue } = formProps
  const { pxPrec, pair, qtyPrec } = pairInfo
  const [base, quote] = baseQuote(pair)
  const classes = useStyles()
  let isSending = false

  const { sniperBuySell, spoofEnabled, spoofAfterSniperOnly = false, sniperCounterOrderEnabled = false } = values
  let isSniperBuy = undefined
  if (sniperBuySell === SIDE_BUY) {
    isSniperBuy = true
  } else if (sniperBuySell === SIDE_SELL) {
    isSniperBuy = false
  }

  let isSpoofBuy = undefined
  if(isSniperBuy !== undefined) {
    isSpoofBuy = !isSniperBuy
  }

  let spoofRemainingAssetLabel = ''
  let spoofPxLabel = 'Spoof Order Lmt Px'
  if (isSpoofBuy !== undefined) {
    if (isSpoofBuy) {
      spoofRemainingAssetLabel = quote
      spoofPxLabel = 'Spoof Order Max Buy Price'
    } else {
      spoofRemainingAssetLabel = base
      spoofPxLabel = 'Spoof Order Min Sell Price'
    }
  }

  let sniperRemainingAssetLabel = ''
  let sniperPxLabel = 'Sniper Order Lmt Px'
  if (isSniperBuy !== undefined) {
    if (isSniperBuy) {
      sniperRemainingAssetLabel = quote
      sniperPxLabel = 'Sniper Order Max Buy Price'
    } else {
      sniperRemainingAssetLabel = base
      sniperPxLabel = 'Sniper Order Min Sell Price'
    }
  }


  const handleLayerNumLabel = useCallback((layerNum) => {
    return `${layerNum}`
  }, [])

  // as long as the slider is base point, we could use this one
  const handleBasePointLabel = useCallback((layerGapBasePoint) => {
    return `${layerGapBasePoint/100}%`
  }, [])

  let startStopButton
  if (active) {
    startStopButton = <Button
      className={classes.button}
      disabled={isSending}
      fullWidth
      size="small"
      onClick={onStopTaker}
      variant="contained"
    >
      Stop
    </Button>
  } else {
    let buttonLabel
    let colorStyle
    if (isSniperBuy !== undefined) {
      if (isSniperBuy) {
        buttonLabel = "Preview & Sniper Buy"
        colorStyle = "green"
      } else {
        buttonLabel = "Preview & Sniper Sell"
        colorStyle = "red"
      }
    } else {
      buttonLabel = "Start"
    }
    startStopButton = <ColorButton
      className={classes.button}
      disabled={isSending}
      fullWidth
      size="small"
      type="submit"
      colorStyle={colorStyle}
    >
      {buttonLabel}
    </ColorButton>
  }


  useEffect(() => {
    if (!lodash.isEmpty(spoofSniperAlgoFormUpdatingData)) {
      setCurPageData({
        spoofSniperAlgoFormUpdatingData: {} // clear the data
      })
      lodash.forEach(spoofSniperAlgoFormUpdatingData, (val, key) => {
        setFieldValue(key, val)
      })
    }
  }, [setFieldValue, spoofSniperAlgoFormUpdatingData, setCurPageData])

  const spoofDisabled = !spoofEnabled
  return (
    <form onSubmit={handleSubmit}>
      <Card>
        <PiCardContent>
          <Grid container>

            <PiTwoColRowGrid>
              <Field
                name="spoofEnabled"
                label="Spoof Enabled"
                component={FormCheckbox}
              />
            </PiTwoColRowGrid>

            <PiTwoColRowGrid>
              <Field
                name="spoofOutrangeSniper"
                label="Spoof Outrange Sniper"
                disabled={spoofDisabled}
                component={FormCheckbox}
              />
            </PiTwoColRowGrid>
            <PiTwoColRowGrid>
              <Field
                name="spoofOrderQty"
                label="Spoof Order Qty"
                disabled={spoofDisabled}
                prec={qtyPrec}
                InputProps={{
                  endAdornment: <PiInputAdornment position="end" label={base}/>
                }}
                inputProps={{
                  type: 'number',
                  style: {
                    textAlign: 'right'
                  }
                }}
                component={FormInput}
              />
            </PiTwoColRowGrid>

            <PiTwoColRowGrid>
              <Field
                name="spoofRemainingQty"
                label="Spoof Remaining Qty"
                prec={qtyPrec}
                disabled={spoofDisabled}
                InputProps={{
                  endAdornment: <PiInputAdornment position="end" label={spoofRemainingAssetLabel}/>
                }}
                inputProps={{
                  type: 'number',
                  style: {
                    textAlign: 'right'
                  }
                }}
                component={FormInput}
              />
            </PiTwoColRowGrid>

            <PiTwoColRowGrid>
              <Field
                name="spoofLmtPx"
                label={spoofPxLabel}
                prec={pxPrec}
                disabled={spoofDisabled}
                inputProps={{
                  type: 'number',
                  style: {
                    textAlign: 'right'
                  }
                }}
                component={FormInput}
              />
            </PiTwoColRowGrid>

            <PiTwoColRowGrid>
              <Field
                name="spoofLayerNum"
                label="Spoof Layer Num"
                min={1}
                max={5}
                disabled={spoofDisabled}
                component={FormSlider}
                handleValueLabel={handleLayerNumLabel}
              />
            </PiTwoColRowGrid>


            <PiTwoColRowGrid>
              <Field
                name="spoofLayerGapBasePoint"
                label="Spoof Layer Gap"
                min={1}
                max={100}
                disabled={spoofDisabled}
                component={FormSlider}
                handleValueLabel={handleBasePointLabel}
              />
            </PiTwoColRowGrid>

            <PiTwoColRowGrid>
              <Field
                name="spoofMinSpread"
                label="Spoof Min Spread"
                prec={pxPrec}
                disabled={spoofDisabled}
                inputProps={{
                  type: 'number',
                  style: {
                    textAlign: 'right'
                  }
                }}
                component={FormInput}
              />
            </PiTwoColRowGrid>

            <PiTwoColRowGrid
            >
              <Field
                disabled={spoofDisabled || !spoofAfterSniperOnly }
                name="spoofAfterSniperItvlMs"
                label="Aft Snp Itvl"
                component={FormInterval}
              />
            </PiTwoColRowGrid>

            <PiTwoColRowGrid>
              <Field
                name="spoofAfterSniperOnly"
                label="Spoof Aft Snp Only"
                disabled={spoofDisabled}
                component={FormCheckbox}
              />
            </PiTwoColRowGrid>

            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
            >
              <Divider orientation="horizontal" className={classes.divider}/>
            </Grid>

            <PiTwoColRowGrid>
              <Field
                name="sniperMinOrderQty"
                label="Sniper Min Order Qty"
                prec={qtyPrec}
                InputProps={{
                  endAdornment: <PiInputAdornment position="end" label={base}/>
                }}
                inputProps={{
                  type: 'number',
                  style: {
                    textAlign: 'right'
                  }
                }}
                component={FormInput}
              />
            </PiTwoColRowGrid>

            <PiTwoColRowGrid>
              <Field
                name="sniperRemainingQty"
                label="Sniper Remaining Qty"
                prec={qtyPrec}
                InputProps={{
                  endAdornment: <PiInputAdornment position="end" label={sniperRemainingAssetLabel}/>
                }}
                inputProps={{
                  type: 'number',
                  style: {
                    textAlign: 'right'
                  }
                }}
                component={FormInput}
              />
            </PiTwoColRowGrid>

            <PiTwoColRowGrid>
              <Field
                name="sniperMaxOrderQty"
                label="Sniper Max Order Qty"
                prec={qtyPrec}
                InputProps={{
                  endAdornment: <PiInputAdornment position="end" label={base}/>
                }}
                inputProps={{
                  type: 'number',
                  style: {
                    textAlign: 'right'
                  }
                }}
                component={FormInput}
              />
            </PiTwoColRowGrid>

            <PiTwoColRowGrid>
              <Field
                name="sniperPxMaxGapBasePoint"
                label="Sniper Px Max Gap"
                min={5}
                max={500}
                component={FormSlider}
                handleValueLabel={handleBasePointLabel}
              />
            </PiTwoColRowGrid>

            <PiTwoColRowGrid>
              <Field
                name="sniperLmtPx"
                label={sniperPxLabel}
                prec={pxPrec}
                inputProps={{
                  type: 'number',
                  style: {
                    textAlign: 'right'
                  }
                }}
                component={FormInput}
              />
            </PiTwoColRowGrid>

            <PiTwoColRowGrid>
              <Field
                name="sniperPctBasePoint"
                label="Sniper Pct"
                min={1000}
                max={9900}
                component={FormSlider}
                handleValueLabel={handleBasePointLabel}
              />
            </PiTwoColRowGrid>

            <PiTwoColRowGrid>
              <Field
                name="sniperCounterOrderQty"
                label="Counter Order Qty"
                disabled={!sniperCounterOrderEnabled}
                prec={qtyPrec}
                InputProps={{
                  endAdornment: <PiInputAdornment position="end" label={base}/>
                }}
                inputProps={{
                  type: 'number',
                  style: {
                    textAlign: 'right'
                  }
                }}
                component={FormInput}
              />
            </PiTwoColRowGrid>

            <PiTwoColRowGrid>
              <Field
                name="sniperCounterOrderEnabled"
                label="Sniper Counter Order Enabled"
                component={FormCheckbox}
              />
            </PiTwoColRowGrid>


            <PiTwoColRowGrid
              xs={12}
            >
              <Field
                name="itvlMs"
                label="Interval"
                component={FormInterval}
              />
            </PiTwoColRowGrid>

            <PiTwoColRowGrid
              xs={12}
            >
              <Field
                name="sniperMinConsItvlMs"
                label="Min Cons"
                component={FormInterval}
              />
            </PiTwoColRowGrid>

            <PiTwoColRowGrid
              xs={12}
            >
              <Field
                name="sniperBuySell"
                label="Sniper Side"
                options={BUY_SELL_OPTIONS}
                component={FormRadio}
              />
            </PiTwoColRowGrid>

            <PiTwoColRowGrid
              xs={12}
            >
              <Field
                name="sniperLayerMinConsItvlMs"
                label="Layer Min Cons"
                component={FormInterval}
              />
            </PiTwoColRowGrid>

          </Grid>
          <Box mt={2}>
            <Grid display="flex" container spacing={3}>
              <PiTwoColRowGrid>
                {startStopButton}
              </PiTwoColRowGrid>
            </Grid>
          </Box>
        </PiCardContent>
      </Card>
    </form>
  )
}

export default SpoofSniperAlgoFormInner
