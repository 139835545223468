import React, {useContext} from 'react'
import clsx from 'clsx'
import lodash from 'lodash'
import PropTypes from 'prop-types'
import {
  Card,
  TableBody,
  TableHead,
  TableRow,
  makeStyles,
  Checkbox,
} from '@material-ui/core'
import { actions as singleActions } from '../../single/singleSlice'
import { mapDispatch } from '../../../utils/utils'
import {
  projectArrayPropType,
} from '../../../utils/propTypesUtils'
import connect from 'react-redux/es/connect/connect'
import XscrollTable from '../../ui/XscrollTable'
import PiTableCell from '../../../components/PiTableCell'
import { PiContext } from '../../../utils/piContext'

const useStyles = makeStyles((theme) => ({
  root: {
      marginBottom: theme.spacing(3),
  },
  cell: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3)
  },
}))

const AccountCell = ({ children, className, ...rest }) => {
  const { isMobile } = useContext(PiContext)
  const classes = useStyles()
  const cellClass = isMobile ? classes.cell : ''
  return (
    <PiTableCell
      className={clsx(cellClass, className)}
      size="medium"
      disablePiStyle
      {...rest}
    >
      {children}
    </PiTableCell>
  )
}

const AccountHeaderCell = ({ children, ...rest }) => {
  return (
    <AccountCell
      {...rest}
    >
      {children}
    </AccountCell>
  )
}

const AccountListResults = ({ className, projects, accountSearchText, selectedAccountIdMap: inputSelectedAccountIdMap,
                              showSelectedChecked, filteredProjectId, filteredExchangeId, setCurPageData }) => {
  const classes = useStyles()

  if (!projects) {
    projects = []
  }

  const allExchangeAccounts = []
  lodash.forEach(projects, (prj) => {
    const { id: projectId, name: projectName, exchangeAccounts } = prj
    lodash.forEach(exchangeAccounts, (exchangeAccount) => {
      allExchangeAccounts.push({
        projectId,
        projectName,
        ...exchangeAccount
      })
    })
  })

  const onClickRow = (accountId) => (p) => {
    const checked = lodash.get(inputSelectedAccountIdMap, accountId, false)
    setCurPageData({
      selectedAccountIdMap: {
        ...inputSelectedAccountIdMap,
        [accountId]: !checked,
      }
    })
  }

  let filterExchangeAccounts = allExchangeAccounts
  if (!lodash.isEmpty(accountSearchText)) {
    const accountSearchTextLower = accountSearchText.toLowerCase()
    filterExchangeAccounts = lodash.filter(filterExchangeAccounts, (cs) => {
      return lodash.get(cs, 'name', '').toLowerCase().includes(accountSearchTextLower) ||
        lodash.get(cs, 'id', '').toString() === accountSearchTextLower
    })
  }

  if (filteredProjectId !== undefined && filteredProjectId !== '') {
    filterExchangeAccounts = lodash.filter(filterExchangeAccounts, (cs) => {
      return lodash.get(cs, 'projectId') === filteredProjectId
    })
  }

  if (filteredExchangeId !== undefined && filteredExchangeId !== '') {
    filterExchangeAccounts = lodash.filter(filterExchangeAccounts, (cs) => {
      return lodash.get(cs, 'exchangeId') === filteredExchangeId
    })
  }

  if (showSelectedChecked) {
    filterExchangeAccounts = lodash.filter(filterExchangeAccounts, (ea) => {
      const { id } = ea
      return lodash.get(inputSelectedAccountIdMap, id, false)
    })
  }

  return (
    <Card
      className={clsx(classes.root, className)}
    >
      <XscrollTable>
        <TableHead>
          <TableRow>
            <AccountHeaderCell
            >
              Acc Name
            </AccountHeaderCell>
            <AccountHeaderCell
            >
              Project
            </AccountHeaderCell>

            <AccountHeaderCell>
              Select
            </AccountHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {lodash.map(filterExchangeAccounts, (exchangeAccount) => {
            const { projectName, id, name } = exchangeAccount
            const selected = lodash.get(inputSelectedAccountIdMap, id, false)
            return (
              <TableRow hover key={`acc_row_${id}`} onClick={onClickRow(id)}>
                <AccountCell>
                  {name}
                </AccountCell>
                <AccountCell>
                  {projectName}
                </AccountCell>
                <AccountCell>
                  <Checkbox
                    checked={selected}
                    size="small"
                    name={`action_${id}`}
                  />
                </AccountCell>
              </TableRow>
            )
          })}
        </TableBody>
      </XscrollTable>
    </Card>
  )
}

AccountListResults.propTypes = {
  className: PropTypes.string,
  filteredProjectId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.oneOf([''])
  ]),
  filteredExchangeId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.oneOf([''])
  ]),
  projects: projectArrayPropType,
  setCurPageData: PropTypes.func.isRequired,
  clearCurPageData: PropTypes.func.isRequired,
  selectedAccountIdMap: PropTypes.object.isRequired,
  showSelectedChecked: PropTypes.bool.isRequired
}

AccountListResults.defaultProps = {
  projects: [],
  selectedAccountIdMap: {},
  showSelectedChecked: false,
  sessionSearchText: ''
}

const mapStateToProps = (state, ownProps)=> {
  const projects = lodash.get(state, ['single', 'profile', 'projects'], [])
  const selectedAccountIdMap = lodash.get(state, ['single', 'curPageData', 'selectedAccountIdMap'], {})
  const filteredProjectId = lodash.get(state, ['single', 'curPageData', 'filteredProjectId'])
  const filteredExchangeId = lodash.get(state, ['single', 'curPageData', 'filteredExchangeId'])
  const showSelectedChecked = lodash.get(state, ['single', 'curPageData', 'showSelectedChecked'])
  const accountSearchText = lodash.get(state, ['single', 'curPageData', 'accountSearchText'])
  return {
    projects,
    selectedAccountIdMap,
    filteredProjectId,
    filteredExchangeId,
    showSelectedChecked,
    accountSearchText
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return mapDispatch(dispatch)({...singleActions})
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountListResults)
