import React from 'react'
import lodash from 'lodash'
import PropTypes from 'prop-types'
import { projectArrayPropType } from '../../../utils/propTypesUtils'
import { mapDispatch } from '../../../utils/utils'
import connect from 'react-redux/es/connect/connect'
import SimpleTradingAccountForm from './SimpleTradingAccountForm'

const MultiAccountsForm = ({ accountConfigs,
                             projectId,
                             projects,
                             fieldNamePrefix,
                             values }) => {

  return lodash.map(accountConfigs, (cfg, idx) => {
    const { accountLabel, supportedAccountCats, pairCountMin, pairCountMax} = cfg
    const fieldPrefix = `${fieldNamePrefix}.${idx}`
    return <SimpleTradingAccountForm
      key={fieldPrefix}
      fieldNamePrefix={fieldPrefix}
      accountLabel={accountLabel}
      pairCountMin={pairCountMin}
      pairCountMax={pairCountMax}
      supportedAccountCats={supportedAccountCats}
      projectId={projectId}
      projects={projects}
      values={values}
    />
  })
}

MultiAccountsForm.propTypes = {
  fieldNamePrefix: PropTypes.string.isRequired,
  accountConfigs: PropTypes.arrayOf(PropTypes.shape({
    accountLabel: PropTypes.string,
    supportedAccountCats: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    pairCountMin: PropTypes.number,
    pairCountMax: PropTypes.number,
  })),
  projectId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.oneOf([''])
  ]),
  values: PropTypes.object.isRequired,
  projects: projectArrayPropType,
  setFieldValue: PropTypes.func.isRequired,
}

MultiAccountsForm.defaultProps = {
}

const mapStateToProps = (state, ownProps)=> {
  const projects = lodash.get(state, ['single', 'profile', 'projects'])
  return {
    projects,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return mapDispatch(dispatch)({})
}

export default connect(mapStateToProps, mapDispatchToProps)(MultiAccountsForm)

