import React from 'react'
import clsx from 'clsx'
import {
  Card,
  TableBody,
  TableRow,
  makeStyles,
  TableHead, Typography
} from '@material-ui/core'
import lodash from 'lodash'
import { mapDispatch } from '../../../../utils/utils'
import { actions as singleActions } from '../../../single/singleSlice'
import connect from 'react-redux/es/connect/connect'
import XscrollTable from '../../../ui/XscrollTable'
import PiCardContent from '../../../../components/PiCardContent'
import SmallTableCell from './SmallTableCell'
import { accStatePropTypes } from './longShortUtils'
import Decimal from "decimal.js"
import PiInfoTip from "../../../../components/PiInfoTip"
import logger from '../../../../utils/logger'
import {fromMs} from "../../../../utils/timeUtils"

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  cardContent: {
    height: '100%'
  },
}))

const LongShortExtraPositionInfoView = ({ accStateA, accStateB }) => {
  const classes = useStyles()
  if (!accStateA || ! accStateB) {
    return null
  }
  const extraPosA = lodash.get(accStateA, ['extraPosInfo', 'positions'])
  const extraPosB = lodash.get(accStateB, ['extraPosInfo', 'positions'])
  const allPosAssets = new Set()

  lodash.forEach(extraPosA, (_, ass) => {
    allPosAssets.add(ass)
  })

  lodash.forEach(extraPosB, (_, ass) => {
    allPosAssets.add(ass)
  })

  if (allPosAssets.size === 0) {
    return null
  }

  allPosAssets.values()
  const views = []

  const extraPosToView = (extraPos) => {
    if (!extraPos) {
      return <SmallTableCell>

      </SmallTableCell>
    }
    const { futurePos = {}, marginNegPos } = extraPos
    const msg = []
    lodash.forEach(futurePos, (qty, p) => {
      msg.push(`${p}: ${qty}`)
    })

    if (marginNegPos) {
      const { borWtInt, free, locked } = marginNegPos
      try {
        const net = new Decimal(free).add(locked).sub(borWtInt)
        msg.push(`net: ${net},fr:${free}, lk:${locked}, bo: ${borWtInt}`)
      } catch (e) {
        msg.push(`fr:${free}, lk:${locked}, bo: ${borWtInt}`)
      }
    }


    const innerViews = []
    lodash.map(msg, (m, idx) => {
      if (idx !== 0) {
        innerViews.push(<br key={`br_idx_${idx}`}/>)
      }
      innerViews.push(m)
    })
    return <SmallTableCell>
      {innerViews}
    </SmallTableCell>
  }
  const allPosAssetVals = Array.from(allPosAssets).sort()

  const msToDated = (inputMs) => {
    if (lodash.isNumber(inputMs)) {
      try {
        return `Updated at: ${fromMs(inputMs).fromNow()}`
      } catch (e) {
        logger.error(e)
        return 'Invalid Update time'
      }
    } else {
      return 'Outdated'
    }
  }



  const getInfoTipStr = (pair, updatedAtMs) => {
    if (pair === '') {
      return 'NA'
    } else {
      return `${pair}: ${msToDated(updatedAtMs)}`
    }
  }

  for (const ass of allPosAssetVals) {
    const posA = lodash.get(extraPosA, ass)
    const posB = lodash.get(extraPosB, ass)

    const pxInfoA = lodash.get(posA, 'pxInfo', {})
    const pxInfoB = lodash.get(posB, 'pxInfo', {})

    const { px: pxA = '?', pair: pairA = '', updatedAtMs: updatedAtMsA = null } = pxInfoA
    const { px: pxB = '?', pair: pairB = '', updatedAtMs: updatedAtMsB = null } = pxInfoB

    views.push(
      <TableRow
        key={`row_${ass}`}
        hover
      >
        <SmallTableCell>
          {ass}
        </SmallTableCell>
        {extraPosToView(posA)}
        {extraPosToView(posB)}
        <SmallTableCell>
          {
            `${pxA}/${pxB}`
          }
          <PiInfoTip
            title={
            [<Typography
              key='pair_a_info'
            >
              {`A: ${getInfoTipStr(pairA, updatedAtMsA)}`}
            </Typography>,
            <Typography
              key='pair_b_info'
            >
              {`B: ${getInfoTipStr(pairB, updatedAtMsB)}`}
            </Typography>]}
            className={classes.infoTip}
          />
        </SmallTableCell>
      </TableRow>
    )
  }

  return (
    <Card className={clsx(classes.root)}
    >
      <PiCardContent className={classes.cardContent}>
        <XscrollTable>
          <TableHead>
            <TableRow
              hover
            >
              <SmallTableCell>
                Asset
              </SmallTableCell>
              <SmallTableCell>
                Acc A Extra
              </SmallTableCell>
              <SmallTableCell>
                Acc B Extra
              </SmallTableCell>

              <SmallTableCell>
                Px A&B
              </SmallTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {views}
          </TableBody>
        </XscrollTable>
      </PiCardContent>
    </Card>
  )
}

LongShortExtraPositionInfoView.propTypes = {
  accStateA: accStatePropTypes,
  accStateB: accStatePropTypes,
}

LongShortExtraPositionInfoView.defaultProps= {
}

const mapStateToProps = (state, ownProps)=> {
  const accStateA = lodash.get(state, ['single', 'curPageData', 'accStateA'])
  const accStateB = lodash.get(state, ['single', 'curPageData', 'accStateB'])
  return {
    accStateA,
    accStateB,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return mapDispatch(dispatch)({...singleActions})
}

export default connect(mapStateToProps, mapDispatchToProps)(LongShortExtraPositionInfoView)

