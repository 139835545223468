import {
  ACTION_TYPE_SOCKET_SEND,
  ACTION_TYPE_SOCKET_REQUEST_INFO_UPDATE,
  MSG_MAX_AGE_MILLISEC,
  REQ_STATUS_FAILURE,
  REQ_STATUS_PENDING,
  REQ_STATUS_SUCCESSFUL,
  REQ_TIMEOUT_MILLISEC,
  ACTION_TYPE_RECONNECT_SOCKET} from '../../constants'
import ReduxSocket from './ReduxSocket'
import { genUuid, getTsMs } from '../../utils/utils'
import Lru from 'lru-cache'
import { showToast } from '../../features/single/singleSlice'
import { updateSocketStatus } from '../../features/socket/socketSlice'
import lodash from 'lodash'
import logger from '../../utils/logger'
import { notifyEvent } from '../../utils/eventBus'
import { ERROR_CODE_SUCCESS, INVALID_AUTH_TOKEN } from '../../errorCodes'
import { CMD_PONG } from '../../protocolConstants'
import { logout } from '../single/singleSlice'



const createSocketMiddleware = () => ({ dispatch, getState }) => {
  // map from rId to rData
  const msgLru = new Lru({
    max: 100,
    maxAge: MSG_MAX_AGE_MILLISEC
  })

  const dispatchErrorMsg = (msg) => {
    dispatch(showToast(msg, 'error'))
  }

  const dispatchSocketStatusChange = () => {
    dispatch(updateSocketStatus({
      socketStatus: socket.getStatus()
    }))
  }

  const onMessage = (event) => {
    const dataStr = String(event.data)
    try{
      const receivedData = JSON.parse(dataStr)
      const rId = lodash.get(receivedData, 'rId')
      const cmd = lodash.get(receivedData, 'cmd')
      if (cmd === CMD_PONG) {
        // logger.debug(`Received pong ${dataStr}`)
        return
      }
      const isbroadcast = lodash.get(receivedData, ['metadata', 'isBroadcast'], false)
      if (isbroadcast){
        notifyEvent(cmd, receivedData)
        return
      }

      const errCode = lodash.get(receivedData, ['err', 'code'], ERROR_CODE_SUCCESS)

      let err = undefined
      let rStatus = REQ_STATUS_SUCCESSFUL
      if (errCode !== ERROR_CODE_SUCCESS) {
        const message = lodash.get(receivedData, ['err', 'message'], '')
        dispatchErrorMsg(`Request Error code: ${errCode}, message: ${message}`)
        err = {
          code: errCode,
          message
        }
        rStatus = REQ_STATUS_FAILURE
      }

      if (errCode === INVALID_AUTH_TOKEN) {
        // token is invalid, hence logout the user
        dispatch(logout())
      }

      if(rId) {
        logger.debug('Received msg with rId', receivedData)
        const serverPushed = true
        dispatch({
          type: ACTION_TYPE_SOCKET_REQUEST_INFO_UPDATE,
          payload: {
            rId,
            serverPushed,
            err,
            receivedData,
            rStatus
          }
        })
        msgLru.del(rId)
      } else {
        logger.debug('message received without rId', receivedData)
      }
    } catch (e) {
      logger.info(dataStr)
      logger.error(`[message] Data received from server: ${event.data} and parse JSON error: ${e}`)
    }
  }

  const onOpen = (e) => {
    logger.info("socket connection established")
    dispatchSocketStatusChange()
  }

  const onClose = (event) => {
    dispatchSocketStatusChange()
    if (event.wasClean) {
      console.log(`Socket on [close] Connection closed cleanly, code=${event.code} reason=${event.reason}`)
    } else {
      // e.g. server process killed or network down
      // event.code is usually 1006 in this case
      console.log('Socket on [close] Connection died')
    }
    console.log(event)
  }

  const onError = (error) => {
    dispatchSocketStatusChange()
    console.log(`Socket on error [error] ${error.message}`)
    console.log(error)
  }

  const socket = new ReduxSocket(onMessage, onOpen, onClose, onError, dispatch, getState)
  socket.connect()

  return next => async action => {
    const { type, payload = {} } = action
    if(type === ACTION_TYPE_SOCKET_SEND) {
      return handleSocketSend(payload, socket, dispatch, dispatchErrorMsg, msgLru, getState)
    }

    if(type === ACTION_TYPE_RECONNECT_SOCKET) {
      return handleSocketReconnect(socket)
    }

    return next(action)
  }
}

const handleSocketReconnect = (socket) => {
  socket.reconnect()
}

const handleSocketSend = (payload, socket, dispatch, dispatchErrorMsg, msgLru, getState) => {
  let { rId, ts } = payload

  if(!rId) {
    rId = genUuid()
    payload['rId'] = rId
  }
  if (!ts) {
    payload['ts'] = getTsMs()
  }

  rId = String(rId)
  try {
    dispatch({
      type: ACTION_TYPE_SOCKET_REQUEST_INFO_UPDATE,
      payload: {
        rId,
        sentData: payload,
        rStatus: REQ_STATUS_PENDING,
      }
    })
    if(!socket.isConnected()) {
      const message = 'Socket is not connected'
      dispatchErrorMsg(message)
      dispatch({
        type: ACTION_TYPE_SOCKET_REQUEST_INFO_UPDATE,
        payload: {
          rId,
          err: {
            message,
          },
          rStatus: REQ_STATUS_FAILURE,
        }
      })
      return
    }

    msgLru.set(rId, true)
    logger.debug(`Send message`, payload)

    const authToken = lodash.get(getState(), ['single', 'authToken'])
    socket.sendMessage({
      authToken,
      ...payload
    })

    setTimeout(() => {
      // check if rId still here, if it is still here, means, not yet received the response
      if (msgLru.get(rId) !== undefined) {
        const message = 'Request timeout'
        dispatchErrorMsg(message)
        dispatch({
          type: ACTION_TYPE_SOCKET_REQUEST_INFO_UPDATE,
          payload: {
            rId,
            err: {
              message,
            },
            rStatus: REQ_STATUS_FAILURE,
          }
        })
      }
    }, REQ_TIMEOUT_MILLISEC)
  }
  catch (e) {
    const message = e.toString()
    dispatchErrorMsg(message)
    dispatch({
      type: ACTION_TYPE_SOCKET_REQUEST_INFO_UPDATE,
      payload: {
        rId,
        err: {
          message,
        },
        rStatus: REQ_STATUS_FAILURE,
      }
    })
  }
}


export {
  createSocketMiddleware
}
