import React from 'react'
import clsx from 'clsx'
import lodash from 'lodash'
import PropTypes from 'prop-types'
import {
  Card,
  makeStyles,
  Box, Typography
} from '@material-ui/core'
import FormSelect from '../../../components/form/FormSelect'
import FormMultiSelect from '../../../components/form/FormMultiSelect'
import { Field } from 'formik'
import { optionArrayPropType } from '../../../utils/propTypesUtils'
import { isValidNumber } from '../../../utils/utils'
import PiCardContent from '../../../components/PiCardContent'
import logger from '../../../utils/logger'

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(3),
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  fieldAcc: {
    paddingBottom: theme.spacing(2),
  },
  accLabel: {
    paddingBottom: theme.spacing(1),
  }
}))


const FormAccountSelect = ({ pairsFieldName, postAccPairsChange, form, ...rest}) => {
  const { setFieldValue } = form
  const onAccountChange = (name, newValue) => {
    logger.debug('onAccountChange, clear pairs field')
    setFieldValue(pairsFieldName, [])
    if(lodash.isFunction(postAccPairsChange)) {
      postAccPairsChange(name, newValue)
    }
  }
  return (
    <FormSelect
      postChange={onAccountChange}
      form={form}
      {...rest}
    />
  )
}


FormAccountSelect.propTypes = {
  pairsFieldName: PropTypes.string.isRequired
}


const AccountFields = ({ className, postAccPairsChange, pairCountMin, pairCountMax, accountIdFieldName, pairsFieldName, accountLabel, accountIdOptions, pairsOptions, fieldNamePrefix, ...rest }) => {
  const classes = useStyles()
  const validateAccountName = (val) => {
    let err
    if (!isValidNumber(val) || val <= 0) {
      err = 'Please select an account'
    }
    return err
  }

  const shouldEmptyPair = pairCountMin === pairCountMax && pairCountMin === 0

  const validatePairs = (val) => {
    let err
    if (lodash.isEmpty(val)) {
      if (!shouldEmptyPair) {
        err = 'Please select pairs'
      }
    } else if (!lodash.isArray(val) ) {
      err = `Pair should be in list format, while it is invalid: ${val}`
    } else {
      if (pairCountMin) {
        if (val.length < pairCountMin) {
          err = `Please at least select ${pairCountMin} pair`
        }
      }
      if (pairCountMax) {
        if (val.length > pairCountMax) {
          err = `Please at most select ${pairCountMax} pair`
        }
      }
    }
    return err
  }

  const singlePair = pairCountMin === 1 && pairCountMax === 1

  return (
    <Box mt={3} className={clsx(classes.root, className)} {...rest}>
      <Card>
        <PiCardContent disablePiStyle>
          {
            lodash.isEmpty(accountLabel)
            ? null :
            <Typography
              className={classes.accLabel}
              color="textPrimary"
              variant="h5"
            >
              {accountLabel}
            </Typography>
          }
          <Field
            className={classes.fieldAcc}
            validate={validateAccountName}
            name={accountIdFieldName}
            label="Account Name"
            pairsFieldName={pairsFieldName}
            options={accountIdOptions}
            postAccPairsChange={postAccPairsChange}
            component={FormAccountSelect}
          />
          {shouldEmptyPair
          ? null
          : <Field
            name={pairsFieldName}
            validate={validatePairs}
            label="Pairs"
            multiple={!singlePair}
            options={pairsOptions}
            postChange={postAccPairsChange}
            component={FormMultiSelect}
          />}
        </PiCardContent>
      </Card>
    </Box>
  )
}

AccountFields.propTypes = {
  className: PropTypes.string,
  accountIdFieldName: PropTypes.string.isRequired,
  pairsFieldName: PropTypes.string.isRequired,

  accountLabel: PropTypes.string.isRequired,
  pairCountMin: PropTypes.number,
  pairCountMax: PropTypes.number,
  accounts: PropTypes.arrayOf(PropTypes.shape({
    cat: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    name: PropTypes.number.isRequired
  })),
  accountOptions: optionArrayPropType,
  pairsOptions: optionArrayPropType,
  postAccPairsChange: PropTypes.func,
}

AccountFields.defaultProps = {
  accountLabel: '',
  accountIdOptions: [],
  pairsOptions: [],
}

export default AccountFields
