import { v4 as uuidv4 } from 'uuid'
import lodash from 'lodash'
import {INSTANCER_NAME_TO_SERV_NAME_MAPPING, REQ_STATUS_PENDING, REQ_STATUS_SUCCESSFUL} from '../constants'
import Decimal from 'decimal.js'
import {DEX_CHAIN_BSC, DEX_CHAIN_ETHER, DEX_CHAIN_MOONRIVER, ROLE_ADMIN} from '../protocolConstants'

function genUuid () {
  // eg => '1b9d6bcd-bbfd-4b2d-9b5d-ab8dfbbd4bed'
  return uuidv4()
}

const mapDispatch = (dispatch) => (actions) => {
  const ret = {}
  for (const [key, f] of Object.entries(actions)) {
    ret[key] = bindDispatch(dispatch)(f)
  }
  return ret
}

const bindDispatch = (dispatch) => (f) => {
  return (...args) => {
    dispatch(f(...args))
  }
}

// get timestamp in millisecond
const getTsMs = () => {
  return new Date().getTime()
}


const isSocketConnected = (socketStatus) => {
  return socketStatus === WebSocket.OPEN
}

const isSocketClosed = (socketStatus) => {
  return socketStatus === WebSocket.CLOSED
}

const isSocketConnecting = (socketStatus) => {
  return socketStatus === WebSocket.CONNECTING
}

const isSocketClosing = (socketStatus) => {
  return socketStatus === WebSocket.CLOSING
}

const parsePath = (path, params) => {
  path = lodash.clone(path)
  lodash.forEach(params, (val, key) => {
    const pathKey = `:${key}`
    path = path.replace(pathKey, val)
  })
  return path
}

const isUserAdmin = (userAllRoles) => {
  return lodash.some(userAllRoles, (r) => {
    const { name } = r
    return name === ROLE_ADMIN
  })
}

const getInstancerServerName = (instancerName) => {
  return lodash.get(INSTANCER_NAME_TO_SERV_NAME_MAPPING, instancerName, instancerName)
}

const getReqInfo = (reqInfoMap, rId) => {
  let isSending = false
  let isSuccessful = false
  let isError = false
  let err = false
  let receivedData = undefined
  let sentData = undefined

  const reqInfo = lodash.get(reqInfoMap, rId, undefined)
  if(reqInfo === undefined) {
    return {
      isSending,
      isSuccessful,
      isError,
      err,
      receivedData,
      sentData
    }
  }

  const rStatus = lodash.get(reqInfo, 'rStatus')
  isSending = rStatus === REQ_STATUS_PENDING
  err = lodash.get(reqInfo, 'err')
  isSuccessful = rStatus === REQ_STATUS_SUCCESSFUL && err === undefined
  isError = (!isSending) && err !== undefined
  receivedData = lodash.get(reqInfo, 'receivedData')
  sentData = lodash.get(reqInfo, 'sentData')
  return {
    isSending,
    isSuccessful,
    isError,
    err,
    receivedData,
    sentData
  }
}

const isValidNumber = (val) => {
  return lodash.isNumber(val) && (!lodash.isNaN(val))
}

function smallFirstLetter(string) {
  return string.charAt(0).toLowerCase() + string.slice(1)
}

const objectKeysToLowerCase = function (origObj) {
  if (lodash.isEmpty(origObj)) {
    return origObj
  }
  if(lodash.isArray(origObj)) {
    return origObj
  }
  return Object.keys(origObj).reduce(function (newObj, key) {
    const val = origObj[key]
    newObj[smallFirstLetter(key)] = (typeof val === 'object') ? objectKeysToLowerCase(val) : val
    return newObj
  }, {})
}

const baseQuote = (pair='') => {
  const bq = pair.split('_')
  if (bq.length !== 2) {
    return ['', '']
  }  else {
    return [bq[0], bq[1]]
  }
}

/**
 * btc_usdt return => [btc, usdt, '']
 * btc_usdt_240323 return => [btc, usdt, '240323]
 * other return => ['', '', '']
 * @param pair
 * @returns {[string,string]}
 */
const baseQuoteExtra = (pair='') => {
  const bq = pair.split('_')
  if (bq.length === 2) {
    return [bq[0], bq[1], '']
  } else if (bq.length === 3) {
    return [bq[0], bq[1], bq[2]]
  } else {
    return ['', '', '']
  }
}

/**
 btc_usdt => return btc, usdt, "", {}
 btc_usdt_240302 => return btc, usdt, "240302", {}
 btc_usdt_240302?acc_t=future => return btc, usdt, "240302", {"acc_t": future}
 otherwise return error
 */
const baseQuoteExtraParams = (pair) => {
  const pa = pair.split('?')
  let pairNoParams = ''
  let paramsRet = {}
  if (pa.length === 1) {
    pairNoParams = pa[0]
  } else if (pa.length === 2) {
    pairNoParams = pa[0]
    const paramsStr = pa[1]
    const paraArr = paramsStr.split('&')
    for (let i = 0; i < paraArr.length; i ++) {
      const para = paraArr[i]
      const kvs = para.split('=')
      if (kvs.length !== 2) {
        return ['', '', '', {}]
      }
      paramsRet[kvs[0]] = kvs[1]
    }
  } else {
    return ['', '', '', {}]
  }
  const [base, quote, extra] = baseQuoteExtra(pairNoParams)
  return [base, quote, extra, paramsRet]
}

const roundDown = (val, prec) => {
  try{
    return new Decimal(val).dividedBy(prec).floor().mul(prec)
  } catch (e) {
    return new Decimal(0)
  }
}

const getMidPxOfOrderbook= (orderbook) => {
  const ask1 = lodash.minBy(orderbook.asks, (a) => {
    return parseFloat(a['px'])
  })
  const bid1 = lodash.maxBy(orderbook.bids, (b) => {
    return parseFloat(b['px'])
  })
  return (parseFloat(ask1['px']) + parseFloat(bid1['px'])) / 2
}

/**
 *
 */
const getQtyInQuotePrec = (qtyPrec, pxPrec) => {
  try{
    return new Decimal(qtyPrec).mul(pxPrec).toFixed()
  } catch (e) {
    return qtyPrec // fallback to a non accurate value
  }
}

const getDexExplorerAddrUrl = (chain, addr) => {
  switch (chain) {
    case DEX_CHAIN_ETHER:
      return `https://etherscan.io/address/${addr}`
    case DEX_CHAIN_BSC:
      return `https://bscscan.com/address/${addr}`
    case DEX_CHAIN_MOONRIVER:
      return `https://moonriver.moonscan.io/address/${addr}`
    default:
      return ''
  }
}

const getDexExplorerTxUrl = (chain, addr) => {
  switch (chain) {
    case DEX_CHAIN_ETHER:
      return `https://etherscan.io/tx/${addr}`
    case DEX_CHAIN_BSC:
      return `https://bscscan.com/tx/${addr}`
    case DEX_CHAIN_MOONRIVER:
      return `https://moonriver.moonscan.io/tx/${addr}`
    default:
      return ''
  }
}

const trimTxId = (txId) => {
  if (txId !== undefined && txId !== null &&txId.length > 8) {
    return `${txId.substring(0, 5)}...${txId.substring(txId.length - 3)}`
  } else {
    return txId
  }
}

const trimEmail = (email) => {
  return trimInfo(email, 4, 4)
}

const trimInfo = (info, preLen, postLen) => {
  if (info !== undefined && info !== null && info.length > preLen + postLen) {
    return `${info.substring(0, preLen)}***${info.substring(info.length - postLen)}`
  } else {
    return info
  }
}

const formatInPct = (val, decimalPlaces = 2) => {
  return `${new Decimal(val).mul(100).toFixed(decimalPlaces)} %`
}

export {
  genUuid,
  mapDispatch,
  bindDispatch,
  getTsMs,

  isSocketConnected,
  isSocketClosed,
  isSocketConnecting,
  isSocketClosing,
  getReqInfo,
  parsePath,
  isValidNumber,
  objectKeysToLowerCase,
  baseQuote,
  baseQuoteExtra,
  baseQuoteExtraParams,
  roundDown,
  getMidPxOfOrderbook,
  getQtyInQuotePrec,
  getDexExplorerAddrUrl,
  getDexExplorerTxUrl,
  trimTxId,
  trimEmail,
  trimInfo,
  formatInPct,

  isUserAdmin,
  getInstancerServerName,
}
