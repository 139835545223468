import React, { useContext, useEffect } from 'react'
import {
  FormControl,
  Chip,
  TextField,
  FormHelperText,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import PropTypes from 'prop-types'
import { createFieldPropTypes} from '../../utils/propTypesUtils'
import { formPropTypes, metaPropTypes } from '../../utils/propTypesUtils'
import lodash from 'lodash'
import { PiFormContext } from '../../utils/piFormContext'

/**
 * The value will always in a array [].
 * 1) if multiple is true, we can select multiple values [ btc_usdt, eth_usdt]
 * 2) if multiple is false, we can only select one value, but the values is still in array format [btc_usdt]
 */
const FormMultiSelect = ({ field, multiple, label, options, postChange, placeholder, form, disabled, ...rest}) => {
  const { disabledAllFields } = useContext(PiFormContext)
  let { value, name } = field
  const { touched, errors, submitCount, setFieldValue } = form
  if(!value) {
    value = []
  }
  const handleOnChange = (event, newValues) => {
    let newFilteredValues = []
    if (multiple) {
      newFilteredValues = newValues
    } else {
      if (newValues !== undefined && newValues !== null) {
        newFilteredValues = [newValues]
      } else {
        newFilteredValues = []
      }
    }
    setFieldValue(name, newFilteredValues)
    if (lodash.isFunction(postChange)) {
      postChange(name, newFilteredValues)
    }
  }

  const valueLabelMap = {}
  options.forEach((opt) => {
    const { value, label } = opt
    valueLabelMap[value] = label
  })

  const getOptionLabel = (valueOption) => {
    return lodash.get(valueLabelMap, valueOption, '')
  }

  const renderTags = (value, getTagProps) => {
    return value.map((v, index) => {
      const label = lodash.get(valueLabelMap, v, '')
      return <Chip variant="outlined" label={label} {...getTagProps({ index })} />
    })
  }

  /**
   * the reason to check submit count is that, if field is not defined in the initValues,
   * the touched field may not set to true if we press submit button
   * */
  const fieldTouched = Boolean(lodash.get(touched, name) || submitCount > 0)
  const errMsg = lodash.get(errors, name, '')
  const error = Boolean(fieldTouched && errMsg)
  const helperText = fieldTouched && errMsg

  const valueOptions = lodash.map(options, (op) => {
    const { value } = op
    return value
  })


  let displayValue
  if (!multiple) {
    if (lodash.isArray(value) && value.length >= 1) {
      displayValue = value[0]
    } else {
      displayValue = ''
    }
  } else {
    displayValue = value
  }

  useEffect(() => {
    if (!multiple && lodash.isArray(value) && value.length >= 2) {
      setFieldValue(name, [value[0]])
    }
  }, [setFieldValue, value, name, multiple])
  return (
    <FormControl fullWidth error={error} {...rest}>
      <Autocomplete
        multiple={multiple}
        getOptionLabel={getOptionLabel}
        disabled={disabled || disabledAllFields}
        value={displayValue}
        onChange={handleOnChange}
        options={valueOptions}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              error={error}
              label={label}
              placeholder={placeholder}
            />
          )
        }}
        renderTags={renderTags}
      />
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  )
}

FormMultiSelect.propTypes = {
  field: createFieldPropTypes({
    value: PropTypes.arrayOf(PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]))
  }),
  form: formPropTypes,
  meta: metaPropTypes,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
    label: PropTypes.string.isRequired
  })),
  multiple: PropTypes.bool.isRequired,
  postChange: PropTypes.func,
}

FormMultiSelect.defaultProps = {
  label: '',
  options: [],
  multiple: true,
}

export default FormMultiSelect
