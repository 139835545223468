import React from 'react'
import lodash from 'lodash'
import PropTypes from 'prop-types'
import { projectArrayPropType } from '../../../utils/propTypesUtils'
import { mapDispatch } from '../../../utils/utils'
import connect from 'react-redux/es/connect/connect'
import SimpleTradingAccountForm from './SimpleTradingAccountForm'
import StartEndFields from './StartEndFields'
import { ACCOUNT_CAT_SPOT } from '../../../protocolConstants'

/**
 * A simple trading account with start and end date
 */
const TradingAccountStartEndForm = ({ pairCountMin, pairCountMax, tradingFieldNamePrefix, startEndFieldNamePrefix, accountLabel, projectId, projects, values }) => {
  const startDateFieldName = `${startEndFieldNamePrefix}.startDate`
  const endDateFieldName = `${startEndFieldNamePrefix}.endDate`
  return [
    <SimpleTradingAccountForm
      key="simpleTradingAccountForm"
      supportedAccountCats={[ACCOUNT_CAT_SPOT]}
      fieldNamePrefix={tradingFieldNamePrefix}
      accountLabel={accountLabel}
      pairCountMin={pairCountMin}
      pairCountMax={pairCountMax}
      projectId={projectId}
      values={values}
    />,
    <StartEndFields
      key="startEnd"
      startDateFieldName={startDateFieldName}
      endDateFieldName={endDateFieldName}
      accountLabel={accountLabel}
      pairCountMin={pairCountMin}
      pairCountMax={pairCountMax}
      values={values}
    />
  ]
}

TradingAccountStartEndForm.propTypes = {
  tradingFieldNamePrefix: PropTypes.string.isRequired,
  startEndFieldNamePrefix: PropTypes.string.isRequired,
  projectId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.oneOf([''])
  ]),
  values: PropTypes.object.isRequired,
  pairCountMin: PropTypes.number,
  pairCountMax: PropTypes.number,
  projects: projectArrayPropType,
  accountLabel: PropTypes.string.isRequired,
}

TradingAccountStartEndForm.defaultProps = {
  accountLabel: 'Trading Account'
}

const mapStateToProps = (state, ownProps)=> {
  const projects = lodash.get(state, ['single', 'profile', 'projects'])
  return {
    projects,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return mapDispatch(dispatch)({})
}

export default connect(mapStateToProps, mapDispatchToProps)(TradingAccountStartEndForm)

