import React, { Component } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import {
  Box,
  Button,
  Card,
  Grid,
  TextField,
  withStyles,
} from '@material-ui/core'
import { mapDispatch } from '../../utils/utils'
import connect from 'react-redux/es/connect/connect'
import PiCardContent from '../../components/PiCardContent'

const withStyle = withStyles((theme) => ({
  root: {},
  sendButton: {
    marginTop: theme.spacing(1)
  }
}))


class SendPanel extends Component {

  constructor (props) {
    super(props)
    this.state = {
      sendText: ''
    }
  }

  onClearSendText = () => {
    const sendText = ''
    this.setState({
      sendText
    })
  }

  onSearchChange = (v) => {
    const sendText = v.target.value
    this.setState({
      sendText
    })
  }

  onSendPressed = () => {
    const { onSendMessage } = this.props
    const { sendText } = this.state
    onSendMessage(sendText)
  }

  render() {
    const { className, classes } = this.props
    const { sendText } = this.state
    return (
      <div className={clsx(classes.root, className)} >
        <Box
          display="flex"
          justifyContent="flex-end"
        >
        </Box>
        <Box mt={3}>
          <Card>
            <PiCardContent>
              <Grid display="flex" container spacing={3}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    value={sendText}
                    onChange={this.onSearchChange}
                    multiline
                    rows={2}
                  />

                <Grid
                  item
                  xs={6}
                  sm={6}
                  lg={3}
                  xl={3}
                >
                  <Button
                    className={classes.sendButton}
                    fullWidth
                    size="large"
                    onClick={this.onClearSendText}
                    variant="contained"
                  >
                    Clear
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  lg={3}
                  xl={3}
                >
                  <Button
                    className={classes.sendButton}
                    color="primary"
                    fullWidth
                    onClick={this.onSendPressed}
                    size="large"
                    variant="contained"
                  >
                    Send
                  </Button>
                </Grid>
              </Grid>
            </PiCardContent>
          </Card>
        </Box>
      </div>
    )
  }
}

SendPanel.propTypes = {
  className: PropTypes.string,
  onSendMessage: PropTypes.func.isRequired
}

SendPanel.defaultProps = {
}

const mapStateToProps = (state, ownProps)=> {
  return {
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return mapDispatch(dispatch)({})
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyle(SendPanel))
