import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import {
  Card,
  TableBody,
  TableRow,
  makeStyles
} from '@material-ui/core'
import { display, fromMs } from '../../../../utils/timeUtils'
import { pairInfoPropTypes } from '../../../../utils/propTypesUtils'
import { baseQuote, mapDispatch } from '../../../../utils/utils'
import lodash from 'lodash'
import { actions as singleActions } from '../../../single/singleSlice'
import connect from 'react-redux/es/connect/connect'
import XscrollTable from '../../../ui/XscrollTable'
import PiTableCell from '../../../../components/PiTableCell'
import PiCardContent from '../../../../components/PiCardContent'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  cardContent: {
    height: '100%'
  },
  tableCell: {
    borderBottom: "none"
  }
}))

const SmallTableCell = ({children}) => {
  const classes = useStyles()
  return <PiTableCell
    className={classes.tableCell}>
    {children}
  </PiTableCell>
}

const IcebergBasicInfoView = ({ pairInfo, serverTime, spread, ask1, avlbBase, avlbQuote, bid1, lockedBase, lockedQuote}) => {
  const classes = useStyles()
  if (pairInfo === undefined) {
    return null
  }
  let updateAt = '-'
  if (serverTime) {
    updateAt = display(fromMs(serverTime))
  }
  const { pair } = pairInfo

  const [base, quote] = baseQuote(pair)
  return (
    <Card className={clsx(classes.root)}
    >
      <PiCardContent className={classes.cardContent}>
        <XscrollTable>
          <TableBody>
            <TableRow
              hover
            >
              <SmallTableCell>
                Update At
              </SmallTableCell>
              <SmallTableCell>
                {updateAt}
              </SmallTableCell>
              <SmallTableCell>
                  Pair
              </SmallTableCell>
              <SmallTableCell>
                {pair}
              </SmallTableCell>
            </TableRow>

            <TableRow
              hover
            >
              <SmallTableCell>
                {`Available Base (${base})`}
              </SmallTableCell>
              <SmallTableCell>
                {avlbBase}
              </SmallTableCell>
              <SmallTableCell>
                {`Frozen Base (${base})`}
              </SmallTableCell>
              <SmallTableCell>
                {lockedBase}
              </SmallTableCell>
            </TableRow>

            <TableRow
              hover
            >
              <SmallTableCell>
                {`Available Quote (${quote})`}
              </SmallTableCell>
              <SmallTableCell>
                {avlbQuote}
              </SmallTableCell>
              <SmallTableCell>
                {`Frozen Quote (${quote})`}
              </SmallTableCell>
              <SmallTableCell>
                {lockedQuote}
              </SmallTableCell>
            </TableRow>

            <TableRow
              hover
            >
              <SmallTableCell>
                Bid/Ask
              </SmallTableCell>
              <SmallTableCell>
                -
              </SmallTableCell>
              <SmallTableCell>
                Spread
              </SmallTableCell>
              <SmallTableCell>
                {spread}
              </SmallTableCell>
            </TableRow>

            <TableRow
              hover
            >
              <SmallTableCell>
                Bid(N/Q/Ap):
              </SmallTableCell>
              <SmallTableCell>
                -
              </SmallTableCell>
              <SmallTableCell>
                Ask(N/Q/Ap):
              </SmallTableCell>
              <SmallTableCell>
                -
              </SmallTableCell>
            </TableRow>

            <TableRow
              hover
            >
              <SmallTableCell>
                BidR(N/Q/AP):
              </SmallTableCell>
              <SmallTableCell>
                -
              </SmallTableCell>
              <SmallTableCell>
                Ask R(N/Q/AP):
              </SmallTableCell>
              <SmallTableCell>
                -
              </SmallTableCell>
            </TableRow>
          </TableBody>
        </XscrollTable>
      </PiCardContent>
    </Card>
  )
}

IcebergBasicInfoView.propTypes = {
  ask1: PropTypes.string.isRequired,
  avlbBase: PropTypes.string.isRequired,
  avlbQuote: PropTypes.string.isRequired,
  bid1: PropTypes.string.isRequired,
  lockedBase: PropTypes.string.isRequired,
  lockedQuote: PropTypes.string.isRequired,
  spread: PropTypes.string.isRequired,
  serverTime: PropTypes.number,
  pairInfo: pairInfoPropTypes
}

IcebergBasicInfoView.defaultProps= {
  ask1: '-',
  avlbBase: '-',
  avlbQuote: '-',
  bid1: '-',
  lockedBase: '-',
  lockedQuote: '-',
  spread: '-',
}

const mapStateToProps = (state, ownProps)=> {
  const pairInfo = lodash.get(state, ['single', 'curPageData', 'pairInfo'])
  const basicInfo = lodash.get(state, ['single', 'curPageData', 'basicInfo'])
  return {
    pairInfo,
    ...basicInfo
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return mapDispatch(dispatch)({...singleActions})
}

export default connect(mapStateToProps, mapDispatchToProps)(IcebergBasicInfoView)
