import PiTwoColRowGrid from '../../../../../components/PiTwoColRowGrid'
import React from 'react'

const DelevTargetItem = ({gridSize = 5, children, rest}) => {
  const keys = [
    'xl', 'lg', 'md', 'sm', 'xs'
  ]
  const rows = {}
  keys.forEach((k) => {
    rows[k] = gridSize
  })
  return (
    <PiTwoColRowGrid
      item
      {...rows}
      {...rest}
    >
      {children}
    </PiTwoColRowGrid>
  )
}

export default DelevTargetItem
