import { Box, makeStyles, Table } from '@material-ui/core'
import React from 'react'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  tableBox: {
    overflowX: 'auto',
    width: '100%',
  }
}))

const XscrollTable = ({ children, className, ...rest}) => {
  const classes = useStyles()

  return (
    <Box className={clsx(classes.tableBox, className)}>
      <Table size="small" {...rest}>
        {children}
      </Table>
    </Box>
  )
}

export default XscrollTable

