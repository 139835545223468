import fileSaver from 'file-saver'

function base64ToBlob (b64Data, contentType, sliceSize) {
  contentType = contentType || ''
  sliceSize = sliceSize || 512

  var byteCharacters = atob(b64Data)
  var byteArrays = []

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize)

    var byteNumbers = new Array(slice.length)
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    var byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }

  return new Blob(byteArrays, {type: contentType})
}

const downloadFile = (base64, fileName) => {
  const contentType = 'csv'

  // generate a blob, then a file and then save the file.
  const blob = base64ToBlob(base64, contentType)
  const file = new File([blob], fileName)
  fileSaver.saveAs(file)
}

export {
  downloadFile
}
