import PropTypes from 'prop-types'


const noteParamPropTypes = PropTypes.shape({
  content: PropTypes.string,
})

export {
  noteParamPropTypes,
}
