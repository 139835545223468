import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  makeStyles, Button
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert/Alert'
import lodash from 'lodash'


const useStyles = makeStyles((theme) => ({
  box: {
    height: '100%',
    display: "flex",
    justifyContent: "center",
    alignItems: 'center',
    flexDirection: "column"
  },
  alert: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  }
}))

const MsgAlertView = ({msg, severity, buttonText, onButtonClick}) => {
  const classes = useStyles()
  return (
    <Box
      display="flex"
      flexDirection="column"
      className={classes.box}
    >
      <Alert severity={severity} className={classes.alert}>
        {msg}
      </Alert>
      {lodash.isEmpty(buttonText)
        ? null
        : <Button
          color="primary"
          size="large"
          variant="contained"
          onClick={onButtonClick}
        >
          {buttonText}
        </Button>
      }
    </Box>
  )
}

MsgAlertView.propTypes = {
  msg: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]).isRequired,
  buttonText: PropTypes.string.isRequired,
  onButtonClick: PropTypes.func,
  severity: PropTypes.string.isRequired
}

MsgAlertView.defaultProps= {
  msg: '',
  buttonText: '',
  severity: 'error'
}

export default MsgAlertView
