import React, { Component } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import lodash from 'lodash'
import {
  Box,
  Card,
  InputLabel,
  FormControl,
  Grid,
  TextField,
  InputAdornment,
  Select,
  SvgIcon,
  withStyles,
  MenuItem, Button, Switch, FormControlLabel
} from '@material-ui/core'
import { Search as SearchIcon } from 'react-feather'
import { mapDispatch } from '../../../utils/utils'
import connect from 'react-redux/es/connect/connect'
import {exchangeArrayPropType, projectArrayPropType} from '../../../utils/propTypesUtils'
import PiCardContent from '../../../components/PiCardContent'

const withStyle = withStyles((theme) => ({
  root: {}
}))

const getProjItemLabel = (projectName, accountCount) => {
  return `${projectName} (${accountCount})`
}

const getExchangeItemLabel = (exchangeName, accountCount) => {
  return `${exchangeName} (${accountCount})`
}

class AccountListToolbar extends Component {

  constructor (props) {
    super(props)
    this.state = {
      searchingText: '', // the text about to search
      selectedProjectId: '',
      selectedExchangeId: '',
    }
  }

  setSearchingText = (searchingText) => {
    const newState = lodash.merge(this.state, {
      searchingText
    })
    this.setState(newState)
  }

  setSelectedProjectId = (selectedProjectId) => {
    this.setState({
      selectedProjectId
    })
  }

  setSelectedExchangeId = (selectedExchangeId) => {
    this.setState({
      selectedExchangeId
    })
  }

  render() {
    const { projects, exchanges, setAccountSearchText, setFilteredProjectId, setFilteredExchangeId, setShowSelectedChecked, onAddSession, className, classes, ...rest } = this.props
    const { searchingText, selectedProjectId, selectedExchangeId } = this.state
    const exchangeIdToNameMap = {}
    lodash.forEach(exchanges, (ec) => {
      const { id, name } = ec
      exchangeIdToNameMap[id] = name
    })

    const exchangeIdToAccCount = {}
    let accTotCount = 0
    const projectOptions = lodash.map(projects, (p) => {
      const { id, name, exchangeAccounts } = p
      const c = lodash.size(exchangeAccounts)
      accTotCount = accTotCount + c
      lodash.forEach(exchangeAccounts, (ea) => {
        const { exchangeId } = ea
        const accCount = lodash.get(exchangeIdToAccCount, exchangeId, 0)
        exchangeIdToAccCount[exchangeId] = accCount + 1
      })

      return {
        value: id,
        label: getProjItemLabel(name, c)
      }
    })

    const exchangeOptions = []
    let exTotCount = 0
    lodash.forEach(exchanges, (e) => {
      const { id: exId, name } = e
      const accCount = lodash.get(exchangeIdToAccCount, exId, 0)
      if (accCount <= 0) {
        return
      }

      exTotCount = exTotCount + accCount
      exchangeOptions.push({
        value: exId,
        label: getExchangeItemLabel(name, accCount)
      })
    })



    const onSearch = (event) => {
      const val = lodash.get(event, ['target', 'value'], '')
      const newSearchText = String(val)
      this.setSearchingText(newSearchText)
      setTimeout(() => {
        if (this.state.searchingText === newSearchText) {
          setAccountSearchText(newSearchText)
        }
      }, 500)
    }

    const onProjectChange = (event) => {
      const val = event.target.value
      this.setSelectedProjectId(val)
      setFilteredProjectId(val)
    }

    const onExchangeChange = (event) => {
      const val = event.target.value
      this.setSelectedExchangeId(val)
      setFilteredExchangeId(val)
    }

    const onShowSelectedChange = (event) => {
      const checked = event.target.checked
      setShowSelectedChecked(checked)
    }

    return (
      <div
        className={clsx(classes.root, className)}
        {...rest}
      >
        <Box
          display="flex"
          justifyContent="flex-end"
        >
          <Button
            color="primary"
            variant="contained"
            onClick={onAddSession}
          >
            Edit Selected Accounts
          </Button>
        </Box>
        <Box mt={3}>
          <Card>
            <PiCardContent
              disablePiStyle
            >
              <Grid display="flex" container spacing={3}>
                <Grid
                  item
                  lg={3}
                  sm={6}
                  xl={3}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgIcon
                            fontSize="small"
                            color="action"
                          >
                            <SearchIcon />
                          </SvgIcon>
                        </InputAdornment>
                      )
                    }}
                    value={searchingText}
                    onChange={onSearch}
                    placeholder="Search Account"
                    variant="outlined"
                  />
                </Grid>

                <Grid
                  item
                  lg={3}
                  sm={6}
                  xl={3}
                  xs={12}
                >
                  <FormControl fullWidth>
                    <InputLabel htmlFor="age-native-simple">Filter by Exchange</InputLabel>
                    <Select
                      value={selectedExchangeId}
                      onChange={onExchangeChange}
                    >
                      <MenuItem key="empty_item" value="">{getExchangeItemLabel('All', exTotCount)}</MenuItem>
                      {
                        exchangeOptions.map((op, idx) => {
                          return (
                            <MenuItem key={op.value} value={op.value}>{op.label}</MenuItem>
                          )
                        })
                      }
                    </Select>
                  </FormControl>
                </Grid>

                <Grid
                  item
                  lg={3}
                  sm={6}
                  xl={3}
                  xs={12}
                >
                  <FormControl fullWidth>
                    <InputLabel htmlFor="age-native-simple">Filter by Project</InputLabel>
                    <Select
                      value={selectedProjectId}
                      onChange={onProjectChange}
                    >
                      <MenuItem key="empty_item" value="">{getProjItemLabel('All', accTotCount)}</MenuItem>
                      {
                        projectOptions.map((op, idx) => {
                          return (
                            <MenuItem key={op.value} value={op.value}>{op.label}</MenuItem>
                          )
                        })
                      }
                    </Select>
                  </FormControl>
                </Grid>

                <Grid
                  item
                  lg={3}
                  sm={6}
                  xl={3}
                  xs={12}
                >
                  <FormControlLabel
                    control={<Switch
                      onChange={onShowSelectedChange}
                    />}
                    label="Show Selected"
                  />
                </Grid>
              </Grid>
            </PiCardContent>
          </Card>
        </Box>
      </div>
    )
  }
}

AccountListToolbar.propTypes = {
  className: PropTypes.string,
  setAccountSearchText: PropTypes.func.isRequired,
  setFilteredProjectId: PropTypes.func.isRequired,
  setFilteredExchangeId: PropTypes.func.isRequired,
  setShowSelectedChecked: PropTypes.func.isRequired,
  onAddSession: PropTypes.func.isRequired,
  projects: projectArrayPropType,
  exchanges: exchangeArrayPropType,
}

AccountListToolbar.defaultProps = {
  projects: [],
  exchanges: []
}

const mapStateToProps = (state, ownProps)=> {
  const projects = lodash.get(state, ['single', 'profile', 'projects'])
  const exchanges = lodash.get(state, ['single', 'profile', 'exchanges'])
  return {
    projects,
    exchanges
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return mapDispatch(dispatch)({})
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyle(AccountListToolbar))
