import React from 'react'
import { Box, Grid, makeStyles } from '@material-ui/core'
import PiTwoColRowGrid from '../../../../../components/PiTwoColRowGrid'
import PiThreeColRowGrid from '../../../../../components/PiThreeColRowGrid'
import { Field } from 'formik'
import FormInput from '../../../../../components/form/FormInput'
import FormSlider from '../../../../../components/form/FormSlider'
import PiInputAdornment from '../../../../../components/PiInputAdornment'
import FormCheckbox from '../../../../../components/form/FormCheckbox'
import { setPctPxConvFieldsByValues, setTotalQtyFieldByValues } from './icebergUtils'
import { pairInfoPropTypes } from '../../../../../utils/propTypesUtils'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  cardSection: {

  },
  gridSection: {
  }
}))

const IcebergPriceSubForm = ({values, setFieldValue, pairInfo, avlbBase, avlbQuote, ...rest}) => {
  const classes = useStyles()
  const { pxPrec } = pairInfo
  const { reserveEnabled = false, infiniteOrdersEnabled=false, tpOrderEnabled = false } = values

  const onChangePctToConv = (fieldName, fieldValue) => {
    const newValues = {
      ...values,
      [fieldName]: fieldValue
    }
    const newValuesUpdated = setPctPxConvFieldsByValues(true, newValues, setFieldValue)
    // based on the new price, update pct
    setTotalQtyFieldByValues(false, avlbBase, avlbQuote, newValuesUpdated, setFieldValue)
  }

  const onChangePxToConv = (fieldName, fieldValue) => {
    const newValues = {
      ...values,
      [fieldName]: fieldValue
    }
    const newValuesUpdated = setPctPxConvFieldsByValues(false, newValues, setFieldValue)
    // based on the new price, update pct
    setTotalQtyFieldByValues(false, avlbBase, avlbQuote, newValuesUpdated, setFieldValue)
  }

  return (
    <Box className={classes.cardSection}>
      <Grid container spacing={0} className={classes.gridSection}>
        <PiTwoColRowGrid>
          <Field
            name="refPx"
            label="Ref Px"
            prec={pxPrec}
            postChange={onChangePctToConv}
            inputProps={{
              type: 'number',
              style: {
                textAlign: 'right'
              }
            }}
            component={FormInput}
          />
        </PiTwoColRowGrid>

        <PiTwoColRowGrid>
          <Field
            name="orderPxRange"
            label="Ord. Px. Rng"
            min={0}
            max={10}
            component={FormSlider}
          />
        </PiTwoColRowGrid>


        <PiTwoColRowGrid>
          <Field
            name="bandStartPct"
            label="Start"
            prec="0.01"
            postChange={onChangePctToConv}
            InputProps={{
              endAdornment: <PiInputAdornment position="end" label="%"/>
            }}
            inputProps={{
              type: 'number',
              style: {
                textAlign: 'right'
              }
            }}
            component={FormInput}
          />
        </PiTwoColRowGrid>
        <PiTwoColRowGrid>
          <Field
            name="bandStartPx"
            prec={pxPrec}
            postChange={onChangePxToConv}
            inputProps={{
              type: 'number',
              style: {
                textAlign: 'right'
              }
            }}
            component={FormInput}
          />
        </PiTwoColRowGrid>

        <PiTwoColRowGrid>
          <Field
            name="bandEndPct"
            label="End"
            prec="0.01"
            postChange={onChangePctToConv}
            InputProps={{
              endAdornment: <PiInputAdornment position="end" label="%"/>
            }}
            inputProps={{
              type: 'number',
              style: {
                textAlign: 'right'
              }
            }}
            component={FormInput}
          />
        </PiTwoColRowGrid>

        <PiTwoColRowGrid>
          <Field
            name="bandEndPx"
            prec={pxPrec}
            postChange={onChangePxToConv}
            inputProps={{
              type: 'number',
              style: {
                textAlign: 'right'
              }
            }}
            component={FormInput}
          />
        </PiTwoColRowGrid>

        <PiThreeColRowGrid>
          <Field
            name="reservePct"
            label="Reserve"
            prec="0.01"
            postChange={onChangePctToConv}
            disabled={!reserveEnabled}
            InputProps={{
              endAdornment: <PiInputAdornment position="end" label="%"/>
            }}
            inputProps={{
              type: 'number',
              style: {
                textAlign: 'right'
              }
            }}
            component={FormInput}
          />
        </PiThreeColRowGrid>


        <PiThreeColRowGrid>
          <Field
            name="reservePx"
            prec={pxPrec}
            postChange={onChangePxToConv}
            disabled={!reserveEnabled || infiniteOrdersEnabled}
            inputProps={{
              type: 'number',
              style: {
                textAlign: 'right'
              }
            }}
            component={FormInput}
          />
        </PiThreeColRowGrid>

        <PiThreeColRowGrid>
          <Field
            name="reserveEnabled"
            label="Use Reserve"
            disabled={infiniteOrdersEnabled}
            component={FormCheckbox}
          />
        </PiThreeColRowGrid>

        <PiThreeColRowGrid>
          <Field
            name="tpPctDelta"
            label="TP At"
            prec="0.001"
            postChange={onChangePctToConv}
            disabled={!tpOrderEnabled || infiniteOrdersEnabled}
            InputProps={{
              endAdornment: <PiInputAdornment position="end" label="%"/>
            }}
            inputProps={{
              type: 'number',
              style: {
                textAlign: 'right'
              }
            }}
            component={FormInput}
          />
        </PiThreeColRowGrid>

        <PiThreeColRowGrid>
          <Field
            name="tpPxDelta"
            prec={pxPrec}
            disabled={!tpOrderEnabled || infiniteOrdersEnabled}
            inputProps={{
              type: 'number',
              style: {
                textAlign: 'right'
              }
            }}
            component={FormInput}
          />
        </PiThreeColRowGrid>

        <PiThreeColRowGrid>
          <Field
            disabled={infiniteOrdersEnabled}
            name="tpOrderEnabled"
            label="Use TP"
            component={FormCheckbox}
          />
        </PiThreeColRowGrid>
      </Grid>
    </Box>
  )
}

IcebergPriceSubForm.propTypes = {
  values: PropTypes.object,
  pairInfo: pairInfoPropTypes,
  setFieldValue: PropTypes.func.isRequired,
  avlbBase: PropTypes.string.isRequired,
  avlbQuote: PropTypes.string.isRequired,
}


export default IcebergPriceSubForm
