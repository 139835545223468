import React, { useEffect, useCallback } from 'react'
import { baseQuote, isValidNumber } from '../../../../../utils/utils'
import { Box, Grid, makeStyles } from '@material-ui/core'
import PiTwoColRowGrid from '../../../../../components/PiTwoColRowGrid'
import { Field } from 'formik'
import lodash from 'lodash'
import PiInputAdornment from '../../../../../components/PiInputAdornment'
import FormInput from '../../../../../components/form/FormInput'
import FormCheckbox from '../../../../../components/form/FormCheckbox'
import FormSlider from '../../../../../components/form/FormSlider'
import { useRefFieldsPrevious, useRunOnce } from '../../../../../utils/hooks'
import PropTypes from 'prop-types'
import { pairInfoPropTypes } from '../../../../../utils/propTypesUtils'
import { BUY_SELL_OPTIONS, SIDE_BUY, SIDE_SELL } from '../../../../../protocolConstants'
import FormRadio from '../../../../../components/form/FormRadio'
import logger from '../../../../../utils/logger'
import { setPctPxConvFieldsByValues, setTotalQtyFieldByValues } from './icebergUtils'
import PiThreeColRowGrid from '../../../../../components/PiThreeColRowGrid'


const useStyles = makeStyles((theme) => ({
  cardSectionQty: {
  },
}))

const ALL_VSB_PCT= 100 // if vsb pct is this number, means all orders are visible, and no hidden orders

const SliderGrid = ({ children }) => {
  return (
    <PiTwoColRowGrid
      sm={12}
      xs={12}
    >
      { children }
    </PiTwoColRowGrid>
  )
}

const sliderExtraProps = () => {
  return {
    labelGridProps:{
      sm: 2,
      xs: 3
    },
    sliderGridProps: {
      sm: 7,
      xs: 6
    }
  }
}


const IcebergQtySubForm = ({values, pairInfo, avlbBase, avlbQuote, setFieldValue}) => {
  const classes = useStyles()
  const {qtyPrec, pair} = pairInfo
  const [base, quote] = baseQuote(pair)
  let {useQuote = false, infiniteOrdersEnabled=false, buySell } = values

  let ifRemainAsset = ''
  if (buySell === SIDE_BUY) {
    ifRemainAsset = quote
  } else if (buySell === SIDE_SELL) {
    ifRemainAsset = base
  }

  const adormentLabel = useQuote ? quote : base

  const pctVisiblePerLayer = lodash.get(values, 'pctVisiblePerLayer')
  const prevPctVisiblePerLayer = useRefFieldsPrevious('pctVisiblePerLayer', pctVisiblePerLayer)

  useEffect(() => {

    if (prevPctVisiblePerLayer === ALL_VSB_PCT && pctVisiblePerLayer !== ALL_VSB_PCT) {
      // change to non zero
      setFieldValue('numHiddenOrdersPerLayer', 1)
    } else if (prevPctVisiblePerLayer !== ALL_VSB_PCT && pctVisiblePerLayer === ALL_VSB_PCT) {
      // change to zero
      setFieldValue('numHiddenOrdersPerLayer', 0)
    }
  }, [pctVisiblePerLayer, prevPctVisiblePerLayer, setFieldValue])

  const buySellPostChange = (fieldName, newBuySell) => {
    if (fieldName !== 'buySell') {
      logger.debug(`BuySellPostChange fieldName: ${fieldName} is not buySell`)
      return
    }
    const newValues = {
      ...values,
      [fieldName]: newBuySell
    }
    setPctPxConvFieldsByValues(true, newValues, setFieldValue)
    setTotalQtyFieldByValues(true, avlbBase, avlbQuote, newValues, setFieldValue)
  }

  const handleIntValueLabel = useCallback((val) => {
    if(isValidNumber(val)) {
      return `${val}`
    } else {
      return ''
    }
  }, [])

  const handleSecValueLabel = useCallback((val) => {
    if(isValidNumber(val)) {
      return `${parseInt(val/1000)} Sec`
    } else {
      return ''
    }
  }, [])

  const onTotalQtyPostChange = (fieldName, fieldNewValue) => {
    const newValues = {
      ...values,
      [fieldName]: fieldNewValue // input fieldName can only be total qty
    }
    setTotalQtyFieldByValues(false, avlbBase, avlbQuote, newValues, setFieldValue)
  }

  /**
   * handle when
   * 1) useQuote changed
   * 2) totalQtyPct Changed
   * Hence we update totalQty
   */
  const onQtyRelatedPostChange = (fieldName, fieldNewValue) => {
    const newValues = {
      ...values,
      [fieldName]: fieldNewValue // input fieldName can be either useQuote or totalQtyPct
    }
    setTotalQtyFieldByValues(true, avlbBase, avlbQuote, newValues, setFieldValue)
  }

  useRunOnce(() => {
    // initialize the total qty pct
    setTotalQtyFieldByValues(false, avlbBase, avlbQuote, values, setFieldValue)
  })

  const qtyPctHandleValueLabel = useCallback((val) => {
    if(isValidNumber(val)) {
      if (val > 100) {
        return `100%+`
      } else {
        return `${val}%`
      }
    } else {
      return ''
    }
  }, [])

  return (
    <Box className={classes.cardSectionQty}
    >
      <Grid display="flex" container spacing={0}>


        <PiThreeColRowGrid>
          <Field
            name="iOPerOrderQty"
            label="Infi Ord Qty"
            prec={qtyPrec}
            disabled={!infiniteOrdersEnabled}
            InputProps={{
              endAdornment: <PiInputAdornment position="end" label={base}/>,
            }}
            inputProps={{
              type: 'number',
              style: {
                textAlign: 'right'
              }
            }}
            component={FormInput}
          />
        </PiThreeColRowGrid>


        <PiThreeColRowGrid>
          <Field
            name="iOFinalInventory"
            label="Infi Fnl Invt"
            disabled={!infiniteOrdersEnabled}
            InputProps={{
              endAdornment: <PiInputAdornment position="end" label={ifRemainAsset}/>,
            }}
            inputProps={{
              type: 'number',
              style: {
                textAlign: 'right'
              }
            }}
            component={FormInput}
          />
        </PiThreeColRowGrid>

        <PiThreeColRowGrid>
          <Field
            name="infiniteOrdersEnabled"
            label="Infi Orders Enabled"
            component={FormCheckbox}
          />
        </PiThreeColRowGrid>

        <PiTwoColRowGrid
          xl={6}
          lg={6}
          md={6}
          sm={5}
          xs={5}
        >
          <Field
            name="totalQty"
            label="Tot. Qty"
            prec={qtyPrec}
            disabled={infiniteOrdersEnabled}
            postChange={onTotalQtyPostChange}
            inputProps={{
              type: 'number',
              style: {
                textAlign: 'right'
              }
            }}
            InputProps={{
              endAdornment: <PiInputAdornment position="end" label={adormentLabel}/>,
            }}
            component={FormInput}
          />
        </PiTwoColRowGrid>

        <PiTwoColRowGrid
          xl={2}
          lg={2}
          md={2}
          sm={2}
          xs={2}
        >
          <Field
            disabled={infiniteOrdersEnabled}
            name="useQuote"
            label="Use Qte"
            postChange={onQtyRelatedPostChange}
            component={FormCheckbox}
          />
        </PiTwoColRowGrid>

        <PiTwoColRowGrid
          xl={4}
          lg={4}
          md={4}
          sm={5}
          xs={5}
        >
          <Field
            name="totalQtyPct"
            label="Qty Pct"
            disabled={infiniteOrdersEnabled}
            min={0}
            max={100}
            step={25}
            marks={true}
            labelGridProps={{
              sm: 3,
              xs: 3
            }}
            valueLabelGridProps= {{
              sm: 4,
              xs: 4
            }}
            handleValueLabel={qtyPctHandleValueLabel}
            postChange={onQtyRelatedPostChange}
            component={FormSlider}
          />
        </PiTwoColRowGrid>

        <SliderGrid>
          <Field
            name="pctVisiblePerLayer"
            disabled={infiniteOrdersEnabled}
            label="Vsb Pct"
            min={0}
            max={100}
            component={FormSlider}
            {...sliderExtraProps()}
          />
        </SliderGrid>

        <SliderGrid>
          <Field
            name="updItvl"
            label="Upd Itvl"
            min={0}
            max={60000}
            handleValueLabel={handleSecValueLabel}
            component={FormSlider}
            {...sliderExtraProps()}
          />
        </SliderGrid>

        <SliderGrid>
          <Field
            name="layers"
            label="No. Layers"
            min={1}
            max={50}
            handleValueLabel={handleIntValueLabel}
            component={FormSlider}
            {...sliderExtraProps()}
          />
        </SliderGrid>

        <SliderGrid>
          <Field
            name="numHiddenOrdersPerLayer"
            label="Hid Ord. PL"
            min={0}
            max={20}
            disabled={pctVisiblePerLayer === ALL_VSB_PCT || infiniteOrdersEnabled}
            handleValueLabel={handleIntValueLabel}
            component={FormSlider}
            {...sliderExtraProps()}
          />
        </SliderGrid>

        <PiTwoColRowGrid>
          <Field
            name="buySell"
            label=""
            postChange={buySellPostChange}
            options={BUY_SELL_OPTIONS}
            component={FormRadio}
          />
        </PiTwoColRowGrid>

        <PiTwoColRowGrid>
          <Field
            disabled={infiniteOrdersEnabled}
            name="orderQtyRange"
            label="Ord. Qty Rng"
            min={0}
            max={10}
            component={FormSlider}
          />
        </PiTwoColRowGrid>
      </Grid>
    </Box>
  )
}

IcebergQtySubForm.propTypes = {
  pairInfo: pairInfoPropTypes,
  setFieldValue: PropTypes.func.isRequired,
  avlbBase: PropTypes.string.isRequired,
  avlbQuote: PropTypes.string.isRequired,
}


export default IcebergQtySubForm
