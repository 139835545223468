import React, { useCallback, useEffect } from 'react'
import * as Yup from 'yup'
import { Field, Formik } from 'formik'
import {
  Box,
  Button,
  makeStyles,
  Card,
  Grid,
} from '@material-ui/core'
import { baseQuote, isValidNumber, mapDispatch } from '../../../../utils/utils'
import { actions as socketActions } from '../../../socket/socketSlice'
import { actions as singleActions, showToast } from '../../../single/singleSlice'
import connect from 'react-redux/es/connect/connect'
import PropTypes from 'prop-types'
import lodash from 'lodash'
import FormRadio from '../../../../components/form/FormRadio'
import FormInterval from '../../../../components/form/FormInterval'
import FormInput from '../../../../components/form/FormInput'
import {
  BUY_SELL_OPTIONS, CMD_MAKER_TAKER_CANCEL_ALL_ORDERS, CMD_MAKER_TAKER_CANCEL_OLD_ORDERS,
  CMD_MAKER_TAKER_EXECUTE_TAKER,
  CMD_MAKER_TAKER_PREVIEW_TAKER,
  CMD_MAKER_TAKER_STOP_TAKER,
  SIDE_BUY, SIDE_SELL,
} from '../../../../protocolConstants'
import PiTwoColRowGrid from '../../../../components/PiTwoColRowGrid'
import { useRefFields, useRefFieldsPrevious, useRefFieldsPrevValueChanged, useStateReq } from '../../../../utils/hooks'
import { makerTakerDialogPropType, pairInfoPropTypes, paramTakerPropType } from '../../../../utils/propTypesUtils'
import TakerAlgoFormDialog from './takerAlgoForm/TakerAlgoFormDialog'
import ColorButton from '../../../../components/ColorButton'
import PiThreeColRowGrid from '../../../../components/PiThreeColRowGrid'
import PiCardContent from '../../../../components/PiCardContent'
import { PiFormContextProvider } from '../../../../utils/piFormContext'
import PiGridItem from '../../../../components/PiGridItem'
import FormCheckbox from '../../../../components/form/FormCheckbox'
import PiInputAdornment from '../../../../components/PiInputAdornment'

const useStyles = makeStyles((theme) => ({
  formBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  button: {
    height: "100%"
  }
}))

const AUTO_START_PX_WRT_STOP_PCT = 0.005


const TakerAlgoForm = ({ sessionId, pairInfo, dialogData, setCurPageData, paramTaker }) => {
  const classes = useStyles()

  const {
    isSending,
    isSuccessful,
    sendReq,
    sentData,
    receivedData,
    setStateField,
    isError,
  } = useStateReq()

  const showPreviewTakerDialog = useCallback((previewDataInput, previewSentDataInput) => {
    const dialogOpen = true
    const dialogType = 'executeTaker'
    setCurPageData({
      dialogData: {
        ...dialogData,
        dialogOpen,
        dialogType,
        previewData: previewDataInput,
        previewSentData: previewSentDataInput
      }
    })
  }, [setCurPageData, dialogData])

  const onCancelAllOrders = useCallback(() => {
    const dialogOpen = true
    const dialogType = 'cancelAllOrders'
    setCurPageData({
      dialogData: {
        ...dialogData,
        dialogOpen,
        dialogType,
      }
    })
  }, [setCurPageData, dialogData])

  const onCancel3HourOldOrders = useCallback(() => {
    const dialogOpen = true
    const dialogType = 'cancelOldOrders'
    setCurPageData({
      dialogData: {
        ...dialogData,
        dialogOpen,
        dialogType,
      }
    })
  }, [setCurPageData, dialogData])

  const onPreviewTaker = (values, { setErrors }) => {
    setStateField('valueErrors', undefined)
    // set the function into the ref
    setRefField('setErrors', setErrors)
    sendReq({
      isRelay: true,
      cmd: CMD_MAKER_TAKER_PREVIEW_TAKER,
      data: {
        sessionId,
        params: values
      }
    })
  }

  const [setRefField, getRefField] = useRefFields()
  const prevIsSending  = useRefFieldsPrevious('isSending', isSending)

  const onStopTaker = () => {
    const dialogOpen = true
    const dialogType = 'stopTaker'
    setCurPageData({
      dialogData: {
        ...dialogData,
        dialogOpen,
        dialogType
      }
    })
  }
  useEffect(() => {
    if (prevIsSending && !isSending) {
      const cmd = lodash.get(sentData, 'cmd')
      if(cmd === CMD_MAKER_TAKER_PREVIEW_TAKER) {
        if (isSuccessful) {
          const previewData = lodash.get(receivedData, 'data')
          showPreviewTakerDialog(previewData, sentData)
        } else if (isError) {
          const valueErrors = lodash.get(receivedData, ['data', 'valueErrors'])
          const formErrors = lodash.get(receivedData, ['data', 'formErrors'])
          setStateField('valueErrors', valueErrors)
          const setErrors = getRefField('setErrors')
          if (!lodash.isEmpty(formErrors) && lodash.isFunction(setErrors)) {
            setErrors(formErrors)
          }
        }
      } else if (cmd === CMD_MAKER_TAKER_STOP_TAKER) {
        if (isSuccessful) {
          showToast("Taker stopped successfully", 'success')
          const active = false
          setCurPageData({
            // params from props, hence immutable
            paramTaker: {
              ...paramTaker,
              active
            }
          })
        }
      } else if (cmd === CMD_MAKER_TAKER_EXECUTE_TAKER) {
        if (isSuccessful) {
          showToast("Taker execution successfully", 'success')
          const active = true
          setCurPageData({
            paramTaker: {
              ...paramTaker,
              active
            }
          })
        }
      } else if (cmd === CMD_MAKER_TAKER_CANCEL_ALL_ORDERS) {
        if (isSuccessful) {
          showToast("Cancel all orders successfully", 'success')
        }
      } else if (cmd === CMD_MAKER_TAKER_CANCEL_OLD_ORDERS) {
        if (isSuccessful) {
          showToast("Cancel 3 hours old orders successfully", 'success')
        }
      }
    }
  }, [prevIsSending, paramTaker, setCurPageData, getRefField, isSending, isSuccessful, isError, receivedData, sentData, setStateField, showPreviewTakerDialog])

  if (!pairInfo) {
    return null
  }

  if (!paramTaker) {
    return null
  }
  const { active } = paramTaker
  return (
    <Box className={classes.formBox}>
      <Formik
        initialValues={{
          ...paramTaker
        }}
        validationSchema={props =>  Yup.lazy(values => {
          return Yup.object().shape({
            // TODO:  further handle the taker algo form validation
            orderQty: Yup.number().required('Order Qty is required').moreThan(0, "Must be positive"),
            remainingQty: Yup.number().required('Remaining Qty is required'),
            startPx: Yup.number().required('Start Price is required').moreThan(0, "Must be positive"),
            stopPx: Yup.number().required('Stop Price is required').moreThan(0, 'Must be positive'),
            buySell: Yup.string().required('Buy and sell is required'),
            itvl: Yup.number().required('Interval is required'),
            manuallySetStartPx: Yup.bool(),
          })
        })}
        onSubmit={onPreviewTaker}
      >

        {(formProps) => {
          return (
            <PiFormContextProvider
              disabledAllFields={active}
            >
              <TakerAlgoFormInner
                formProps={formProps}
                active={active}
                pairInfo={pairInfo}
                onCancelAllOrders={onCancelAllOrders}
                onCancel3HourOldOrders={onCancel3HourOldOrders}
                onStopTaker={onStopTaker}
              />
            </PiFormContextProvider>
          )
        }}
      </Formik>

      <TakerAlgoFormDialog
        sessionId={sessionId}
        sendReq={sendReq}
      />
    </Box>
  )
}


TakerAlgoForm.propTypes = {
  sessionId: PropTypes.number.isRequired,
  dialogData: makerTakerDialogPropType,
  paramTaker: paramTakerPropType, // it is server's status
  pairInfo: pairInfoPropTypes,
}

TakerAlgoForm.defaultProps = {
  algorithms: []
}

const mapStateToProps = (state, ownProps)=> {
  const dialogData = lodash.get(state, ['single', 'curPageData', 'dialogData'])
  const paramTaker = lodash.get(state, ['single', 'curPageData', 'paramTaker'])
  const pairInfo = lodash.get(state, ['single', 'curPageData', 'pairInfo'])
  return {
    dialogData,
    paramTaker,
    pairInfo,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return mapDispatch(dispatch)({ ...socketActions, ...singleActions})
}

export default connect(mapStateToProps, mapDispatchToProps)(TakerAlgoForm)

const autoCalStartPx = (stopPx, buySell) => {
  stopPx = parseFloat(stopPx)
  if (!isValidNumber(stopPx)) {
    return 0
  }
  if (buySell === SIDE_BUY) {
    return stopPx * (1 - AUTO_START_PX_WRT_STOP_PCT)
  } else if (buySell === SIDE_SELL) {
    return stopPx * (1 + AUTO_START_PX_WRT_STOP_PCT)
  } else {
    return 0
  }
}

const TakerAlgoFormInner = ({ formProps, pairInfo, active, onStopTaker, onCancelAllOrders, onCancel3HourOldOrders }) => {
  const { values, handleSubmit, setFieldValue } = formProps
  const { pxPrec, pair } = pairInfo
  const [base, quote] = baseQuote(pair)
  const classes = useStyles()
  let isSending = false
  const { buySell,manuallySetStartPx, stopPx } = values

  let remainingAssetLabel = ''
  if (buySell === SIDE_BUY) {
    remainingAssetLabel = quote
  } else {
    remainingAssetLabel = base
  }

  const stopPxChanged = useRefFieldsPrevValueChanged('stopPx', stopPx)
  const buySellChanged = useRefFieldsPrevValueChanged('buySell', buySell)
  const manuallySetStartPxChanged = useRefFieldsPrevValueChanged('manuallySetStartPx', manuallySetStartPx)
  useEffect(() => {
    if (!manuallySetStartPx) {
      if (stopPxChanged || buySellChanged || manuallySetStartPxChanged) {
        const newStartPx = autoCalStartPx(stopPx, buySell)
        setFieldValue('startPx', newStartPx)
      }
    }
  }, [stopPxChanged, buySellChanged, buySell, manuallySetStartPx, stopPx, setFieldValue, manuallySetStartPxChanged])

  let startStopButton
  if (active) {
    startStopButton = <Button
      className={classes.button}
      disabled={isSending}
      fullWidth
      size="small"
      onClick={onStopTaker}
      variant="contained"
    >
      Stop
    </Button>
  } else {
    let buttonLabel
    let colorStyle
    if (buySell === SIDE_BUY) {
      buttonLabel = "Preview & Buy"
      colorStyle = "green"
    } else if (buySell === SIDE_SELL) {
      buttonLabel = "Preview & Sell"
      colorStyle = "red"
    } else {
      buttonLabel = "Start"
    }
    startStopButton = <ColorButton
      className={classes.button}
      disabled={isSending}
      fullWidth
      size="small"
      type="submit"
      colorStyle={colorStyle}
    >
      {buttonLabel}
    </ColorButton>
  }

  return (
    <form onSubmit={handleSubmit}>
      <Card>
        <PiCardContent>
          <Grid container>
            <PiTwoColRowGrid>
              <Field
                name="orderQty"
                label="Order Quantity"
                InputProps={{
                  endAdornment: <PiInputAdornment position="end" label={base}/>
                }}
                inputProps={{
                  type: 'number',
                  style: {
                    textAlign: 'right'
                  }
                }}
                component={FormInput}
              />
            </PiTwoColRowGrid>

            <PiTwoColRowGrid>
              <Field
                name="remainingQty"
                label="Remaining Asset"
                InputProps={{
                  endAdornment: <PiInputAdornment position="end" label={remainingAssetLabel}/>
                }}
                inputProps={{
                  type: 'number',
                  style: {
                    textAlign: 'right'
                  }
                }}
                component={FormInput}
              />
            </PiTwoColRowGrid>

            <PiGridItem
              xl={8}
              lg={8}
              md={8}
              sm={8}
              xs={8}
            >
              <Field
                name="startPx"
                label="Start Price"
                disabled={!manuallySetStartPx}
                prec={pxPrec}
                inputProps={{
                  type: 'number',
                  style: {
                    textAlign: 'right'
                  }
                }}
                component={FormInput}
              />
            </PiGridItem>

            <PiGridItem
              xl={4}
              lg={4}
              md={4}
              sm={4}
              xs={4}
            >
              <Field
                name="manuallySetStartPx"
                label="Man. Set Start Px"
                component={FormCheckbox}
              />
            </PiGridItem>

            <PiTwoColRowGrid
              xl={8}
              lg={8}
              md={8}
              sm={8}
              xs={8}
            >
              <Field
                name="stopPx"
                label="Stop Price"
                prec={pxPrec}
                inputProps={{
                  type: 'number',
                  style: {
                    textAlign: 'right'
                  }
                }}
                component={FormInput}
              />
            </PiTwoColRowGrid>

            <PiGridItem
              xl={4}
              lg={4}
              md={4}
              sm={4}
              xs={4}
            >
              {/* Empty grid just to ensure start and stop px align */}
            </PiGridItem>

            <PiGridItem
              xl={8}
              lg={8}
              md={8}
              sm={8}
              xs={8}
            >
              <Field
                name="itvl"
                label="Interval"
                component={FormInterval}
              />
            </PiGridItem>

            <PiGridItem
              xl={4}
              lg={4}
              md={4}
              sm={4}
              xs={4}
            >
              <Field
                name="itvlRandEnabled"
                label="Itvl Rand Enabled"
                component={FormCheckbox}
              />
            </PiGridItem>

            <PiTwoColRowGrid
              xs={12}
            >
              <Field
                name="buySell"
                label=""
                options={BUY_SELL_OPTIONS}
                component={FormRadio}
              />
            </PiTwoColRowGrid>

          </Grid>

          <Box mt={2}>
            <Grid display="flex" container spacing={3}>
              <PiThreeColRowGrid>
                {startStopButton}
              </PiThreeColRowGrid>
              <PiThreeColRowGrid>
                <Button
                  className={classes.button}
                  fullWidth
                  size="small"
                  variant="contained"
                  onClick={onCancelAllOrders}
                >

                  Cancel All
                </Button>
              </PiThreeColRowGrid>

              <PiThreeColRowGrid>
                <Button
                  className={classes.button}
                  disabled={isSending}
                  fullWidth
                  size="small"
                  variant="contained"
                  onClick={onCancel3HourOldOrders}
                >
                  Cancel 3+ Hrs
                </Button>
              </PiThreeColRowGrid>
            </Grid>
          </Box>
        </PiCardContent>
      </Card>
    </form>
  )
}
