import React, { useContext } from 'react'
import { Checkbox, Grid, FormHelperText, FormControlLabel } from '@material-ui/core'
import PropTypes from 'prop-types'
import lodash from 'lodash'
import { createFieldPropTypes} from '../../utils/propTypesUtils'
import { formPropTypes, metaPropTypes } from '../../utils/propTypesUtils'
import { PiFormContext } from '../../utils/piFormContext'

const FormCheckbox = ({ field, label, form, postChange, disabled }) => {
  const { disabledAllFields } = useContext(PiFormContext)
  const { value = '', name } = field
  const { touched, errors, submitCount, setFieldValue } = form
  /**
   * the reason to check submit count is that, if field is not defined in the initValues,
   * the touched field may not set to true if we press submit button
   * */
  const fieldTouched = Boolean(lodash.get(touched, name) || submitCount > 0)
  const errMsg = lodash.get(errors, name, '')
  const error = Boolean(fieldTouched && errMsg)
  const helperText = fieldTouched && errMsg


  const handleOnChange = (e) => {
    let val = e.target.checked
    setFieldValue(name, val)
    if (lodash.isFunction(postChange)) {
      postChange(name, val)
    }
  }

  return (
    <Grid
      direction="column"
      container
    >
      <FormControlLabel
        disabled={disabled || disabledAllFields }
        control={
          <Checkbox
            checked={Boolean(value)}
            onChange={handleOnChange}
            size="small"
            name={name}
          />
        }
        label={label}
      />
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </Grid>
  )
}

FormCheckbox.propTypes = {
  field: createFieldPropTypes({
    value: PropTypes.bool
  }),
  postChange: PropTypes.func,
  form: formPropTypes,
  meta: metaPropTypes,
  label: PropTypes.string.isRequired,
}

FormCheckbox.defaultProps = {
  label: ''
}

export default FormCheckbox
