import React from 'react'
import XscrollTable from '../../../../ui/XscrollTable'
import { TableBody, Button, TableRow, makeStyles } from '@material-ui/core'
import PiTableCell from '../../../../../components/PiTableCell'
import lodash from 'lodash'
import Decimal from 'decimal.js'
import PropTypes from 'prop-types'
import { FORMAT_MDHMS_SHORT, fromMs } from '../../../../../utils/timeUtils'
import { COLOR_SELL } from '../../../../../constants'

const useStyles = makeStyles((theme) => ({
  buttonRed: {
    height: '100%',
    background: COLOR_SELL,
    "&:hover": {
      backgroundColor: COLOR_SELL
    },
    color: '#ffffff'
  }
}))

const TableRowNameVal = ({accName, tokenASpending, tokenAApproved, tokenAUpdatedAt, tokenARefresh, tokenBSpending, tokenBApproved, tokenBUpdatedAt, tokenBRefresh}) => {
  return (
    <TableRow hover>
      <PiTableCell
        variant='head'
      >
        {accName}
      </PiTableCell>
      <PiTableCell>
        {tokenASpending}
      </PiTableCell>

      <PiTableCell>
        {tokenAApproved}
      </PiTableCell>
      <PiTableCell>
        {tokenAUpdatedAt}
      </PiTableCell>
      <PiTableCell>
        {tokenARefresh}
      </PiTableCell>
      <PiTableCell>
      </PiTableCell>
      <PiTableCell>
        {tokenBSpending}
      </PiTableCell>
      <PiTableCell>
        {tokenBApproved}
      </PiTableCell>
      <PiTableCell>
        {tokenBUpdatedAt}
      </PiTableCell>
      <PiTableCell>
        {tokenBRefresh}
      </PiTableCell>
    </TableRow>
  )
}

const fromUpdatedAtMs = (ms) => {
  return ms === 0 ? 'N.A.' : fromMs(ms).format(FORMAT_MDHMS_SHORT)
}

const DexTakerSpendingDetailContent = ({ spendingInfos = {}, generalInfo, approveSpending, isSending }) => {
  const classes = useStyles()
  const accInfos = lodash.get(generalInfo, 'accInfos')
  const tokenAAddr = lodash.get(generalInfo, ['tokenA', 'addr'], '')
  const tokenBAddr = lodash.get(generalInfo, ['tokenB', 'addr'], '')

  const rows = lodash.map(accInfos, (accInfo) => {
    const { accName } = accInfo
    const { tokenAAllowable, tokenAUpdatedAtMs, tokenAIsApproved, tokenBAllowable, tokenBUpdatedAtMs, tokenBIsApproved } = lodash.get(spendingInfos, ['spendings', accName], {})
    let tokenAAllowableStr = ''
    let tokenBAllowableStr = ''
    try {
      tokenAAllowableStr = new Decimal(tokenAAllowable).toExponential(5)
    } catch (e) {}

    try {
      tokenBAllowableStr = new Decimal(tokenBAllowable).toExponential(5)
    } catch (e) {}

    const tokenAUpdatedAt = fromUpdatedAtMs(tokenAUpdatedAtMs)
    const tokenBUpdatedAt = fromUpdatedAtMs(tokenBUpdatedAtMs)
    return (<TableRowNameVal
      key={accName}
      accName={accName}
      tokenASpending={tokenAAllowableStr}
      tokenAApproved={`${tokenAIsApproved}`}
      tokenAUpdatedAt={tokenAUpdatedAt}
      tokenARefresh= {<Button
        disabled={isSending}
        variant='contained'
        size='small'
        className={tokenAIsApproved ? classes.buttonRed : ''}
        onClick={() => approveSpending(tokenAAddr, accName)}
      >
        Approve A
      </Button>
      }
      tokenBSpending={tokenBAllowableStr}
      tokenBApproved={`${tokenBIsApproved}`}
      tokenBUpdatedAt={tokenBUpdatedAt}
      tokenBRefresh={<Button
        disabled={isSending}
        variant='contained'
        size='small'
        className={tokenBIsApproved ? classes.buttonRed : ''}
        onClick={() => approveSpending(tokenBAddr, accName)}
      >
        Approve B
      </Button>}
    />)
  })
  return (
    <XscrollTable>
      <TableBody>
        <TableRowNameVal
          key='title_row'
          accName=""
          tokenASpending="A Allowable"
          tokenAApproved="A Approved"
          tokenAUpdatedAt='A UpdatedAt'
          tokenARefresh="A Action"
          tokenBSpending="B Allowable"
          tokenBApproved="B Approved"
          tokenBUpdatedAt='B UpdatedAt'
          tokenBRefresh="B Action"
        />
        {rows}
      </TableBody>
    </XscrollTable>
  )
}

DexTakerSpendingDetailContent.propTypes = {
  spendingInfos: PropTypes.object,
  generalInfo: PropTypes.shape({
    accInfos: PropTypes.arrayOf(PropTypes.shape({
      accAddr: PropTypes.string.isRequired,
      accName: PropTypes.string.isRequired,
    }))
  }),
  isSending: PropTypes.bool.isRequired,
  approveSpending: PropTypes.func.isRequired,
}

DexTakerSpendingDetailContent.defaultProps= {

}

export default DexTakerSpendingDetailContent
