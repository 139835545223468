import { Box, makeStyles, Typography, TableBody, TableRow } from '@material-ui/core'
import React from 'react'
import PropTypes from 'prop-types'
import lodash from 'lodash'
import { baseQuote, mapDispatch, roundDown } from '../../../../../utils/utils'
import { actions as singleActions } from '../../../../single/singleSlice'
import connect from 'react-redux/es/connect/connect'
import { pairInfoPropTypes } from '../../../../../utils/propTypesUtils'
import { calTotalBaseQuote } from './icebergUtils'
import Decimal from 'decimal.js'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import XscrollTable from '../../../../ui/XscrollTable'
import PiTableCell from '../../../../../components/PiTableCell'
import { SIDE_BUY, SIDE_SELL } from '../../../../../protocolConstants'
import { INFINITE_SYMBOL } from '../../../../../constants'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  tableFixed: {
    tableLayout: 'fixed'
  },
  tableCell: {
  }
}))

const BreakpointTableRows = ({ nameValues, colPerRow }) => {
  const rowCount = Math.ceil(nameValues.length / colPerRow)
  const ret = []
  for (let i = 0; i < rowCount; i ++) {
    const cells = []
    for (let j = 0; j < colPerRow; j  ++) {
      const nvIdx = i * colPerRow + j
      if (nvIdx >= nameValues.length) {
        break
      }
      const { name, value } = nameValues[nvIdx]
      cells.push(<PiTableCell
        variant='head'
        size='small'
        key={`${i}_${j}_name`}
      >
        {name}
      </PiTableCell>)

      cells.push(<PiTableCell
        size='small'
        key={`${i}_${j}_value`}
      >
        {value}
      </PiTableCell>)
    }
    ret.push(
      <TableRow hover key={`row_${i}`}>
        {cells}
      </TableRow>
    )
  }

  return ret
}

const IcebergEstimationView = ({values, pairInfo}) => {
  const classes = useStyles()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('md'))
  const {
    bandEndPx,
    bandStartPx,
    layers,
    pctVisiblePerLayer,
    numHiddenOrdersPerLayer,
    totalQty,
    useQuote,

    infiniteOrdersEnabled,
    iOFinalInventory,
    iOPerOrderQty,

    buySell,
  } = values

  if (!bandStartPx || !bandEndPx || !totalQty || !pairInfo) {
    return null
  }
  const { qtyPrec, pair } = pairInfo
  const [base, quote] = baseQuote(pair)
  let { avgPx, totalQuote, totalBase, totalHiddenBase, totalVisibleBase } = calTotalBaseQuote(
      totalQty, bandStartPx, bandEndPx, useQuote, pctVisiblePerLayer, qtyPrec
  )

  let hidQtyPerOrder
  if (totalHiddenBase.isZero()){
    hidQtyPerOrder = new Decimal('0')
  } else {
    hidQtyPerOrder = totalHiddenBase.div(layers).div(numHiddenOrdersPerLayer)
  }
  hidQtyPerOrder = roundDown(hidQtyPerOrder, qtyPrec)
  totalQuote = roundDown(totalQuote, qtyPrec)
  totalBase = roundDown(totalBase, qtyPrec)

  let vsbOrdQty = totalVisibleBase.div(layers)
  vsbOrdQty = roundDown(vsbOrdQty, qtyPrec)

  let nameValues
  if (infiniteOrdersEnabled) {
    let infiAsset = ''
    if (buySell === SIDE_BUY) {
      infiAsset = quote
    } else if (buySell === SIDE_SELL) {
      infiAsset = base
    }

    nameValues = [
      {
        name: "Infi Ord Enabled",
        value: "True",
      },
      {
        name: "Inf Per Ord Qty",
        value: `${iOPerOrderQty} ${base}`
      },
      {
        name: "Infi Fnl Invt",
        value: `${iOFinalInventory} ${infiAsset}`
      },
      {
        name: `Vsb. Ord. ${base}`,
        value: `${iOPerOrderQty} * ${layers}`
      },
      {
        name: `Hid. Ord ${base}`,
        value: `${iOPerOrderQty} * ${layers} * ${INFINITE_SYMBOL}`
      },
      {
        name: "Avg Px",
        value: new Decimal(avgPx).toFixed(),
      },
    ]
  } else {
    nameValues = [
      {
        name:"Num of Layers",
        value: new Decimal(layers).toFixed(),
      },
      {
        name: "Hid. Ord. per Layer",
        value: new Decimal(numHiddenOrdersPerLayer).toFixed(),
      },
      {
        name: `Vsb. Ord. ${base}`,
        value: `${vsbOrdQty} * ${layers}`
      },
      {
        name: `Hid. Ord ${base}`,
        value: `${hidQtyPerOrder} * ${layers} * ${numHiddenOrdersPerLayer}`
      },
      {
        name: "Avg Px",
        value: new Decimal(avgPx).toFixed(),
      },
      {
        name: `Tot. ${base}`,
        value: new Decimal(totalBase).toFixed(),
      },
      {
        name: `Tot. ${quote}`,
        value: new Decimal(totalQuote).toFixed(),
      }
    ]
  }
  let colPerRow
  if(matches) {
    // 1 col per row
    colPerRow = 1
  } else {
    // 2 col per row
    colPerRow = 2
  }
  return (
    <Box className={classes.root}>
      <Typography>
        Estimation
      </Typography>
      <XscrollTable className={classes.tableFixed}>
        <TableBody>
          <BreakpointTableRows
            colPerRow={colPerRow}
            nameValues={nameValues}
          />
        </TableBody>
      </XscrollTable>
    </Box>
  )
}

IcebergEstimationView.propTypes = {
  values: PropTypes.object,
  pairInfo: pairInfoPropTypes,
}

const mapStateToProps = (state, ownProps)=> {
  const pairInfo = lodash.get(state, ['single', 'curPageData', 'pairInfo'])
  return {
    pairInfo
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return mapDispatch(dispatch)(singleActions)
}

export default connect(mapStateToProps, mapDispatchToProps)(IcebergEstimationView)
