import lodash from 'lodash'
import { baseQuote } from '../../../../../utils/utils'
import React, { useCallback } from 'react'
import { Field } from 'formik'
import FormSelect from '../../../../../components/form/FormSelect'
import { DEFAULT_ASSET_QTY_PRECISION } from '../../../../../protocolConstants'
import PiInputAdornment from '../../../../../components/PiInputAdornment'
import FormInput from '../../../../../components/form/FormInput'
import { IconButton } from '@material-ui/core'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'
import TargetItem from './TargetItem'
import { createEmptySpammingTarget } from '../longShortUtils'

const SpammingTargetViews = ({values, accountPairsMap, setFieldValue, accountOptions, disabledAllFields}) => {
  const spammingTargetsView = []
  const {spammingAccName = null, spammingEnabled} = values
  const assetsOptions = []
  if (spammingAccName !== null) {
    const pairs = lodash.get(accountPairsMap, spammingAccName, [])
    const allBaseAssets = {}
    lodash.forEach(pairs, (pair) => {
      const [base, quote] = baseQuote(pair)
      if (base === '' || quote === '') {
        return
      }
      allBaseAssets[base] = true
    })
    lodash.forEach(allBaseAssets, (v, k) => {
      assetsOptions.push({
        value: k,
        label: k,
      })
    })
  }

  const spammingTargetsFieldName = 'spammingTargets'
  const spammingTargetsValues = lodash.get(values, spammingTargetsFieldName, [])
  const onAddPath = () => {
    if (spammingTargetsValues.length >= assetsOptions.length) {
      return
    }
    const targetsClone = lodash.clone(spammingTargetsValues)
    targetsClone.push(createEmptySpammingTarget())
    setFieldValue(spammingTargetsFieldName, targetsClone)
  }

  const onRemovePath = useCallback((idx) => () => {
    // at least need 2 items to remove
    if (spammingTargetsValues.length < 1) {
      return
    }
    const pathValuesClone = []
    spammingTargetsValues.forEach((tv, cuIdx) => {
      if (cuIdx === idx) {
        return
      }
      pathValuesClone.push(tv)
    })
    setFieldValue(spammingTargetsFieldName, pathValuesClone)
  }, [spammingTargetsValues, setFieldValue])

  if (accountOptions.length <= 0) {
    return null
  }
  spammingTargetsValues.forEach((target, idx) => {
    const isLast = idx === spammingTargetsValues.length - 1
    const asset = lodash.get(target, 'asset')
    const assetGridSize = 2
    const contentGridSize = 4


    spammingTargetsView.push(
      <TargetItem gridSize={assetGridSize} key={`targetitem_${idx}_asset`}>
        <Field
          disabled={!spammingEnabled}
          name={`${spammingTargetsFieldName}.${idx}.asset`}
          placeholder="Asset"
          options={assetsOptions}
          component={FormSelect}
        />
      </TargetItem>
    )

    spammingTargetsView.push(
      <TargetItem gridSize={contentGridSize} key={`targetitem_${idx}_targetqty`}>
        <Field
          name={`${spammingTargetsFieldName}.${idx}.targetQty`}
          label="Target Qty"
          prec={DEFAULT_ASSET_QTY_PRECISION}
          disabled={!spammingEnabled}
          InputProps={{
            endAdornment: <PiInputAdornment position="end" label={asset}/>,
          }}
          inputProps={{
            type: 'number',
            style: {
              textAlign: 'right'
            }
          }}
          component={FormInput}
        />
      </TargetItem>
    )

    spammingTargetsView.push(
      <TargetItem gridSize={contentGridSize} key={`targetitem_${idx}_orderqty`}>
        <Field
          name={`${spammingTargetsFieldName}.${idx}.orderQty`}
          label="Order Qty"
          prec={DEFAULT_ASSET_QTY_PRECISION}
          disabled={!spammingEnabled}
          InputProps={{
            endAdornment: <PiInputAdornment position="end" label={asset}/>,
          }}
          inputProps={{
            type: 'number',
            style: {
              textAlign: 'right'
            }
          }}
          component={FormInput}
        />
      </TargetItem>
    )

    spammingTargetsView.push(
      <TargetItem gridSize={2} key={`addRemoveButtons_${idx}`}>
        { isLast? <IconButton onClick={onAddPath} disabled={!spammingEnabled || disabledAllFields}>
          <AddCircleIcon color="primary"/>
        </IconButton>: null }
        <IconButton onClick={onRemovePath(idx)} disabled={!spammingEnabled || disabledAllFields}>
          <RemoveCircleIcon color="secondary"/>
        </IconButton>
      </TargetItem>
    )
  })

  return spammingTargetsView
}

export default SpammingTargetViews
