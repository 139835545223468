
const ERROR_CODE_SUCCESS = 0

const NOT_LOGGED_IN = 40000
const LOGIN_EXPIRED = 40001
const INVALID_AUTH_TOKEN = 40002 // need to log out, and remove the invalid token
const UNAUTHORIZED = 40003
const UNKNOWN_USER = 40004
const UNAUTHORIZED_TO_ALGO = 40101
const UNAUTHORIZED_TO_PROJECT = 40201
const UNAUTHORIZED_TO_EXCHANGE_ACCOUNT = 40202

const INVALID_CMD = 60001
const OUT_OF_WINDOW = 60002

const SERVER_ERROR = 50000
const CANNOT_READ_LOG_FILE = 50001

const MALFORMED_REQUEST = 70000
const UNKOWN_ALGO = 70001
const UNKOWN_PROJECT = 70002
const UNKOWN_EXCHANGE_ACCOUNT = 70003
const UNKOWN_SESSION = 70004
const DB_DATA_ERROR = 70005
const NO_SUITABLE_INSTANCER = 70006
const INSTANCER_HAS_GONE_AWAY = 70007

const INSTANCER_COMMUNICATION_ERROR = 80001
const SESSION_CLOSED = 90000
const UNABLE_TO_CLOSE_SESSION = 90001
const SESSION_ALREADY_RUNNING = 90002
const CANNOT_START_WORKER = 90003
const CANNOT_TERMINATE_WORKER = 90004
const SESSION_STARTING = 90005
const WORKER_NOT_READY = 90006

const VALIDATION_ERROR = 10000
const ALGO_ERROR = 10001
const TIMEOUT = 30000

export {
  ERROR_CODE_SUCCESS,
  NOT_LOGGED_IN,
  LOGIN_EXPIRED,
  INVALID_AUTH_TOKEN,
  UNAUTHORIZED,
  UNKNOWN_USER,
  UNAUTHORIZED_TO_ALGO,
  UNAUTHORIZED_TO_PROJECT,
  UNAUTHORIZED_TO_EXCHANGE_ACCOUNT,

  INVALID_CMD,
  OUT_OF_WINDOW,

  SERVER_ERROR,
  CANNOT_READ_LOG_FILE,

  MALFORMED_REQUEST,
  UNKOWN_ALGO,
  UNKOWN_PROJECT,
  UNKOWN_EXCHANGE_ACCOUNT,
  UNKOWN_SESSION,
  DB_DATA_ERROR,
  NO_SUITABLE_INSTANCER,
  INSTANCER_HAS_GONE_AWAY,

  INSTANCER_COMMUNICATION_ERROR,
  SESSION_CLOSED,
  UNABLE_TO_CLOSE_SESSION,
  SESSION_ALREADY_RUNNING,
  CANNOT_START_WORKER,
  CANNOT_TERMINATE_WORKER,
  SESSION_STARTING,
  WORKER_NOT_READY,

  VALIDATION_ERROR,
  ALGO_ERROR,
  TIMEOUT
}
