import React from 'react'
import {
  Box,
  makeStyles,
} from '@material-ui/core'
import { mapDispatch } from '../../../../utils/utils'
import { actions as socketActions } from '../../../socket/socketSlice'
import { actions as singleActions } from '../../../single/singleSlice'
import connect from 'react-redux/es/connect/connect'
import PropTypes from 'prop-types'
import lodash from 'lodash'
import {
  CMD_TOKEN_BUY_SELL_PLACE_ORDER,
} from '../../../../protocolConstants'
import PlaceOrderForm from '../../../units/place_order'
import {pairInfoPropTypes} from "../../../../utils/propTypesUtils"
import {EMPTY_ACCOUNT_NAME_KEY, EMPTY_PAIR_NAME_KEY} from "../../../units/place_order/utils"


const useStyles = makeStyles((theme) => ({
  formBox: {
  },
}))

const TokenBuySellPlaceOrderForm = ({ sessionId, pairInfo }) => {
  const classes = useStyles()
  const accNameOptions = []
  const accNamePairOptions = []
  const accNamePairPairInfos = {
    [EMPTY_ACCOUNT_NAME_KEY]: {
      [EMPTY_PAIR_NAME_KEY]: pairInfo
    }
  }
  return (
    <Box
      className={classes.formBox}
      display="flex"
      flexDirection="column"
      justifyContent="center"
    >
      <PlaceOrderForm
        accNameOptions ={accNameOptions}
        accNamePairOptions = {accNamePairOptions}
        accNamePairPairInfos={accNamePairPairInfos}
        placeOrderCmd={CMD_TOKEN_BUY_SELL_PLACE_ORDER}
        isEmptyAccName={true}
        isEmptyPair={true}
        sessionId={sessionId}
      />
    </Box>
  )
}


TokenBuySellPlaceOrderForm.propTypes = {
  sessionId: PropTypes.number.isRequired,
  pairInfo: pairInfoPropTypes,
}

TokenBuySellPlaceOrderForm.defaultProps = {

}

const mapStateToProps = (state, ownProps)=> {
  const pairInfo = lodash.get(state, ['single', 'curPageData', 'pairInfo'])
  return {
    pairInfo
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return mapDispatch(dispatch)({...socketActions, ...singleActions})
}

export default connect(mapStateToProps, mapDispatchToProps)(TokenBuySellPlaceOrderForm)

