import React, { useEffect } from 'react'
import { Box, makeStyles, } from '@material-ui/core'
import { mapDispatch } from '../../../../utils/utils'
import connect from 'react-redux/es/connect/connect'
import PropTypes from 'prop-types'
import lodash from 'lodash'
import { useRefFieldsPrevious, useRunOnce, useStateReqView } from '../../../../utils/hooks'
import { CMD_GET_FULL_STATE } from '../../../../protocolConstants'
import { actions as singleActions } from '../../../single/singleSlice'
import { accountPropTypes, algorithmPropTypes, projectPropType } from '../../../../utils/propTypesUtils'
import { downloadFile } from '../../../../utils/fileUtils'
import MsgAlertView from '../../../ui/MsgAlertView'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: "flex",
    "justify-content": "center",
    'align-items': 'center',
  }
}))


const DownloadAlgoView = ({ setCurPageData, project, clearCurPageData, sessionAccounts, sessionId, algorithm }) => {
  const classes = useStyles()
  useRunOnce(() => {
    clearCurPageData()
    if (!lodash.isEmpty(sessionAccounts)) {
      const sessionAccount = sessionAccounts[0]
      const { accId, pairs } = sessionAccount
      const exchangeAccounts = lodash.get(project, 'exchangeAccounts', [])
      const account = lodash.find(exchangeAccounts, (ea) => {
        return ea['id'] === accId
      })
      if (account) {
        let pair = ''
        if (!lodash.isEmpty(pairs)) {
          pair = pairs[0]
        }
        let toolbarTitle
        if (pair === '') {
          toolbarTitle = `${algorithm.name} - ${account.name}`
        } else {
          toolbarTitle = `${algorithm.name} - ${account.name} - ${pair}`
        }
        setCurPageData({
          toolbarTitle
        })
      }
    }
  })

  const genFullState = () => {
    return {
      cmd: CMD_GET_FULL_STATE,
      isRelay: true,
      data: {
        sessionId,
      }
    }
  }

  let { isSuccessful, receivedData, loadingView, getStateField, setStateField } = useStateReqView(genFullState)
  const prevIsSuccessful = useRefFieldsPrevious('isSuccessful', isSuccessful)

  useEffect(() => {
    if (!prevIsSuccessful && isSuccessful) {
      const fileData = lodash.get(receivedData, ['data', 'specific', 'data'])
      const fileName = lodash.get(receivedData, ['data', 'specific', 'fileName'])
      setStateField('fileData', fileData)
      setStateField('fileName', fileName)
      downloadFile(fileData, fileName)
    }
  })

  if(loadingView) {
    return <Box className={classes.root}>
      {loadingView}
    </Box>
  }

  const onClick = () => {
    const fileData = getStateField('fileData')
    const fileName = getStateField('fileName')
    downloadFile(fileData, fileName)
  }

  return (
      <Box
        className={classes.root}
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <MsgAlertView
          msg="Download is starting automatically. If it does not start, you may click button below to download again."
          severity="info"
          buttonText="Download"
          onButtonClick={onClick}
        />
      </Box>
  )
}


DownloadAlgoView.propTypes = {
  algorithm: algorithmPropTypes, // the selected algorithm
  sessionAccounts: PropTypes.arrayOf(accountPropTypes),
  clearCurPageData: PropTypes.func.isRequired,
  setCurPageData: PropTypes.func.isRequired,
  sessionId: PropTypes.number.isRequired,
  project: projectPropType.isRequired,
}

DownloadAlgoView.defaultProps = {
}

const mapStateToProps = (state, ownProps)=> {
  return {

  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return mapDispatch(dispatch)({...singleActions})
}

export default connect(mapStateToProps, mapDispatchToProps)(DownloadAlgoView)

