import React, { useEffect } from 'react'
import * as Yup from 'yup'
import { Field, Formik } from 'formik'
import {
  Box,
  Button,
  Container,
  Typography,
  makeStyles,
  Card,
  Grid, Link,
} from '@material-ui/core'
import Page from '../../../components/Page'
import {baseQuoteExtraParams, mapDispatch, parsePath, trimTxId} from '../../../utils/utils'
import { actions as socketActions } from '../../socket/socketSlice'
import { actions as singleActions } from '../../single/singleSlice'
import connect from 'react-redux/es/connect/connect'
import PropTypes from 'prop-types'
import lodash from 'lodash'
import logger from '../../../utils/logger'
import { PATH_SESSION_WITH_ID } from '../../../constants'
import FormSelect from '../../../components/form/FormSelect'
import SimpleTradingAccountForm from './SimpleTradingAccountForm'
import DexSimpleTradingAccountForm from './DexSingleTradingAccountForm'
import TradingAccountStartEndForm from './TradingAccountStartEndForm'
import { exchangeArrayPropType, projectArrayPropType } from '../../../utils/propTypesUtils'
import MultiAccountsForm from './MultiAccountsForm'
import {
  ALGO_KEY_ICEBERG,
  ALGO_KEY_TRADE_HISTORY,
  ALGO_KEY_ASSET_MOVEMENT_HISTORY,
  ALGO_KEY_MAKER_TAKER,
  ALGO_KEY_ASSET_LIST,
  CMD_NEW_SESSION,
  ALGO_KEY_PROJECT_ASSET_LIST,
  ALGO_KEY_TOKEN_BUY_SELL,
  ALGO_KEY_DEX_TAKER,
  ALGO_KEY_SPOOF_SNIPER,
  ALGO_KEY_LONG_SHORT,
  ACCOUNT_CAT_SPOT,
  ACCOUNT_CAT_MARGIN,
  ACCOUNT_CAT_CONTRACT,
  ACCOUNT_CAT_FUTURE, ACCOUNT_CAT_PORTFOLIO
} from '../../../protocolConstants'
import { useRefFieldsPrevious, useRefFieldsPrevValueChanged, useStateFields, useStateReq } from '../../../utils/hooks'
import ConfirmDialog from '../../../components/ConfirmDialog'
import MsgAlertView from '../../ui/MsgAlertView'
import FormInput from '../../../components/form/FormInput'
import AccountListFields from './AccountListFields'
import PiCardContent from '../../../components/PiCardContent'


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  formBox: {
    paddingTop: theme.spacing(5)
  }
}))

const USDT = 'usdt'
const BUSD = 'busd'
const FDUSD = 'fdusd'
const USDC = "usdc"
const TUSD = "tusd"


const getSelectedProjectAlgorithm = (values, algorithms, projects) => {
  const selectedProjectId=lodash.get(values, 'projectId')
  const selectedAlgorithmId = lodash.get(values, 'algorithmId')
  const selectedAlgorithm = lodash.find(algorithms, (p) => {
    return p['id'] === selectedAlgorithmId
  })
  const selectedProject = lodash.find(projects, (p) => {
    return p['id'] === selectedProjectId
  })
  return {
    selectedProjectId,
    selectedAlgorithmId,
    selectedAlgorithm,
    selectedProject,
  }
}

const isStables = (ass) => {
  return ass ===  USDT || ass === BUSD || ass === FDUSD || ass === USDC || ass === TUSD
}

const SessionNewView = (props) => {
  const classes = useStyles()
  const { algorithms, projects, showToast, onSessionCreated } = props
  const { isSending, isSuccessful, sendReq, getStateField, setStateField, receivedData } = useStateReq()
  /**
   * the values will be in the format below:
   * {
   *   algorithmId: 1,
   *   projectId: 2,
   *   algoForm: { // map from algo keys to the algo data
   *     makerTaker: {},
   *     iceberg: {}
   *   }
   * }
   */
  const onNewSession = (values) => {
    const { algorithmId, name, projectId } = values
    const {
      selectedAlgorithm
    } = getSelectedProjectAlgorithm(values, algorithms, projects)
    if (selectedAlgorithm === undefined) {
      showToast('Please select an algorithm', 'error')
      return
    }
    const algoFormData = lodash.get(values, `algoForm.${selectedAlgorithm.key}`)
    const { key: algoKey } = selectedAlgorithm
    if(algoKey === ALGO_KEY_ICEBERG || algoKey === ALGO_KEY_ASSET_LIST || algoKey === ALGO_KEY_MAKER_TAKER || algoKey === ALGO_KEY_TOKEN_BUY_SELL || algoKey === ALGO_KEY_SPOOF_SNIPER) {
      // send trading Account
      const tradingAccount = algoFormData['tradingAccount']
      const data = {
        algorithmId,
        projectId,
        name,
        variadicData: {
          accounts: [{
            ...tradingAccount
          }]
        }
      }
      logger.debug('values is: ', values, ' sentData is', data)
      sendReq({
        cmd: CMD_NEW_SESSION,
        data,
      })
    } else if(algoKey === ALGO_KEY_TRADE_HISTORY || algoKey === ALGO_KEY_ASSET_MOVEMENT_HISTORY){
      // send trading account & start end date
      const tradingAccount = algoFormData['tradingAccount']
      const startEnd = algoFormData['startEnd']
      const data = {
        algorithmId,
        projectId,
        name,
        variadicData: {
          accounts: [{
            ...tradingAccount,
          }],
          algoSpecificData: {
            ...startEnd
          }
        }
      }
      logger.debug('values is: ', values, ' sentData is', data)
      sendReq({
        cmd: CMD_NEW_SESSION,
        data,
      })
    } else if(algoKey === ALGO_KEY_PROJECT_ASSET_LIST) {
      const accIds = algoFormData['accIds']
      const accounts = accIds.map((accId) => {
        return {
          accId
        }
      })
      const data = {
        algorithmId,
        projectId,
        name,
        variadicData: {
          accounts,
        }
      }
      logger.debug('values is: ', values, ' sentData is', data)
      sendReq({
        cmd: CMD_NEW_SESSION,
        data,
      })
    } else if (algoKey === ALGO_KEY_DEX_TAKER) {
      const tradingAccounts = algoFormData['tradingAccounts']
      const { accIds, dexRawPath } = tradingAccounts
      const accounts = lodash.map(accIds, (accId) => {
        return {
          accId,
          dexRawPaths: [dexRawPath]
        }
      })
      const data = {
        algorithmId,
        projectId,
        name,
        variadicData: {
          accounts
        }
      }
      logger.debug('values is: ', values, ' sentData is', data)
      sendReq({
        cmd: CMD_NEW_SESSION,
        data,
      })
    } else if (algoKey === ALGO_KEY_LONG_SHORT) {
      const tradingAccounts = lodash.get(algoFormData, 'tradingAccounts', null)
      if (lodash.size(tradingAccounts) !== 2) {
        showToast('Trading Accounts size is not 2', 'error')
        return
      }
      // in the form of long account - short account
      const tradingAccA = tradingAccounts[0]
      const tradingAccB = tradingAccounts[1]
      const pairsA = lodash.get(tradingAccA, 'pairs', [])
      const accIdA = lodash.get(tradingAccA, 'accId')
      const pairsB = lodash.get(tradingAccB, 'pairs', [])
      const accIdB = lodash.get(tradingAccB, 'accId')

      if (pairsA.length !== pairsB.length) {
        showToast(`Pairs length does not match`, 'error')
        return
      }

      if (pairsA.length === 0) {
        showToast(`Pairs is empty`, 'error')
      }

      const baseSet = new Set()

      let onePairIsSame = false
      for (let i = 0; i < pairsA.length; i ++) {
        const pA = pairsA[i]
        const pB = pairsB[i]

        const baseQuoteExParamA = baseQuoteExtraParams(pA)
        const baseQuoteExParamB = baseQuoteExtraParams(pB)
        const baseA = baseQuoteExParamA[0]
        const quoteA = baseQuoteExParamA[1]
        const baseB = baseQuoteExParamB[0]
        const quoteB = baseQuoteExParamB[1]
        if (pA === pB) {
          onePairIsSame = true
        }

        if (baseA === '' || baseB === '') {
          showToast(`Pair at index ${i} is not valid. pairA ${pA}, pairB ${pB}`, 'error')
          return
        }
        if (baseA !== baseB) {
          showToast(`Base does not match at index ${i}. PairA ${pA}, pairB ${pB}`, 'error')
          return
        }

        if (baseSet.has(baseA)) {
          showToast(`Base has appeared another time at index ${i} base ${baseA}`, 'error')
          return
        }
        baseSet.add(baseA)
        if (!isStables(quoteA)) {
          showToast(`Pair A quote is not stables at index ${i}, pairA ${pA}, quoteA ${quoteA}`, 'error')
          return
        }
        if (!isStables(quoteB)) {
          showToast(`Pair B quote is not stables at index ${i}, pairB ${pB}, quoteB ${quoteB}`, 'error')
          return
        }
      }

      if (accIdA === accIdB) {
        // it is the same account, only be used for switch position, in this case, we should ensure the pairs are not exactly the same
        if (onePairIsSame) {
          showToast(`Two account is the same. IdA ${accIdA}, idB ${accIdB}, while at least one pair is also the same`, 'error')
          return
        }
      }
      const data = {
        algorithmId,
        projectId,
        name,
        variadicData: {
          accounts: [{
            ...tradingAccA,
          }, {
            ...tradingAccB,
          }],
        }
      }
      logger.debug('values is: ', values, ' sent Data is ', data)
      sendReq({
        cmd: CMD_NEW_SESSION,
        data,
      })
    } else {
      showToast(`The algorithm key ${selectedAlgorithm.key} is not supported`, 'error')
    }
  }
  const newSessionDialog = getStateField('newSessionDialog', false)
  const newSessionId = getStateField('newSessionId', undefined)

  const hideDialog = () => {
    setStateField('newSessionDialog', false)
    setStateField('newSessionId', undefined)
  }

  const prevIsSuccessful = useRefFieldsPrevious('isSuccessful', isSuccessful)
  useEffect(() => {
    if (!prevIsSuccessful && isSuccessful) {
      const sessionId = lodash.get(receivedData, ['data', 'sessionId'])
      setStateField('newSessionId', sessionId)
      setStateField('newSessionDialog', true)
      onSessionCreated() // call back
    }
  }, [isSuccessful, receivedData, setStateField, prevIsSuccessful, onSessionCreated])

  const dialogContent = () => {
    return (
      <Typography
        color="textPrimary"
        variant="body1"
      >
        New session is created and running. If you want to open the new session in a new tab, please click Open Session button below. Otherwise click Dismiss button below.
      </Typography>
    )
  }
  return (
    <Page
      className={classes.root}
      title="New Session"
    >
      <Container maxWidth={false}>
        <Box
          className={classes.formBox}
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Formik
            initialValues={{
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().required('Please input session name'),
              projectId: Yup.number().required('Please select project'),
              algorithmId: Yup.number().required('Please select an algorithm'),
            })}
            onSubmit={onNewSession}
          >
            {createNewSessionForm(props, isSending)}
          </Formik>
        </Box>

        <ConfirmDialog
          dialogOpen={newSessionDialog && (newSessionId !== undefined)}
          dialogTitle="New Session Created"
          dialogContent={dialogContent()}
          dialogCancelText="Dismiss"
          dialogOnCancel={hideDialog}
          dialogConfirmButton = {<Button>
            <Link
              href={parsePath(PATH_SESSION_WITH_ID, { sessionId: newSessionId})}
              target="_blank"
            >
              Open Session
            </Link>
          </Button>}
        />
      </Container>
    </Page>
  )
}


SessionNewView.propTypes = {
  reqInfoMap: PropTypes.object,
  algorithms: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    key: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  })),
  projects: projectArrayPropType,
  exchanges: exchangeArrayPropType,
  roles: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
  })),
  onSessionCreated: PropTypes.func.isRequired
}

SessionNewView.defaultProps = {
  algorithms: [],
  projects: [],
  roles: [],
  exchanges: []
}

const mapStateToProps = (state, ownProps)=> {
  const reqInfoMap = lodash.get(state, ['socket', 'reqInfoMap'], {})
  const algorithms = lodash.get(state, ['single', 'profile', 'algorithms'])
  const projects = lodash.get(state, ['single', 'profile', 'projects'])
  const roles = lodash.get(state, ['single', 'profile', 'roles'])
  const exchanges = lodash.get(state, ['single', 'profile', 'exchanges'])
  return {
    reqInfoMap,
    algorithms,
    projects,
    roles,
    exchanges
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return mapDispatch(dispatch)({ ...socketActions, ...singleActions })
}

export default connect(mapStateToProps, mapDispatchToProps)(SessionNewView)


const autoGenNamePrefix = (values, selectedAlgorithm, selectedProject, exchanges) => {
  if (!selectedAlgorithm) {
    return ''
  }
  if (!selectedProject) {
    return ''
  }
  const { key: algoKey, name: algoName } = selectedAlgorithm
  const { exchangeAccounts =[] } = selectedProject
  const algoFormData = lodash.get(values, `algoForm.${algoKey}`)

  const getAlgoFormInfo = () => {
    const tradingAccount = lodash.get(algoFormData, 'tradingAccount')
    if (!tradingAccount){
      return {}
    }
    const { accId, pairs } = tradingAccount
    const ex = lodash.find(exchangeAccounts, (ea) => {
      return ea['id'] === accId
    })
    if (!ex) {
      return {}
    }
    return {
      ex,
      tradingAccount,
      pairs
    }
  }
  if(algoKey === ALGO_KEY_ICEBERG || algoKey === ALGO_KEY_TRADE_HISTORY || algoKey === ALGO_KEY_MAKER_TAKER || algoKey === ALGO_KEY_TOKEN_BUY_SELL || algoKey === ALGO_KEY_SPOOF_SNIPER) {
    // in format algoName - accName - pair
    const { ex, pairs } = getAlgoFormInfo()
    if (!ex) {
      return ''
    }
    if (lodash.isEmpty(pairs)) {
      return ''
    }
    const { name } = ex
    return `${algoName} - ${name} - ${pairs[0]}`
  } else if (algoKey === ALGO_KEY_ASSET_LIST || algoKey === ALGO_KEY_ASSET_MOVEMENT_HISTORY) {
    // in format algoName - accName
    const { ex } = getAlgoFormInfo()
    if (!ex) {
      return ''
    }
    const { name } = ex
    return `${algoName} - ${name}`
  } else if (algoKey === ALGO_KEY_PROJECT_ASSET_LIST) {
    // in format algoName - accName List
    const accIds = lodash.get(algoFormData, 'accIds')
    if (lodash.isEmpty(accIds)) {
      return ''
    }
    const accNames = accIds.map((accId) => {
      const ex = lodash.find(exchangeAccounts, (ea) => {
        return ea['id'] === accId
      })
      if (!ex) {
        return ''
      } else {
        return ex['name']
      }
    })

    return `${algoName} - ${accNames.join('*')}`
  } else if (algoKey === ALGO_KEY_DEX_TAKER) {
    // in format algoName - accName - pathAddr
    const exId = lodash.get(algoFormData, ['tradingAccounts', 'exId'])
    const accIds = lodash.get(algoFormData, ['tradingAccounts', 'accIds'], [])
    const selectedExchange = lodash.find(exchanges, (ea) => {
      return ea['id'] === exId
    })

    const dexRawPath = lodash.get(algoFormData, ['tradingAccounts', 'dexRawPath'], [])
    if (!selectedExchange) {
      return ''
    }
    if (accIds.length <= 0 ) {
      return ''
    }
    const dexPathAddrLst = []
    for (let i = 0; i < dexRawPath.length; i ++) {
      const addr = lodash.get(dexRawPath, [i, 'addr'], '')
      if (addr === '') {
        return ''
      }
      dexPathAddrLst.push(`<${trimTxId(addr)}>`)
    }

    const accNames = accIds.map((accId) => {
      const ex = lodash.find(exchangeAccounts, (ea) => {
        return ea['id'] === accId
      })
      if (!ex) {
        return ''
      } else {
        return ex['name']
      }
    })

    // acc name
    let accNameStr = ''
    if (accNames.length === 1) {
      accNameStr = accNames[0]
    } else {
      accNameStr = `${accNames[0]}&(${accNames.length - 1}More)`
    }

    // pair info
    const pathStr = dexPathAddrLst.join('')
    return `${algoName} - ${accNameStr} - ${pathStr}`
  } else if (algoKey === ALGO_KEY_LONG_SHORT) {
    // in format of algo Name - spotAccName*spotPair - contAccName*contPair
    const tradingAccounts = lodash.get(algoFormData, 'tradingAccounts', null)

    if (lodash.size(tradingAccounts) !== 2) {
      return ''
    }
    const tradingAccA = tradingAccounts[0]
    const tradingAccB = tradingAccounts[1]
    if (!tradingAccA || !tradingAccB) {
      return ''
    }
    const { accId: accIdA, pairs: pairsA } = tradingAccA
    const { accId: accIdB, pairs: pairsB } = tradingAccB
    const exA = lodash.find(exchangeAccounts, (ea) => {
      return ea['id'] === accIdA
    })
    const exB = lodash.find(exchangeAccounts, (ea) => {
      return ea['id'] === accIdB
    })
    if (!exA || !exB) {
      return ''
    }

    if (lodash.isEmpty(pairsA) || lodash.isEmpty(pairsB)) {
      return ''
    }
    const { name: nameA } = exA
    const { name: nameB } = exB
    return `${algoName} - ${nameA}*${pairsA[0]} - ${nameB}*${pairsB[0]}`
  }
  return ''
}

const createNewSessionForm = (props, isSending) => ({ errors, values, handleBlur, handleChange, handleSubmit, setFieldValue }) => {
  const {projects, algorithms, exchanges} = props

  const projectOptions = lodash.map(projects, (p) => {
    return {
      value: p['id'],
      label: p['name']
    }
  })

  const [_, setStateField, getStateField] = useStateFields() // eslint-disable-line no-unused-vars
  const shouldAutoGenName = getStateField('shouldAutoGenName', true)

  const algorithmOptions = lodash.map(algorithms, (p) => {
    return {
      value: p['id'],
      label: p['name']
    }
  })

  const {
    selectedProjectId,
    selectedAlgorithm,
    selectedProject
  } = getSelectedProjectAlgorithm(values, algorithms, projects)

  const name = lodash.get(values, 'name')
  const onNamePostChange = (name, newVal) => {
    if (newVal === '') {
      if (!shouldAutoGenName) {
        setStateField('shouldAutoGenName', true)
        logger.debug('shouldAutoGenName to ', true)
      }
    } else {
      if (shouldAutoGenName) {
        setStateField('shouldAutoGenName', false)
        logger.debug('shouldAutoGenName to ', false)
      }
    }
  }

  const changedSelectedProjectId = useRefFieldsPrevValueChanged('selectedProjectId', selectedProjectId)
  useEffect(() => {
    // clear algo form, whenever we change project Id
    if (changedSelectedProjectId) {
      logger.debug('changed selected project id hence clear algo Form')
      setFieldValue('algoForm', {})
    }
  }, [changedSelectedProjectId, setFieldValue])

  const previousValues = useRefFieldsPrevious('values', values)
  useEffect(() => {
    if (!shouldAutoGenName) {
      return
    }

    const previousValuesNoName = {
      ...previousValues,
      name: null
    }

    const valuesNoName = {
      ...values,
      name: null
    }

    if (lodash.isEqual(previousValuesNoName, valuesNoName)) {
      logger.debug('prev value equal to values, exit')
      return
    }

    const newName = autoGenNamePrefix(values, selectedAlgorithm, selectedProject, exchanges)
    logger.debug(`Auto generate name: ${newName}`)
    if (newName === name) {
      logger.info(`name is unchanged: ${name}, newName: ${newName}`)
      return
    }
    logger.info(`setNewName: ${name}, newName: ${newName}`)
    setFieldValue('name', newName)

    // only re-run the effect after any of below value changed
  }, [setFieldValue, name, selectedAlgorithm, selectedProject, values, shouldAutoGenName, previousValues, exchanges])

  let algoForm = null
  if (selectedProjectId !== undefined && selectedAlgorithm) {
    const selectedAlgorithmKey = lodash.get(selectedAlgorithm, 'key')
    if (selectedAlgorithmKey === ALGO_KEY_MAKER_TAKER) {
      algoForm = <SimpleTradingAccountForm
        fieldNamePrefix={`algoForm.${ALGO_KEY_MAKER_TAKER}.tradingAccount`}
        accountLabel="Maker Taker Trading Account"
        projectId={selectedProjectId}
        supportedAccountCats={[ACCOUNT_CAT_SPOT]}
        pairCountMin={1}
        pairCountMax={1}
        values={values}
      />
    } else if (selectedAlgorithmKey === ALGO_KEY_TOKEN_BUY_SELL) {
      algoForm = <SimpleTradingAccountForm
        fieldNamePrefix={`algoForm.${selectedAlgorithmKey}.tradingAccount`}
        accountLabel="Token Buy Sell Trading Account"
        projectId={selectedProjectId}
        supportedAccountCats={[ACCOUNT_CAT_SPOT]}
        pairCountMin={1}
        pairCountMax={1}
        values={values}
      />
    } else if (selectedAlgorithmKey === ALGO_KEY_SPOOF_SNIPER) {
      algoForm = <SimpleTradingAccountForm
        fieldNamePrefix={`algoForm.${selectedAlgorithmKey}.tradingAccount`}
        accountLabel="Spoof Sniper Trading Account"
        supportedAccountCats={[ACCOUNT_CAT_SPOT]}
        projectId={selectedProjectId}
        pairCountMin={1}
        pairCountMax={1}
        values={values}
      />
    } else if (selectedAlgorithmKey === ALGO_KEY_PROJECT_ASSET_LIST) {
      algoForm = <AccountListFields
        projectId={selectedProjectId}
        fieldName={`algoForm.${selectedAlgorithmKey}.accIds`}
      />
    } else if (selectedAlgorithmKey === ALGO_KEY_ICEBERG) {
      algoForm = <SimpleTradingAccountForm
        fieldNamePrefix={`algoForm.${ALGO_KEY_ICEBERG}.tradingAccount`}
        accountLabel="Iceberg Trading Account"
        supportedAccountCats={[ACCOUNT_CAT_SPOT]}
        projectId={selectedProjectId}
        pairCountMin={1}
        pairCountMax={1}
        values={values}
      />
    } else if (selectedAlgorithmKey === ALGO_KEY_ASSET_LIST) {
      const accountLabel = "Asset List Account"
      algoForm = <SimpleTradingAccountForm
        fieldNamePrefix={`algoForm.${selectedAlgorithmKey}.tradingAccount`}
        accountLabel={accountLabel}
        supportedAccountCats={[ACCOUNT_CAT_SPOT]}
        projectId={selectedProjectId}
        pairCountMin={0}
        pairCountMax={0}
        values={values}
      />
    } else if (selectedAlgorithmKey === ALGO_KEY_TRADE_HISTORY || selectedAlgorithmKey === ALGO_KEY_ASSET_MOVEMENT_HISTORY) {
      let pairCount = 1
      let accountLabel = 'Account'
      if (selectedAlgorithmKey === ALGO_KEY_TRADE_HISTORY) {
        pairCount = 1
        accountLabel = "Trade History Account"
      } else if (selectedAlgorithmKey === ALGO_KEY_ASSET_MOVEMENT_HISTORY) {
        pairCount = 0
        accountLabel = "Asset Movement History Account"
      }
      algoForm = <TradingAccountStartEndForm
        tradingFieldNamePrefix={`algoForm.${selectedAlgorithmKey}.tradingAccount`}
        startEndFieldNamePrefix={`algoForm.${selectedAlgorithmKey}.startEnd`}
        accountLabel={accountLabel}
        projectId={selectedProjectId}
        pairCountMin={pairCount}
        pairCountMax={pairCount}
        values={values}
      />
    } else if (selectedAlgorithmKey === ALGO_KEY_DEX_TAKER) {
      algoForm = <DexSimpleTradingAccountForm
        fieldNamePrefix={`algoForm.${ALGO_KEY_DEX_TAKER}.tradingAccounts`}
        accountLabel="Dex Taker Trading Accounts"
        projectId={selectedProjectId}
        values={values}
        setFieldValue={setFieldValue}
      />
    } else if (selectedAlgorithmKey === ALGO_KEY_LONG_SHORT) {
      const pairCount = 1
      algoForm = <MultiAccountsForm
        fieldNamePrefix={`algoForm.${selectedAlgorithmKey}.tradingAccounts`}
        accountConfigs={[
          {
            accountLabel: 'Account A',
            supportedAccountCats: [ACCOUNT_CAT_SPOT, ACCOUNT_CAT_MARGIN, ACCOUNT_CAT_CONTRACT, ACCOUNT_CAT_FUTURE, ACCOUNT_CAT_PORTFOLIO],
            pairCountMin: pairCount,
          },
          {
            accountLabel: 'Account B',
            supportedAccountCats: [ACCOUNT_CAT_SPOT, ACCOUNT_CAT_MARGIN, ACCOUNT_CAT_CONTRACT, ACCOUNT_CAT_FUTURE, ACCOUNT_CAT_PORTFOLIO],
            pairCountMin: pairCount,
          }
        ]}
        projectId={selectedProjectId}
        setFieldValue={setFieldValue}
        values={values}
      />
    } else {
      algoForm = <MsgAlertView
        msg={`Algorithm Key ${selectedAlgorithmKey} is not supported`}
      />
    }
  }
  return (
    <form
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      <Box mb={3}>
        <Typography
          color="textPrimary"
          variant="h2"
        >
          New Session
        </Typography>
      </Box>

      <Box mt={3}>
        <Card>
          <PiCardContent
            disablePiStyle
          >
            <Grid display="flex" container spacing={3} alignItems='center'>
              <Grid
                item
                lg={3}
                sm={6}
                xl={3}
                xs={12}
              >
                <Field
                  isInline={false}
                  name="name"
                  label="Name"
                  placeholder="Leave name blank for auto generation"
                  postChange={onNamePostChange}
                  component={FormInput}
                />
              </Grid>
              <Grid
                item
                lg={3}
                sm={6}
                xl={3}
                xs={12}
              >
                <Field
                  name="projectId"
                  label="Project"
                  options={projectOptions}
                  component={FormSelect}
                />
              </Grid>

              <Grid
                item
                lg={3}
                sm={6}
                xl={3}
                xs={12}
              >
                <Field
                  name="algorithmId"
                  label="Algorithm"
                  options={algorithmOptions}
                  component={FormSelect}
                />
              </Grid>
            </Grid>
          </PiCardContent>
        </Card>
      </Box>
      {algoForm}
      <Box my={2}>
        <Button
          color="primary"
          disabled={isSending}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
        >
          Create New Session
        </Button>
      </Box>
    </form>
  )
}
