import React from 'react'
import { TableBody, TableRow } from '@material-ui/core'
import ConfirmDialog from '../../../../../components/ConfirmDialog'
import {
  CMD_MAKER_TAKER_CANCEL_ALL_ORDERS,
  CMD_MAKER_TAKER_CANCEL_OLD_ORDERS,
  CMD_MAKER_TAKER_EXECUTE_TAKER,
  CMD_MAKER_TAKER_STOP_TAKER,
  SIDE_BUY
} from '../../../../../protocolConstants'
import ColorButton from '../../../../../components/ColorButton'
import PropTypes from 'prop-types'
import { makerTakerDialogPropType } from '../../../../../utils/propTypesUtils'
import lodash from 'lodash'
import { mapDispatch } from '../../../../../utils/utils'
import { actions as singleActions } from '../../../../single/singleSlice'
import connect from 'react-redux/es/connect/connect'
import { SIDE_SELL } from '../../../../../protocolConstants'
import XscrollTable from '../../../../ui/XscrollTable'
import PiTableCell from '../../../../../components/PiTableCell'

const TableRowNameVal = ({name, value}) => {
  return (
    <TableRow hover>
      <PiTableCell
        variant='head'
      >
        {name}
      </PiTableCell>
      <PiTableCell>
        {value}
      </PiTableCell>
    </TableRow>
  )
}
const DialogContent = ({previewData}) => {
  const { buySell, itvl, remainingQty, remainingAsset, orderQty, startPx, stopPx, itvlRandEnabled } = previewData
  return (
    <XscrollTable>
      <TableBody>
        <TableRowNameVal
          name="Side"
          value={buySell}
        />
        <TableRowNameVal
          name="Interval (sec)"
          value={parseInt(itvl / 1000)}
        />
        <TableRowNameVal
          name="Remaining Asset"
          value={`${remainingQty} (${remainingAsset})`}
        />
        <TableRowNameVal
          name="Order Qty"
          value={orderQty}
        />
        <TableRowNameVal
          name="Start Px"
          value={startPx}
        />
        <TableRowNameVal
          name="Stop Px"
          value={stopPx}
        />

        <TableRowNameVal
          name="Itvl Rand Enabled"
          value={itvlRandEnabled.toString()}
        />
      </TableBody>
    </XscrollTable>
  )
}


const TakerAlgoFormDialog = ({dialogData, setCurPageData, sendReq, sessionId}) => {
  if (!dialogData) {
    return null
  }
  const { dialogOpen, dialogType, previewData, previewSentData} = dialogData
  const hideDialog = () => {
    setCurPageData({
      dialogData: {
        ...dialogData,
        dialogOpen: false,
      }
    })
  }

  if (dialogType === 'executeTaker') {
    const executeTaker = () => {
      sendReq({
        cmd: CMD_MAKER_TAKER_EXECUTE_TAKER,
        isRelay: true,
        data: lodash.get(previewSentData, 'data')
      })
      hideDialog()
    }
    const { buySell } = previewData
    let confirmButton
    if (buySell === SIDE_BUY) {
      confirmButton = <ColorButton onClick={executeTaker} colorStyle='green'>
        Taker Buy
      </ColorButton>
    } else if (buySell === SIDE_SELL) {
      confirmButton = <ColorButton onClick={executeTaker} colorStyle='red'>
        Taker Sell
      </ColorButton>
    } else {
      // something wrong, hide the button
      confirmButton = null
    }
    return (
      <ConfirmDialog
        dialogOpen={dialogOpen}
        dialogTitle='Are you sure you want to execute taker algo?'
        dialogContent={<DialogContent previewData={previewData}/>}
        dialogCancelText='Dismiss'
        dialogConfirmButton={confirmButton}
        dialogOnCancel={hideDialog}
      />
    )
  } else if (dialogType === 'stopTaker') {
    const stopTaker = () => {
      sendReq({
        cmd: CMD_MAKER_TAKER_STOP_TAKER,
        isRelay: true,
        data: {
          sessionId
        }
      })
      hideDialog()
    }
    return (
      <ConfirmDialog
        dialogOpen={dialogOpen}
        dialogTitle='Are you sure you want to stop taker algo?'
        dialogContent=''
        dialogCancelText='Dismiss'
        dialogConfirmButton='Stop now'
        dialogOnCancel={hideDialog}
        dialogOnConfirm={stopTaker}
      />
    )
  } else if (dialogType === 'cancelAllOrders') {
    const cancelAllOrders = () => {
      sendReq({
        isRelay: true,
        cmd: CMD_MAKER_TAKER_CANCEL_ALL_ORDERS,
        data: {
          sessionId,
        }
      })
      hideDialog()
    }
    return (
      <ConfirmDialog
        dialogOpen={dialogOpen}
        dialogTitle='Are you sure you want to cancel all orders?'
        dialogContent=''
        dialogCancelText='Dismiss'
        dialogConfirmButton='Cancel All Orders'
        dialogOnCancel={hideDialog}
        dialogOnConfirm={cancelAllOrders}
      />
    )
  } else if (dialogType === 'cancelOldOrders') {
    const cancelOldOrders = () => {
      sendReq({
        isRelay: true,
        cmd: CMD_MAKER_TAKER_CANCEL_OLD_ORDERS,
        data: {
          sessionId,
        }
      })
      hideDialog()
    }
    return (
      <ConfirmDialog
        dialogOpen={dialogOpen}
        dialogTitle='Are you sure you want to cancel orders older than 3 hours?'
        dialogContent=''
        dialogCancelText='Dismiss'
        dialogConfirmButton='Cancel Now'
        dialogOnCancel={hideDialog}
        dialogOnConfirm={cancelOldOrders}
      />
    )
  } else {
    return null
  }
}


TakerAlgoFormDialog.propTypes = {
  dialogData: makerTakerDialogPropType,
  setCurPageData: PropTypes.func.isRequired,
  sendReq: PropTypes.func.isRequired,
  sessionId: PropTypes.number.isRequired
}

TakerAlgoFormDialog.defaultProps= {

}

const mapStateToProps = (state, ownProps)=> {
  const dialogData = lodash.get(state, ['single', 'curPageData', 'dialogData'])
  return {
    dialogData
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return mapDispatch(dispatch)(singleActions)
}

export default connect(mapStateToProps, mapDispatchToProps)(TakerAlgoFormDialog)
