import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'


const useStyles = makeStyles(() => ({
  logoTitle: {
    color: 'white'
  }
}))

const Logo = (props) => {
  const classes = useStyles()
  return (
    <Typography
      className={classes.logoTitle}
      variant="h3"
    >
      PNYX
    </Typography>
  )
}

export default Logo
