import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import {
  Card,
  TableBody,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
} from '@material-ui/core'
import XscrollTable from '../ui/XscrollTable'
import PiTableCell from '../../components/PiTableCell'


const useStyles = makeStyles((theme) => ({
  root: {
      marginBottom: theme.spacing(3),
  },
  avatar: {
    marginRight: theme.spacing(2)
  }
}))

const Results = ({ className, reqInfoLst, ...rest }) => {
  const classes = useStyles()

  const formatObj = (obj) => {
    return JSON.stringify(obj, null, 2)
  }

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <XscrollTable>
        <TableHead>
          <TableRow>
            <PiTableCell>
              rId
            </PiTableCell>
            <PiTableCell>
              Sent Data
            </PiTableCell>
            <PiTableCell>
              Received Data
            </PiTableCell>
            <PiTableCell>
              Status
            </PiTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {reqInfoLst.map((reqInfo) => (
            <TableRow
              hover
              key={reqInfo.rId}
            >
              <PiTableCell>
                <Typography>
                  {formatObj(reqInfo.rId)}
                </Typography>

              </PiTableCell>
              <PiTableCell>
                <Typography>
                {formatObj(reqInfo.sentData)}
                </Typography>
              </PiTableCell>
              <PiTableCell>
                <Typography>
                {formatObj(reqInfo.receivedData)}
                </Typography>
              </PiTableCell>
              <PiTableCell>
                <Typography>
                {formatObj(reqInfo.rStatus)}
                </Typography>
              </PiTableCell>
            </TableRow>
          ))}
        </TableBody>
      </XscrollTable>
    </Card>
  )
}

Results.propTypes = {
  className: PropTypes.string,
  reqInfoLst: PropTypes.arrayOf(PropTypes.shape({
    rId: PropTypes.string.isRequired,
    rStatus: PropTypes.string.isRequired,
    updateAtMs: PropTypes.number.isRequired,
    sentData: PropTypes.object.isRequired,
    receivedData: PropTypes.object,
  }))
}

export default Results
