import logger from 'loglevel'
import { isDev, isProd } from '../config'

if (isProd()) {
  logger.setLevel('info')
} else if (isDev()) {
  logger.setLevel('debug')
} else {
  logger.setLevel('info')
}

export default logger
