import React from 'react'
import PiTableCell from '../../../../components/PiTableCell'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  tableCell: {
    borderBottom: "none"
  }
}))

const SmallTableCell = ({children, ...rest}) => {
  const classes = useStyles()
  return <PiTableCell
    className={classes.tableCell}
    {...rest}
  >
    {children}
  </PiTableCell>
}

export default SmallTableCell
