import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import {
  Card,
  makeStyles
} from '@material-ui/core'
import lodash from 'lodash'
import PiCardContent from '../../components/PiCardContent'
import LoggerInnerView from './LoggerInnerView'
import { genUuid } from '../../utils/utils'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  cardContent: {
    height: '100%'
  },
}))

const LoggerView = ({ className, logs }) => {
  const classes = useStyles()
  return (
    <Card
      className={clsx(classes.root, className)}
    >
      <PiCardContent className={classes.cardContent}>
        <LoggerInnerView
          logs={logs}
        />
      </PiCardContent>
    </Card>
  )
}

LoggerView.propTypes = {
  logs: PropTypes.arrayOf(PropTypes.shape({
      method: PropTypes.string.isRequired,
      data: PropTypes.array.isRequired
  }))
}

LoggerView.defaultProps= {
}

const methods = [{
  prefix: '[INFO]:',
  method: 'info'
}, {
  prefix: '[ERROR]:',
  method: 'error'
}]

const processLogs = (logLines=[]) => {
  return lodash.map(logLines, (l) => {
    for (let i = 0; i < methods.length ; i ++) {
      const { prefix, method } = methods[i]
      if (lodash.startsWith(l, prefix)) {
        var data = l.substring(prefix.length, l.length)
        return {
          key: genUuid(),
          method,
          data: [data]
        }
      }
    }
    // fallback
    return {
      key: genUuid(),
      method: 'info',
      data: [l]
    }
  })
}

export {
  processLogs
}

export default LoggerView
