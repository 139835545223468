import React, { useEffect } from 'react'
import * as Yup from 'yup'
import lodash from 'lodash'
import { Formik } from 'formik'
import { Box, makeStyles } from '@material-ui/core'
import { mapDispatch } from '../../../utils/utils'
import { actions as socketActions } from '../../socket/socketSlice'
import { actions as singleActions } from '../../single/singleSlice'
import connect from 'react-redux/es/connect/connect'
import PropTypes from 'prop-types'
import { useRefFields, useRefFieldsPrevious, useStateReq } from '../../../utils/hooks'
import { NoteFormInner } from './note_form_inner'

const useStyles = makeStyles((theme) => ({
  formBox: {
  },
  bidAskButton: {
    color: "#000000",
    height: 'auto',
  }
}))

const NoteForm = ({ sessionId, updateNoteCmd, initContent, showToast }) => {
  const classes = useStyles()
  const {
    sendReq,
    isSending,
    isSuccessful,
    sentData,
    isError,
    receivedData,
    setStateField,
  } = useStateReq()
  const onNewSession = (values) => {
    sendReq({
      cmd: updateNoteCmd,
      isRelay: true,
      data: {
        sessionId,
        params: {
          ...values
        }
      }
    })
  }
  const schema = {
    content: Yup.string(),
  }
  const getRefField = lodash.get(useRefFields(), 1)
  const prevIsSending  = useRefFieldsPrevious('isSending', isSending)
  useEffect(() => {
    if (prevIsSending && !isSending) {
      const cmd = lodash.get(sentData, 'cmd')
      if(cmd === updateNoteCmd) {
        if (isSuccessful) {
          showToast("Update note successfully", 'success')
        } else if (isError) {
          const valueErrors = lodash.get(receivedData, ['data', 'valueErrors'])
          const formErrors = lodash.get(receivedData, ['data', 'formErrors'])
          setStateField('valueErrors', valueErrors)
          const setErrors = getRefField('setErrors')
          if (!lodash.isEmpty(formErrors) && lodash.isFunction(setErrors)) {
            setErrors(formErrors)
          }
        }
      }
    }
  }, [prevIsSending, isSending, isSuccessful, getRefField, isError, receivedData, sentData, setStateField, showToast, updateNoteCmd])


  return (
    <Box
      className={classes.formBox}
      display="flex"
      flexDirection="column"
      justifyContent="center"
    >
      <Formik
        initialValues={{
          content: initContent,
        }}
        validationSchema={Yup.object().shape(schema)}
        onSubmit={onNewSession}
      >
        {(formProps) => {
          return (
            <NoteFormInner
              isSending={isSending}
              formProps={formProps}
              initContent={initContent}
            />)
        }}
      </Formik>
    </Box>
  )
}


NoteForm.propTypes = {
  sessionId: PropTypes.number.isRequired,
  updateNoteCmd: PropTypes.string.isRequired,
  initContent: PropTypes.string.isRequired,
}

NoteForm.defaultProps = {
  initContent: '',
}

const mapStateToProps = (state, ownProps)=> {
  return {

  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return mapDispatch(dispatch)({...socketActions, ...singleActions})
}

export default connect(mapStateToProps, mapDispatchToProps)(NoteForm)

