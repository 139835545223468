import { PanicError } from './exception'

/**
 *
 * possible environment variables are all started with REACT_APP_
 *
 * 1) REACT_APP_API_URL: the websocket API url, it should NOT be used in production
 */

/**
 * @type {string}
 */
const DEV_REQ_WS_URL = 'ws://localhost:8000/appUser'
const PROD_REQ_WS_URL = 'wss://tradebackend.pnyx.ventures/appUser'

function getNodeEnv() {
  return process.env.NODE_ENV
}

function getApiUrlEnv() {
  return process.env.REACT_APP_API_URL
}

function isProd() {
  return getNodeEnv() === 'production'
}

function isDev() {
  return getNodeEnv() === 'development'
}

function getApiUrl() {
  if (isProd()) {
    return PROD_REQ_WS_URL
  } if (isDev()) {
    const envApiUrl = getApiUrlEnv()
    if (envApiUrl === undefined) {
      return DEV_REQ_WS_URL
    }
    return envApiUrl
  }
  throw new PanicError(`It is neither production nor development, nodeEnv is ${getNodeEnv()}`)
}

function getLoginInitValues () {
  if (isDev()) {
    return {
      username: 'user@test.com',
      password: '888888'
    }
  } else {
    return {
      username: '',
      password: ''
    }
  }
}

export {
  isProd,
  isDev,
  getApiUrl,
  getLoginInitValues
}
