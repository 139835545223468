

class Storage {

  setStr = (key, val) => {
    if(val === undefined) {
      this.removeObj(key)
      return
    }
    localStorage.setItem(key, String(val))
  }

  getStr = (key, df) => {
    const val = localStorage.getItem(key)
    if (val === undefined || val === null) {
      return df
    }
    if(val === undefined) {
      return df
    } else {
      return val
    }
  }

  setObj = (key, val) => {
    const valStr = JSON.stringify(val)
    localStorage.setItem(key, valStr)
  }

  getObj = (key, dv=undefined) => {
    const valStr = localStorage.getItem(key)
    if (valStr === undefined || valStr === null) {
      return dv
    }
    try{
      return JSON.parse(valStr)
    } catch (e) {
      return dv
    }
  }

  removeObj = (key) => {
    localStorage.removeItem(key)
  }
}


const storage = new Storage()

export default storage
