import React from 'react'
import clsx from 'clsx'
import {
  Card,
  TableBody,
  TableRow,
  makeStyles,
  TableHead
} from '@material-ui/core'
import lodash from 'lodash'
import { formatInPct, mapDispatch } from '../../../../utils/utils'
import { actions as singleActions } from '../../../single/singleSlice'
import connect from 'react-redux/es/connect/connect'
import XscrollTable from '../../../ui/XscrollTable'
import PiCardContent from '../../../../components/PiCardContent'
import SmallTableCell from './SmallTableCell'
import { accStatePropTypes } from './longShortUtils'
import { Decimal } from 'decimal.js'
import { ACCOUNT_CAT_CONTRACT } from '../../../../protocolConstants'
import logger from '../../../../utils/logger'
import PiInfoTip from "../../../../components/PiInfoTip"

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  cardContent: {
    height: '100%'
  },
}))

const GenInfoTableCell = ({ genInfos }) => {
  const views = []
  lodash.forEach(genInfos, (info, idx) => {
    if (idx !== 0) {
      views.push(<br key={`br_${idx}`}/>)
    }
    views.push(info)
  })
  return <SmallTableCell>
    {views}
  </SmallTableCell>
}

const LongShortAccStateView = ({ accStateA, accStateB }) => {
  const classes = useStyles()
  if (!accStateA || ! accStateB) {
    return null
  }
  const { accName: accNameA, accCat: accCatA, ename: enameA, accBalInfo: accBalInfoA = {}, genInfos: genInfosA } = accStateA
  const { accName: accNameB, accCat: accCatB, ename: enameB, accBalInfo: accBalInfoB = {}, genInfos: genInfosB} = accStateB
  const { totalMarginBal: totalMarginBalA, margOutLmt: margOutLmtA, leverage: leverageA, totalPosAbsVal: totalPosAbsValA } = accBalInfoA
  const { totalMarginBal: totalMarginBalB, margOutLmt: margOutLmtB, leverage: leverageB, totalPosAbsVal: totalPosAbsValB } = accBalInfoB

  let utilisation = '-'
  try {
    let posTotal = new Decimal(0)
    let totalMargBalSum = new Decimal(totalMarginBalA).add(totalMarginBalB)
    if (accCatA === ACCOUNT_CAT_CONTRACT) {
      posTotal = new Decimal(totalMarginBalA).mul(leverageA)
    } else if (accCatB === ACCOUNT_CAT_CONTRACT) {
      posTotal = new Decimal(totalMarginBalB).mul(leverageB)
    }
    if (!totalMargBalSum.eq(new Decimal(0))) {
      utilisation = posTotal.div(totalMargBalSum)
    }

    if (accNameA === accNameB) {
      utilisation = utilisation.mul(new Decimal(2))
    }
    utilisation = formatInPct(utilisation)
  } catch (e) {
    logger.error(e)
  }

  return (
    <Card className={clsx(classes.root)}
    >
      <PiCardContent className={classes.cardContent}>
        <XscrollTable>
          <TableHead>
            <TableRow
              hover
            >
              <SmallTableCell>
              </SmallTableCell>
              <SmallTableCell>
                Acc A
              </SmallTableCell>
              <SmallTableCell>
                Acc B
              </SmallTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow
              hover
            >
              <SmallTableCell>
                Ename
              </SmallTableCell>
              <SmallTableCell>
                {enameA}
              </SmallTableCell>
              <SmallTableCell>
                {enameB}
              </SmallTableCell>
            </TableRow>

            <TableRow
              hover
            >
              <SmallTableCell>
                AccName
              </SmallTableCell>
              <SmallTableCell>
                {accNameA}
              </SmallTableCell>
              <SmallTableCell>
                {accNameB}
              </SmallTableCell>
            </TableRow>

            <TableRow
              hover
            >
              <SmallTableCell>
                Tot Margin Bal
              </SmallTableCell>
              <SmallTableCell>
                {totalMarginBalA}
              </SmallTableCell>
              <SmallTableCell>
                {totalMarginBalB}
              </SmallTableCell>
            </TableRow>

            <TableRow
              hover
            >
              <SmallTableCell>
                Marg Out Lmt
              </SmallTableCell>
              <SmallTableCell>
                {margOutLmtA}
              </SmallTableCell>
              <SmallTableCell>
                {margOutLmtB}
              </SmallTableCell>
            </TableRow>

            <TableRow
              hover
            >
              <SmallTableCell>
                Tot Pos Abs Val
                <PiInfoTip
                  title='Position Includes 1) Position in Contract/Future account 2) Net negative assets in Margin account '
                />
              </SmallTableCell>
              <SmallTableCell>
                {totalPosAbsValA}
              </SmallTableCell>
              <SmallTableCell>
                {totalPosAbsValB}
              </SmallTableCell>
            </TableRow>

            <TableRow
              hover
            >
              <SmallTableCell>
                Leverage
              </SmallTableCell>
              <SmallTableCell>
                {leverageA}
              </SmallTableCell>
              <SmallTableCell>
                {leverageB}
              </SmallTableCell>
            </TableRow>

            <TableRow
              hover
            >
              <SmallTableCell>
                Utilization
              </SmallTableCell>
              <SmallTableCell colSpan={2}>
                {utilisation}
              </SmallTableCell>
            </TableRow>

            <TableRow
              hover
            >
              <SmallTableCell>
                Acc Info
              </SmallTableCell>
              <GenInfoTableCell genInfos={genInfosA}/>
              <GenInfoTableCell genInfos={genInfosB}/>
            </TableRow>
          </TableBody>
        </XscrollTable>
      </PiCardContent>
    </Card>
  )
}

LongShortAccStateView.propTypes = {
  accStateA: accStatePropTypes,
  accStateB: accStatePropTypes,
}

LongShortAccStateView.defaultProps= {
}

const mapStateToProps = (state, ownProps)=> {
  const accStateA = lodash.get(state, ['single', 'curPageData', 'accStateA'])
  const accStateB = lodash.get(state, ['single', 'curPageData', 'accStateB'])
  return {
    accStateA,
    accStateB
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return mapDispatch(dispatch)({...singleActions})
}

export default connect(mapStateToProps, mapDispatchToProps)(LongShortAccStateView)

