import React, {useEffect} from 'react'
import lodash from 'lodash'
import PropTypes from 'prop-types'
import {
  makeStyles,
  Box,
} from '@material-ui/core'
import { actions as singleActions } from '../../single/singleSlice'
import { mapDispatch } from '../../../utils/utils'
import {
  projectArrayPropType,
} from '../../../utils/propTypesUtils'
import {useRefFieldsPrevious, useStateReq} from '../../../utils/hooks'
import connect from 'react-redux/es/connect/connect'
import * as Yup from "yup"
import {Formik} from "formik"
import {CMD_UPDATE_EXCHANGE_ACCOUNTS} from "../../../protocolConstants"
import EditAccountListFormInner from "./EditAccountListFormInner"
import ConfirmDialog from "../../../components/ConfirmDialog"

const useStyles = makeStyles((theme) => ({
  formBox: {
  },
}))

const EditAccountListForm = ({ className, projects, selectedAccountIdMap: inputSelectedAccountIdMap, showSelectedChecked, filteredProjectId, setCurPageData, showToast }) => {
  const classes = useStyles()
  const {
    getStateField,
    setStateField,
    sendReq,
    isSending,
    isSuccessful
  } = useStateReq()

  const prevIsSuccessful = useRefFieldsPrevious('isSuccessful', isSuccessful)

  const dialogIsOpen = getStateField('dialogIsOpen', false)

  useEffect(() => {
    if (!prevIsSuccessful && isSuccessful) {
      setStateField('dialogIsOpen', true)
    }
  })

  const onDismiss = () => {
    setStateField('dialogIsOpen', false)
  }

  const onConfirmDialog = () => {
    setStateField('dialogIsOpen', false)
    window.location.reload()
  }

  const onEditAccounts = (values) => {
    sendReq({
      cmd: CMD_UPDATE_EXCHANGE_ACCOUNTS,
      isRelay: false,
      data: {
        ...values
      }
    })
  }

  const allExchangeAccounts = []
  const accIdToExId = {}
  lodash.forEach(projects, (prj) => {
    const { id: projectId, name: projectName, exchangeAccounts } = prj
    lodash.forEach(exchangeAccounts, (exchangeAccount) => {
      const { exchangeId, id: accId } = exchangeAccount
      allExchangeAccounts.push({
        projectId,
        projectName,
        ...exchangeAccount
      })
      accIdToExId[accId] = exchangeId
    })
  })

  const filterExchangeAccounts = lodash.filter(allExchangeAccounts, (ea) => {
    const { id } = ea
    return lodash.get(inputSelectedAccountIdMap, id, false)
  })
  const initExchangeAccounts = lodash.map(filterExchangeAccounts, (ea) => {
    const { id, name, pairs } = ea
    const pairsString = lodash.join(pairs, ',')
    return {
      id,
      name,
      pairsString,
    }
  })
  return (
    <Box
      className={classes.formBox}
      display="flex"
      flexDirection="column"
      justifyContent="center"
    >
      <Formik
        initialValues={{
          exchangeAccounts: initExchangeAccounts,
        }}
        validationSchema={Yup.object().shape({
          exchangeAccounts: Yup.array().of(Yup.object().shape({
            id: Yup.number().required(),
            pairsString: Yup.string().required('Pairs cannot be empty'), // pairs array separate by comma ','
            name: Yup.string().required(),
          }))
        })}
        onSubmit={onEditAccounts}
      >
        {(formProps) => {
          return (
            <EditAccountListFormInner
              isSending={isSending}
              accIdToExId={accIdToExId}
              formProps={formProps}
            />
          )
        }}
      </Formik>

      <ConfirmDialog
        maxWidth='lg'
        dialogOpen={dialogIsOpen}
        dialogTitle=''
        dialogContent="Update the pairs successfully...Please refresh the website to take effects..."
        dialogOnCancel={onDismiss}
        dialogOnConfirm={onConfirmDialog}
        dialogCancelText='Dismiss'
        dialogConfirmButton='Refresh Now'
      />
    </Box>
  )
}

EditAccountListForm.propTypes = {
  className: PropTypes.string,
  filteredProjectId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.oneOf([''])
  ]),
  projects: projectArrayPropType,
  setCurPageData: PropTypes.func.isRequired,
  clearCurPageData: PropTypes.func.isRequired,
  // map from accountId to bool, bool represents if it is selected
  selectedAccountIdMap: PropTypes.object.isRequired,
}

EditAccountListForm.defaultProps = {
  projects: [],
  selectedAccountIdMap: {},
  sessionSearchText: ''
}

const mapStateToProps = (state, ownProps)=> {
  const projects = lodash.get(state, ['single', 'profile', 'projects'], [])
  const selectedAccountIdMap = lodash.get(state, ['single', 'curPageData', 'selectedAccountIdMap'], {})
  const filteredProjectId = lodash.get(state, ['single', 'curPageData', 'filteredProjectId'])
  return {
    projects,
    selectedAccountIdMap,
    filteredProjectId,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return mapDispatch(dispatch)({...singleActions})
}



export default connect(mapStateToProps, mapDispatchToProps)(EditAccountListForm)
