/**
 * All the utils related to the protocols.
 */

/**
 * In API all the fee need to multiplied by 10 ^ -6
 */
const feeMtNumToLabel = (feeMt) => {
  return `${feeMt * 100 / (1e6)}%`
}

export {
  feeMtNumToLabel
}
