import React from 'react'
import * as Yup from 'yup'
import { Field, Formik } from 'formik'
import {
  Box,
  Button,
  makeStyles,
  Card,
  Grid, CardHeader, Divider
} from '@material-ui/core'
import { mapDispatch } from '../../utils/utils'
import connect from 'react-redux/es/connect/connect'
import PropTypes from 'prop-types'
import Decimal from 'decimal.js'
import PiCardContent from '../../components/PiCardContent'
import PiOneColRowGrid from '../../components/PiOneColRowGrid'
import storage from '../../utils/storage'
import { STORAGE_KEY_LOCAL_SETTINGS } from '../../constants'
import FormSlider from '../../components/form/FormSlider'
import { changeViewportScale } from '../../utils/miscUtils'


const useStyles = makeStyles((theme) => ({
  formBox: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  formInner: {
    paddingTop: theme.spacing(5),
  },
  cardContent: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  }
}))

const LocalSettingsForm = ({ initValues }) => {
  const classes = useStyles()

  const onSaveLocalSettings = (values) => {
    storage.setObj(STORAGE_KEY_LOCAL_SETTINGS, values)
    const { viewportScale } = values
    changeViewportScale(viewportScale)
  }

  return (
    <Box
      autoComplete="off"
      className={classes.formBox}
      display="flex"
      flexDirection="column"
      justifyContent="center"
    >
      <Formik
        initialValues={{
          ...initValues
        }}
        validationSchema={Yup.object().shape({
          viewportScale: Yup.number().max(100, 'Max 1').min(10, 'Min is 0.1')
        })}
        onSubmit={onSaveLocalSettings}
      >
        {(formProps) => {
          return (
            <LocalSettingsFormInner
              formProps={formProps}
            />
          )
        }}
      </Formik>
    </Box>
  )
}


LocalSettingsForm.propTypes = {
  initValues: PropTypes.object.isRequired,
}

LocalSettingsForm.defaultProps = {
}

const mapStateToProps = (state, ownProps)=> {
  return {
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return mapDispatch(dispatch)({})
}

export default connect(mapStateToProps, mapDispatchToProps)(LocalSettingsForm)


const LocalSettingsFormInner = ({ formProps}) => {
  const classes = useStyles()
  const { handleSubmit } = formProps
  const handleViewportValueLabel = (val) => {
    if (val === 100) {
      return 1
    }
    return new Decimal(val / 100).toFixed(2)
  }
  return (
    <form onSubmit={handleSubmit}>
      <Card>
        <PiCardContent
          className={classes.cardContent}
        >
          <CardHeader
            title="Settings"
          />
          <Divider />
          <Grid container spacing={3} className={classes.formInner}>
            <PiOneColRowGrid>
              <Field
                name="viewportScale"
                label="Mobile Viewport Scale"
                min={10}
                max={100}
                handleValueLabel={handleViewportValueLabel}
                component={FormSlider}
              />
            </PiOneColRowGrid>

            <PiOneColRowGrid>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                type="submit">
                Save
              </Button>
            </PiOneColRowGrid>
          </Grid>
        </PiCardContent>
      </Card>
    </form>
  )
}
