import { createTheme, colors } from '@material-ui/core'
import shadows from './shadows'
import typography from './typography'
import lodash from 'lodash'

const themeInfo = {
  palette: {
    background: {
      dark: '#F4F6F8',
      default: colors.common.white,
      paper: colors.common.white
    },
    primary: {
      main: colors.indigo[500]
    },
    secondary: {
      main: colors.indigo[500]
    },
    text: {
      primary: colors.blueGrey[900],
      secondary: colors.blueGrey[600]
    }
  },
  shadows,
  typography,
}

const theme = createTheme(themeInfo)

/**
 * Some customization, such as less spacing
 */
const mobileTheme = createTheme(lodash.merge({}, themeInfo, {
  spacing: 2,
}))

const smallTheme = createTheme(lodash.merge({}, themeInfo, {
  typography: {
    fontSize: 10
  }
}))

export default theme

export {
  smallTheme,
  mobileTheme
}
