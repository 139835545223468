import { baseQuote } from '../../../../utils/utils'
import {
  BUY_SELL_OPTIONS,
  SIDE_BUY,
  SIDE_SELL,
  TOKEN_BUY_SELL_MODE_NO_SPREAD,
  TOKEN_BUY_SELL_MODE_WITH_SPREAD
} from '../../../../protocolConstants'
import React, { useCallback, useEffect } from 'react'
import { Button, Card, Grid, makeStyles } from '@material-ui/core'
import ColorButton from '../../../../components/ColorButton'
import PiCardContent from '../../../../components/PiCardContent'
import PiTwoColRowGrid from '../../../../components/PiTwoColRowGrid'
import { Field } from 'formik'
import PiInputAdornment from '../../../../components/PiInputAdornment'
import FormInput from '../../../../components/form/FormInput'
import FormRadio from '../../../../components/form/FormRadio'
import FormInterval from '../../../../components/form/FormInterval'
import FormSlider from '../../../../components/form/FormSlider'
import lodash from 'lodash'
import FormSelect from "../../../../components/form/FormSelect"

const useStyles = makeStyles((theme) => ({
  button: {
    height: "100%"
  }
}))

const MODE_OPTIONS = [
  {
    value: TOKEN_BUY_SELL_MODE_NO_SPREAD,
    label: 'Mode: No Spread',
  },
  {
    value: TOKEN_BUY_SELL_MODE_WITH_SPREAD,
    label: 'Mode: With Spread'
  }
]

const TokenBuySellAlgoFormInner = ({ formProps, pairInfo, active, onStopTaker, setCurPageData, tokenBuySellAlgoFormUpdatingData }) => {
  const { values, handleSubmit, setFieldValue } = formProps
  const { pxPrec, pair, qtyPrec } = pairInfo
  const [base, quote] = baseQuote(pair)
  const classes = useStyles()
  let isSending = false

  const { buySell } = values
  let remainingAssetLabel = ''
  let pxLabel = 'Order Lmt Px'
  if (buySell === SIDE_BUY) {
    remainingAssetLabel = quote
    pxLabel = 'Order Max Price'
  } else if (buySell === SIDE_SELL) {
    remainingAssetLabel = base
    pxLabel = 'Order Min Price'
  }

  const handleLayerNumLabel = useCallback((layerNum) => {
    return `${layerNum}`
  }, [])

  const handleLayerGapBasePointLabel = useCallback((layerGapBasePoint) => {
    return `${layerGapBasePoint/100}%`
  }, [])

  let startStopButton
  if (active) {
    startStopButton = <Button
      className={classes.button}
      disabled={isSending}
      fullWidth
      size="small"
      onClick={onStopTaker}
      variant="contained"
    >
      Stop
    </Button>
  } else {
    let buttonLabel
    let colorStyle
    if (buySell === SIDE_BUY) {
      buttonLabel = "Preview & Buy Tokens"
      colorStyle = "green"
    } else if (buySell === SIDE_SELL) {
      buttonLabel = "Preview & Sell Tokens"
      colorStyle = "red"
    } else {
      buttonLabel = "Start"
    }
    startStopButton = <ColorButton
      className={classes.button}
      disabled={isSending}
      fullWidth
      size="small"
      type="submit"
      colorStyle={colorStyle}
    >
      {buttonLabel}
    </ColorButton>
  }


  useEffect(() => {
    if (!lodash.isEmpty(tokenBuySellAlgoFormUpdatingData)) {
      setCurPageData({
        tokenBuySellAlgoFormUpdatingData: {} // clear the data
      })
      lodash.forEach(tokenBuySellAlgoFormUpdatingData, (val, key) => {
        setFieldValue(key, val)
      })
    }
  }, [setFieldValue, tokenBuySellAlgoFormUpdatingData, setCurPageData])

  return (
    <form onSubmit={handleSubmit}>
      <Card>
        <PiCardContent>
          <Grid container>
            <PiTwoColRowGrid>
              <Field
                name="orderQty"
                label="Order Quantity"
                prec={qtyPrec}
                InputProps={{
                  endAdornment: <PiInputAdornment position="end" label={base}/>
                }}
                inputProps={{
                  type: 'number',
                  style: {
                    textAlign: 'right'
                  }
                }}
                component={FormInput}
              />
            </PiTwoColRowGrid>

            <PiTwoColRowGrid>
              <Field
                name="remainingQty"
                label="Remaining Asset"
                prec={qtyPrec}
                InputProps={{
                  endAdornment: <PiInputAdornment position="end" label={remainingAssetLabel}/>
                }}
                inputProps={{
                  type: 'number',
                  style: {
                    textAlign: 'right'
                  }
                }}
                component={FormInput}
              />
            </PiTwoColRowGrid>

            <PiTwoColRowGrid>
              <Field
                name="orderLmtPx"
                label={pxLabel}
                prec={pxPrec}
                inputProps={{
                  type: 'number',
                  style: {
                    textAlign: 'right'
                  }
                }}
                component={FormInput}
              />
            </PiTwoColRowGrid>

            <PiTwoColRowGrid>
              <Field
                name="layerNum"
                label="Layer Num"
                min={1}
                max={5}
                component={FormSlider}
                handleValueLabel={handleLayerNumLabel}
              />
            </PiTwoColRowGrid>


            <PiTwoColRowGrid>
              <Field
                name="layerGapBasePoint"
                label="Layer Gap"
                min={1}
                max={100}
                component={FormSlider}
                handleValueLabel={handleLayerGapBasePointLabel}
              />
            </PiTwoColRowGrid>

            <PiTwoColRowGrid>
              <Field
                name="minSpread"
                label="Min Spread"
                prec={pxPrec}
                inputProps={{
                  type: 'number',
                  style: {
                    textAlign: 'right'
                  }
                }}
                component={FormInput}
              />
            </PiTwoColRowGrid>

            <PiTwoColRowGrid
              xs={12}
            >
              <Field
                name="buySell"
                label=""
                options={BUY_SELL_OPTIONS}
                component={FormRadio}
              />
            </PiTwoColRowGrid>

            <PiTwoColRowGrid
              xs={12}
            >
              <Field
                name="tokenBuySellMode"
                placeholder="Mode"
                options={MODE_OPTIONS}
                component={FormSelect}
              />
            </PiTwoColRowGrid>

            <PiTwoColRowGrid
              xs={12}
            >
              <Field
                name="itvlMs"
                label="Interval"
                component={FormInterval}
              />
            </PiTwoColRowGrid>

            <PiTwoColRowGrid
              xs={12}
            >
              {startStopButton}
            </PiTwoColRowGrid>
          </Grid>
        </PiCardContent>
      </Card>
    </form>
  )
}

export default TokenBuySellAlgoFormInner
