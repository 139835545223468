import XscrollTable from '../../../../ui/XscrollTable'
import { TableBody, TableRow } from '@material-ui/core'
import React from 'react'
import PiTableCell from '../../../../../components/PiTableCell'

const TableRowNameVal = ({name, value}) => {
  return (
    <TableRow hover>
      <PiTableCell
        variant='head'
      >
        {name}
      </PiTableCell>
      <PiTableCell>
        {value}
      </PiTableCell>
    </TableRow>
  )
}

const DialogContent = ({previewData}) => {
  const { buySell, itvl, remainingQty, remainingAsset, orderQty, startPx, stopPx } = previewData
  return (
    <XscrollTable>
      <TableBody>
        <TableRowNameVal
          name="Side"
          value={buySell}
        />
        <TableRowNameVal
          name="Interval (sec)"
          value={parseInt(itvl / 1000)}
        />
        <TableRowNameVal
          name="Remaining Asset"
          value={`${remainingQty} (${remainingAsset})`}
        />
        <TableRowNameVal
          name="Order Qty"
          value={orderQty}
        />
        <TableRowNameVal
          name="Start Px"
          value={startPx}
        />
        <TableRowNameVal
          name="Stop Px"
          value={stopPx}
        />
      </TableBody>
    </XscrollTable>
  )
}

export default DialogContent
