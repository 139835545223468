import React from 'react'
import {
  Box,
  makeStyles,
} from '@material-ui/core'
import { mapDispatch } from '../../../../utils/utils'
import { actions as socketActions } from '../../../socket/socketSlice'
import { actions as singleActions } from '../../../single/singleSlice'
import connect from 'react-redux/es/connect/connect'
import PropTypes from 'prop-types'
import lodash from 'lodash'
import {
  ACCOUNT_CAT_CONTRACT,
  ACCOUNT_CAT_FUTURE,
  ACCOUNT_CAT_MARGIN,
  ACCOUNT_CAT_SPOT,
  ACCOUNT_CAT_PORTFOLIO,
  CMD_LONG_SHORT_PLACE_ORDER,
} from '../../../../protocolConstants'
import logger from '../../../../utils/logger'
import PlaceOrderForm from '../../../units/place_order'
import { longShortAccCfgPropTypes } from '../../../../utils/propTypesUtils'


const useStyles = makeStyles((theme) => ({
  formBox: {
  },
}))


const isValidCat = (cat) => {
  return cat === ACCOUNT_CAT_SPOT ||
    cat === ACCOUNT_CAT_MARGIN ||
    cat === ACCOUNT_CAT_FUTURE ||
    cat === ACCOUNT_CAT_CONTRACT ||
    cat === ACCOUNT_CAT_PORTFOLIO
}

const LongShortPlaceOrderForm = ({ sessionId, accCfgA, accCfgB }) => {
  const classes = useStyles()
  if (!accCfgA || !accCfgB) {
    return null
  }

  const accInfoLst = [
    accCfgA,
    accCfgB
  ]

  const accNamePairMap = {} // map from accName => pair => bool
  const accNamePairPairInfos = {}
  for (let i = 0; i < accInfoLst.length; i ++) {
    const accInfo = accInfoLst[i]
    const { accCat, accName, tradablePairs, pairInfos } = accInfo
    if (!isValidCat(accCat)) {
      continue
    }

    const accPairs = lodash.get(accNamePairMap, accName, {})
    lodash.forEach(tradablePairs, (p) => {
      if (p === ''){
        logger.error(`pairs has empty pair: ${p}`)
        return
      }
      accPairs[p] = true
    })
    accNamePairMap[accName] = accPairs

    const pairPairInfos = lodash.get(accNamePairPairInfos, accName, {})
    lodash.forEach(pairInfos, (pi, p) => {
      pairPairInfos[p] = pi
    })
    accNamePairPairInfos[accName] = pairPairInfos
  }

  const accNameOptions = []
  const accNamePairOptions = []
  lodash.forEach(accNamePairMap, (pairMap, accName) => {
      accNameOptions.push({
        value: accName,
        label: accName,
      })

    const pairOptions = []
    lodash.forEach(pairMap, (_, p) => {
      pairOptions.push({
        value: p,
        label: p,
      })
    })
    accNamePairOptions.push({
        accName,
        pairOptions,
    })
  })

  return (
    <Box
      className={classes.formBox}
      display="flex"
      flexDirection="column"
      justifyContent="center"
    >
      <PlaceOrderForm
        accNameOptions ={accNameOptions}
        accNamePairOptions = {accNamePairOptions}
        accNamePairPairInfos={accNamePairPairInfos}
        placeOrderCmd={CMD_LONG_SHORT_PLACE_ORDER}
        isEmptyAccName={false}
        isEmptyPair={false}
        sessionId={sessionId}
      />
    </Box>
  )
}


LongShortPlaceOrderForm.propTypes = {
  sessionId: PropTypes.number.isRequired,
  accCfgA: longShortAccCfgPropTypes,
  accCfgB: longShortAccCfgPropTypes,
}

LongShortPlaceOrderForm.defaultProps = {

}

const mapStateToProps = (state, ownProps)=> {
  const accCfgA = lodash.get(state, ['single', 'curPageData', 'accCfgA'])
  const accCfgB = lodash.get(state, ['single', 'curPageData', 'accCfgB'])
  return {
    accCfgA,
    accCfgB
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return mapDispatch(dispatch)({...socketActions, ...singleActions})
}

export default connect(mapStateToProps, mapDispatchToProps)(LongShortPlaceOrderForm)

