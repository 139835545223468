import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'
import TopBar from './TopBar'
import ToastAlertView from '../ToastAlertView'
import PropTypes from 'prop-types'
import lodash from 'lodash'
import { mapDispatch } from '../../utils/utils'
import connect from 'react-redux/es/connect/connect'
import { PATH_HOME } from '../../constants'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}))

/**
 * The layout when user has not yet logged in
 */
const MainLayout = (props) => {
  const classes = useStyles()
  const { authToken } = props
  if(!lodash.isEmpty(authToken)) {
    return (
      <Navigate to={PATH_HOME} />
    )
  }
  return (
    <div className={classes.root}>
      <TopBar />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <ToastAlertView/>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  )
}


MainLayout.propTypes = {
  authToken: PropTypes.string
}

const mapStateToProps = (state, ownProps)=> {
  const authToken = lodash.get(state, ['single', 'authToken'], '')
  return {
    authToken
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return mapDispatch(dispatch)({})
}

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout)
