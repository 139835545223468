import React, { useEffect, useContext } from 'react'
import clsx from 'clsx'
import lodash from 'lodash'
import PropTypes from 'prop-types'
import {
  Card,
  TableBody,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
  Button,
  Link,
  TableSortLabel,
} from '@material-ui/core'
import logger from '../../../utils/logger'
import { actions as singleActions } from '../../single/singleSlice'
import ConfirmDialog from '../../../components/ConfirmDialog'
import {mapDispatch, parsePath} from '../../../utils/utils'
import { PATH_SESSION_WITH_ID } from '../../../constants'
import {
  algorithmArrayPropType,
  projectArrayPropType,
  sessionArrayPropType,
} from '../../../utils/propTypesUtils'
import { useIsMobile, useRefFieldsPrevious, useStateReq } from '../../../utils/hooks'
import connect from 'react-redux/es/connect/connect'
import {CMD_TERMINATE_WORKER_PROCESS, SESSION_STATUS_WAITING_FOR_WORKER} from '../../../protocolConstants'
import XscrollTable from '../../ui/XscrollTable'
import PiTableCell from '../../../components/PiTableCell'
import { PiContext } from '../../../utils/piContext'
import {dayjsNow, displayShort, fromMs} from "../../../utils/timeUtils"


const useStyles = makeStyles((theme) => ({
  root: {
      marginBottom: theme.spacing(3),
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  wordWrap: {
    'word-wrap': 'break-word',
  },
  noBoraderBottom : {
    borderBottom: "none"
  },
  table: {
    tableLayout: 'fixed'
  },
  cell: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3)
  },
  errorSpan: {
    color: "red"
  }
}))

const SessionCell = ({ children, className, ...rest }) => {
  const { isMobile } = useContext(PiContext)
  const classes = useStyles()
  const cellClass = isMobile ? classes.cell : ''
  return (
    <PiTableCell
      className={clsx(cellClass, className)}
      size="medium"
      disablePiStyle
      {...rest}
    >
      {children}
    </PiTableCell>
  )
}

const SessionHeaderCell = ({ children, fieldName, onHeaderClick, sortedByFieldName, isAsc, ...rest }) => {
  let sortDirection = 'asc'
  let active = false
  if (!lodash.isEmpty(fieldName) && !lodash.isEmpty(sortedByFieldName) && fieldName === sortedByFieldName) {
    active = true
    if (isAsc) {
      sortDirection = 'asc'
    } else {
      sortDirection = 'desc'
    }
  }
  return (
    <SessionCell
      onClick={lodash.isEmpty(fieldName) ? null : onHeaderClick(fieldName)}
      {...rest}
    >
      <TableSortLabel
        active={active}
        direction={sortDirection}
      >
        {children}
      </TableSortLabel>
    </SessionCell>
  )
}

const SessionNameCell = ({name, id, isMobile, isAbnormal}) => {
  const classes = useStyles()
  const nameClassName = isMobile ? clsx(classes.wordWrap, classes.noBoraderBottom) : classes.wordWrap
  return (
    <SessionCell
      key='name'
      className={nameClassName}
      colSpan={isMobile ? 4: undefined}
    >
      <Typography
        color="textPrimary"
        variant="body1"
      >
        <Link href={parsePath(PATH_SESSION_WITH_ID, {
          sessionId: id
        })} target="_blank">
          {name}
        </Link>
        { isAbnormal ? <span className={classes.errorSpan}> (Abn.)</span> : null }
      </Typography>
    </SessionCell>
  )
}

const oneSessionInfoRows = ({ isMobile,  sessionInfo, handleShowDestroyDialog, algorithms, projects}) => {
  const {
    id,
    algorithmName,
    createdAtMs = null,
    projectName,
    name='',
    status,
    serverName='',
  } = sessionInfo

  let createdAtStr = '-'

  let statusAbnormal = status === SESSION_STATUS_WAITING_FOR_WORKER
  if (createdAtMs !== null) {
    const createdDayjs =  fromMs(createdAtMs)
    createdAtStr = displayShort(createdDayjs)
    if (statusAbnormal && dayjsNow().subtract(1, 'minute').isBefore(createdDayjs)) {
      // if created in less than 1 min, ignore the abnormal status
      statusAbnormal = false
    }
  }
  const nameCell = <SessionNameCell
    name={name}
    id={id}
    isMobile={isMobile}
    isAbnormal={statusAbnormal}
  />
  const restCells = [
    <SessionCell key='projectName'>
      {projectName}
    </SessionCell>,
    <SessionCell key='algorithmName'>
      {algorithmName}
    </SessionCell>,
    <SessionCell key='id'>
      {id}
    </SessionCell>,
    <SessionCell key='serverName'>
      {serverName}
    </SessionCell>,
    <SessionCell key='createdAtMs'>
      {createdAtStr}
    </SessionCell>,
    <SessionCell key='destroy'>
      <Button
      size="small"
      variant="contained"
      onClick={(event) => handleShowDestroyDialog(event, sessionInfo)}
    >
      Destroy
      </Button>
    </SessionCell>
  ]

  if (isMobile) {
    return [
      <TableRow
        key={`${sessionInfo.id}_name`}
      >
        {nameCell}
      </TableRow>,
      <TableRow
        key={`${sessionInfo.id}_rest`}
      >
        {restCells}
      </TableRow>
    ]
  } else {
    return [
      <TableRow
        hover
        key={sessionInfo.id}
      >
        {nameCell}
        {restCells}
      </TableRow>
    ]
  }
}

const SessionContentTableBody = ({filteredSessionList, handleShowDestroyDialog, projects, algorithms}) => {
  const piValues = useContext(PiContext)
  const { isMobile } = piValues
  const ret = []
  lodash.forEach(filteredSessionList, (sessionInfo) => {
    ret.push(...oneSessionInfoRows({
      sessionInfo,
      isMobile,
      handleShowDestroyDialog,
      projects,
      algorithms
    }))
  })
  return ret
}

const SessionListResults = ({ className, algorithms, projects, sessionList, sessionSearchText, filteredProjectId, setCurPageData }) => {
  const classes = useStyles()
  const isMobile = useIsMobile()

  const {
    getStateField,
    setStateField,
    sendReq,
    isSuccessful,
    sentData
  } = useStateReq()

  if (!sessionList) {
    sessionList = []
  }

  sessionList = lodash.map(sessionList, (sessionInfo) => {
    // add algorithmName and projectName into this
    const {
      algorithmId,
      projectId
    } = sessionInfo
    const algorithm = lodash.find(algorithms, (a) => {
      return a['id'] === algorithmId
    })

    const project = lodash.find(projects, (a) => {
      return a['id'] === projectId
    })
    return {
      ...sessionInfo,
      projectName: project.name,
      algorithmName: algorithm.name
    }
  })

  let filteredSessionList = sessionList
  if (!lodash.isEmpty(sessionSearchText)) {
    const sessionSearchTextLower = sessionSearchText.toLowerCase()
    filteredSessionList = lodash.filter(filteredSessionList, (cs) => {
      return lodash.get(cs, 'name', '').toLowerCase().includes(sessionSearchTextLower) ||
        lodash.get(cs, 'id', '').toString() === sessionSearchTextLower ||
        lodash.get(cs, 'instancerName', '').toString().toLowerCase() === sessionSearchTextLower ||
        lodash.get(cs, 'serverName', '').toString().toLowerCase() === sessionSearchTextLower
    })
  }

  if (filteredProjectId !== undefined && filteredProjectId !== '') {
    filteredSessionList = lodash.filter(filteredSessionList, (cs) => {
      return lodash.get(cs, 'projectId') === filteredProjectId
    })
  }



  const sortedByFieldName = getStateField('sortedByFieldName', 'name')
  const isAsc = getStateField('isAsc', true) // asc or desc
  // sort the session list
  const sessionOrderByIteratee = (session) => {
    const fieldVal = session[sortedByFieldName]
    if (lodash.isString(fieldVal)) {
      return fieldVal.toLowerCase()
    } else {
      return fieldVal
    }
  }
  filteredSessionList = lodash.orderBy(filteredSessionList, sessionOrderByIteratee, [isAsc ? 'asc': 'desc'])

  const onHeaderClick = (fieldName) => () => {
    if (sortedByFieldName !== fieldName) {
      setStateField('sortedByFieldName', fieldName)
      setStateField('isAsc', true)
    } else {
      setStateField('isAsc', !Boolean(isAsc))
    }
  }

  const prevIsSuccessful = useRefFieldsPrevious('isSuccessful', isSuccessful)
  useEffect(() => {
    if (!prevIsSuccessful && isSuccessful) {
      const destroySessionId = lodash.get(sentData, ['data', 'sessionId'])
      if (destroySessionId !== undefined) {
        const postDestroySessionList = lodash.filter(sessionList, (session) => {
          return lodash.get(session, 'id') !== destroySessionId
        })
        setCurPageData({
          sessionList: postDestroySessionList
        })
      }
    }
  }, [prevIsSuccessful, isSuccessful, setCurPageData, sessionList, sentData])

  const destroySessionInfo = getStateField('destroySessionInfo', {
      destroyDialogOpen: false,
      destroySessionId: undefined,
      destroySessionName: '',
  })

  const { destroyDialogOpen, destroySessionId, destroySessionName } = destroySessionInfo

  const handleCancelDestroy = () => {
    setStateField('destroySessionInfo', {
      destroyDialogOpen: false,
      destroySessionId: undefined,
      destroySessionName: '',
    })
  }

  const handleExecuteDestroy = () => {
    logger.info('executing destroy', {
      destroySessionId,
      destroySessionName
    })
    setStateField('destroySessionInfo', {
      destroyDialogOpen: false,
      destroySessionId: undefined,
      destroySessionName: '',
    })
    sendReq({
      cmd: CMD_TERMINATE_WORKER_PROCESS,
      data: {
        sessionId: destroySessionId
      }
    })
  }

  const handleShowDestroyDialog = (event, session) => {
    const { id, name } = session
    setStateField('destroySessionInfo', {
      destroyDialogOpen: true,
      destroySessionId: id,
      destroySessionName: name,
    })
  }
  return (
    <Card
      className={clsx(classes.root, className)}
    >
      <XscrollTable>
        <TableHead>
          <TableRow>
            {
              isMobile ? null : <SessionHeaderCell
                fieldName="name"
                onHeaderClick={onHeaderClick}
                sortedByFieldName={sortedByFieldName}
                isAsc={isAsc}
              >
                Name
              </SessionHeaderCell>
            }
            <SessionHeaderCell
              fieldName='projectName'
              onHeaderClick={onHeaderClick}
              sortedByFieldName={sortedByFieldName}
              isAsc={isAsc}
            >
              Project
            </SessionHeaderCell>
            <SessionHeaderCell
              fieldName='algorithmName'
              onHeaderClick={onHeaderClick}
              sortedByFieldName={sortedByFieldName}
              isAsc={isAsc}
            >
              Algorithm
            </SessionHeaderCell>
            <SessionHeaderCell
              fieldName='id'
              onHeaderClick={onHeaderClick}
              sortedByFieldName={sortedByFieldName}
              isAsc={isAsc}
            >
              Id
            </SessionHeaderCell>
            <SessionHeaderCell
              fieldName='serverName'
              onHeaderClick={onHeaderClick}
              sortedByFieldName={sortedByFieldName}
              isAsc={isAsc}
            >
              Svr
            </SessionHeaderCell>

            <SessionHeaderCell
              fieldName='createdAtMs'
              onHeaderClick={onHeaderClick}
              sortedByFieldName={sortedByFieldName}
              isAsc={isAsc}
            >
              Created At
            </SessionHeaderCell>

            <SessionCell>
              Action
            </SessionCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <SessionContentTableBody
            filteredSessionList={filteredSessionList}
            handleShowDestroyDialog={handleShowDestroyDialog}
            algorithms={algorithms}
            projects={projects}
          />
        </TableBody>
      </XscrollTable>

      <ConfirmDialog
        dialogOpen={destroyDialogOpen}
        dialogTitle='Are you sure you want to destroy the session?'
        dialogContent={`Confirm to destroy session with id: ${destroySessionId} name: ${destroySessionName}.`}
        dialogCancelText='Cancel'
        dialogConfirmButton='Destroy Now'
        dialogOnCancel={handleCancelDestroy}
        dialogOnConfirm={handleExecuteDestroy}
      />
    </Card>
  )
}

SessionListResults.propTypes = {
  className: PropTypes.string,
  sessionList: sessionArrayPropType,
  sessionSearchText: PropTypes.string.isRequired,
  filteredProjectId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.oneOf([''])
  ]),
  algorithms: algorithmArrayPropType,
  projects: projectArrayPropType,
  setCurPageData: PropTypes.func.isRequired,
}

SessionListResults.defaultProps = {
  sessionList: [],
  algorithms: [],
  projects: []
}

const mapStateToProps = (state, ownProps)=> {
  const sessionList = lodash.get(state, ['single', 'curPageData', 'sessionList'], [])
  const sessionSearchText = lodash.get(state, ['single', 'curPageData', 'sessionSearchText'], '')
  const filteredProjectId = lodash.get(state, ['single', 'curPageData', 'filteredProjectId'])
  return {
    sessionList,
    sessionSearchText,
    filteredProjectId,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return mapDispatch(dispatch)({...singleActions})
}

export default connect(mapStateToProps, mapDispatchToProps)(SessionListResults)
