import { CardContent, makeStyles } from '@material-ui/core'
import { useContext } from 'react'
import { PiContext } from '../utils/piContext'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  piCard: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  }
}))

const PiCardContent = ({ children, className, disablePiStyle, ...rest }) => {
  const piContext = useContext(PiContext)
  const classes = useStyles()
  const { isMobile } = piContext
  if (!disablePiStyle) {
    if (isMobile) {
      className = clsx(className, classes.piCard)
    }
  }
  return (
    <CardContent className={className} {...rest}>
      {children}
    </CardContent>
  )
}

export default PiCardContent
