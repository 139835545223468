

/**
 * Client side only constants
 * @type {string}
 */
const ALERT_INFO = 'info'
const ALERT_WARNING = 'warning'
const ALERT_SUCCESS = 'success'
const ALTER_ERROR = 'error'

const ALERT_DEFAULT_DURATION_MS = 6000

const ACTION_TYPE_SOCKET_SEND = 'socket_send'
const ACTION_TYPE_SOCKET_REQUEST_INFO_UPDATE = 'socket_req_info_update'
const ACTION_TYPE_RECONNECT_SOCKET = 'socket_reconnect'

const REQ_STATUS_PENDING = 'sending'
const REQ_STATUS_SUCCESSFUL = 'successful'
const REQ_STATUS_FAILURE = 'failure'

const MSG_MAX_AGE_MILLISEC = 60 * 1000
/**
 * we will start to clean the message, when we find that
 * the oldest msg is (MSG_MAX_AGE_MILLISEC + MSG_PRUNE_AFTER_MAX_AGE_MILLISEC) older than current timestamp
 */
const MSG_PRUNE_ADDITIONAL_MAX_AGE_MILLISEC = 30 * 1000

const MSG_MIN_CACHE_SIZE = 30
const MSG_PRUNE_ADDITIONAL_CACHE_SIZE = 15

const REQ_TIMEOUT_MILLISEC = 30 * 1000

const SOCKET_PING_ITV_MILLISEC = 7000 // the ping interval
const SOCKET_PING_TIMEOUT_MILLISEC = 22000 //

const SOCKET_RECONNECT_MIN_SEC = 1
const SOCKET_RECONNECT_MAX_SEC = 25
const SOCKET_RECONNECT_DELTA_SEC = 5


const STORAGE_KEY_AUTH_TOKEN = 'auth_token'
const STORAGE_KEY_LOCAL_SETTINGS= 'local_settings'

/**
 * Server side and client side shared constants
 * @type {string}
 */

const PATH_HOME = '/'
const PATH_LOGIN = '/login'
const PATH_SESSIONS = '/sessions'
const PATH_SESSION_WITH_ID = '/sessions/:sessionId'
const PATH_ACCOUNTS = '/accounts'
const PATH_SETTINGS = '/settings'
const PATH_404 = '/404'
const PATH_DEBUGZONE = '/debugzone'


// start to trim if max length is larger than StartToTrim, and will trim to TrimTo num
const LOG_LINES_START_TO_TRIM = 410
const LOG_LINES_TRIM_TO = 400

const COLOR_BUY = '#0f8f62'
const COLOR_SELL = '#d9304f'

const COLOR_LIGHT_BUY =  'rgba(15, 143, 98, 0.5)'
const COLOR_LIGHT_SELL = 'rgba(217, 48, 79, 0.5)'

const COLOR_BG_BUY = '#D7EAE4'
const COLOR_BG_SELL = '#F5DCF5'

const COLOR_BG_GREY = '#E8E8E8'


const INFINITE_SYMBOL = '∞'


const HIDDEN_PROJECT_NAMES = new Set([
  'BzzMm',
  'RampMM',
  'StripMm',
  'Upbot',
  'EvryMm',
  'EthaMM',
  'RikiRuMm',
  'PtfMM',
  'CalamariMm',
  'CalamariMm2',
  'BynMm',
  'BifrostMm2',
  'DbioMm',
])

// for front end display purpose
const INSTANCER_NAME_TO_SERV_NAME_MAPPING = {
  'sg1': 'asg3',
  'sg2': 'asg28',
  'sg3': 'asg30',
  'sg4': 'asg23',
  'sg5': 'asg25',
  'jp1': 'jp12',
  'jp2': 'jp7',

  'instancer1': 'instancerServer1' // dev env
}

export {
  ALERT_INFO,
  ALERT_WARNING,
  ALERT_SUCCESS,
  ALTER_ERROR,
  ALERT_DEFAULT_DURATION_MS,

  ACTION_TYPE_SOCKET_SEND,
  ACTION_TYPE_SOCKET_REQUEST_INFO_UPDATE,
  ACTION_TYPE_RECONNECT_SOCKET,


  REQ_STATUS_PENDING,
  REQ_STATUS_SUCCESSFUL,
  REQ_STATUS_FAILURE,

  MSG_MAX_AGE_MILLISEC,
  MSG_PRUNE_ADDITIONAL_MAX_AGE_MILLISEC,
  MSG_MIN_CACHE_SIZE,
  MSG_PRUNE_ADDITIONAL_CACHE_SIZE,
  REQ_TIMEOUT_MILLISEC,

  SOCKET_PING_ITV_MILLISEC,
  SOCKET_PING_TIMEOUT_MILLISEC,

  SOCKET_RECONNECT_MIN_SEC,
  SOCKET_RECONNECT_MAX_SEC,
  SOCKET_RECONNECT_DELTA_SEC,

  STORAGE_KEY_AUTH_TOKEN,
  STORAGE_KEY_LOCAL_SETTINGS,

  PATH_HOME,
  PATH_LOGIN,
  PATH_SESSIONS,
  PATH_SESSION_WITH_ID,
  PATH_ACCOUNTS,
  PATH_SETTINGS,
  PATH_DEBUGZONE,
  PATH_404,

  LOG_LINES_START_TO_TRIM,
  LOG_LINES_TRIM_TO,

  COLOR_BUY,
  COLOR_SELL,
  COLOR_LIGHT_BUY,
  COLOR_LIGHT_SELL,
  COLOR_BG_BUY,
  COLOR_BG_SELL,
  COLOR_BG_GREY,

  INFINITE_SYMBOL,

  HIDDEN_PROJECT_NAMES,
  INSTANCER_NAME_TO_SERV_NAME_MAPPING,
}
