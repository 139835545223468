import React from 'react'
import clsx from 'clsx'
import {
  Card,
  TableBody,
  TableRow,
  makeStyles,
  TableHead, Typography
} from '@material-ui/core'
import lodash from 'lodash'
import { baseQuote, mapDispatch } from '../../../../utils/utils'
import { actions as singleActions } from '../../../single/singleSlice'
import connect from 'react-redux/es/connect/connect'
import XscrollTable from '../../../ui/XscrollTable'
import PiCardContent from '../../../../components/PiCardContent'
import SmallTableCell from './SmallTableCell'
import { accStatePropTypes, longShortOrderbookPropTypes } from './longShortUtils'
import { ACCOUNT_CAT_MARGIN } from '../../../../protocolConstants'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  cardContent: {
    height: '100%'
  },
}))

const FreeLockedLmtCells = ({ accCat, pair='', accCode, marginState, isTitle=false }) => {
  if (accCat === ACCOUNT_CAT_MARGIN) {
    let freeLockVal = `${accCode}(Fre/Lok)`
    let borLmtVal = `${accCode}(Bor/Lmt)`
    if (!isTitle) {
      const base = baseQuote(pair)[0]
      const crossMarginAssetInfos = lodash.get(marginState, 'crossMarginAssetInfos', {})
      const assetInfo = lodash.get(crossMarginAssetInfos, base, {})
      const { free, locked, borWtInt, borLmt } = assetInfo
      freeLockVal = `${free}/${locked} ${base}`
      borLmtVal = `${borWtInt}/${borLmt} ${base}`
    }
    return [
      <SmallTableCell key={`fllc_freelock_${pair}_${isTitle}`}>
        {freeLockVal}
      </SmallTableCell>,
      <SmallTableCell key={`fllc_borlmt_${pair}_${isTitle}`}>
        {borLmtVal}
      </SmallTableCell>
    ]

  } else {
    return null
  }
}

const LongShortPositionInfoView = ({ accStateA, accStateB, orderbookA, orderbookB }) => {
  const classes = useStyles()
  if (!accStateA || ! accStateB) {
    return null
  }
  const { accCat: accCatA, pairs: pairsA, pairStates: pairStatesA, marginState: marginStateA = {} } = accStateA
  const { accCat: accCatB, pairs: pairsB, pairStates: pairStatesB, marginState: marginStateB = {} } = accStateB
  const views = []
  for (let i = 0; i < lodash.max([pairsA.length, pairsB.length]); i ++) {
    const pA = pairsA[i]
    const pB = pairsB[i]
    const stateA = lodash.get(pairStatesA, pA, {})
    const { openQty: openQtyA } = stateA
    const stateB = lodash.get(pairStatesB, pB, {})
    const { openQty: openQtyB } = stateB
    const bid1A = lodash.get(orderbookA, [pA, 'bid1'], '-')
    const ask1A = lodash.get(orderbookA, [pA, 'ask1'], '-')
    const bid1B = lodash.get(orderbookB, [pB, 'bid1'], '-')
    const ask1B = lodash.get(orderbookB, [pB, 'ask1'], '-')
    views.push(
      <TableRow
        key={`row_${i}_pair_${pA}_${pB}`}
        hover
      >
        <SmallTableCell
          variant='head'
        >
          {pA === pB ? pA : `${pA}/${pB}`}
        </SmallTableCell>
        <SmallTableCell>
          {openQtyA}
        </SmallTableCell>
        <SmallTableCell>
          {openQtyB}
        </SmallTableCell>
        <SmallTableCell>
          <Typography>
            {`${bid1A}/${ask1A}`}
          </Typography>
          <Typography>
            {`${bid1B}/${ask1B}`}
          </Typography>
        </SmallTableCell>
        <FreeLockedLmtCells
          accCat={accCatA}
          accCode='Acc A'
          pair={pA}
          marginState={marginStateA}
          isTitle={false}
        />
        <FreeLockedLmtCells
          accCat={accCatB}
          accCode='Acc B'
          pair={pB}
          marginState={marginStateB}
          isTitle={false}
        />
      </TableRow>
    )
  }
  return (
    <Card className={clsx(classes.root)}
    >
      <PiCardContent className={classes.cardContent}>
        <XscrollTable>
          <TableHead>
            <TableRow
              hover
            >
              <SmallTableCell>
              </SmallTableCell>
              <SmallTableCell>
                Acc A Open
              </SmallTableCell>
              <SmallTableCell>
                Acc B Open
              </SmallTableCell>

              <SmallTableCell>
                Bid/Ask A&B
              </SmallTableCell>

              <FreeLockedLmtCells
                accCat={accCatA}
                accCode='Acc A'
                isTitle
              />
              <FreeLockedLmtCells
                accCat={accCatB}
                accCode='Acc B'
                isTitle
              />
            </TableRow>
          </TableHead>

          <TableBody>
            {views}
          </TableBody>
        </XscrollTable>
      </PiCardContent>
    </Card>
  )
}

LongShortPositionInfoView.propTypes = {
  accStateA: accStatePropTypes,
  accStateB: accStatePropTypes,
  orderbookA: longShortOrderbookPropTypes,
  orderbookB: longShortOrderbookPropTypes,
}

LongShortPositionInfoView.defaultProps= {
}

const mapStateToProps = (state, ownProps)=> {
  const accStateA = lodash.get(state, ['single', 'curPageData', 'accStateA'])
  const accStateB = lodash.get(state, ['single', 'curPageData', 'accStateB'])
  const orderbookA = lodash.get(state, ['single', 'curPageData', 'orderbookA'])
  const orderbookB = lodash.get(state, ['single', 'curPageData', 'orderbookB'])
  return {
    accStateA,
    accStateB,
    orderbookA,
    orderbookB
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return mapDispatch(dispatch)({...singleActions})
}

export default connect(mapStateToProps, mapDispatchToProps)(LongShortPositionInfoView)

