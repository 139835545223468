import React, { useState } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'
import NavBar from './NavBar'
import TopBar from './TopBar'
import ToastAlertView from '../ToastAlertView'
import PropTypes from 'prop-types'
import lodash from 'lodash'
import { mapDispatch } from '../../utils/utils'
import connect from 'react-redux/es/connect/connect'
import { PATH_LOGIN } from '../../constants'
import SplashView from '../../features/splash/SplashView'


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}))

/**
 * The layout when user has logged in
 */
const DashboardLayout = (props) => {
  const classes = useStyles()
  const [isMobileNavOpen, setMobileNavOpen] = useState(false)
  const { authToken, showedSplash } = props
  if(lodash.isEmpty(authToken)) {
    return (
      <Navigate to={PATH_LOGIN} />
    )
  }

  if (!showedSplash) {
    return (
      <div className={classes.root}>
        <TopBar onMobileNavOpen={() => setMobileNavOpen(true)}/>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <ToastAlertView/>
            <SplashView/>
          </div>
        </div>
      </div>
    )
  }

  /**
   * Check if we need to get profile
   */
  return (
    <div className={classes.root}>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)}/>
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <ToastAlertView/>
            <Outlet/>
          </div>
        </div>
      </div>
    </div>
  )
}

DashboardLayout.propTypes = {
  authToken: PropTypes.string,
  showedSplash: PropTypes.bool.isRequired,
}

const mapStateToProps = (state, ownProps)=> {
  const authToken = lodash.get(state, ['single', 'authToken'], '')
  const showedSplash = lodash.get(state, ['single', 'showedSplash'], false)
  return {
    authToken,
    showedSplash
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return mapDispatch(dispatch)({})
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardLayout)
