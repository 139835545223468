import React from 'react'
import PropTypes from 'prop-types'
import lodash from 'lodash'
import {
  Typography,
  TableBody,
  TableHead,
  TableRow,
  makeStyles,
} from '@material-ui/core'
import { mapDispatch } from '../../../../utils/utils'
import connect from 'react-redux/es/connect/connect'
import XscrollTable from '../../../ui/XscrollTable'
import PiTableCell from '../../../../components/PiTableCell'
import { COLOR_BG_GREY } from '../../../../constants'


const useStyles = makeStyles((theme) => ({
  root: {
  },
  tableCellSum :{
    'font-weight': 'bold',
    background: COLOR_BG_GREY,
  }
}))


const SimpleTableCell = ({children, isSum, ...rest}) => {
  const classes = useStyles()
  let className = undefined
  if (isSum) {
    className = classes.tableCellSum
  }
  return (
    <PiTableCell
      className={className}
      {...rest}
    >
      <Typography
        className={className}
      >
        {children}
      </Typography>
    </PiTableCell>
  )
}

const DexTakerBasicInfoDetailView = ({ basicInfo ={}, generalInfo }) => {

  const { avlbTokenASum = '-', avlbTokenBSum = '-', avlbTokenGasSum = '-', assetInfos = [] } = basicInfo

  const assetTokenA = lodash.get(generalInfo, ['tokenA', 'asset'], 'tokenA')
  const assetTokenB = lodash.get(generalInfo, ['tokenB', 'asset'], 'tokenB')
  const assetTokenGas = lodash.get(generalInfo, ['tokenGas', 'asset'], 'tokenGas')

  return (

    <XscrollTable>
      <TableHead>
        <TableRow>
          <SimpleTableCell>
            Account
          </SimpleTableCell>
          <SimpleTableCell>
            {`AvlbTokenA(${assetTokenA})`}
          </SimpleTableCell>
          <SimpleTableCell>
            {`AvlbTokenB(${assetTokenB})`}
          </SimpleTableCell>
          <SimpleTableCell>
            {`AvlbTokenGas(${assetTokenGas})`}
          </SimpleTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {assetInfos.map((assetRow, idx) => {
          const { accName, avlbTokenA, avlbTokenB, avlbTokenGas } = assetRow
          return (
            <TableRow
              hover
              key={accName}
            >

              <SimpleTableCell>
                {accName}
              </SimpleTableCell>
              <SimpleTableCell>
                {avlbTokenA}
              </SimpleTableCell>
              <SimpleTableCell>
                {avlbTokenB}
              </SimpleTableCell>
              <SimpleTableCell>
                {avlbTokenGas}
              </SimpleTableCell>
            </TableRow>
          )
        })}

        <TableRow
          hover
          key="sum"
        >
          <SimpleTableCell
            isSum
          >
            Sum
          </SimpleTableCell>
          <SimpleTableCell
            isSum
          >
            {avlbTokenASum}
          </SimpleTableCell>
          <SimpleTableCell
            isSum
          >
            {avlbTokenBSum}
          </SimpleTableCell>

          <SimpleTableCell
            isSum
          >
            {avlbTokenGasSum}
          </SimpleTableCell>
        </TableRow>
      </TableBody>
    </XscrollTable>
  )
}

DexTakerBasicInfoDetailView.propTypes = {
  basicInfo: PropTypes.shape({
    avlbTokenASum: PropTypes.string.isRequired,
    avlbTokenBSum: PropTypes.string.isRequired,
    avlbTokenGasSum: PropTypes.string.isRequired,
    assetInfos: PropTypes.array,
  }),
  generalInfo: PropTypes.object.isRequired,
}

DexTakerBasicInfoDetailView.defaultProps= {
}

const mapStateToProps = (state, ownProps)=> {
  const basicInfo = lodash.get(state, ['single', 'curPageData', 'basicInfo'])
  const generalInfo = lodash.get(state, ['single', 'curPageData', 'generalInfo'], {})
  return {
    basicInfo,
    generalInfo
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return mapDispatch(dispatch)({})
}

export default connect(mapStateToProps, mapDispatchToProps)(DexTakerBasicInfoDetailView)
