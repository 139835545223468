import { InputAdornment, InputLabel } from '@material-ui/core'
import React from 'react'

const PiInputAdornment = ({ label, position }) => {
  return (
    <InputAdornment position={position} disablePointerEvents>
      <InputLabel>
        {label}
      </InputLabel>
    </InputAdornment>
  )
}

export default PiInputAdornment
