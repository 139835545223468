import React, { Component } from 'react'
import {
  CircularProgress,
  withStyles,
  Button,
  Box
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import Page from '../../components/Page'
import { mapDispatch, genUuid, getReqInfo } from '../../utils/utils'
import { actions as socketActions } from '../socket/socketSlice'
import { actions as singleActions } from '../single/singleSlice'
import connect from 'react-redux/es/connect/connect'
import PropTypes from 'prop-types'
import lodash from 'lodash'
import logger from '../../utils/logger'
import { CMD_GET_PROFILE } from '../../protocolConstants'


const withStyle = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    display: "flex",
    "justify-content": "center",
    'align-items': 'center',
  },
  box: {
    "justify-content": "center",
    'align-items': 'center',
  },
  alert: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
  }
}))

/**
 * Redirect into this view for fetch one time essential data, e.g. user profile
 */
class SplashView extends Component {

  constructor (props){
    super(props)
    this.state = {
      rId: genUuid(),
      hasReqProfile: false
    }
  }

  /**
   *
   * @param initReqOnly: request only if it is initial request
   */
  reqProfile = (initReqOnly) => {
    if (initReqOnly) {
      const { socketStatus } = this.props
      const { hasReqProfile } = this.state
      if(hasReqProfile || socketStatus !== WebSocket.OPEN) {
        logger.debug(`Do not req now. InitOnly ${initReqOnly}, hasReq ${hasReqProfile}, socketStatus ${socketStatus}`)
        return
      }
      logger.debug(`Req now for initReq`)
    }
    const rId = genUuid()
    const { sendMessage } = this.props
    sendMessage({
      rId,
      'cmd': CMD_GET_PROFILE,
    })
    this.setState({
      rId,
      hasReqProfile: true
    })
  }

  componentDidMount() {
    this.reqProfile(true)
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    this.reqProfile(true)
    const { rId } = this.state
    const { reqInfoMap, setShowedSplash, updateProfile } = this.props
    const { isSuccessful, receivedData } = getReqInfo(reqInfoMap, rId)
    if(isSuccessful) {
      const profileData = lodash.get(receivedData, 'data')
      const { algorithms, projects, roles, username, exchanges, dexAssets } = profileData
      // sort 1) algorithm 2) project
      const sortedAlgorithms = lodash.sortBy(algorithms, (al) => {
        return al['name']
      })

      // sort exchange accounts in each project

      const newProjects = []
      lodash.forEach(projects, (proj) => {
        const { exchangeAccounts = [] } = proj
        const sortedExchangeAccounts = lodash.sortBy(exchangeAccounts, (ea) => {
          return ea['name']
        })
        newProjects.push({
          ...proj,
          exchangeAccounts: sortedExchangeAccounts
        })
      })

      const sortedProjects = lodash.sortBy(newProjects, (p) => {
        return p['name']
      })

      const sortedExchanges = lodash.sortBy(exchanges, (p) => {
        return p['name']
      })
      updateProfile({
        algorithms: sortedAlgorithms,
        projects: sortedProjects,
        roles,
        username,
        exchanges: sortedExchanges,
        dexAssets,
      })
      // after setShowedSplash, the DashboardLayout will skip the splash view and render the real content
      setShowedSplash()
    }
  }

  render() {
    const { classes, reqInfoMap } = this.props
    const { rId } = this.state
    const { isSending, isError, err } = getReqInfo(reqInfoMap, rId)


    if(isError) {
      const msg = `Request Error. code: ${lodash.get(err, 'code')}, message: ${lodash.get(err, 'message')}`
      return (
        <Page
          className={classes.root}
          title="PNYX Trading System"
        >
          <Box
            className={classes.root}
            display="flex"
            flexDirection="column"
            justifyContent="flex-end"
          >
            <Alert severity="error" className={classes.alert}>
              {msg}
            </Alert>
            <Button
              color="primary"
              disabled={isSending}
              size="large"
              variant="contained"
              onClick={() => this.reqProfile(false)}
            >
              Retry
            </Button>
          </Box>
        </Page>
      )
    }
    return (
      <Page
        className={classes.root}
        title="PNYX Trading System"
      >
        <CircularProgress color="inherit"/>
      </Page>
    )
  }
}


SplashView.propTypes = {
  reqInfoMap: PropTypes.object,
  updateAuthToken: PropTypes.func.isRequired,
  updateProfile: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
  socketStatus: PropTypes.number.isRequired,
  setShowedSplash: PropTypes.func.isRequired
}


const mapStateToProps = (state, ownProps)=> {
  const reqInfoMap = lodash.get(state, ['socket', 'reqInfoMap'], {})
  const socketStatus = lodash.get(state, ['socket', 'socketStatus'])
  return {
    reqInfoMap,
    socketStatus,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return mapDispatch(dispatch)({ ...socketActions, ...singleActions})
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyle(SplashView))

