import storage from './storage'
import { STORAGE_KEY_LOCAL_SETTINGS } from '../constants'
import lodash from 'lodash'

/**
 * The viewportScale should be from 1 to 100,
 */
const changeViewportScale = (viewportScale) => {
  let viewport = document.querySelector("meta[name=viewport]")
  if (viewport) {
    const content = viewport.getAttribute('content', '')
    const contentArr = content.split(',')
    for (let i = 0; i < contentArr.length; i ++) {
      const c = contentArr[i]
      if (c.includes('initial-scale')) {
        contentArr[i] = `initial-scale=${viewportScale / 100}`
      }
    }
    // seems only work on mobile
    viewport.setAttribute('content', contentArr.join(','))
  }
}

const getStorageLocalSettings = () => {
  const savedStorage = storage.getObj(STORAGE_KEY_LOCAL_SETTINGS, {})
  return lodash.merge({}, {
    viewportScale: 100 // it is a percentage
  }, savedStorage)
}


export {
  changeViewportScale,
  getStorageLocalSettings
}
