import React from 'react'
import clsx from 'clsx'
import lodash from 'lodash'
import PropTypes from 'prop-types'
import {
  Card,
  makeStyles,
  Box,
  Typography
} from '@material-ui/core'
import FormSelect from '../../../components/form/FormSelect'
import { Field } from 'formik'
import { optionArrayPropType } from '../../../utils/propTypesUtils'
import { isValidNumber } from '../../../utils/utils'
import PiCardContent from '../../../components/PiCardContent'
import logger from '../../../utils/logger'
import FormDexPath from '../../../components/form/FormDexPath'
import FormMultiSelect from '../../../components/form/FormMultiSelect'

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(3),
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  fieldAcc: {
    paddingBottom: theme.spacing(2),
  },
  accLabel: {
    paddingBottom: theme.spacing(1),
  },
  dexPathLabel: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  }
}))

const FormExchangeSelect = ({ accountIdsFieldName, pathFieldName, form, ...rest}) => {
  const { setFieldValue } = form
  const onExchangeSelect = (name, newValue) => {
    logger.debug('onExchangeSelect, clear account ids pairs field')
    setFieldValue(accountIdsFieldName, [])
    setFieldValue(pathFieldName, [{},{}])
  }
  return (
    <FormSelect
      postChange={onExchangeSelect}
      form={form}
      {...rest}
    />
  )
}

const DexAccountFields = ({ className, exchangeIdFieldName, accountIdsFieldName, setFieldValue, pathFieldName, accountLabel, exchangeIdOptions, accountIdOptions, feeOptions, assOptions, fieldNamePrefix, values, ...rest }) => {
  const classes = useStyles()
  const validateAccountName = (val) => {
    let err
    if (!lodash.isArray(val) || val.length <= 0) {
      err = 'Please select an exchange'
    }
    return err
  }

  const validateExchange = (val) => {
    let err
    if (!isValidNumber(val) || val <= 0) {
      err = 'Please select an exchange'
    }
    return err
  }
  return (
    <Box mt={3} className={clsx(classes.root, className)} {...rest}>
      <Card>
        <PiCardContent disablePiStyle>
          {
            lodash.isEmpty(accountLabel)
            ? null :
            <Typography
              className={classes.accLabel}
              color="textPrimary"
              variant="h5"
            >
              {accountLabel}
            </Typography>
          }

          <Field
            className={classes.fieldAcc}
            validate={validateExchange}
            name={exchangeIdFieldName}
            label="Exchange"
            accountIdsFieldName={accountIdsFieldName}
            pathFieldName={pathFieldName}
            options={exchangeIdOptions}
            component={FormExchangeSelect}
          />

          <Field
            className={classes.fieldAcc}
            validate={validateAccountName}
            name={accountIdsFieldName}
            label="Account Name"
            options={accountIdOptions}
            component={FormMultiSelect}
          />
          <Typography
            className={classes.dexPathLabel}
            color="textPrimary"
            variant="h6"
          >
            Dex Path
          </Typography>
          <FormDexPath
            values={values}
            feeOptions={feeOptions}
            assOptions={assOptions}
            pathFieldName={pathFieldName}
            setFieldValue={setFieldValue}
          />
        </PiCardContent>
      </Card>
    </Box>
  )
}

DexAccountFields.propTypes = {
  className: PropTypes.string,
  accountIdsFieldName: PropTypes.string.isRequired,
  pathFieldName: PropTypes.string.isRequired,
  accountLabel: PropTypes.string.isRequired,
  accounts: PropTypes.arrayOf(PropTypes.shape({
    cat: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    name: PropTypes.number.isRequired
  })),
  values: PropTypes.object.isRequired,
  accountIdOptions: optionArrayPropType,
  exchangeIdOptions: optionArrayPropType,
  feeOptions: optionArrayPropType,
  assOptions: optionArrayPropType,
  setFieldValue: PropTypes.func.isRequired,
}

DexAccountFields.defaultProps = {
  accountLabel: '',
  exchangeIdOptions: [],
  accountIdOptions: [],
}

export default DexAccountFields
