import { SOCKET_RECONNECT_MIN_SEC, SOCKET_RECONNECT_MAX_SEC, SOCKET_RECONNECT_DELTA_SEC } from '../../constants'


class SocketReconnectDelay {

  constructor () {
    this.reconnectDelaySec = SOCKET_RECONNECT_MIN_SEC
  }

  getReconnectDelaySec = () => {
    return this.reconnectDelaySec
  }

  connectFailure = () => {
    this.reconnectDelaySec = this.reconnectDelaySec + SOCKET_RECONNECT_DELTA_SEC
    if(this.reconnectDelaySec > SOCKET_RECONNECT_MAX_SEC) {
      this.reconnectDelaySec = SOCKET_RECONNECT_MAX_SEC
    }
  }

  connectSuccess = () => {
    this.reconnectDelaySec = SOCKET_RECONNECT_MIN_SEC
  }
}

export default SocketReconnectDelay
