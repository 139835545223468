import React, { useCallback } from 'react'
import {
  IconButton,
  Grid
} from '@material-ui/core'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'
import PropTypes from 'prop-types'
import lodash from 'lodash'
import { Field} from 'formik'
import FormSelect from './FormSelect'
import { useRunOnce } from '../../utils/hooks'
import logger from '../../utils/logger'
import FormInputSelect from './FormInputSelect'


const AddrGridItem = ({ children, isLeft }) => {
  let gridWeight
  if (isLeft) {
    gridWeight = 9
  } else {
    gridWeight = 3
  }
  return (
    <Grid
      item
      xl={gridWeight}
      lg={gridWeight}
      sm={gridWeight}
      xs={gridWeight}
    >
      { children }
    </Grid>
  )
}


/**
 *
 * Select pair for dex
 * value is a list:  [{ addr, feeMillionth }]
 */
const validateAsset = (val) => {
  let err
  if (lodash.isEmpty(val) || !lodash.isString(val)) {
    err = 'Please input the asset address'
  }
  return err
}

const validateFee = (val) => {
  let err
  if (!lodash.isNumber(val)) {
    err = 'Please select the fee'
  }
  return err
}

const createEmptyPath = () => {
  return {}
}

const FormDexPath = ({ pathFieldName, assOptions, feeOptions, setFieldValue, values, disabled, ...rest}) => {
  const assValueLabelMap = {}
  assOptions.forEach((opt) => {
    const { value, label } = opt
    assValueLabelMap[value] = label
  })

  const feeValueLabelMap = {}
  feeOptions.forEach((opt) => {
    const { value, label } = opt
    feeValueLabelMap[value] = label
  })
  const pathValues = lodash.get(values, pathFieldName, [])
  const onAddPath = useCallback(() => {
    const pathValuesClone = lodash.clone(pathValues)
    pathValuesClone.push(createEmptyPath())
    setFieldValue(pathFieldName, pathValuesClone)
  }, [pathValues, setFieldValue, pathFieldName])

  const onRemovePath = useCallback(() => {
    // at least need 2 items
    if (pathValues.length > 2) {
      const pathValuesClone = lodash.clone(pathValues)
      pathValuesClone.pop()
      setFieldValue(pathFieldName, pathValuesClone)
    }
  }, [pathValues, setFieldValue, pathFieldName])

  useRunOnce(() => {
    if (pathValues.length === 0) {
      // set default value
      logger.debug(`setFieldValue ${pathFieldName}`)
      setFieldValue(pathFieldName, [createEmptyPath(),createEmptyPath()])
    }
  })

  const pathValueViews = []
  pathValues.forEach((pv, idx) => {
    const isLast = idx === pathValues.length - 1

    pathValueViews.push(
      <AddrGridItem
        key={`addr_${idx}`}
        isLeft
      >
        <Field
          validate={validateAsset}
          name={`${pathFieldName}.${idx}.addr`}
          label="Asset"
          options={assOptions}
          component={FormInputSelect}
        />
      </AddrGridItem>
    )

    if (isLast) {
      pathValueViews.push(
        <AddrGridItem
          key='addRemoveButtons'
          isLeft={false}
        >
          <IconButton onClick={onAddPath}>
            <AddCircleIcon color="primary"/>
          </IconButton>
          <IconButton onClick={onRemovePath}>
            <RemoveCircleIcon color="secondary"/>
          </IconButton>
        </AddrGridItem>
      )
    } else {
      pathValueViews.push(
        <AddrGridItem
          key={`fee${idx}`}
          isLeft={false}
        >
          <Field
            validate={validateFee}
            name={`${pathFieldName}.${idx}.feeMillionth`}
            label="Fee"
            options={feeOptions}
            component={FormSelect}
          />
        </AddrGridItem>
      )
    }
  })
  return (
      <Grid container spacing={3}>
        {pathValueViews}
      </Grid>
  )
}

FormDexPath.propTypes = {
  feeOptions: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
    label: PropTypes.string.isRequired
  })),
  assOptions: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
    label: PropTypes.string.isRequired,
  })),
  pathFieldName: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
}

FormDexPath.defaultProps = {
}

export default FormDexPath
