import React, { useCallback } from 'react'
import lodash from 'lodash'
import { Field } from 'formik'
import FormSelect from '../../../../../components/form/FormSelect'
import PiInputAdornment from '../../../../../components/PiInputAdornment'
import FormInput from '../../../../../components/form/FormInput'
import { IconButton } from '@material-ui/core'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'
import DelevTargetitem from "./DelevTargetitem"
import { longShortAccCfgPropTypes } from '../../../../../utils/propTypesUtils'
import {createEmptyLongShortDeleverageTarget} from '../longShortUtils'

const AddRemoveButton = ({ gridSize, disabled, hideAdd, hideRemove, onAdd, onRemove }) => {
  return (
    <DelevTargetitem gridSize={gridSize}>
      {hideRemove ? null : <IconButton onClick={onRemove} disabled={disabled}>
        <RemoveCircleIcon color="secondary"/>
      </IconButton>}
      {hideAdd ? null : <IconButton onClick={onAdd} disabled={disabled}>
        <AddCircleIcon color="primary"/>
      </IconButton>}
    </DelevTargetitem>
  )
}

const LongShortDeleverageTargetViews = ({values, accCfgA, accCfgB, setFieldValue, disabledAllFields}) => {
  const longShortDeleverageTargetsView = []
  const { longShortEnabled, longShortAutoDeleverageEnabled } = values
  const { accName: accNameA } = accCfgA
  const { accName: accNameB } = accCfgB

  const allAccNames = [accNameA]
  if (accNameA !== accNameB) {
    allAccNames.push(accNameB)
  }
  const accNameOptions = lodash.map(allAccNames, (p) => {
    return {
      value: p,
      label: p
    }
  })

  const longShortDeleverageTargetsFieldName = 'longShortAutoDeleverageTargets'
  let longShortDeleverageTargetsValues = lodash.get(values, longShortDeleverageTargetsFieldName, [])
  if (!longShortDeleverageTargetsValues) {
    longShortDeleverageTargetsValues = []
  }

  const onAddPath = () => {
    if (longShortDeleverageTargetsValues.length >= accNameOptions.length) {
      return
    }
    const targetsClone = lodash.clone(longShortDeleverageTargetsValues)
    targetsClone.push(createEmptyLongShortDeleverageTarget())
    setFieldValue(longShortDeleverageTargetsFieldName, targetsClone)
  }

  const onRemovePathAtIdx = useCallback((idx) => () => {
    // at least need 2 items to remove
    if (longShortDeleverageTargetsValues.length <= 1) {
      return
    }
    if (longShortDeleverageTargetsValues.length <= idx) {
      return
    }
    const pathValuesClone = []
    for (let i =0; i < longShortDeleverageTargetsValues.length; i ++) {
      if (i === idx) {
        continue
      }
      pathValuesClone.push(longShortDeleverageTargetsValues[i])
    }
    setFieldValue(longShortDeleverageTargetsFieldName, pathValuesClone)
  }, [longShortDeleverageTargetsFieldName, longShortDeleverageTargetsValues, setFieldValue])

  const accGridSize = 4
  const startDelevGridSize = 3
  const endDelevGridSize = 3
  const buttonGridSize = 2

  const autoDelevEnabled = longShortEnabled && longShortAutoDeleverageEnabled
  lodash.forEach(longShortDeleverageTargetsValues, (target, idx) => {
    const isLast = idx === longShortDeleverageTargetsValues.length - 1
    longShortDeleverageTargetsView.push(
      <DelevTargetitem gridSize={accGridSize} key={`targetitem_${idx}_acc_name`}>
        <Field
          disabled={!autoDelevEnabled}
          name={`${longShortDeleverageTargetsFieldName}.${idx}.delevAccName`}
          placeholder="AccName"
          options={accNameOptions}
          component={FormSelect}
        />
      </DelevTargetitem>
    )

    longShortDeleverageTargetsView.push(
      <DelevTargetitem gridSize={startDelevGridSize} key={`targetitem_${idx}_start_delev`}>
        <Field
          name={`${longShortDeleverageTargetsFieldName}.${idx}.startDelevLeverage`}
          label="Start Lev"
          disabled={!autoDelevEnabled}
          InputProps={{
            endAdornment: <PiInputAdornment position="end" label={'X'}/>,
          }}
          inputProps={{
            type: 'number',
            style: {
              textAlign: 'right'
            }
          }}
          component={FormInput}
        />
      </DelevTargetitem>
    )

    longShortDeleverageTargetsView.push(
      <DelevTargetitem gridSize={endDelevGridSize} key={`targetitem_${idx}_end_delev`}>
        <Field
          name={`${longShortDeleverageTargetsFieldName}.${idx}.endDelevLeverage`}
          label="End Lev"
          disabled={!autoDelevEnabled}
          InputProps={{
            endAdornment: <PiInputAdornment position="end" label={'X'}/>,
          }}
          inputProps={{
            type: 'number',
            style: {
              textAlign: 'right'
            }
          }}
          component={FormInput}
        />
      </DelevTargetitem>
    )

    const hideAdd = !isLast || longShortDeleverageTargetsValues.length >= accNameOptions.length
    longShortDeleverageTargetsView.push(
      <AddRemoveButton
        gridSize={buttonGridSize}
        key={`addRemoveButtons_${idx}`}
        disabled={!autoDelevEnabled}
        onAdd={onAddPath}
        hideAdd={hideAdd}
        onRemove={onRemovePathAtIdx(idx)}
      />
    )
  })

  if (longShortDeleverageTargetsValues.length === 0){
    longShortDeleverageTargetsView.push(
      <AddRemoveButton
        gridSize={buttonGridSize}
        key='addRemoveButtonsExtra'
        disabled={!autoDelevEnabled}
        onAdd={onAddPath}
        hideRemove={true}
      />
    )
  }
  return longShortDeleverageTargetsView
}

LongShortDeleverageTargetViews.propTypes = {
  accCfgA: longShortAccCfgPropTypes,
  accCfgB: longShortAccCfgPropTypes
}

export default LongShortDeleverageTargetViews
