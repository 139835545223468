import React, { useState, useEffect } from 'react'
import * as Yup from 'yup'
import { Formik, Field } from 'formik'
import {
  Box,
  Button,
  Container,
  Typography,
  makeStyles
} from '@material-ui/core'
import Page from '../../components/Page'
import { mapDispatch, genUuid } from '../../utils/utils'
import { actions as socketActions } from '../socket/socketSlice'
import { actions as singleActions } from '../single/singleSlice'
import connect from 'react-redux/es/connect/connect'
import PropTypes from 'prop-types'
import lodash from 'lodash'
import logger from '../../utils/logger'
import { REQ_STATUS_PENDING, REQ_STATUS_SUCCESSFUL } from '../../constants'
import FormInput from '../../components/form/FormInput'
import { getLoginInitValues } from '../../config'
import { CMD_LOGIN } from '../../protocolConstants'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(15),
    paddingTop: theme.spacing(3)
  }
}))

const LoginView = (props) => {
  const classes = useStyles()
  const [rId, setRId] = useState(genUuid())
  const { sendMessage, reqInfoMap, updateAuthToken, showToast } = props
  const reqInfo = lodash.get(reqInfoMap, rId)

  const onSignIn = (values, { setSubmitting }) => {
    const newRid = genUuid()
    setRId(newRid)
    sendMessage({
      rId: newRid,
      'cmd': CMD_LOGIN,
      'data': values
    })
  }

  const rStatus = lodash.get(reqInfo, 'rStatus')
  const isSending = rStatus === REQ_STATUS_PENDING
  const err = lodash.get(reqInfo, 'err')

  useEffect(() => {
    if(rStatus === REQ_STATUS_SUCCESSFUL && err === undefined) {
      const receivedData = lodash.get(reqInfo, 'receivedData')
      logger.debug('the data is', receivedData)
      const authToken = lodash.get(receivedData, ['data', 'authToken'])
      if(authToken !== undefined) {
        // after update the auth token the base layout will help use to redirect to logged in page
        updateAuthToken({
          authToken
        })
      } else {
        showToast('Server returns a empty auth token', 'error')
      }
    }
  })

  return (
    <Page
      className={classes.root}
      title="Login"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={getLoginInitValues()}
            validationSchema={Yup.object().shape({
              username: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
              password: Yup.string().max(255).required('Password is required'),
              otp: Yup.number().max(999999, 'Max 6 digits')
            })}
            onSubmit={onSignIn}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form
                autoComplete="off"
                onSubmit={handleSubmit}
              >
                <Box mb={3}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    Login
                  </Typography>
                </Box>

                <Field
                  name="username"
                  label="Username"
                  margin="normal"
                  variant="outlined"
                  inputProps = {{
                    type: "email"
                  }}
                  component={FormInput}
                />


                <Field
                  name="password"
                  label="Password"
                  margin="normal"
                  variant="outlined"
                  inputProps = {{
                    type: "password"
                  }}
                  component={FormInput}
                />

                <Field
                  name="otp"
                  label="OTP"
                  margin="normal"
                  variant="outlined"
                  isInteger
                  inputProps = {{
                    type: "number",
                  }}
                  component={FormInput}
                />
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSending}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Login now
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  )
}


LoginView.propTypes = {
  reqInfoMap: PropTypes.object,
  updateAuthToken: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired
}


const mapStateToProps = (state, ownProps)=> {
  const reqInfoMap = lodash.get(state, ['socket', 'reqInfoMap'], {})
  return {
    reqInfoMap
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return mapDispatch(dispatch)({ ...socketActions, ...singleActions})
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginView)

