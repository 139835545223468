import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import PropTypes from 'prop-types'

const Page = (props) => {
  const { children, title, ...rest } = props
  return (
    <div
      {...rest}
    >
      <HelmetProvider
      >
        <Helmet>
          <title>{title}</title>
        </Helmet>
        {children}
      </HelmetProvider>
    </div>
  )
}

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string
}

export default Page
