import React from 'react'
import { Navigate } from 'react-router-dom'
import DashboardLayout from './layouts/DashboardLayout'
import MainLayout from './layouts/MainLayout'
import LoginView from './features/login/LoginView'
import SessionListView from './features/session/sessionListView/index'
import AccountListView from "./features/account/accountListView/index"
import SessionView from './features/session/sessionView/index'
import SettingsView from './features/settings/index'
import NotFoundView from './views/errors/NotFoundView'
import RegisterView from './views/auth/RegisterView'

import {
  PATH_404,
  PATH_SESSIONS,
  PATH_DEBUGZONE,
  PATH_SESSION_WITH_ID,
  PATH_SETTINGS,
  PATH_LOGIN,
  PATH_ACCOUNTS
} from './constants'
import DebugzoneView from './features/debugzone/index'
import { isDev } from './config'

const dashboardLayoutChildren = [
  {path: PATH_SESSIONS, element: <SessionListView/>},
  {path: PATH_SESSION_WITH_ID, element: <SessionView/>},
  {path: PATH_ACCOUNTS, element: <AccountListView/>},
  {path: PATH_SETTINGS, element: <SettingsView/>},
  {path: '*', element: <Navigate to={PATH_404}/>},
  {path: '/', element: <Navigate to={PATH_SESSIONS}/>},
  {path: PATH_404, element: <NotFoundView/>} // 404 is for not found
]

if (isDev()) {
  dashboardLayoutChildren.push({
    path: PATH_DEBUGZONE,
    element: <DebugzoneView/>
  })
}

const routes = [
  {
    path: '/',
    element: <DashboardLayout/>,
    children: dashboardLayoutChildren
  },
  {
    path: '/',
    element: <MainLayout/>,
    children: [
      {path: PATH_LOGIN, element: <LoginView/>},
      {path: 'register', element: <RegisterView/>},
      {path: '*', element: <Navigate to="/login"/>}
    ]
  }
]

export default routes
