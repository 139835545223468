import { SIDE_BUY} from '../../../../../protocolConstants'
import lodash from 'lodash'
import { isValidNumber, roundDown } from '../../../../../utils/utils'
import Decimal from 'decimal.js'
import { SIDE_SELL } from '../../../../../protocolConstants'
import logger from '../../../../../utils/logger'

/**
 *
 * @returns {{}} Return the change fields with its value in a dictionary
 */
const pctPxConv = (isPctToPx, refPx, buySell, values) => {
  if (refPx === 0) {
    if (!isPctToPx) {
      logger.debug(`refPx is ${refPx}, isPctToPx ${isPctToPx}. Hence do not convert`)
      return {}
    }
  }
  let isBuy = false
  if (buySell === SIDE_BUY) {
    isBuy = true
  } else if (buySell === SIDE_SELL) {
    isBuy = false
  } else {
    return {}
  }

  const cvtInfos = [{
    pctKey: 'bandStartPct',
    pxKey: 'bandStartPx',
    isDelta: false,
  }, {
    pctKey: 'bandEndPct',
    pxKey: 'bandEndPx',
    isDelta: false
  }, {
    pctKey: 'reservePct',
    pxKey: 'reservePx',
    isDelta: false
  }, {
    pctKey: 'tpPctDelta',
    pxKey: 'tpPxDelta',
    isDelta: true
  }]

  const changedValues = {}

  let cvtFn
  if (isPctToPx) {
    // pct => px
    cvtFn = (cvtInfo) => {
      const { pctKey, pxKey, isDelta } = cvtInfo
      let pct = lodash.get(values, pctKey)
      if (pct === '') {
        pct = 0 // if it is empty, set it to be 0
      } else {
        pct = parseFloat(pct)
        if (!isValidNumber(pct)) {
          return
        }
      }
      let px
      if (isDelta) {
        px = refPx * pct / 100
      } else {
        if (isBuy) {
          px = refPx * (1 - pct / 100)
        } else {
          px = refPx * (1 + pct / 100)
        }
      }
      changedValues[pxKey] = px
    }
  } else {
    // px => pct
    cvtFn = (cvtInfo) => {
      const { pctKey, pxKey, isDelta } = cvtInfo
      let px = lodash.get(values, pxKey)
      if (px === '') {
        px = 0
      } else {
        px = parseFloat(px)
        if (!isValidNumber(px)) {
          return
        }
      }
      let pct
      if (isDelta) {
        pct = (px / refPx) * 100
      } else {
        if (isBuy) {
          pct = (1 - px / refPx) * 100
        } else {
          pct = (px / refPx - 1) * 100
        }
      }
      changedValues[pctKey] = pct
    }
  }
  lodash.forEach((cvtInfos), cvtFn)
  return changedValues
}

/**
 *
 * @returns {{}} new updated form values
 */
const setPctPxConvFieldsByValues = (isPctToPx, curValues, setFieldValue) => {
  let { refPx, buySell } = curValues
  refPx = parseFloat(refPx)
  if (!isValidNumber(refPx)) {
    refPx = 0
  }
  const changedValues = pctPxConv(isPctToPx, refPx, buySell, curValues)
  lodash.forEach(changedValues, (val, key)=> {
    setFieldValue(key, val)
  })

  return {
    ...curValues,
    ...changedValues
  }
}

const setTotalQtyFieldByValues = (isPctToPx, avlbBase, avlbQuote, curValues, setFieldValue) => {
  let { useQuote, totalQty, totalQtyPct, bandStartPx, bandEndPx, buySell } = curValues

  let isBuy
  if (buySell === SIDE_BUY) {
    isBuy = true
  } else if(buySell === SIDE_SELL) {
    isBuy = false
  } else {
    return
  }

  avlbQuote = parseFloat(avlbQuote)
  avlbBase = parseFloat(avlbBase)
  bandStartPx = parseFloat(bandStartPx)
  bandEndPx = parseFloat(bandEndPx)

  const getPx = () => {
    if (isValidNumber(bandStartPx) && isValidNumber(bandEndPx)) {
      return (bandStartPx + bandEndPx) / 2
    } else {
      return undefined
    }
  }


  if (!isValidNumber(avlbQuote) || !isValidNumber(avlbBase)) {
    logger.debug(`Qty is invalid num avlbQuote: ${avlbQuote}, avlbBase: ${avlbBase}`)
    return
  }

  let totalAvlb
  if (isBuy) {
    // use quote
    if (useQuote) {
      totalAvlb = avlbQuote
    } else {
      // calculate the px
      const px = getPx()
      if (!isValidNumber(px)) {
        logger.debug(`Px is invalid mid px: ${px}`)
        return
      }
      totalAvlb = avlbQuote / px
    }
  } else {
    // sell the base
    if (useQuote) {
      const px = getPx()
      if (!isValidNumber(px)) {
        logger.debug(`Px is invalid mid px: ${px}`)
        return
      }
      totalAvlb = avlbBase * px
    } else {
      totalAvlb = avlbBase
    }
  }

  if (isPctToPx) {
    totalQtyPct = parseInt(totalQtyPct)
    if (!isValidNumber(totalQtyPct)) {
      logger.debug(`Qty is invalid totalQtyPct: ${totalQtyPct},`)
      return
    }
    totalQty = totalQtyPct * totalAvlb / 100
    logger.debug(`setField totalQty ${totalQty}`)
    setFieldValue('totalQty', totalQty)
  } else {
    totalQty = parseFloat(totalQty)
    totalQtyPct = parseInt(totalQty / totalAvlb * 100)
    logger.debug(`setField totalQtyPct ${totalQtyPct}`)
    setFieldValue('totalQtyPct', totalQtyPct)
  }
}

const calTotalBaseQuote = (totalQty, bandStartPx, bandEndPx, useQuote, pctVisiblePerLayer, qtyPrec) => {
  const avgPx = new Decimal(bandStartPx).add(bandEndPx).div(2)

  let totalBase
  let totalQuote
  if (useQuote) {
    totalQuote = new Decimal(totalQty)
    totalBase = totalQuote.div(new Decimal(avgPx))
  } else {
    totalBase = new Decimal(totalQty)
    totalQuote = totalBase.times(new Decimal(avgPx))
  }

  const totalVisibleBase  = roundDown(totalBase.times(pctVisiblePerLayer).div(100), qtyPrec)
  const totalVisibleQuote = roundDown(totalQuote.times(pctVisiblePerLayer).div(100), qtyPrec)

  const totalHiddenBase = roundDown(totalBase.times(100 - pctVisiblePerLayer).div(100), qtyPrec)
  const totalHiddenQuote = roundDown(totalQuote.times(100 - pctVisiblePerLayer).div(100), qtyPrec)

  return {
    avgPx,
    totalBase,
    totalQuote,
    totalHiddenBase,
    totalHiddenQuote,
    totalVisibleBase,
    totalVisibleQuote,
  }
}

export {
  pctPxConv,
  calTotalBaseQuote,
  setPctPxConvFieldsByValues,
  setTotalQtyFieldByValues
}
