import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)

const FORMAT_YMD = 'YYYY-MM-DD'
const FORMAT_MDHMS_SHORT = 'MM-DD HH:mm:ss'
const FORMAT_YMDHMS_SHORT = 'YY-MM-DD HH:mm:ss'
const FORMAT_YMDHMS_NORMAL = 'YYYY-MM-DD HH:mm:ss'


const dayjsNow = () => {
  return dayjs()
}

const fromMs = (ms) => {
  return dayjs(ms)
}

const display = (dj) => {
  return dj.format('YYYY-MM-DD HH:mm:ss')
}

const displayShort = (dj) => {
  return dj.format('YY-MM-DD HH:mm')
}

const dayjsToMs = (dj) => {
  return dj.valueOf()
}

const dayjsStartOfDay = (dj) => {
  return dj.startOf('day')
}

const dayjsEndOfDay = (dj) => {
  return dj.endOf('day')
}

export {
  fromMs,
  display,
  displayShort,

  dayjsNow,

  dayjsToMs,
  dayjsStartOfDay,
  dayjsEndOfDay,
  FORMAT_YMD,
  FORMAT_MDHMS_SHORT,
  FORMAT_YMDHMS_SHORT,
  FORMAT_YMDHMS_NORMAL
}
