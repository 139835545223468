import React, { useCallback, useEffect } from 'react'
import * as Yup from 'yup'
import { Formik } from 'formik'
import {
  Box,
  makeStyles,
} from '@material-ui/core'
import { mapDispatch } from '../../../../utils/utils'
import { actions as socketActions } from '../../../socket/socketSlice'
import { actions as singleActions, showToast } from '../../../single/singleSlice'
import connect from 'react-redux/es/connect/connect'
import PropTypes from 'prop-types'
import lodash from 'lodash'
import SpoofSniperAlgoFormInner from './SpoofSniperAlgoFormInner'
import {
  CMD_TOKEN_BUY_SELL_EXECUTE,
  CMD_TOKEN_BUY_SELL_STOP,
  CMD_SPOOF_SNIPER_PREVIEW,
} from '../../../../protocolConstants'
import { useRefFields, useRefFieldsPrevious, useStateReq } from '../../../../utils/hooks'
import {
  pairInfoPropTypes,
  paramSpoofSniperPropType,
  spoofSniperDialogPropType
} from '../../../../utils/propTypesUtils'
import TakerAlgoFormDialog from './SpoofSniperAlgoFormDialog'
import { PiFormContextProvider } from '../../../../utils/piFormContext'

const useStyles = makeStyles((theme) => ({
  formBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  }
}))


const SpoofSniperAlgoForm = ({ sessionId, pairInfo, dialogData, setCurPageData, paramSpoofSniper, spoofSniperAlgoFormUpdatingData }) => {
  const classes = useStyles()
  const {
    isSending,
    isSuccessful,
    sendReq,
    sentData,
    receivedData,
    setStateField,
    isError,
  } = useStateReq()

  const showPreviewSpoofSniperDialog = useCallback((previewDataInput, previewSentDataInput) => {
    const dialogOpen = true
    const dialogType = 'executeSpoofSniperAlgo'
    setCurPageData({
      dialogData: {
        ...dialogData,
        dialogOpen,
        dialogType,
        previewData: previewDataInput,
        previewSentData: previewSentDataInput
      }
    })
  }, [setCurPageData, dialogData])

  const onPreviewTokenBuySell = (values, { setErrors }) => {
    setStateField('valueErrors', undefined)
    // set the function into the ref
    setRefField('setErrors', setErrors)
    sendReq({
      isRelay: true,
      cmd: CMD_SPOOF_SNIPER_PREVIEW,
      data: {
        sessionId,
        params: values
      }
    })
  }

  const [setRefField, getRefField] = useRefFields()
  const prevIsSending  = useRefFieldsPrevious('isSending', isSending)

  const onStopTokenBuySell = () => {
    const dialogOpen = true
    const dialogType = 'stopSpoofSniperAlgo'
    setCurPageData({
      dialogData: {
        ...dialogData,
        dialogOpen,
        dialogType
      }
    })
  }
  useEffect(() => {
    if (prevIsSending && !isSending) {
      const cmd = lodash.get(sentData, 'cmd')
      if(cmd === CMD_SPOOF_SNIPER_PREVIEW) {
        if (isSuccessful) {
          const previewData = lodash.get(receivedData, 'data')
          showPreviewSpoofSniperDialog(previewData, sentData)
        } else if (isError) {
          const valueErrors = lodash.get(receivedData, ['data', 'valueErrors'])
          const formErrors = lodash.get(receivedData, ['data', 'formErrors'])
          setStateField('valueErrors', valueErrors)
          const setErrors = getRefField('setErrors')
          if (!lodash.isEmpty(formErrors) && lodash.isFunction(setErrors)) {
            setErrors(formErrors)
          }
        }
      } else if (cmd === CMD_TOKEN_BUY_SELL_STOP) {
        if (isSuccessful) {
          showToast("Token Buy Sell stopped successfully", 'success')
          const active = false
          setCurPageData({
            // params from props, hence immutable
            paramSpoofSniper: {
              ...paramSpoofSniper,
              active
            }
          })
        }
      } else if (cmd === CMD_TOKEN_BUY_SELL_EXECUTE) {
        if (isSuccessful) {
          showToast("Token Buy Sell execution successfully", 'success')
          const active = true
          setCurPageData({
            paramSpoofSniper: {
              ...paramSpoofSniper,
              active
            }
          })
        }
      }
    }
  }, [prevIsSending, paramSpoofSniper, setCurPageData, getRefField, isSending, isSuccessful, isError, receivedData, sentData, setStateField, showPreviewSpoofSniperDialog])

  if (!pairInfo) {
    return null
  }

  if (!paramSpoofSniper) {
    return null
  }
  const { active } = paramSpoofSniper
  return (
    <Box className={classes.formBox}>
      <Formik
        initialValues={{
          ...paramSpoofSniper
        }}
        validationSchema={props =>  Yup.lazy(values => {

          const { spoofEnabled = false, sniperCounterOrderEnabled = false } = values
          let schema = {
            itvlMs: Yup.number().required('Interval is required').moreThan(0, 'Must be positive'),
            spoofEnabled: Yup.bool(),
            sniperBuySell: Yup.string().required('Buy and sell is required'),
            sniperMinOrderQty: Yup.number().required('Sniper Min Order Qty is required').moreThan(0, 'Must be positive'),
            sniperMaxOrderQty: Yup.number().required('Sniper Max Order Qty is required').moreThan(0, 'Must be positive'),
            sniperRemainingQty: Yup.number().required('Sniper Remaining Qty is required'),
            sniperLmtPx: Yup.number().required('Sniper Lmt Px is required').moreThan(0, 'Must be positive'),
            sniperPxMaxGapBasePoint: Yup.number().required('Sniper Px Max Gap is required').moreThan(0, 'Must be positive'),
            sniperPctBasePoint: Yup.number().required('Sniper Pct is required').moreThan(0, 'Must be positive'),
          }
          if (spoofEnabled) {
            schema = {
              ...schema,
              spoofOrderQty: Yup.number().required('Spoof Order Qty required').moreThan(0, "Must be positive"),
              spoofLayerNum: Yup.number().required('Spoof Layer Num is required').moreThan(0, 'Must be positive'),
              spoofLayerGapBasePoint: Yup.number().required('Spoof Layer Gap is required').moreThan(0, 'Must be positive'),
              spoofMinSpread: Yup.number().required('spoofMinSpread is required'),
              spoofRemainingQty: Yup.number().required('Spoof Remaining Qty is required'),
              spoofLmtPx: Yup.number().required('Spoof Lmt Px is required').moreThan(0, 'Must be positive'),
              spoofOutrangeSniper: Yup.bool(),
              spoofAfterSniperOnly: Yup.bool(),
              spoofAfterSniperItvlMs: Yup.number().required('Spoof After Sniper Itvl is required'),
            }
            if (sniperCounterOrderEnabled) {
              schema['sniperCounterOrderQty'] = Yup.number().required('Sniper Counter Order Qty is required').moreThan(0, 'Must be positive')
            }
          }
          return Yup.object().shape(schema)
        })}
        onSubmit={onPreviewTokenBuySell}
      >

        {(formProps) => {
          return (
            <PiFormContextProvider
              disabledAllFields={active}
            >
              <SpoofSniperAlgoFormInner
                formProps={formProps}
                active={active}
                pairInfo={pairInfo}
                onStopTaker={onStopTokenBuySell}
                setCurPageData={setCurPageData}
                spoofSniperAlgoFormUpdatingData={spoofSniperAlgoFormUpdatingData}
              />
            </PiFormContextProvider>
          )
        }}
      </Formik>

      <TakerAlgoFormDialog
        sessionId={sessionId}
        sendReq={sendReq}
      />
    </Box>
  )
}


SpoofSniperAlgoForm.propTypes = {
  sessionId: PropTypes.number.isRequired,
  dialogData: spoofSniperDialogPropType,
  paramSpoofSniper: paramSpoofSniperPropType, // it is server's status
  pairInfo: pairInfoPropTypes,
  spoofSniperAlgoFormUpdatingData: PropTypes.object,
}

SpoofSniperAlgoForm.defaultProps = {
  algorithms: []
}

const mapStateToProps = (state, ownProps)=> {
  const dialogData = lodash.get(state, ['single', 'curPageData', 'dialogData'])
  const paramSpoofSniper = lodash.get(state, ['single', 'curPageData', 'paramSpoofSniper'])
  const pairInfo = lodash.get(state, ['single', 'curPageData', 'pairInfo'])
  const spoofSniperAlgoFormUpdatingData = lodash.get(state, ['single', 'curPageData', 'spoofSniperAlgoFormUpdatingData'])
  return {
    dialogData,
    paramSpoofSniper,
    pairInfo,
    spoofSniperAlgoFormUpdatingData
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return mapDispatch(dispatch)({ ...socketActions, ...singleActions})
}

export default connect(mapStateToProps, mapDispatchToProps)(SpoofSniperAlgoForm)
