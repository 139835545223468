import React, {useCallback} from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import lodash from 'lodash'
import {
  Card,
  Typography,
  TableBody,
  TableHead,
  TableRow,
  makeStyles,
  TableSortLabel,
  Button,
} from '@material-ui/core'
import { orderArrayPropTypes } from '../../../../utils/propTypesUtils'
import {
  CMD_TOKEN_BUY_SELL_CANCEL_ORDER,
  SIDE_BUY
} from '../../../../protocolConstants'
import { mapDispatch } from '../../../../utils/utils'
import connect from 'react-redux/es/connect/connect'
import PiTableCell from '../../../../components/PiTableCell'
import { FORMAT_MDHMS_SHORT, fromMs } from '../../../../utils/timeUtils'
import {useStateReq} from '../../../../utils/hooks'
import { actions as singleActions } from '../../../single/singleSlice'
import XscrollTable from '../../../ui/XscrollTable'
import PiCardContent from '../../../../components/PiCardContent'


const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  cardContent: {
    height: '100%'
  },
  refreshButtonParent: {
    textAlign: "right",
  },
  tableCellGreen: {
    color: '#0f8f62',
  },
  tableCellRed: {
    color: '#d9304f',
  },
  typography: {
    fontSize: '13px',
  },
  table: {
    "overflow-y": "scroll",
    height: '100%',
  }
}))


const SimpleTableCell = ({children, isRed, isGreen, ...rest}) => {
  const classes = useStyles()
  let className = undefined
  if (isRed) {
    className = classes.tableCellRed
  } else if (isGreen) {
    className = classes.tableCellGreen
  }
  return (
    <PiTableCell
      className={clsx(className)}
      {...rest}
    >
      <Typography className={classes.typography}>
        {children}
      </Typography>
    </PiTableCell>
  )
}

const HeaderTableCell = ({children, fieldName, onHeaderClick, sortedByName, isAsc, ...rest}) => {
  let sortDirection = 'asc'
  let active = false
  if (!lodash.isEmpty(fieldName) && !lodash.isEmpty(sortedByName) && fieldName === sortedByName) {
    active = true
    if (isAsc) {
      sortDirection = 'asc'
    } else {
      sortDirection = 'desc'
    }
  }
  return (
    <SimpleTableCell
      onClick={lodash.isEmpty(fieldName) ? null : onHeaderClick(fieldName)}
      {...rest}
    >
      <TableSortLabel
        active={active}
        direction={sortDirection}
      >
        {children}
      </TableSortLabel>
    </SimpleTableCell>
  )
}

const TokenBuySellActiveOrdersView = ({ className, activeOrders, sessionId, setCurPageData }) => {
  const classes = useStyles()
  if (lodash.isEmpty(activeOrders)) {
    activeOrders = []
  }
  const {
    isSending,
    sendReq,
    getStateField,
    setStateField,
  } = useStateReq()
  const sortedByName = getStateField('sortedByName', 'time')
  const isAsc = getStateField('isAsc', false) // asc or desc
  const sortedActiveOrders = lodash.orderBy(activeOrders, [sortedByName], [isAsc ? 'asc' : 'desc'])
  const onHeaderClick = useCallback((fieldName) => () => {
    if (sortedByName !== fieldName) {
      setStateField('sortedByName', fieldName)
      setStateField('isAsc', true)
    } else {
      setStateField('isAsc', !Boolean(isAsc))
    }
  }, [isAsc, setStateField, sortedByName])

  const cancelOrder = useCallback((order) => () => {
    sendReq({
      cmd: CMD_TOKEN_BUY_SELL_CANCEL_ORDER,
      isRelay: true,
      data: {
        sessionId,
        params: {
          ...order // though only eid, and pair needed
        }
      }
    })
  }, [sessionId, sendReq])

  return (
    <Card
      className={clsx(classes.root, className)}
    >
      <PiCardContent className={classes.cardContent}>
        <XscrollTable stickyHeader className={classes.table}>
          <TableHead>
            <TableRow>
              <HeaderTableCell
                fieldName='time'
                onHeaderClick={onHeaderClick}
                sortedByName={sortedByName}
                isAsc={isAsc}
              >
                Time
              </HeaderTableCell>
              <HeaderTableCell
                fieldName='side'
                onHeaderClick={onHeaderClick}
                sortedByName={sortedByName}
                isAsc={isAsc}
              >
                Side
              </HeaderTableCell>
              <HeaderTableCell
                fieldName='px'
                onHeaderClick={onHeaderClick}
                sortedByName={sortedByName}
                isAsc={isAsc}
              >
                Px
              </HeaderTableCell>
              <HeaderTableCell
                fieldName='qty'
                onHeaderClick={onHeaderClick}
                sortedByName={sortedByName}
                isAsc={isAsc}
              >
                Qty Open /Qty
              </HeaderTableCell>
              <SimpleTableCell
              >
                Status
              </SimpleTableCell>
              <SimpleTableCell
              >
                Action
              </SimpleTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedActiveOrders.map((order, idx) => {
              let {
                px,
                qty,
                eid,
                qtyFilled,
                side,
                status,
                time
              } = order
              const qtyOpen = parseFloat(qty) - parseFloat(qtyFilled)
              const isBuy = side === SIDE_BUY
              return (
                <TableRow
                  hover
                  key={eid}
                >
                  <SimpleTableCell>
                    {fromMs(time).format(FORMAT_MDHMS_SHORT)}
                  </SimpleTableCell>
                  <SimpleTableCell
                    isRed={!isBuy}
                    isGreen={isBuy}
                  >
                    {side}
                  </SimpleTableCell>
                  <SimpleTableCell>
                    {px}
                  </SimpleTableCell>
                  <SimpleTableCell>
                    {`${qtyOpen} / ${qty}`}
                  </SimpleTableCell>
                  <SimpleTableCell>
                    {status}
                  </SimpleTableCell>

                  <SimpleTableCell>
                    <Button
                      onClick={cancelOrder(order)}
                      size="small"
                      variant="contained"
                      disabled={isSending}
                    >
                      Cancel
                    </Button>
                  </SimpleTableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </XscrollTable>
      </PiCardContent>
    </Card>
  )
}

TokenBuySellActiveOrdersView.propTypes = {
  className: PropTypes.string,
  activeOrders: orderArrayPropTypes,
  sessionId: PropTypes.number.isRequired,
  setCurPageData: PropTypes.func.isRequired
}

TokenBuySellActiveOrdersView.defaultProps= {
  activeOrders: []
}

const mapStateToProps = (state, ownProps)=> {
  const activeOrders = lodash.get(state, ['single', 'curPageData', 'activeOrders'])
  return {
    activeOrders
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return mapDispatch(dispatch)({...singleActions})
}

export default connect(mapStateToProps, mapDispatchToProps)(TokenBuySellActiveOrdersView)
