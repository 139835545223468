import React from 'react'
import { Console } from 'console-feed'
import { ThemeProvider } from 'emotion-theming'
import { Root } from 'console-feed/lib/Component/elements'
import Message from 'console-feed/lib/Component/Message'

/**
 * TODO: fix it
 * Check original source https://github.com/samdenty/console-feed/blob/master/src/Component/index.tsx
 * There is bug in original lib, he used index as key in react
 * I have wrap it, and use uuid as the key
 */


// https://stackoverflow.com/a/48254637/4089357
const customStringify = function (v) {
  const cache = new Set()
  return JSON.stringify(v, function (key, value) {
    if (typeof value === 'object' && value !== null) {
      if (cache.has(value)) {
        // Circular reference found, discard key
        return
      }
      // Store value in our set
      cache.add(value)
    }
    return value
  })
}

class PIConsole extends Console {
  render() {
    let {
      filter = [],
      logs = [],
      searchKeywords,
      logFilter,
      logGrouping = true,
    } = this.props

    if (searchKeywords) {
      const regex = new RegExp(searchKeywords)

      const filterFun = logFilter
        ? logFilter
        : (log) => {
          try {
            return regex.test(customStringify(log))
          } catch (e) {
            return true
          }
        }

      // @ts-ignore
      logs = logs.filter(filterFun)
    }

    if (logGrouping) {
      // @ts-ignore
      logs = logs.reduce((acc, log) => {
        const prevLog = acc[acc.length - 1]

        if (
          prevLog &&
          prevLog.amount &&
          prevLog.method === log.method &&
          prevLog.data.length === log.data.length &&
          prevLog.data.every((value, i) => log.data[i] === value)
        ) {
          prevLog.amount += 1

          return acc
        }

        acc.push({ ...log, amount: 1 })

        return acc
      }, [])
    }

    return (
      <ThemeProvider theme={this.theme}>
        <Root>
          {/*TODO: this part is different from original source */}
          {logs.map((log) => {
            // If the filter is defined and doesn't include the method
            const filtered =
              filter.length !== 0 &&
              log.method &&
              filter.indexOf(log.method) === -1
            const { key } = log
            return filtered ? null : (
              <Message log={log} key={key} />
            )
          })}
        </Root>
      </ThemeProvider>
    )
  }
}



export default PIConsole
