import { createSlice } from '@reduxjs/toolkit'
import { genUuid } from '../../utils/utils'
import { ALERT_DEFAULT_DURATION_MS, HIDDEN_PROJECT_NAMES, STORAGE_KEY_AUTH_TOKEN } from '../../constants'
import storage from '../../utils/storage'
import lodash from 'lodash'

export const singleSlice = createSlice({
  name: 'single',
  initialState: {
    // { 'msgType': 'info', 'msg': 'test message to display', 'msgId': 'asdfadf', msgOpen: false }
    displayingMsg: {
      msgOpen: false
    },

    // below are the authed info, will be cleared, after log out
    authToken: storage.getStr(STORAGE_KEY_AUTH_TOKEN, undefined),
    profile: undefined,
    showedSplash: false,

    // the current page data
    curPageData: {}
  },
  reducers: {
    logout: (state, action) => {
      state.authToken = undefined
      state.profile = undefined
      state.showedSplash = false
      storage.removeObj(STORAGE_KEY_AUTH_TOKEN)
    },
    updateAuthToken: (state, action) => {
      const { authToken } = action.payload
      state.authToken = authToken
      storage.setStr(STORAGE_KEY_AUTH_TOKEN, authToken)
    },
    updateProfile: (state, action) => {
      const { algorithms, projects, roles, username, exchanges, dexAssets } = action.payload
      const smallLettersDexAssets = {}
      lodash.forEach(dexAssets, (mp, chain) => {
        const das = {}
        lodash.forEach(mp, (sym, addr) => {
          das[addr.toLowerCase()] = sym.toLocaleString()
        })
        smallLettersDexAssets[chain] = das
      })
      const filteredProjects = lodash.filter(projects, (pr) => {
        const { name } = pr
        return !HIDDEN_PROJECT_NAMES.has(name)
      })

      state.profile = {
        algorithms,
        projects: filteredProjects,
        roles,
        username,
        exchanges,
        dexAssets: smallLettersDexAssets,
      }
    },
    setShowedSplash: (state, action) => {
      state.showedSplash = true
    },
    showToastSync: (state, action) => {
      let { msgType, msg, msgId } = action.payload
      if (msgId === undefined) {
        msgId = genUuid()
      }
      const msgOpen = true
      state.displayingMsg = {
        msgType,
        msg,
        msgId,
        msgOpen
      }
    },
    hideToastSync: (state, action) => {
      const { msgId } = action.payload || {}
      const curMsgId = lodash.get(state, ['displayingMsg', 'msgId'])
      if (msgId === undefined || curMsgId === undefined || msgId === curMsgId) {
        state.displayingMsg.msgOpen = false
      }
    },
    clearCurPageData: (state, action) => {
      state.curPageData = {}
    },
    setCurPageData: (state, action) => {
      const payload = action.payload || {}
      state.curPageData = {
        ...state.curPageData,
        ...payload
      }
    }
  },
})

const { updateAuthToken, setShowedSplash, logout, updateProfile, showToastSync, hideToastSync, clearCurPageData, setCurPageData } = singleSlice.actions

const setToolbarTitle = (toolbarTitle) => {
  return setCurPageData({
    toolbarTitle
  })
}


// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
const showToast = (msg, msgType, durationMs=ALERT_DEFAULT_DURATION_MS) => (dispatch) => {
  const msgId = genUuid()
  const msgObj = {
    msg,
    msgType,
    msgId
  }
  dispatch(showToastSync(msgObj))
  setTimeout(() => {
    dispatch(hideToastSync({msgId}))
  }, durationMs)
}

const actions = {
  updateAuthToken,
  updateProfile,
  setShowedSplash,
  logout,
  showToastSync,
  hideToastSync,
  showToast,
  clearCurPageData,
  setCurPageData,
  setToolbarTitle
}

export {
  actions,
  showToast,
  logout
}


export default singleSlice.reducer
