import React, { useContext } from 'react'
import {
  Typography,
  Slider,
  Grid,
  FormHelperText,
  FormLabel,
  FormControl,
  makeStyles
} from '@material-ui/core'
import PropTypes from 'prop-types'
import lodash from 'lodash'
import { createFieldPropTypes} from '../../utils/propTypesUtils'
import { formPropTypes, metaPropTypes } from '../../utils/propTypesUtils'
import { isValidNumber } from '../../utils/utils'
import { PiFormContext } from '../../utils/piFormContext'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    justifyItems: 'center',
    display: "flex",
    "align-items": "center",
  },
  outerGrid: {
    height: '100%'
  },
  label: {
    marginRight: theme.spacing(3),
    display: "flex",
    "align-items": "center",
  },
  valueLabel: {
    marginLeft: theme.spacing(3),
  },
  gridContainer: {
    padding: 0,
    margin: 0,
    alignItems: 'center'
  }
}))


const formatValue = (val) => {
  if(val === undefined || val === null || val === '') {
    return 0
  } else {
    return val
  }
}

const FormSlider = ({ field, label, labelGridProps, sliderGridProps, valueLabelGridProps, postChange, min, max, step, marks, form, handleValueLabel, disabled }) => {
  const { disabledAllFields } = useContext(PiFormContext)
  const classes = useStyles()
  const { value = '', name } = field
  const { touched, errors, submitCount, setFieldValue } = form
  /**
   * the reason to check submit count is that, if field is not defined in the initValues,
   * the touched field may not set to true if we press submit button
   * */
  const fieldTouched = Boolean(lodash.get(touched, name) || submitCount > 0)
  const errMsg = lodash.get(errors, name, '')
  const error = Boolean(fieldTouched && errMsg)
  const helperText = fieldTouched && errMsg

  const onValueChange = (e, newValue) => {
    setFieldValue(name, newValue)
    if (lodash.isFunction(postChange)) {
      postChange(name, newValue)
    }
  }

  const  valueLabel = handleValueLabel(value)
  return (
    <FormControl fullWidth error={error} className={classes.root}>
      <Grid display="flex" container className={classes.outerGrid} justifyContent="center" spacing={0}>
        <Grid display="flex" container className={classes.gridContainer} spacing={0}>
          <Grid
            item
            xl={4}
            lg={4}
            md={4}
            sm={4}
            xs={4}
            {...labelGridProps}
          >
            <FormLabel
              className={classes.label}
            >
              {label}
            </FormLabel>
          </Grid>

          <Grid
            item
            xl={5}
            lg={5}
            md={5}
            sm={5}
            xs={5}
            {...sliderGridProps}
          >
            <Slider
              size="small"
              defaultValue={30}
              min={min}
              max={max}
              step={step}
              marks={marks}
              disabled={disabled || disabledAllFields}
              value={formatValue(value)}
              onChange={onValueChange}
            />
          </Grid>

          <Grid
            item
            xl={3}
            lg={3}
            md={3}
            sm={3}
            xs={3}
            {...valueLabelGridProps}
          >
            <Typography
              className={classes.valueLabel}
              align="right"
              variant="body1"
            >
              {Boolean(valueLabel)
              ? valueLabel
              : ''}
            </Typography>
          </Grid>
        </Grid>
        {lodash.isEmpty(helperText)
          ? null
          : <FormHelperText>{helperText}</FormHelperText>
        }
      </Grid>
    </FormControl>
  )
}

FormSlider.propTypes = {
  field: createFieldPropTypes(),
  form: formPropTypes,
  meta: metaPropTypes,
  label: PropTypes.string.isRequired,
  handleValueLabel: PropTypes.func.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  step: PropTypes.number,
  marks: PropTypes.bool.isRequired,
  labelGridProps: PropTypes.object,
  sliderGridProps: PropTypes.object,
  valueLabelGridProps: PropTypes.object,
  postChange: PropTypes.func
}

FormSlider.defaultProps = {
  label: '',
  min: 0,
  max: 100,
  marks: false,
  handleValueLabel: (val) => {
    if(isValidNumber(val)) {
      return `${val}%`
    } else {
      return ''
    }
  }
}

export default FormSlider
