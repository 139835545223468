import React from 'react'
import clsx from 'clsx'
import {
  Card,
  TableBody,
  TableRow,
  makeStyles
} from '@material-ui/core'
import lodash from 'lodash'
import { baseQuote, mapDispatch } from '../../../../utils/utils'
import { actions as singleActions } from '../../../single/singleSlice'
import connect from 'react-redux/es/connect/connect'
import PropTypes from 'prop-types'
import { pairInfoPropTypes } from '../../../../utils/propTypesUtils'
import XscrollTable from '../../../ui/XscrollTable'
import PiTableCell from '../../../../components/PiTableCell'
import PiCardContent from '../../../../components/PiCardContent'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  cardContent: {
    height: '100%'
  },
  tableCell: {
    borderBottom: "none"
  }
}))

const SmallTableCell = ({children}) => {
  const classes = useStyles()
  return <PiTableCell
    className={classes.tableCell}
  >
    {children}
  </PiTableCell>
}

const MakerTakerBaiscInfoView = ({ basicInfo, pairInfo }) => {
  const classes = useStyles()
  if (!basicInfo || !pairInfo) {
    return null
  }

  const { pair } = pairInfo
  const [base, quote] = baseQuote(pair)

  const { avlbBase = '-', avlbQuote = '-', lockedBase = '-', lockedQuote = '-' } = basicInfo
  return (
    <Card className={clsx(classes.root)}
    >
      <PiCardContent className={classes.cardContent}>
        <XscrollTable>
          <TableBody>
            <TableRow
              hover
            >
              <SmallTableCell>
                {`Available Base (${base})`}
              </SmallTableCell>
              <SmallTableCell>
                {avlbBase}
              </SmallTableCell>
              <SmallTableCell>
                {`Frozen Base (${base})`}
              </SmallTableCell>
              <SmallTableCell>
                {lockedBase}
              </SmallTableCell>
            </TableRow>

            <TableRow
              hover
            >
              <SmallTableCell>
                {`Available Quote (${quote})`}
              </SmallTableCell>
              <SmallTableCell>
                {avlbQuote}
              </SmallTableCell>
              <SmallTableCell>
                {`Frozen Quote (${quote})`}
              </SmallTableCell>
              <SmallTableCell>
                {lockedQuote}
              </SmallTableCell>
            </TableRow>
          </TableBody>
        </XscrollTable>
      </PiCardContent>
    </Card>
  )
}

MakerTakerBaiscInfoView.propTypes = {
  basicInfo: PropTypes.shape({
    serverTime: PropTypes.number.isRequired,
    avlbBase: PropTypes.string.isRequired,
    avlbQuote: PropTypes.string.isRequired,
    lockedBase: PropTypes.string.isRequired,
    lockedQuote: PropTypes.string.isRequired,
  }),
  pairInfo: pairInfoPropTypes
}

MakerTakerBaiscInfoView.defaultProps= {
}

const mapStateToProps = (state, ownProps)=> {
  const basicInfo = lodash.get(state, ['single', 'curPageData', 'basicInfo'])
  const pairInfo = lodash.get(state, ['single', 'curPageData', 'pairInfo'])
  return {
    basicInfo,
    pairInfo,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return mapDispatch(dispatch)({...singleActions})
}

export default connect(mapStateToProps, mapDispatchToProps)(MakerTakerBaiscInfoView)

