import React, { useContext } from 'react'
import {
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  FormHelperText,
  Grid,
  makeStyles
} from '@material-ui/core'
import PropTypes from 'prop-types'
import lodash from 'lodash'
import { createFieldPropTypes} from '../../utils/propTypesUtils'
import { formPropTypes, metaPropTypes } from '../../utils/propTypesUtils'
import { PiFormContext } from '../../utils/piFormContext'

const useStyles = makeStyles((theme) => ({
  rowLabel: {
    marginRight: theme.spacing(3),
    display: "flex",
    "align-items": "center"
  }
}))


const FormRadio = ({ field, postChange, row, label, options, form, disabled, ...rest}) => {
  const { disabledAllFields } = useContext(PiFormContext)
  const classes = useStyles()
  const { value = '', name } = field
  const { touched, errors, submitCount, setFieldValue } = form
  /**
   * the reason to check submit count is that, if field is not defined in the initValues,
   * the touched field may not set to true if we press submit button
   * */
  const fieldTouched = Boolean(lodash.get(touched, name) || submitCount > 0)
  const errMsg = lodash.get(errors, name, '')
  const error = Boolean(fieldTouched && errMsg)
  const helperText = fieldTouched && errMsg

  const handleOnChange = (e) => {
    let targetVal = e.target.value
    setFieldValue(name, targetVal)
    if (lodash.isFunction(postChange)) {
      postChange(name, targetVal)
    }
  }

  const renderChild = () => {
    if(row) {
      return (
        <Grid display="flex" container>
          <FormLabel className={classes.rowLabel}>
            {label}
          </FormLabel>
          <RadioGroup
            row={row}
            name={name}
            value={value}
            onChange={handleOnChange}
          >
            {
              options.map((op, idx) => {
                return (
                  <FormControlLabel
                    key={op.value}
                    value={op.value}
                    disabled={disabled || disabledAllFields}
                    control={<Radio
                      size="small"
                    />}
                    label={op.label}
                  />
                )
              })
            }
          </RadioGroup>
        </Grid>
      )
    } else {
      return [
        <FormLabel>
          {label}
        </FormLabel>,
        <RadioGroup
          row={row}
          name={name}
          value={value}
          onChange={handleOnChange}
        >
          {
            options.map((op, idx) => {
              return (
                <FormControlLabel
                  key={op.value}
                  value={op.value}
                  disabled={disabled || disabledAllFields}
                  control={<Radio />}
                  label={op.label}
                />
              )
            })
          }
        </RadioGroup>
      ]
    }
  }

  return (
    <FormControl fullWidth error={error} {...rest}>
      {renderChild()}
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  )
}

FormRadio.propTypes = {
  postChange: PropTypes.func, // input will be <name, newvalue>

  field: createFieldPropTypes(),
  form: formPropTypes,
  meta: metaPropTypes,
  label: PropTypes.string.isRequired,
  row: PropTypes.bool.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
    label: PropTypes.string.isRequired
  })),
}

FormRadio.defaultProps = {
  label: '',
  row: true,
  options: [],
}

export default FormRadio
