import React from 'react'
import { Box, Card, Grid, Button } from '@material-ui/core'
import PiCardContent from '../../../components/PiCardContent'
import { Field } from 'formik'
import FormInput from '../../../components/form/FormInput'

const DefaultGridItem = ({children}) => {
  return (
    <Grid
      item
      xl={12}
      lg={12}
      sm={12}
      xs={12}
    >
      {children}
    </Grid>
  )
}

export const NoteFormInner = ({isSending, formProps }) => {
  const { handleSubmit } = formProps
  return (
    <form onSubmit={handleSubmit}>
      <Box>
        <Card>
          <PiCardContent>
            <Box mx={2}>
              <Grid container>
                <DefaultGridItem>
                  <Field
                    name="content"
                    placeholder="Input and save any note for this session"
                    multiline
                    variant="outlined"
                    component={FormInput}
                  />
                </DefaultGridItem>

                <DefaultGridItem>
                  <Button
                    fullWidth
                    size="small"
                    disabled={isSending}
                    color="primary"
                    variant="contained"
                    type="submit">
                    Update Note
                  </Button>
                </DefaultGridItem>
              </Grid>
            </Box>
          </PiCardContent>
        </Card>
      </Box>
    </form>
  )
}
