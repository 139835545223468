import PropTypes from 'prop-types'
import { pairInfoPropTypes } from '../../../../utils/propTypesUtils'

const ctState = PropTypes.shape({
  marginBal: PropTypes.string.isRequired,
  avlbBal: PropTypes.string.isRequired,
  settlementAsset: PropTypes.string.isRequired,
})

const margState = PropTypes.shape({
  crossMarginAssetInfos: PropTypes.objectOf(PropTypes.shape({
    free: PropTypes.string,
    locked: PropTypes.string,
    // when paying back, we need to pay for both borrowed + interest
    borWtInt: PropTypes.string,
    borLmt: PropTypes.string,
  }))
})

const spState = PropTypes.shape({

})

const portState = PropTypes.shape({
  uniMmr: PropTypes.string,
  portfolioAssets: PropTypes.objectOf(PropTypes.shape({
    free: PropTypes.string,
    locked: PropTypes.string,
    // when paying back, we need to pay for both borrowed + interest
    borWtInt: PropTypes.string,
    borLmt: PropTypes.string,
  }))
})

const pairStatePropTypes = PropTypes.shape({
  pair: PropTypes.string.isRequired,
  openQty: PropTypes.string.isRequired,
})

const accStatePropTypes = PropTypes.shape({
  ename: PropTypes.string.isRequired,
  accName: PropTypes.string.isRequired,
  pairs: PropTypes.arrayOf(PropTypes.string),
  pairStates: PropTypes.objectOf(pairStatePropTypes),

  contractState: ctState,
  marginState: margState,
  spotState: spState,
  portfolioState :portState
})

const longShortOrderbookPropTypes = PropTypes.objectOf(PropTypes.shape({
  bid1: PropTypes.string.isRequired,
  ask1: PropTypes.string.isRequired,
}))

const createEmptyLongShortTarget = () => {
  return {
    pairA: '',
    targetQtyA: '',
    orderQtyA: 0,
    sideA: '',
    initABSum: 0
  }
}

const createEmptyLongShortDeleverageTarget = () => {
  return {
    delevAccName: '',
    startDelevLeverage: 7,
    endDelevLeverage: 6.5
  }
}

const createEmptySpammingTarget = () => {
  return {}
}

const longShortAccInfoPropTypes = PropTypes.shape({
  accCat: PropTypes.string.isRequired,
  accName: PropTypes.string.isRequired,
  ename: PropTypes.string.isRequired,
  pairs: PropTypes.arrayOf(PropTypes.string),
  pairInfos: PropTypes.objectOf(pairInfoPropTypes),
})

export {
  accStatePropTypes,
  longShortAccInfoPropTypes,
  longShortOrderbookPropTypes,
  createEmptyLongShortTarget,
  createEmptyLongShortDeleverageTarget,
  createEmptySpammingTarget,
}
