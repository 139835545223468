import PropTypes from 'prop-types'
import { optionArrayPropType, pairInfoPropTypes } from '../../../utils/propTypesUtils'


const accNamePairOptionArrayPropTypes = PropTypes.arrayOf(PropTypes.shape({
  accName: PropTypes.string.isRequired,
  pairOptions: optionArrayPropType,
}))

// map from accName => pair => pairInfo
const accNamePairPairInfoPropType = PropTypes.objectOf(PropTypes.objectOf(pairInfoPropTypes))

const EMPTY_ACCOUNT_NAME_KEY = 'empty_account_name_key'
const EMPTY_PAIR_NAME_KEY = 'empty_pair_name_key'

export {
  accNamePairOptionArrayPropTypes,
  accNamePairPairInfoPropType,
  EMPTY_ACCOUNT_NAME_KEY,
  EMPTY_PAIR_NAME_KEY
}
