import React from 'react'
import { Box, Table, TableBody, TableHead, Button, TableRow } from '@material-ui/core'
import ConfirmDialog from '../../../../../components/ConfirmDialog'
import Decimal from 'decimal.js'
import { SIDE_BUY} from '../../../../../protocolConstants'
import ColorButton from '../../../../../components/ColorButton'
import PropTypes from 'prop-types'
import { icebergDialogPropType } from '../../../../../utils/propTypesUtils'
import lodash from 'lodash'
import { mapDispatch } from '../../../../../utils/utils'
import { actions as singleActions } from '../../../../single/singleSlice'
import connect from 'react-redux/es/connect/connect'
import IcebergActiveOrdersView from '../IcebergActiveOrdersView'
import { CMD_EXECUTE_ICEBERG, CMD_STOP_ICEBERG, SIDE_SELL } from '../../../../../protocolConstants'
import { useIsMobile } from '../../../../../utils/hooks'
import PiTableCell from '../../../../../components/PiTableCell'
import { INFINITE_SYMBOL } from '../../../../../constants'

const TableRowNameVal = ({name, value}) => {
  return (
    <TableRow hover>
      <PiTableCell
        variant='head'
      >
        {name}
      </PiTableCell>
      <PiTableCell>
        {value}
      </PiTableCell>
    </TableRow>
  )
}
const DialogContentExecuteIceberg = ({previewData}) => {
  const {
    numHiddenOrdersPerLayer,
    reservePx,
    reserveQty,
    rows,
    totalHiddenBase,
    totalHiddenQuote,
    totalVisibleBase,
    totalVisibleQuote,

    infiniteOrdersEnabled,
    iOPerOrderQty,
    iOFinalInventory,
    iOPerOrderQtySymbol,
    iOFinalInventorySymbol,
  } = previewData
  if (infiniteOrdersEnabled) {
    return (
      <Box>
        <Box mb={1}>
          <Table>
            <TableBody>
              <TableRowNameVal
                name="Num of Layers"
                value={rows.length}
              />

              <TableRowNameVal
                name="Infi Ord Qty"
                value={`${iOPerOrderQty} ${iOPerOrderQtySymbol}`}
              />
              <TableRowNameVal
                name="Infi Fnl Invt"
                value={`${iOFinalInventory} ${iOFinalInventorySymbol}`}
              />
            </TableBody>
          </Table>
        </Box>


        <Box>
          <Table>
            <TableHead>
              <TableRow>
                <PiTableCell
                >
                  Px
                </PiTableCell>
                <PiTableCell
                >
                  Visible Qty
                </PiTableCell>
                <PiTableCell
                >
                  Hidden Qty Per Layer
                </PiTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => {
                const {hiddenQtyPerOrder, px, visibleQty} = row
                return (
                  <TableRow
                    hover
                    size='small'
                    key={`${hiddenQtyPerOrder}_${px}_${visibleQty}`}
                  >
                    <PiTableCell
                    >
                      {px}
                    </PiTableCell>
                    <PiTableCell
                    >
                      {visibleQty}
                    </PiTableCell>
                    <PiTableCell
                    >
                      {`${hiddenQtyPerOrder} * ${INFINITE_SYMBOL}`}
                    </PiTableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </Box>
      </Box>
    )

  } else {
    return (
      <Box>
        <Box mb={1}>
          <Table>
            <TableBody>
              <TableRowNameVal
                name="Num of Layers"
                value={rows.length}
              />
              <TableRowNameVal
                name="Num Hidden Orders Per Layer"
                value={numHiddenOrdersPerLayer}
              />
              <TableRowNameVal
                name="Reserve Px"
                value={reservePx}
              />
              <TableRowNameVal
                name="Reserve Qty"
                value={reserveQty}
              />
            </TableBody>
          </Table>
        </Box>

        <Box mb={1}>
          <Table>
            <TableBody>

              <TableRow hover>
                <PiTableCell
                  variant='head'
                >

                </PiTableCell>
                <PiTableCell
                  variant='head'
                >
                  Base
                </PiTableCell>

                <PiTableCell
                  variant='head'
                >
                  Quote
                </PiTableCell>
              </TableRow>

              <TableRow hover>
                <PiTableCell
                  variant='head'
                >
                  Visible
                </PiTableCell>
                <PiTableCell
                >
                  {totalVisibleBase}
                </PiTableCell>

                <PiTableCell
                >
                  {totalVisibleQuote}
                </PiTableCell>
              </TableRow>

              <TableRow hover>
                <PiTableCell
                  variant='head'
                >
                  Hidden
                </PiTableCell>
                <PiTableCell
                >
                  {totalHiddenBase}
                </PiTableCell>

                <PiTableCell
                >
                  {totalHiddenQuote}
                </PiTableCell>
              </TableRow>

              <TableRow hover>
                <PiTableCell
                  variant='head'
                >
                  Total
                </PiTableCell>
                <PiTableCell
                >
                  {new Decimal(totalHiddenBase).add(totalVisibleBase).toFixed()}
                </PiTableCell>

                <PiTableCell
                >
                  {new Decimal(totalHiddenQuote).add(totalVisibleQuote).toFixed()}
                </PiTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>

        <Box>
          <Table>
            <TableHead>
              <TableRow>
                <PiTableCell
                >
                  Px
                </PiTableCell>
                <PiTableCell
                >
                  Visible Qty
                </PiTableCell>
                <PiTableCell
                >
                  Hidden Qty Per Layer
                </PiTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => {
                const {hiddenQtyPerOrder, px, visibleQty} = row
                return (
                  <TableRow
                    hover
                    size='small'
                    key={`${hiddenQtyPerOrder}_${px}_${visibleQty}`}
                  >
                    <PiTableCell
                    >
                      {px}
                    </PiTableCell>
                    <PiTableCell
                    >
                      {visibleQty}
                    </PiTableCell>
                    <PiTableCell
                    >
                      {numHiddenOrdersPerLayer === 1
                        ? hiddenQtyPerOrder
                        : `${hiddenQtyPerOrder} * ${numHiddenOrdersPerLayer}`
                      }
                    </PiTableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </Box>
      </Box>
    )
  }
}


const IcebergAlgoFormDialog = ({dialogData, setCurPageData, sendReq, sessionId}) => {
  const isMobile = useIsMobile()
  if (!dialogData) {
    return null
  }
  const {
    dialogOpen,
    dialogType,
    previewData,  // preview data, preview sent data for execute iceberg
    previewSentData,
    activeOrderData = {}, // for active orders
  } = dialogData
  const hideDialog = () => {
    setCurPageData({
      dialogData: {
        ...dialogData,
        dialogOpen: false,
      }
    })
  }

  if (dialogType === 'executeIceberg') {
    const executeIceberg = () => {
      sendReq({
        cmd: CMD_EXECUTE_ICEBERG,
        isRelay: true,
        data: lodash.get(previewSentData, 'data')
      })
      hideDialog()
    }
    const { buySell } = previewData
    let confirmButton
    if (buySell === SIDE_BUY) {
      confirmButton = <ColorButton onClick={executeIceberg} colorStyle='green'>
        Execute Buy
      </ColorButton>
    } else if (buySell === SIDE_SELL) {
      confirmButton = <ColorButton onClick={executeIceberg} colorStyle='red'>
        Execute Sell
      </ColorButton>
    } else {
      confirmButton = <Button onClick={executeIceberg}>
        Execute Now
      </Button>
    }
    return (
      <ConfirmDialog
        dialogOpen={dialogOpen}
        dialogTitle='Are you sure you want to execute iceberg?'
        maxWidth='lg'
        dialogContent={<DialogContentExecuteIceberg
          previewData={previewData}
        />}
        dialogCancelText='Dismiss'
        dialogConfirmButton={confirmButton}
        dialogOnCancel={hideDialog}
      />
    )
  } else if (dialogType === 'stopIceberg') {
    const stopIceberg = () => {
      sendReq({
        cmd: CMD_STOP_ICEBERG,
        isRelay: true,
        data: {
          sessionId
        }
      })
      hideDialog()
    }
    return (
      <ConfirmDialog
        dialogOpen={dialogOpen}
        dialogTitle='Are you sure you want to stop iceberg?'
        dialogContent=''
        dialogCancelText='Dismiss'
        dialogConfirmButton='Stop now'
        dialogOnCancel={hideDialog}
        dialogOnConfirm={stopIceberg}
      />
    )
  } else if (dialogType === 'showActiveOrders') {
    const { tpOrderEnabled } = activeOrderData
    return (
      <ConfirmDialog
        maxWidth='lg'
        fullScreen={isMobile}
        fullWidth={true}
        dialogOpen={dialogOpen}
        dialogTitle="Active Orders"
        dialogContent={<IcebergActiveOrdersView
          tpOrderEnabled={tpOrderEnabled}
        />}
        dialogConfirmButton='Dismiss'
        dialogOnConfirm={hideDialog}
      />
    )
  } else {
    return null
  }
}


IcebergAlgoFormDialog.propTypes = {
  dialogData: icebergDialogPropType,
  setCurPageData: PropTypes.func.isRequired,
  sendReq: PropTypes.func.isRequired,
  sessionId: PropTypes.number.isRequired
}

IcebergAlgoFormDialog.defaultProps= {

}

const mapStateToProps = (state, ownProps)=> {
  const dialogData = lodash.get(state, ['single', 'curPageData', 'dialogData'])
  return {
    dialogData
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return mapDispatch(dispatch)(singleActions)
}

export default connect(mapStateToProps, mapDispatchToProps)(IcebergAlgoFormDialog)
