import React, { useEffect, useState, useCallback, useRef } from 'react'
import { genUuid, getReqInfo, bindDispatch, getTsMs } from './utils'
import lodash from 'lodash'
import { Box, CircularProgress, makeStyles, useTheme } from '@material-ui/core'
import logger from './logger'
import { useSelector, useDispatch } from 'react-redux'
import { sendMessage as sendMessageAction } from '../features/socket/socketSlice'
import MsgAlertView from '../features/ui/MsgAlertView'
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery'

const useStateFields = () => {
  const [state, setState] = useState({})
  const setStateField = (key, value) => {
    setState(prevState => {
      return {
        ...prevState,
        [key]: value
      }
    })
  }

  const getStateField = (key, defaultValue=undefined) => {
    return lodash.get(state, key, defaultValue)
  }
  return [state, setStateField, getStateField]
}

const useRunOnce= (f) => {
  useEffect(() => {
    f()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
}

const useRefFields = () => {
  const ref = useRef({})
  const setRefField = (key, val) => {
    ref.current[key] = val
  }

  const getRefField = (key, defaultValue) => {
    const val = ref.current[key]
    if (val === undefined) {
      return defaultValue
    }  else {
      return val
    }
  }
  return [setRefField, getRefField]
}

/**
 * It will cancel the previous call
 */
const useDebounceCall = (fn, delayMs) => {
  const [setRefField, getRefField] = useRefFields()
  return (...args) => {
    const curCallTimeMs = getTsMs()
    setRefField('debounceCallTimeMs', curCallTimeMs)
    setTimeout(() => {
      const callTimeMs = getRefField('debounceCallTimeMs')
      if (curCallTimeMs === callTimeMs) {
        fn(...args)
      } else {
        logger.debug(`Debounce call ignore calltimeMs ${curCallTimeMs}`)
      }
    }, delayMs)
  }
}

const useRefFieldsPrevious = (key, val, defaultValue=undefined) => {
  const ref = useRef({})
  useEffect(() => {
    ref.current[key] = val
  })
  const refVal = ref.current[key]
  if (refVal === undefined) {
    return defaultValue
  } else {
    return refVal
  }
}

const useRefFieldsPrevValueChanged = (key, val, defaultValue=undefined) => {
  const preVal = useRefFieldsPrevious(key, val, defaultValue)
  return preVal !== val
}

const useRefWillMount = (f) => {
  const [setRefField, getRefField] = useRefFields()
  if (getRefField('_willMountRef', true)) {
    setRefField('_willMountRef', false)
    f()
  }
}

const useIsDesktop = () => {
  const theme = useTheme()
  return useMediaQuery(theme.breakpoints.up('md'))
}


const useIsMobile = () => {
  return !useIsDesktop()
}

const useStateReq = (rIdKey='rIdKey') => {
  const reqInfoMap = useSelector((state) => {
    return lodash.get(state, ['socket', 'reqInfoMap'], {})
  })
  const dispatch = useDispatch()
  const sendMessage = bindDispatch(dispatch)(sendMessageAction)
  const [_, setStateField, getStateField] = useStateFields() // eslint-disable-line no-unused-vars
  const rId = getStateField(rIdKey, undefined)
  if(!reqInfoMap) {
    logger.error(`useStateReq Hook, reqInfoMap is empty`)
  }
  const { isSending, isSuccessful, isError, err, receivedData, sentData } = getReqInfo(reqInfoMap, rId)
  const clearRId= () => {
    setStateField(rIdKey)
  }
  const sendReq = useCallback((msg) => {
    let rId = lodash.get(msg, 'rId')
    if (!rId) {
      rId = genUuid()
      msg['rId'] = rId
    }
    setStateField(rIdKey, rId)
    sendMessage(msg)
  }, [sendMessage, setStateField, rIdKey])
  return {
    isSending,
    isSuccessful,
    isError,
    sendReq,
    err,
    getStateField,
    setStateField,
    receivedData,
    sentData,
    clearRId,
    rId
  }
}

const useStyles = makeStyles((theme) => ({
  box: {
    height: '100%',
    display: "flex",
    justifyContent: "center",
    alignItems: 'center',
    flexDirection: "column"
  },
  alert: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  }
}))

/**
 *
 * @param genReqData: generate request data 1) can be a object 2) can be a function () => {}, which generate a object to
 * @param rIdKey
 * @returns {{sendReq: (Function|*), content: *, receivedData: (undefined|*), getStateField, setStateField, isSuccessful: (boolean|*)}}
 */
const useStateReqView = (genReqData, rIdKey='rIdKey') => {
  const {
    isSending,
    getStateField,
    setStateField,
    sendReq,
    isError,
    err,
    receivedData,
    isSuccessful,
    clearRId,
    rId
  } = useStateReq(rIdKey)
  let loadingView = null
  const reqNow = useCallback(() => {
    let reqData
    if(lodash.isFunction(genReqData)) {
      reqData = genReqData()
    } else {
      reqData = genReqData
    }
    sendReq(reqData)
  }, [genReqData, sendReq])
  const classes = useStyles()
  if(isError) {
    const msg = `Request Error. code: ${lodash.get(err, 'code')}, message: ${lodash.get(err, 'message')}`
    loadingView = <MsgAlertView
      msg={msg}
      buttonText="Retry"
      onButtonClick={reqNow}
    />
  } else if(isSending || !isSuccessful){
    loadingView = <Box
      display="flex"
      flexDirection="column"
      className={classes.box}
    >
      <CircularProgress color="inherit"/>
    </Box>
  }
  useRunOnce(() => {
    reqNow() // run only once
  })
  return {
    sendReq,
    loadingView,
    receivedData,
    getStateField,
    setStateField,
    isSuccessful,
    clearRId,
    rId
  }
}

export {
  useStateFields,
  useRunOnce,
  useRefFields,
  useStateReq,
  useStateReqView,
  useRefFieldsPrevious,
  useRefFieldsPrevValueChanged,
  useRefWillMount,
  useIsMobile,
  useDebounceCall
}
