import React, { useContext, useEffect } from 'react'
import {
  FormLabel,
  TextField,
  FormControl, FormHelperText, Grid, makeStyles
} from '@material-ui/core'
import PropTypes from 'prop-types'
import lodash from 'lodash'
import { createFieldPropTypes} from '../../utils/propTypesUtils'
import { formPropTypes, metaPropTypes } from '../../utils/propTypesUtils'
import { isValidNumber } from '../../utils/utils'
import PiInputAdornment from '../PiInputAdornment'
import { PiFormContext } from '../../utils/piFormContext'
import clsx from 'clsx'


const useStyles = makeStyles((theme) => ({
  label: {
    marginRight: theme.spacing(3),
    display: "flex",
    "align-items": "center",
    "text-align": "center",
    "height": "100%"
  },
  labelGrid: {
    'align-items': 'center'
  },
  min: {
    marginRight: theme.spacing(1),
  },
  textFieldDisabled: {
    backgroundColor: '#e8e8e8'
  },
  textFieldNormal: {

  }
}))

/**
 * The value will be in millisecond
 */
const FormInterval = ({ field, row, label, options, form, disabled, ...rest}) => {
  const { disabledAllFields } = useContext(PiFormContext)
  const classes = useStyles()
  const { value = '', name, onChange } = field
  const { touched, errors, submitCount, setFieldValue, setFieldTouched } = form
  /**
   * the reason to check submit count is that, if field is not defined in the initValues,
   * the touched field may not set to true if we press submit button
   * */
  const fieldTouched = Boolean(lodash.get(touched, name) || submitCount > 0)
  const errMsg = lodash.get(errors, name, '')
  const error = Boolean(fieldTouched && errMsg)
  const helperText = fieldTouched && errMsg

  const fromValue = (val) => {
    let valueInt = parseInt(val)
    if (!isValidNumber(valueInt)) {
      valueInt = 0
    }
    const valueTotalSec = parseInt(valueInt / 1000)
    const valueSec = valueTotalSec % 60
    const valueMin = Math.floor(valueTotalSec / 60)
    return {
      valueMin,
      valueSec
    }
  }

  const toValue = (min, sec) => {
    min = parseInt(min)
    sec = parseInt(sec)
    if (!isValidNumber(sec) || !isValidNumber(min)) {
      return 0
    } else {
      const totalSec = min * 60 + sec
      return totalSec * 1000
    }
  }

  const onMinuteChange = (e) => {
    let min = parseInt(e.target.value)
    if(!isValidNumber(min)) {
      min = 0
    }
    if(min < 0) {
      min = 0
    }
    const { valueSec: curSec } = fromValue(value)
    const newValue = toValue(min, curSec)
    setFieldValue(name, newValue)
  }

  const onSecondChange = (e) => {
    let sec = parseInt(e.target.value)
    if(!isValidNumber(sec)) {
      sec = 0
    }
    if (sec < 0) {
      sec = 0
    }
    if (sec > 59) {
      sec = 59
    }
    const { valueMin: curMin } = fromValue(value)
    const newValue = toValue(curMin, sec)
    onChange({
      target: {
        value: newValue
      }
    })
    setFieldValue(name, newValue)
  }

  const handleOnBlur = (e) => {
    setFieldTouched(name, true)
  }

  const { valueMin, valueSec } = fromValue(value)

  useEffect(() => {
    if (!isValidNumber(value)) {
      setFieldValue(name, 0)
    }
  }, [value, setFieldValue, name])

  const textFieldDisabled = disabled || disabledAllFields

  const textFieldClasses = textFieldDisabled ? classes.textFieldDisabled : classes.textFieldNormal
  return (
    <FormControl fullWidth error={error} {...rest}>
      <Grid container spacing={1}>
        <Grid
          item
          className={classes.labelGrid}
        >
          <FormLabel className={classes.label}>
            {label}
          </FormLabel>
        </Grid>
        <Grid
          item
          xs={4}
        >
          <TextField
            className={clsx(classes.min, textFieldClasses)}
            fullWidth
            error={error}
            label=""
            InputProps = {{
              startAdornment: <PiInputAdornment position="start" label="Min"/>,
            }}
            inputProps={{
              type: 'number',
              style: {
                textAlign: 'right'
              }
            }}
            onChange={onMinuteChange}
            onBlur={handleOnBlur}
            name="minute"
            size='small'
            margin='dense'
            disabled={textFieldDisabled}
            value={valueMin}/>
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            className={textFieldClasses}
            error={error}
            label=""
            InputProps = {{
              startAdornment: <PiInputAdornment position="start" label="Sec"/>,
            }}
            inputProps={{
              type: 'number',
              style: {
                textAlign: 'right'
              }
            }}
            onChange={onSecondChange}
            onBlur={handleOnBlur}
            name="minute"
            size='small'
            margin='dense'
            value={valueSec}
            disabled={textFieldDisabled}
          />
        </Grid>
      </Grid>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  )
}

FormInterval.propTypes = {
  field: createFieldPropTypes(),
  form: formPropTypes,
  meta: metaPropTypes,
  label: PropTypes.string.isRequired,
  row: PropTypes.bool.isRequired
}

FormInterval.defaultProps = {
  label: '',
  row: true
}

export default FormInterval
