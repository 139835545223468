import React, { useEffect } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import {
  makeStyles,
  Box,
  Grid
} from '@material-ui/core'
import { mapDispatch } from '../../../../utils/utils'
import connect from 'react-redux/es/connect/connect'
import { processLogs } from '../../../ui/LoggerView'
import {
  CMD_LONG_SHORT_BROADCAST_STARTED,
  CMD_LONG_SHORT_BROADCAST_STOPPED,
  CMD_GET_FULL_STATE,
  CMD_LOG_UPDATE,
  CMD_LONG_SHORT_BROADCAST_PARTIAL_FULL_STATE,
} from '../../../../protocolConstants'
import { useRefFieldsPrevious, useRunOnce, useStateReqView } from '../../../../utils/hooks'
import lodash from 'lodash'
import LongShortLoggerView from './LongShortLoggerView'
import LongShortBasicInfoView from './LongShortAccStateView'
import LongShortPositionInfoView from './LongShortPositionInfoView'
import LongShortExtraPositionInfoView from "./LongShortExtraPositionInfoView"
import { actions as singleActions } from '../../../single/singleSlice'
import { addEventListener, removeEventListener } from '../../../../utils/eventBus'
import { accountPropTypes, algorithmPropTypes, projectPropType } from '../../../../utils/propTypesUtils'
import SpammingForm from './LongShortForm'
import LongShortActiveOrdersView from './LongShortActiveOrdersView'
import {
  createOnAlgoActiveUpdate,
  createOnLogfileUpdate
} from '../utils/onUpdate'
import LongShortBorrowRepayForm from './LongShortBorrowRepayForm'
import LongShortPlaceOrderForm from './LongShortPlaceOrderForm'
import LongShortNoteForm from './LongShortNoteForm'


const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  leftRoot: {
    padding: theme.spacing(1)
  },
  leftTop: {
    width: '100%',
    padding: theme.spacing(1)
  },
  leftMid: {
    width: '100%',
    padding: theme.spacing(1)
  },
  leftBottom: {
    width: '100%',
    height: '300px', // it is actually the min height to be 300
    padding: theme.spacing(1),
    "overflow": "auto"
  },
  rightRoot: {
    padding: theme.spacing(1)
  },
  rightTop: {
    width: '100%',
    padding: theme.spacing(1)
  },
  rightMid: {
    width: '100%',
    padding: theme.spacing(1)
  },
  rightBottom: {
    width: '100%',
    height: '300px', // it is actually the min height to be 300
    padding: theme.spacing(1),
    "overflow": "auto"
  },
}))


const LongShortAlgoView = ({ sessionId, sessionAccounts, project, algorithm, setCurPageData, paramLongShort, logs, clearCurPageData, setToolbarTitle }) => {
  const classes = useStyles()
  useRunOnce(() => {
    clearCurPageData()
    if (!lodash.isEmpty(sessionAccounts)) {
      const sessionAccount = sessionAccounts[0]
      const { accId, pairs } = sessionAccount
      const exchangeAccounts = lodash.get(project, 'exchangeAccounts', [])
      const account = lodash.find(exchangeAccounts, (ea) => {
        return ea['id'] === accId
      })
      if (account) {
        let pair = ''
        if (!lodash.isEmpty(pairs)) {
          pair = pairs[0]
        }
        const toolbarTitle = `${algorithm.name} - ${account.name} - ${pair}`
        setToolbarTitle(toolbarTitle)
      }
    }
  })

  const genFullState = () => {
    return {
      cmd: CMD_GET_FULL_STATE,
      isRelay: true,
      data: {
        sessionId,
      }
    }
  }

  let { isSuccessful, receivedData, loadingView } = useStateReqView(genFullState)

  const onLongShortStarted = createOnAlgoActiveUpdate(setCurPageData, sessionId, true, 'paramLongShort', paramLongShort)

  const onLongShortStopped = createOnAlgoActiveUpdate(setCurPageData, sessionId, false, 'paramLongShort', paramLongShort)

  const onLongShortPartialFullStateUpdate = (event) => {
    const { data: partialFullState, sessionId: eventSessionId } = lodash.get(event, 'data')
    if (eventSessionId !== sessionId) {
      return
    }
    // not all the fields will be sent in partial full State, since it is partial
    // const { accStateA, accStateB, accCfgA, accCfgB, orderbookA, orderbookB, activeOrders } = partialFullState
    const nonEmptyState = lodash.pickBy(partialFullState, (x) => x !== undefined && x !== null)
    setCurPageData(nonEmptyState)
  }

  const onLogfileUpdate = createOnLogfileUpdate(setCurPageData, sessionId, logs)

  useEffect(() => {
    const partialFullStateCallbackId = addEventListener(CMD_LONG_SHORT_BROADCAST_PARTIAL_FULL_STATE, onLongShortPartialFullStateUpdate)
    const longShortStartedId = addEventListener(CMD_LONG_SHORT_BROADCAST_STARTED, onLongShortStarted)
    const longShortStoppedId = addEventListener(CMD_LONG_SHORT_BROADCAST_STOPPED, onLongShortStopped)
    const logfileUpdateId = addEventListener(CMD_LOG_UPDATE, onLogfileUpdate)
    return () => {
      removeEventListener(CMD_LONG_SHORT_BROADCAST_PARTIAL_FULL_STATE, partialFullStateCallbackId)
      removeEventListener(CMD_LONG_SHORT_BROADCAST_STARTED, longShortStartedId)
      removeEventListener(CMD_LONG_SHORT_BROADCAST_STOPPED, longShortStoppedId)
      removeEventListener(CMD_LOG_UPDATE, logfileUpdateId)
    }
  })

  const prevIsSuccessful  = useRefFieldsPrevious('isSuccessful', isSuccessful)
  useEffect(() => {
    if ((!prevIsSuccessful) && isSuccessful) {
      const logLines = lodash.get(receivedData, ['data', 'shared', 'logLines'])
      const logs = processLogs(logLines)
      const specific = lodash.get(receivedData, ['data', 'specific'], {})
      const { accStateA, accStateB, accCfgA, accCfgB, orderbookA, orderbookB, paramLongShort={}, activeOrders, note } = specific
      setCurPageData({
        logs,
        paramLongShort,
        accStateA,
        accStateB,
        accCfgA,
        accCfgB,
        orderbookA,
        orderbookB,
        activeOrders,
        note,
      })
    }
  })

  if(loadingView) {
    return <Box className={classes.root}>
      {loadingView}
    </Box>
  }

  return (
    <Grid
      className={clsx(classes.root)}
      container
    >
      <Grid
        item
        className={classes.leftRoot}
        xl={6}
        lg={6}
        sm={12}
        xs={12}
      >

        <Box height="100%"
             width="100%"
             display="flex"
             flexDirection="column"
        >
          <Box className={classes.leftTop}>
            <LongShortBasicInfoView />
          </Box>

          <Box className={classes.leftMid}>
            <LongShortNoteForm
              sessionId={sessionId}
            />
          </Box>
          <Box className={classes.leftMid}>
            <LongShortPositionInfoView
            />
          </Box>

          <Box className={classes.leftMid}>
            <LongShortExtraPositionInfoView
            />
          </Box>

          <Box className={classes.leftBottom} flexGrow={1}>
            <LongShortActiveOrdersView
              sessionId={sessionId}
            />
          </Box>
        </Box>
    </Grid>


    <Grid
      item
      className={classes.rightRoot}
      xl={6}
      lg={6}
      sm={12}
      xs={12}
    >
      <Box
        height="100%"
        width="100%"
        display="flex"
        flexDirection="column"
      >
        <Box className={classes.rightTop}>
          <SpammingForm
            sessionId={sessionId}
          />
        </Box>

        <Box className={classes.rightMid}>
          <LongShortBorrowRepayForm
            sessionId={sessionId}
          />
        </Box>

        <Box className={classes.rightMid}>
          <LongShortPlaceOrderForm
            sessionId={sessionId}
          />
        </Box>

        <Box className={classes.rightBottom} flexGrow={1}>
          <LongShortLoggerView />
        </Box>
      </Box>
    </Grid>
  </Grid>
  )
}

LongShortAlgoView.propTypes = {
  sessionId: PropTypes.number.isRequired,
  algorithm: algorithmPropTypes, // the selected algorithm
  setCurPageData: PropTypes.func.isRequired,
  sessionAccounts: PropTypes.arrayOf(accountPropTypes),
  logs: PropTypes.array.isRequired,
  paramTaker: PropTypes.object,
  clearCurPageData: PropTypes.func.isRequired,
  setToolbarTitle: PropTypes.func.isRequired,
  project: projectPropType.isRequired,
}

LongShortAlgoView.defaultProps = {
  logs: [],
}

const mapStateToProps = (state, ownProps)=> {
  const logs = lodash.get(state, ['single', 'curPageData', 'logs'], [])
  const paramLongShort = lodash.get(state, ['single', 'curPageData', 'paramLongShort'])
  return {
    logs,
    paramLongShort
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return mapDispatch(dispatch)({...singleActions})
}

export default connect(mapStateToProps, mapDispatchToProps)(LongShortAlgoView)

