import React from 'react'
import PropTypes from 'prop-types'

/**
 * Store global values, that may need to shared by vast of childrens
 */
const PiFormContext = React.createContext({})

const PiFormContextProvider = ({ children, disabledAllFields }) => {
  const value = {
    disabledAllFields
  }
  return (
    <PiFormContext.Provider
      value={value}
    >
      {children}
    </PiFormContext.Provider>
  )
}

PiFormContextProvider.propTypes = {
  disabledAllFields: PropTypes.bool.isRequired
}

PiFormContextProvider.defaultProps = {
  disabledAllFields: false
}


export {
  PiFormContextProvider,
  PiFormContext
}
