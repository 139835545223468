import React from 'react'
import PropTypes from 'prop-types'
import { Backdrop, makeStyles } from '@material-ui/core'


const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}))


const PiBackdrop = ({children, open, ...rest}) => {
  const classes = useStyles()
  return (
    <Backdrop
      className={classes.backdrop}
      open={open}
      {...rest}
    >
      {children}
    </Backdrop>
  )
}

PiBackdrop.propTypes = {
  open: PropTypes.bool.isRequired,
}

PiBackdrop.defaultProps = {
  open: false
}

export default PiBackdrop
