import { createSlice, createAction } from '@reduxjs/toolkit'
import lodash from 'lodash'
import {
  ACTION_TYPE_SOCKET_SEND,
  ACTION_TYPE_SOCKET_REQUEST_INFO_UPDATE,
  ACTION_TYPE_RECONNECT_SOCKET,

  MSG_MAX_AGE_MILLISEC,
  MSG_PRUNE_ADDITIONAL_MAX_AGE_MILLISEC,
  MSG_MIN_CACHE_SIZE,
  MSG_PRUNE_ADDITIONAL_CACHE_SIZE
} from '../../constants'
import { getTsMs, isValidNumber } from '../../utils/utils'

// send with payload in format { 'rId': 'abc', 'meta': xxxx, 'data': xxxx }
// the action will be handled in the middleware
const sendMessage = createAction(ACTION_TYPE_SOCKET_SEND)

/**
 * updateSocketMsg will only be called in the socketMiddleware
 * serverPushed, means the message is received from the websocket
 *      Sometimes server may push msg, with his own rId, but it may not be initiated by client side
 * { rId, rStatus, updateAtMs, error, sentData, receivedData, serverPushed }
 */
const updateSocketReqInfo = createAction(ACTION_TYPE_SOCKET_REQUEST_INFO_UPDATE)

const reconnectSocket = createAction(ACTION_TYPE_RECONNECT_SOCKET)


export const socketSlice = createSlice({
  name: 'socket',
  initialState: {
    socketStatus: WebSocket.CONNECTING,
    reconnectCountdown: 0, // count down in second about count down

    /**
     * map from the rId to msg
     * {
     *   'rId': 'abcd',
     *   'rStatus': 'pending',
     *   'updateAtMs': // the updated ms
     *   'error': {
     *      'code': 111,
     *      'msg': 'Error get back'
     *   },
     *   'sentData': {
     *      'rId': 'abcd',
     *      'data': { // the data sent
     *      }
     *   },
     *   'receivedData': {
     *      'rId': 'abcd',
     *      'data': { // the data received
     *
     *      }
     *   }
     * }
     */
    reqInfoMap: {}, // map the rId to request info
    oldestUpdateAtMs: undefined, // the smallest updateAtMs for all the reqInfoMap
    reqInfoSize: 0
  },
  reducers: {
    updateSocketStatus: (state, action) => {
      const { socketStatus } = action.payload
      if (socketStatus !== WebSocket.OPEN &&
        socketStatus !== WebSocket.CLOSED &&
        socketStatus !== WebSocket.CLOSING &&
        socketStatus !== WebSocket.CONNECTING) {
        console.log(`socket Status is invalid: ${socketStatus}`)
        return
      }
      state.socketStatus = socketStatus
    },
    updateReconnectCountdown: (state, action) => {
      const { reconnectCountdown } = action.payload
      if (isValidNumber(reconnectCountdown)) {
        state.reconnectCountdown = reconnectCountdown
      }
    },
    decreaseReconnectCountdown: (state) => {
      state.reconnectCountdown = state.reconnectCountdown - 1
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateSocketReqInfo, (state, action) => {
        // action is inferred correctly here if using TS
        const payloadKeys = [
          'rId', 'rStatus', 'err', 'sentData', 'receivedData'
        ]
        const { rId, serverPushed=false } = action.payload
        if (!rId) {
          return
        }
        const curTsMs = getTsMs()
        let reqInfo = lodash.get(state.reqInfoMap, rId, undefined)
        if (reqInfo === undefined) {
          if (serverPushed) {
            return
          } else {
            reqInfo = {}
          }
        }
        reqInfo['updateAtMs'] = curTsMs
        payloadKeys.forEach((key) => {
          const keyVal = lodash.get(action.payload, key)
          if(keyVal !== undefined) {
            reqInfo[key] = keyVal
          }
        })

        const addReqInfoIntoMap = (inputRid, inputReqInfo, inputTsMs) => {
          if (lodash.get(state.reqInfoMap, inputRid, undefined) === undefined) {
            state.reqInfoSize = state.reqInfoSize + 1
          }
          state.reqInfoMap[inputRid] = inputReqInfo
          if(state.oldestUpdateAtMs === undefined || state.oldestUpdateAtMs > inputTsMs) {
            state.oldestUpdateAtMs = inputTsMs
          }
        }

        addReqInfoIntoMap(rId, reqInfo, curTsMs)
        // const printReqInfoMap = (reqMap) => {
        //   lodash.forEach(reqMap, (req, rId) => {
        //     console.log(`id ${rId}, Req info ${req.rId}, ms: ${req.updateAtMs}`)
        //   })
        // }
        //
        // const printReqLst = (reqLst) => {
        //   lodash.forEach((reqLst, req) => {
        //     console.log(`Req info ${req.rId}, ms: ${req.updateAtMs}`)
        //   })
        // }
        /**
         * Check if need to prune, We will prune only if both of 2 condition meet
         * 1) oldestUpdateAtMs is too old
         * 2) reqInfoSize is larger than the max cache size
         */
        if(state.oldestUpdateAtMs < curTsMs - MSG_MAX_AGE_MILLISEC - MSG_PRUNE_ADDITIONAL_MAX_AGE_MILLISEC && state.reqInfoSize > MSG_MIN_CACHE_SIZE + MSG_PRUNE_ADDITIONAL_CACHE_SIZE) {
          console.log(`Need prune oldest update at ms${state.oldestUpdateAtMs}, cur ts: ${curTsMs}, req size: ${state.reqInfoSize}`)
          const expireMs = curTsMs - MSG_MAX_AGE_MILLISEC
          let newOldestUpdateAtMs = undefined
          const newReqInfoMap = {}
          let newReqInfoSize = 0

          let allReqInfoLst = []
          lodash.forEach(state.reqInfoMap, (reqInfo, rId) => {
            const updateAtMs = lodash.get(reqInfo, 'updateAtMs')
            if(isValidNumber(updateAtMs)) {
              if(updateAtMs > expireMs) {
                if(newOldestUpdateAtMs === undefined || newOldestUpdateAtMs > updateAtMs) {
                  newOldestUpdateAtMs = updateAtMs
                }
                newReqInfoMap[rId] = reqInfo
                newReqInfoSize = newReqInfoSize + 1
              }
              allReqInfoLst.push(reqInfo)
            }
          })
          state.oldestUpdateAtMs = newOldestUpdateAtMs
          state.reqInfoMap = newReqInfoMap
          state.reqInfoSize = newReqInfoSize

          // we need to at least get a minimum cache size
          if(state.reqInfoSize < MSG_MIN_CACHE_SIZE) {
            allReqInfoLst = lodash.orderBy(allReqInfoLst, 'updateAtMs', 'desc')
            allReqInfoLst = allReqInfoLst.slice(0, MSG_MIN_CACHE_SIZE)
            state.oldestUpdateAtMs= lodash.min([allReqInfoLst[allReqInfoLst.length - 1].updateAtMs, state.oldestUpdateAtMs])
            allReqInfoLst.forEach((reqInfo) => {
              addReqInfoIntoMap(reqInfo.rId, reqInfo, reqInfo.updateAtMs)
            })
          }
        }
      })
  },
})

const { updateSocketStatus, updateReconnectCountdown, decreaseReconnectCountdown } = socketSlice.actions

const actions = {
  sendMessage,
  updateSocketStatus,
  updateReconnectCountdown,
  decreaseReconnectCountdown,
  reconnectSocket
}

export {
  actions,
  sendMessage,
  updateSocketStatus,
  updateReconnectCountdown,
  decreaseReconnectCountdown,
  reconnectSocket
}

export default socketSlice.reducer
