import React, { useCallback } from 'react'
import { baseQuote, getQtyInQuotePrec, isValidNumber } from '../../../utils/utils'
import { BUY_SELL_OPTIONS, SIDE_BUY, SIDE_SELL } from '../../../protocolConstants'
import logger from '../../../utils/logger'
import lodash from 'lodash'
import { Box, Card, Grid } from '@material-ui/core'
import PiCardContent from '../../../components/PiCardContent'
import { Field } from 'formik'
import FormInput from '../../../components/form/FormInput'
import PiInputAdornment from '../../../components/PiInputAdornment'
import FormRadio from '../../../components/form/FormRadio'
import ColorButton from '../../../components/ColorButton'
import FormSelect from '../../../components/form/FormSelect'
import PropTypes from "prop-types"
import {
  EMPTY_ACCOUNT_NAME_KEY,
  EMPTY_PAIR_NAME_KEY
} from "./utils"

const DefaultGridItem = ({children}) => {
  return (
    <Grid
      item
      xl={6}
      lg={6}
      sm={6}
      xs={6}
    >
      {children}
    </Grid>
  )
}

const PlaceOrderFormInner = ({isSending, formProps, isEmptyAccName, isEmptyPair, accNameOptions, accNamePairOptions, accNamePairPairInfos}) => {
  const {values, handleSubmit, setFieldValue} = formProps
  const {buySell, accName, pair} = values
  let pairOptions = []
  if (!lodash.isEmpty(accName)) {
    const foundPairOptionsObj = lodash.find(accNamePairOptions, (pairOp) => {
      const { accName: curAccName } = pairOp
      return curAccName === accName
    }, null)
    if(!lodash.isEmpty(foundPairOptionsObj)) {
      const { pairOptions: foundPairOptions } = foundPairOptionsObj
      pairOptions = foundPairOptions
    }
  }

  let pairInfo = null
  if (isEmptyAccName && isEmptyPair) {
    pairInfo = lodash.get(accNamePairPairInfos, [EMPTY_ACCOUNT_NAME_KEY, EMPTY_PAIR_NAME_KEY], null)
  } else {
    pairInfo = lodash.get(accNamePairPairInfos, [accName, pair], null)
  }

  let pxPrec, qtyPrec, qtyInQuotePrec = null
  if (pairInfo) {
    const { qtyPrec: qtyPrecI, pxPrec: pxPrecI } = pairInfo
    pxPrec = pxPrecI
    qtyPrec = qtyPrecI
    qtyInQuotePrec = getQtyInQuotePrec(qtyPrec, pxPrec)
  }

  let base, quote = ''
  if (pair) {
    [base, quote] = baseQuote(pair)
  }

  let buttonLabel = 'Place Now'
  let colorStyle = ''
  if (buySell === SIDE_BUY) {
    colorStyle = 'green'
    buttonLabel = 'Buy Now'
  } else if (buySell === SIDE_SELL) {
    colorStyle = 'red'
    buttonLabel = 'Sell Now'
  }

  // either px qty, qtyInQuote changed, we will calculate the other one
  const postChangeField = useCallback((fieldName, fieldNewValue) => {
    // may need to calculate accordingly
    const newValues = {
      ...values,
      [fieldName]: fieldNewValue
    }
    let {px, qty, qtyInQuote} = newValues
    switch (fieldName) {
      case 'px':
      case 'qty':
        px = parseFloat(px)
        qty = parseFloat(qty)
        if (isValidNumber(px) && isValidNumber(qty)) {
          const newQtyInQuote = px * qty
          logger.debug(`new qty in quote set ${newQtyInQuote}`)
          setFieldValue('qtyInQuote', newQtyInQuote)
        }
        break
      case 'qtyInQuote':
        px = parseFloat(px)
        qtyInQuote = parseFloat(qtyInQuote)
        if (isValidNumber(qtyInQuote) && isValidNumber(px) && px !== 0) {
          const newQty = qtyInQuote / px
          logger.debug(`new qty in ${newQty}`)
          setFieldValue('qty', newQty)
        }
        break
      default:
        logger.error(`Unrecognized field name ${fieldName}`)
    }
  }, [values, setFieldValue])

  return (
    <form onSubmit={handleSubmit}>
      <Box>
        <Card>
          <PiCardContent>
            <Box mx={2}>
              <Grid container spacing={3}>
                {
                  isEmptyAccName ?
                    null :
                    <DefaultGridItem>
                      <Field
                        name="accName"
                        label="Acc Name"
                        options={accNameOptions}
                        component={FormSelect}
                      />
                    </DefaultGridItem>
                }

                {
                  isEmptyPair ?
                    null :
                    <DefaultGridItem>
                      <Field
                        name="pair"
                        label="Pair"
                        options={pairOptions}
                        component={FormSelect}
                      />
                    </DefaultGridItem>
                }
                <DefaultGridItem>
                  <Field
                    name="px"
                    label="Price"
                    prec={pxPrec}
                    postChange={postChangeField}
                    inputProps={{
                      type: 'number',
                      style: {
                        textAlign: 'right'
                      }
                    }}
                    component={FormInput}
                  />
                </DefaultGridItem>

                <DefaultGridItem>
                  <Field
                    name="qty"
                    label="Quantity"
                    prec={qtyPrec}
                    postChange={postChangeField}
                    InputProps={{
                      endAdornment: <PiInputAdornment position="end" label={base}/>,
                    }}
                    inputProps={{
                      type: 'number',
                      style: {
                        textAlign: 'right'
                      }
                    }}
                    component={FormInput}
                  />
                </DefaultGridItem>

                <DefaultGridItem>
                  <Field
                    name="buySell"
                    label=""
                    options={BUY_SELL_OPTIONS}
                    component={FormRadio}
                  />
                </DefaultGridItem>

                <DefaultGridItem>
                  <Field
                    name="qtyInQuote"
                    label="Total"
                    prec={qtyInQuotePrec}
                    InputProps={{
                      endAdornment: <PiInputAdornment position="end" label={quote}/>,
                    }}
                    postChange={postChangeField}
                    inputProps={{
                      type: 'number',
                      style: {
                        textAlign: 'right'
                      }
                    }}
                    component={FormInput}
                  />
                </DefaultGridItem>

                <DefaultGridItem>
                  <ColorButton
                    fullWidth
                    size="small"
                    disabled={isSending}
                    type="submit"
                    colorStyle={colorStyle}>
                    {buttonLabel}
                  </ColorButton>
                </DefaultGridItem>
              </Grid>
            </Box>
          </PiCardContent>
        </Card>
      </Box>
    </form>
  )
}

PlaceOrderFormInner.propTypes = {
  // config
  isEmptyPair: PropTypes.bool.isRequired,
  isEmptyAccName: PropTypes.bool.isRequired,
}

PlaceOrderFormInner.defaultProps = {
  isEmptyPair: false,
  isEmptyAccName: false,
}


export {
  PlaceOrderFormInner
}
