import React from 'react'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { Box, makeStyles } from '@material-ui/core'
import { mapDispatch } from '../../../utils/utils'
import { actions as socketActions } from '../../socket/socketSlice'
import { actions as singleActions } from '../../single/singleSlice'
import connect from 'react-redux/es/connect/connect'
import PropTypes from 'prop-types'
import { useStateReq } from '../../../utils/hooks'
import { SIDE_BUY } from '../../../protocolConstants'
import { optionArrayPropType } from '../../../utils/propTypesUtils'
import { PlaceOrderFormInner } from './place_order_form_inner'
import {
  accNamePairOptionArrayPropTypes,
  accNamePairPairInfoPropType
} from './utils'

const useStyles = makeStyles((theme) => ({
  formBox: {
  },
  bidAskButton: {
    color: "#000000",
    height: 'auto',
  }
}))

const PlaceOrderForm = ({ sessionId, placeOrderCmd, accNameOptions, accNamePairOptions,
                          accNamePairPairInfos, isEmptyPair, isEmptyAccName }) => {
  const classes = useStyles()
  const {
    sendReq,
    isSending
  } = useStateReq()
  const onNewSession = (values) => {
    sendReq({
      cmd: placeOrderCmd,
      isRelay: true,
      data: {
        sessionId,
        params: {
          ...values
        }
      }
    })
  }
  const schema = {
    qty: Yup.number().required('Qty is required').moreThan(0, "Must be positive"),
    px: Yup.number().required('Price is required').moreThan(0, "Must be positive"),
    buySell: Yup.string().required('Buy sell is required'),
  }
  if (!isEmptyAccName) {
    schema['accName'] = Yup.string().required('AccName is required')
  }
  if (!isEmptyPair) {
    schema['pair'] = Yup.string().required('Pair is required')
  }

  return (
    <Box
      className={classes.formBox}
      display="flex"
      flexDirection="column"
      justifyContent="center"
    >
      <Formik
        initialValues={{
          "buySell": SIDE_BUY
        }}
        validationSchema={Yup.object().shape(schema)}
        onSubmit={onNewSession}
      >
        {(formProps) => {
          return (
            <PlaceOrderFormInner
              isSending={isSending}
              formProps={formProps}
              accNameOptions={accNameOptions}
              accNamePairOptions={accNamePairOptions}
              accNamePairPairInfos={accNamePairPairInfos}
              isEmptyAccName={isEmptyAccName}
              isEmptyPair={isEmptyPair}
            />
          )
        }}
      </Formik>
    </Box>
  )
}


PlaceOrderForm.propTypes = {
  sessionId: PropTypes.number.isRequired,
  placeOrderCmd: PropTypes.string.isRequired,

  accNameOptions: optionArrayPropType,
  accNamePairOptions: accNamePairOptionArrayPropTypes,
  accNamePairPairInfos: accNamePairPairInfoPropType,

  // config
  isEmptyPair: PropTypes.bool.isRequired,
  isEmptyAccName: PropTypes.bool.isRequired,
}

PlaceOrderForm.defaultProps = {
  isEmptyPair: false,
  isEmptyAccName: false,
}

const mapStateToProps = (state, ownProps)=> {
  return {
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return mapDispatch(dispatch)({...socketActions, ...singleActions})
}

export default connect(mapStateToProps, mapDispatchToProps)(PlaceOrderForm)

