import React, { useContext } from 'react'
import { makeStyles, TableCell } from '@material-ui/core'
import { PiContext } from '../utils/piContext'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  piTableCellMobile: {
    padding: theme.spacing(1)
  }
}))

/**
 * Auto adjustable table cell (according to mobile ui)
 * disablePiStyle : if True, it will act like normal cell, and do not adjust size if it is mobile
 */
const PiTableCell = ({children, disablePiStyle= false, className, ...rest}) => {
  const piContext = useContext(PiContext)
  const classes = useStyles()
  const { isMobile } = piContext
  if (!disablePiStyle) {
    if (isMobile) {
      className = clsx(className, classes.piTableCellMobile)
    }
  }
  return (
    <TableCell
      size="small"
      className={className}
      {...rest}
    >
      {children}
    </TableCell>
  )
}

export default PiTableCell
