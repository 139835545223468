import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  rowGrid: {
    paddingRight: theme.spacing(1) / 2,
    paddingLeft: theme.spacing(1) / 2
  }
}))

const PiGridItem = ({children, className, ...rest}) => {
  const classes = useStyles()
  className = clsx(className, classes.rowGrid)
  return (
    <Grid
      item
      className={className}
      {...rest}
    >
      {children}
    </Grid>
  )
}

export default PiGridItem
