import React from 'react'
import { ThemeProvider } from '@material-ui/core'
import { smallTheme } from './theme'


const SmallThemeProvider = ({children}) => {
  return (
      <ThemeProvider theme={smallTheme}>
        {children}
      </ThemeProvider>
  )
}

export default SmallThemeProvider
