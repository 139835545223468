import React, { useCallback, useContext, useEffect } from 'react'
import {
  KeyboardDatePicker,
} from '@material-ui/pickers'
import PropTypes from 'prop-types'
import lodash from 'lodash'
import { createFieldPropTypes} from '../../utils/propTypesUtils'
import { formPropTypes, metaPropTypes } from '../../utils/propTypesUtils'
import clsx from 'clsx'
import { FORMAT_YMD } from '../../utils/timeUtils'
import { PiFormContext } from '../../utils/piFormContext'
import logger from '../../utils/logger'


const FormDatePicker = ({ dayjsToVal, valToDayjs, defaultEmptyValue, className, field, label, placeholder, inputProps, InputProps, options, form, disabled, ...rest}) => {
  const { disabledAllFields } = useContext(PiFormContext)
  let { value = '', name } = field
  const { touched, errors, submitCount, setFieldValue } = form
  /**
   * the reason to check submit count is that, if field is not defined in the initValues,
   * the touched field may not set to true if we press submit button
   * */
  const fieldTouched = Boolean(lodash.get(touched, name) || submitCount > 0)
  const errMsg = lodash.get(errors, name, '')
  const error = Boolean(fieldTouched && errMsg)
  const helperText = fieldTouched && errMsg

  useEffect(() => {
    if (!value && defaultEmptyValue > 0) {
      logger.debug(`set using default empty value of date picker name: ${name} val: ${defaultEmptyValue}`)
      setFieldValue(name, defaultEmptyValue)
    }
  }, [value, defaultEmptyValue, setFieldValue, name])

  const handleOnChange = useCallback((dj) => {
    // the return is a dayjs object
    const val = dayjsToVal(dj)
    setFieldValue(name, val)
  }, [dayjsToVal, name, setFieldValue])

  let dt
  if (value) {
    dt = valToDayjs(value)
  } else {
    dt = null
  }
  return (
    <KeyboardDatePicker
      className={clsx(className)}
      fullWidth
      disableToolbar
      variant="inline"
      format={FORMAT_YMD}
      onChange={handleOnChange}
      KeyboardButtonProps={{
        'aria-label': 'change date',
      }}
      label={label}
      value={dt}
      margin='dense'
      error={error}
      name={name}
      size='small'
      helperText={helperText}
      disabled={disabled || disabledAllFields}
      {...rest}/>
  )
}

FormDatePicker.propTypes = {
  dayjsToVal: PropTypes.func.isRequired,
  valToDayjs: PropTypes.func.isRequired,
  field: createFieldPropTypes(),
  form: formPropTypes,
  meta: metaPropTypes,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  // if defaultEmptyValue set larger than 0,
  // if current value is empty, system will set the current value to defaultEmptyValue
  defaultEmptyValue: PropTypes.number.isRequired,
}

FormDatePicker.defaultProps = {
  label: '',
  defaultEmptyValue: 0,
}

export default FormDatePicker
