import React, { useContext } from 'react'
import { FormControl, FormHelperText, TextField } from '@material-ui/core'
import PropTypes from 'prop-types'
import lodash from 'lodash'
import { createFieldPropTypes} from '../../utils/propTypesUtils'
import { formPropTypes, metaPropTypes } from '../../utils/propTypesUtils'
import { PiFormContext } from '../../utils/piFormContext'
import Autocomplete from '@material-ui/lab/Autocomplete'

const FormSelect = ({ field, label, options, postChange, placeholder, form, disabled, autoCompleteRest, ...rest}) => {
  const { disabledAllFields } = useContext(PiFormContext)
  let { value, name } = field
  const { touched, errors, submitCount, setFieldValue } = form
  const emptyValue = null
  if(!value) {
    value = emptyValue
  }
  const handleOnChange = (event, newValues) => {
    if (newValues !== undefined && newValues !== null) {
      setFieldValue(name, newValues)
    } else {
      setFieldValue(name, emptyValue)
    }
    if (lodash.isFunction(postChange)) {
      postChange(name, newValues)
    }
  }

  const valueLabelMap = {}
  options.forEach((opt) => {
    const { value, label } = opt
    valueLabelMap[value] = label
  })

  const getOptionLabel = (valueOption) => {
    return lodash.get(valueLabelMap, valueOption, '')
  }

  /**
   * the reason to check submit count is that, if field is not defined in the initValues,
   * the touched field may not set to true if we press submit button
   * */
  const fieldTouched = Boolean(lodash.get(touched, name) || submitCount > 0)
  const errMsg = lodash.get(errors, name, '')
  const error = Boolean(fieldTouched && errMsg)
  const helperText = fieldTouched && errMsg

  const valueOptions = lodash.map(options, (op) => {
    const { value } = op
    return value
  })

  const displayValue = value
  return (
    <FormControl fullWidth error={error} {...rest}>
      <Autocomplete
        multiple={false}
        getOptionLabel={getOptionLabel}
        disabled={disabled || disabledAllFields}
        value={displayValue}
        onChange={handleOnChange}
        options={valueOptions}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              error={error}
              label={label}
              placeholder={placeholder}
            />
          )
        }}
        {...autoCompleteRest}
      />
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  )
}

FormSelect.propTypes = {
  field: createFieldPropTypes(),
  form: formPropTypes,
  meta: metaPropTypes,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
    label: PropTypes.string.isRequired
  })),
  postChange: PropTypes.func,
  placeholder: PropTypes.string,
  autoCompleteRest: PropTypes.object,
}

FormSelect.defaultProps = {
  label: '',
  options: [],
  autoCompleteRest: {}
}

export default FormSelect
