import React, { useCallback } from 'react'
import OrderbookView from '../../../ui/OrderbookView'
import { pairInfoPropTypes } from '../../../../utils/propTypesUtils'
import PropTypes from 'prop-types'
import lodash from 'lodash'
import { mapDispatch } from '../../../../utils/utils'
import connect from 'react-redux/es/connect/connect'
import { actions as singleActions } from '../../../single/singleSlice'


const TakerMakerOrderbook = ({ pairInfo, orderbook, setCurPageData }) => {
  const onRowClick = useCallback((item) => {
    const px = item['px']
    setCurPageData({
      placeOrderFormUpdatingData: {
        px: px
      }
    })
  }, [setCurPageData])
  if (lodash.isEmpty(pairInfo)) {
    return <div/>
  }
  const { pxPrec } = pairInfo
  return (
    <OrderbookView
      pxPrec={pxPrec}
      orderbook={orderbook}
      onRowClick={onRowClick}
    />
  )
}

TakerMakerOrderbook.propTypes = {
  pairInfo: pairInfoPropTypes,
  orderbook: PropTypes.object,
  setCurPageData: PropTypes.func.isRequired,
}

TakerMakerOrderbook.defaultProps = {

}

const mapStateToProps = (state, ownProps)=> {
  const pairInfo = lodash.get(state, ['single', 'curPageData', 'pairInfo'])
  const orderbook = lodash.get(state, ['single', 'curPageData', 'orderbook'])
  return {
    pairInfo,
    orderbook
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return mapDispatch(dispatch)({...singleActions})
}

export default connect(mapStateToProps, mapDispatchToProps)(TakerMakerOrderbook)
