import React from 'react'
import ConfirmDialog from '../../../../../components/ConfirmDialog'
import {
  CMD_DEX_TAKER_APPROVE_SPENDING,
  CMD_DEX_TAKER_EXECUTE_TAKER,
  CMD_DEX_TAKER_STOP_TAKER,
  SIDE_BUY,
  SIDE_SELL
} from '../../../../../protocolConstants'
import ColorButton from '../../../../../components/ColorButton'
import PropTypes from 'prop-types'
import { dexTakerDialogPropType } from '../../../../../utils/propTypesUtils'
import lodash from 'lodash'
import { mapDispatch } from '../../../../../utils/utils'
import { actions as singleActions } from '../../../../single/singleSlice'
import connect from 'react-redux/es/connect/connect'
import DexTakerBasicInfoDetailView from '../DexTakerBasicInfoDetailView'
import DialogContent from './DexTakerPreviewDialogContent'
import DexTakerSpendingDetailContent from './DexTakerSpendingDetailContent'


const DexTakerAlgoFormDialog = ({dialogData, setCurPageData, sendReq, isSending, sessionId, spendingInfos, generalInfo}) => {
  if (!dialogData) {
    return null
  }
  const { dialogOpen, dialogType, previewData, previewSentData} = dialogData
  const hideDialog = () => {
    setCurPageData({
      dialogData: {
        ...dialogData,
        dialogOpen: false,
      }
    })
  }

  if (dialogType === 'executeDexTaker') {
    const executeTaker = () => {
      sendReq({
        cmd: CMD_DEX_TAKER_EXECUTE_TAKER,
        isRelay: true,
        data: lodash.get(previewSentData, 'data')
      })
      hideDialog()
    }
    const { buySell } = previewData
    let confirmButton
    if (buySell === SIDE_BUY) {
      confirmButton = <ColorButton onClick={executeTaker} colorStyle='green'>
        Taker Buy
      </ColorButton>
    } else if (buySell === SIDE_SELL) {
      confirmButton = <ColorButton onClick={executeTaker} colorStyle='red'>
        Taker Sell
      </ColorButton>
    } else {
      // something wrong, hide the button
      confirmButton = null
    }
    return (
      <ConfirmDialog
        dialogOpen={dialogOpen}
        dialogTitle='Are you sure you want to execute dex taker algo?'
        dialogContent={<DialogContent previewData={previewData}/>}
        dialogCancelText='Dismiss'
        dialogConfirmButton={confirmButton}
        dialogOnCancel={hideDialog}
      />
    )
  } else if (dialogType === 'stopDexTaker') {
    const stopTaker = () => {
      sendReq({
        cmd: CMD_DEX_TAKER_STOP_TAKER,
        isRelay: true,
        data: {
          sessionId
        }
      })
      hideDialog()
    }
    return (
      <ConfirmDialog
        dialogOpen={dialogOpen}
        dialogTitle='Are you sure you want to stop dex taker algo?'
        dialogContent=''
        dialogCancelText='Dismiss'
        dialogConfirmButton='Stop now'
        dialogOnCancel={hideDialog}
        dialogOnConfirm={stopTaker}
      />
    )
  } else if (dialogType === 'showBasicInfoDetail') {
    return (
      <ConfirmDialog
        maxWidth='lg'
        fullWidth={true}
        dialogOpen={dialogOpen}
        dialogTitle="Basic Info Details"
        dialogContent={<DexTakerBasicInfoDetailView/>}
        dialogConfirmButton='Dismiss'
        dialogOnConfirm={hideDialog}
      />
    )
  } else if (dialogType === 'showSpendingDetail') {
    const approveSpending = (tokenAddr, accName) => {
      sendReq({
        cmd: CMD_DEX_TAKER_APPROVE_SPENDING,
        isRelay: true,
        data: {
          sessionId,
          params: {
            tokenAddr,
            approveAll: false,
            accNames: [accName]
          }
        }
      })
    }
    return (
      <ConfirmDialog
        maxWidth='lg'
        fullWidth={true}
        dialogOpen={dialogOpen}
        dialogTitle='Spending Details'
        dialogContent={<DexTakerSpendingDetailContent
          spendingInfos={spendingInfos}
          isSending={isSending}
          approveSpending={approveSpending}
          generalInfo={generalInfo}
        />}
        dialogConfirmButton='Dismiss'
        dialogOnConfirm={hideDialog}
      />
    )
  } else {
    return null
  }
}


DexTakerAlgoFormDialog.propTypes = {
  dialogData: dexTakerDialogPropType,
  setCurPageData: PropTypes.func.isRequired,
  sendReq: PropTypes.func.isRequired,
  isSending: PropTypes.bool.isRequired,
  sessionId: PropTypes.number.isRequired,
  spendingInfos: PropTypes.object,
  generalInfo: PropTypes.shape({
    accInfos: PropTypes.arrayOf(PropTypes.shape({
      accAddr: PropTypes.string.isRequired,
      accName: PropTypes.string.isRequired,
    }))
  }),
}

DexTakerAlgoFormDialog.defaultProps= {
  spendingInfos: {}
}

const mapStateToProps = (state, ownProps)=> {
  const dialogData = lodash.get(state, ['single', 'curPageData', 'dialogData'])
  const spendingInfos = lodash.get(state, ['single', 'curPageData', 'spendingInfos'], {})
  const generalInfo = lodash.get(state, ['single', 'curPageData', 'generalInfo'])
  return {
    dialogData,
    spendingInfos,
    generalInfo
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return mapDispatch(dispatch)(singleActions)
}

export default connect(mapStateToProps, mapDispatchToProps)(DexTakerAlgoFormDialog)
