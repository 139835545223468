import React, { useContext } from 'react'
import {
  FormControl,
  TextField,
  FormHelperText,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import { createFieldPropTypes} from '../../utils/propTypesUtils'
import { formPropTypes, metaPropTypes } from '../../utils/propTypesUtils'
import lodash from 'lodash'
import { PiFormContext } from '../../utils/piFormContext'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'
const filter = createFilterOptions()

/**
 * 1) Able to allow user input text
 * 2) Able to allow user to select from the dropdown
 */
const FormInputSelect = ({ field, label, options, placeholder, form, disabled, ...rest}) => {
  const { disabledAllFields } = useContext(PiFormContext)
  let { value, name } = field
  const { touched, errors, submitCount, setFieldValue } = form
  if(!value) {
    value = ''
  }

  const handleOnChange = (event, newValue) => {
    setFieldValue(name, newValue)
  }

  const filterOptions =(options, params) => {
    const filtered = filter(options, params)
    // Suggest the creation of a new value
    if (params.inputValue !== '') {
      filtered.push(params.inputValue)
    }
    return filtered
  }

  const valueLabelMap = {}
  options.forEach((opt) => {
    const { value, label } = opt
    valueLabelMap[value] = label
  })

  const getOptionLabel = (val) => {
    const label = lodash.get(valueLabelMap, val, '')
    if(label === ''){
      if(val === '') {
        return ''
      } else {
        return `Choose <${val}>`
      }
    } else {
      return label
    }
  }
  /**
   * the reason to check submit count is that, if field is not defined in the initValues,
   * the touched field may not set to true if we press submit button
   * */
  const fieldTouched = Boolean(lodash.get(touched, name) || submitCount > 0)
  const errMsg = lodash.get(errors, name, '')
  const error = Boolean(fieldTouched && errMsg)
  const helperText = fieldTouched && errMsg

  const valueOptions = lodash.map(options, (op) => {
    const { value } = op
    return value
  })
  return (
    <FormControl fullWidth error={error} {...rest}>
      <Autocomplete
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        getOptionLabel={getOptionLabel}
        filterOptions={filterOptions}
        disabled={disabled || disabledAllFields}
        value={value}
        onChange={handleOnChange}
        options={valueOptions}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              error={error}
              label={label}
              placeholder={placeholder}
            />
          )
        }}
      />
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  )
}

FormInputSelect.propTypes = {
  field: createFieldPropTypes({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ])
  }),
  form: formPropTypes,
  meta: metaPropTypes,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
    label: PropTypes.string.isRequired
  })),
}

FormInputSelect.defaultProps = {
  label: '',
  options: [],
}

export default FormInputSelect
