
class PanicError extends Error {
  constructor(message) {
    super(message) // (1)
    this.name = 'PanicError' // (2)
  }
}

class ApiError extends Error {
  constructor(message) {
    super(message)
    this.name = 'ApiError'
  }
}

export {
  PanicError,
  ApiError
}
