import lodash from 'lodash'
import React, { Component } from 'react'
import { withStyles, Snackbar, Button, Backdrop, CircularProgress } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { connect } from 'react-redux'
import { actions as singleActions } from '../features/single/singleSlice'
import { actions as socketActions } from '../features/socket/socketSlice'
import { mapDispatch } from '../utils/utils'
import { ALERT_WARNING, ALERT_SUCCESS, ALTER_ERROR } from '../constants'
import PropTypes from 'prop-types'


const withStyle = withStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}))


class ToastAlertView extends Component {
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { socketStatus: preSocketStatus } = prevProps
    const { socketStatus, showToast } = this.props
    if(preSocketStatus !== socketStatus && socketStatus === WebSocket.OPEN) {
      showToast('Socket connected.', 'success')
    }
  }

  render() {
    const {
      classes,
      msgOpen,
      msgType,
      msg,
      socketStatus,
      socketReconnectCountdown,
      hideToastSync,
      reconnectSocket
    } = this.props

    const onReconnect = () => {
      reconnectSocket()
    }

    const alertOnClose = () => {
      hideToastSync()
    }

    const msgOnClose = () => {

    }


    let showSocketAlert = false
    let showAction = false
    let socketMsg = ''
    let alertSeverity = ALERT_WARNING
    switch (socketStatus) {
      case WebSocket.OPEN:
        showSocketAlert = false
        showAction = false
        socketMsg = 'Socket is connected.'
        alertSeverity = ALERT_SUCCESS
        break
      case WebSocket.CONNECTING:
        showSocketAlert = true
        showAction = false
        socketMsg = 'Socket is connecting.'
        alertSeverity = ALERT_WARNING
        break
      case WebSocket.CLOSING:
        showSocketAlert = true
        showAction = false
        socketMsg = 'Socket is closing.'
        alertSeverity = ALERT_WARNING
        break
      case WebSocket.CLOSED:
      default:
        showSocketAlert = true
        showAction = true
        if(socketReconnectCountdown <= 0 ) {
          socketMsg = `Socket is closed.`
        } else {
          socketMsg = `Socket is closed. Connecting in ${socketReconnectCountdown} sec`
        }
        alertSeverity = ALTER_ERROR
        break
    }

    return (
      <div>
        <Snackbar
          key='gen_info'
          open={msgOpen}
          onClose={msgOnClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <Alert onClose={alertOnClose} severity={msgType}>
            {msg}
          </Alert>
        </Snackbar>
        <Snackbar
          key='socket_info'
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={showSocketAlert}
        >
          <Alert
            severity={alertSeverity}
            action={
              showAction
                ?
                <Button size="small" onClick={onReconnect}>
                  Try now
                </Button>
                : null
            }
          >
            {socketMsg}
          </Alert>
        </Snackbar>

        <Backdrop className={classes.backdrop} open={false}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    )
  }
}


ToastAlertView.propTypes = {
  msgOpen: PropTypes.bool,
  msgType: PropTypes.string,
  msg: PropTypes.string,
  socketStatus: PropTypes.number.isRequired,
  socketReconnectCountdown: PropTypes.number.isRequired,
  sendMessage: PropTypes.func,
  reconnectSocket: PropTypes.func,
  showToast: PropTypes.func.isRequired,
  hideToastSync: PropTypes.func.isRequired
}

const mapStateToProps = (state, ownProps)=> {
  const displayingMsg = lodash.get(state, ['single', 'displayingMsg'], {})
  const socketStatus = lodash.get(state, ['socket', 'socketStatus'])
  const socketReconnectCountdown = lodash.get(state, ['socket', 'reconnectCountdown'])
  const { msgOpen, msgType, msg } = displayingMsg
  return {
    msgOpen,
    msgType,
    msg,
    socketStatus,
    socketReconnectCountdown
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return mapDispatch(dispatch)({...singleActions, ...socketActions})
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyle(ToastAlertView))
