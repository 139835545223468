import React from 'react'
import {
  Box,
  makeStyles,
} from '@material-ui/core'
import { mapDispatch } from '../../../../utils/utils'
import { actions as socketActions } from '../../../socket/socketSlice'
import { actions as singleActions } from '../../../single/singleSlice'
import connect from 'react-redux/es/connect/connect'
import PropTypes from 'prop-types'
import lodash from 'lodash'
import {
  ACCOUNT_CAT_MARGIN, ACCOUNT_CAT_PORTFOLIO,
  CMD_LONG_SHORT_BORROW_REPAY,
} from '../../../../protocolConstants'
import { accStatePropTypes } from './longShortUtils'
import BorrowRepayForm from '../../../units/borrow_repay'
import logger from '../../../../utils/logger'


const useStyles = makeStyles((theme) => ({
  formBox: {
  },
}))

const LongShortBorrowRepayForm = ({ sessionId, accStateA, accStateB }) => {
  const classes = useStyles()

  if (!accStateA || !accStateB) {
    return null
  }

  const accStateLst = [
    accStateA,
    accStateB
  ]
  const accNameToLabel = {} // map from accName to label display, usually it's accName too
  // in case acc A and acc B is the same account, and remove duplicates
  const accNameToAssetToBool = {} // map from accName to borrowable asset to bool
  const accNameAssetBorrowRepayLmt = {}
  for (let i = 0; i < accStateLst.length; i ++) {
    const accState = accStateLst[i]
    const { accCat, accName, borrowableAssets = [], marginState ={}, portfolioState = {} } = accState
    if (accCat === ACCOUNT_CAT_MARGIN) {
      // only support cross margin for now
      const crossMarginAssetInfos = lodash.get(marginState, 'crossMarginAssetInfos', {})
      accNameToLabel[accName] = accName
      lodash.forEach(borrowableAssets, (ass) => {
        if (ass === '') {
          logger.error(`borrowableAssets has empty asset: ${ass}`)
          return
        }
        lodash.set(accNameToAssetToBool, [accName, ass], true)
      })
      lodash.forEach(crossMarginAssetInfos, (assetInfo = {}, ass) => {
        const { free, borWtInt, borLmt } = assetInfo
        lodash.set(accNameAssetBorrowRepayLmt, [accName, ass], {
          borrowLmt: parseFloat(borLmt),
          repayLmt: lodash.min([parseFloat(free), parseFloat(borWtInt)])
        })
      })
    } else if (accCat === ACCOUNT_CAT_PORTFOLIO) {
      const portfolioAssets = lodash.get(portfolioState, 'portfolioAssets', {})
      accNameToLabel[accName] = accName
      lodash.forEach(borrowableAssets, (ass) => {
        if (ass === '') {
          logger.error(`borrwableAssets has empty asset: ${ass}`)
          return
        }
        lodash.set(accNameToAssetToBool, [accName, ass], true)
      })
      lodash.forEach(portfolioAssets, (assInfo = {}, ass) => {
        const { free, borWtInt, borLmt } = assInfo
        lodash.set(accNameAssetBorrowRepayLmt, [accName, ass], {
          borrowLmt: parseFloat(borLmt),
          repayLmt: lodash.min([parseFloat(free), parseFloat(borWtInt)])
        })
      })
    }
  }

  const accNameOptions = lodash.map(accNameToLabel, (val, ky) => {
    return {
        value: ky,
        label: val,
    }
  })

  const accNameAssetOptions = lodash.map(accNameToAssetToBool, (assToBool, accName) => {
    return {
      accName,
      assetOptions: lodash.map(assToBool, (b, ass) => {
        return {
          value: ass,
          label: ass
        }
      })
    }
  })

  return (
    <Box
      className={classes.formBox}
      display="flex"
      flexDirection="column"
      justifyContent="center"
    >
      <BorrowRepayForm
        accNameOptions ={accNameOptions}
        accNameAssetOptions = {accNameAssetOptions}
        accNameAssetBorrowRepayLmt={accNameAssetBorrowRepayLmt}
        borrowRepayCmd={CMD_LONG_SHORT_BORROW_REPAY}
        isEmptyAccName={false}
        isEmptyPair={true}
        sessionId={sessionId}
      />
    </Box>
  )
}


LongShortBorrowRepayForm.propTypes = {
  sessionId: PropTypes.number.isRequired,
  accStateA: accStatePropTypes,
  accStateB: accStatePropTypes,
}

LongShortBorrowRepayForm.defaultProps = {

}

const mapStateToProps = (state, ownProps)=> {
  const accStateA = lodash.get(state, ['single', 'curPageData', 'accStateA'])
  const accStateB = lodash.get(state, ['single', 'curPageData', 'accStateB'])
  return {
    accStateA,
    accStateB
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return mapDispatch(dispatch)({...socketActions, ...singleActions})
}

export default connect(mapStateToProps, mapDispatchToProps)(LongShortBorrowRepayForm)



