import React from 'react'
import clsx from 'clsx'
import lodash from 'lodash'
import PropTypes from 'prop-types'
import {
  Card,
  makeStyles,
  Box
} from '@material-ui/core'
import FormMultiSelect from '../../../components/form/FormMultiSelect'
import { Field } from 'formik'
import { optionArrayPropType } from '../../../utils/propTypesUtils'
import { mapDispatch } from '../../../utils/utils'
import connect from 'react-redux/es/connect/connect'
import PiCardContent from '../../../components/PiCardContent'
import { ACCOUNT_CAT_SPOT } from '../../../protocolConstants'

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(3),
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  fieldAcc: {
    paddingBottom: theme.spacing(2),
  },
  accLabel: {
    paddingBottom: theme.spacing(1),
  }
}))

const AccountListFields = ({ className, fieldName, projects, projectId, ...rest }) => {
  const classes = useStyles()


  const project = lodash.find(projects, (p) => {
    return p['id'] === projectId
  })

  if(project === undefined) {
    return null
  }

  let { exchangeAccounts = [] } = project

  exchangeAccounts = lodash.filter(exchangeAccounts, (ea) => {
    const { cat } = ea
    return cat === ACCOUNT_CAT_SPOT
  })

  const accountIdOptions = lodash.map(exchangeAccounts, (ex) => {
    return {
      value: ex['id'],
      label: ex['name']
    }
  })


  const validateAccountIds = (val) => {
    let err

    if (!lodash.isArray(val) || val.length <= 0) {
      err = 'Please at least select one account'
    }
    return err
  }

  return (
    <Box mt={3} className={clsx(classes.root, className)} {...rest}>
      <Card>
        <PiCardContent disablePiStyle>
          <Field
            className={classes.fieldAcc}
            validate={validateAccountIds}
            name={fieldName}
            label="Accounts"
            options={accountIdOptions}
            component={FormMultiSelect}
          />
        </PiCardContent>
      </Card>
    </Box>
  )
}

AccountListFields.propTypes = {
  className: PropTypes.string,
  fieldName: PropTypes.string.isRequired,
  accounts: PropTypes.arrayOf(PropTypes.shape({
    cat: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    name: PropTypes.number.isRequired
  })),
  accountOptions: optionArrayPropType,
}

AccountListFields.defaultProps = {
}


const mapStateToProps = (state, ownProps)=> {
  const projects = lodash.get(state, ['single', 'profile', 'projects'])
  return {
    projects,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return mapDispatch(dispatch)({})
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountListFields)


