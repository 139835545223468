import React, { useEffect } from 'react'
import { useRoutes } from 'react-router-dom'
import { ThemeProvider } from '@material-ui/core'
import GlobalStyles from './components/GlobalStyles'
import theme from './theme'
import { mobileTheme } from './theme'
import routes from './routes'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DayjsUtils from '@date-io/dayjs'
import { useIsMobile } from './utils/hooks'
import { PiContextProvider } from './utils/piContext'
import { changeViewportScale, getStorageLocalSettings } from './utils/miscUtils'
import logger from './utils/logger'

const App = () => {
  const routing = useRoutes(routes)
  const isMobile = useIsMobile()
  let usingTheme
  if (isMobile) {
    usingTheme = mobileTheme
  } else {
    usingTheme = theme
  }

  useEffect(() => {
    // change the view port
    const { viewportScale } = getStorageLocalSettings()
    changeViewportScale(viewportScale)
    logger.debug('change default view port scale')
  })
  return (<MuiPickersUtilsProvider utils={DayjsUtils}>
    <PiContextProvider>
      <ThemeProvider theme={usingTheme}>
        <GlobalStyles />
        {routing}
      </ThemeProvider>
    </PiContextProvider>
  </MuiPickersUtilsProvider>
  )
}

export default App
