import React, { useContext } from 'react'
import { Box, Button, Card, Divider, Grid, makeStyles } from '@material-ui/core'
import PiCardContent from '../../../../components/PiCardContent'
import { Field } from 'formik'
import PropTypes from 'prop-types'
import {
  ACCOUNT_CAT_MARGIN,
  LONG_SHORT_MODE_MAKER_WITH_TAKER,
  LONG_SHORT_MODE_NORMAL,
  MENTION_MODE_CHANNEL,
  MENTION_MODE_DEFAULT_NONE
} from '../../../../protocolConstants'
import PiOneColRowGrid from '../../../../components/PiOneColRowGrid'
import ValueErrorText from '../icebergAlgoView/icebergAlgoForm/ValueErrorText'
import FormInterval from '../../../../components/form/FormInterval'
import FormSelect from '../../../../components/form/FormSelect'
import PiTwoColRowGrid from '../../../../components/PiTwoColRowGrid'
import FormCheckbox from '../../../../components/form/FormCheckbox'
import { PiFormContext } from '../../../../utils/piFormContext'
import SpammingTargetViews from './longShortFormInnerViews/SpammingTargetViews'
import { longShortAccCfgPropTypes } from '../../../../utils/propTypesUtils'
import LongShortTargetViews from './longShortFormInnerViews/LongShortTargetViews'
import Alert from '@material-ui/lab/Alert/Alert'
import LongShortDeleverageTargetViews from "./longShortFormInnerViews/LongShortDeleverageTargetViews"

const useStyles = makeStyles((theme) => ({
  button: {
    height: "100%"
  },
  divider: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  }
}))

const MODE_OPTIONS = [
  {
    value: LONG_SHORT_MODE_NORMAL,
    label: 'Normal',
  },
  {
    value: LONG_SHORT_MODE_MAKER_WITH_TAKER,
    label: 'Mk W. Taker'
  }
]

const MENTION_USER_MODE_OPTIONS = [
  {
    value: MENTION_MODE_DEFAULT_NONE,
    label: 'None',
  },
  {
    value: MENTION_MODE_CHANNEL,
    label: 'Mention Channel',
  },
]

const DefaultGridItem = ({ children, ...rest }) => {
  return (
    <PiOneColRowGrid
      item
      {...rest}
    >
      { children }
    </PiOneColRowGrid>
  )
}

const LongShortFormInner = ({ isSending, active, formProps, valueErrors, accCfgA, accCfgB, accStateA, accStateB, onStopLongShort }) => {
  const classes = useStyles()
  const { disabledAllFields = false } = useContext(PiFormContext)
  const { values = {}, handleSubmit, setFieldValue } = formProps
  const { accName: accNameA } = accCfgA

  console.log('the values is', values)

  const accStateLst = [
    accStateA,
    accStateB
  ]
  const accountPairsMap = {}
  const { spammingEnabled, longShortEnabled } = values

  const accountOptions = []
  for (let i = 0; i < accStateLst.length; i ++) {
    const accState = accStateLst[i]
    const { accCat, accName, pairs } = accState
    if (accCat === ACCOUNT_CAT_MARGIN) {
      accountOptions.push({
        value: accName,
        label: accName,
      })
      accountPairsMap[accName] = pairs
    }
  }
  let startStopButton
  if (active) {
    startStopButton = <Button
      className={classes.button}
      disabled={isSending}
      fullWidth
      size="small"
      onClick={onStopLongShort}
      variant="contained"
    >
      Stop
    </Button>
  } else {
    startStopButton = <Button
      className={classes.button}
      disabled={isSending}
      fullWidth
      color="primary"
      size="small"
      type="submit"
      variant="contained"
    >
      Start
    </Button>
  }

  return (
    <form onSubmit={handleSubmit}>
      <Box>
        <Card>
          <PiCardContent>
            <Grid container spacing={1}>
              <ValueErrorText valueErrors={valueErrors}/>
              <Grid container spacing={3}>

                <PiTwoColRowGrid>
                  <Field
                    name="longShortEnabled"
                    label="Long Short Enabled"
                    component={FormCheckbox}
                  />
                </PiTwoColRowGrid>
                <PiTwoColRowGrid>
                  <Alert
                    icon={false}
                    variant="outlined"
                    severity="info"
                  >
                    {`All fields are wrt Acc A (${accNameA}).`}
                  </Alert>
                </PiTwoColRowGrid>
                <LongShortTargetViews
                  values={values}
                  accCfgA={accCfgA}
                  accStateA={accStateA}
                  setFieldValue={setFieldValue}
                  disabledAllFields={disabledAllFields}
                />

                <PiTwoColRowGrid
                >
                  <Field
                    name="longShortStopOnComplete"
                    label="Long Short Stop On Complete"
                    component={FormCheckbox}
                    disabled={!longShortEnabled}
                  />
                </PiTwoColRowGrid>

                <PiTwoColRowGrid
                >
                  <Field
                    name="longShortMentionUserOnComplete"
                    label="Mention User On Complete"
                    options={MENTION_USER_MODE_OPTIONS}
                    component={FormSelect}
                    disabled={!longShortEnabled}
                  />
                </PiTwoColRowGrid>

                <PiTwoColRowGrid
                >
                  <Field
                    name="longShortMode"
                    placeholder="Mode"
                    options={MODE_OPTIONS}
                    component={FormSelect}
                    disabled={!longShortEnabled}
                  />
                </PiTwoColRowGrid>

                <PiTwoColRowGrid
                >
                  <Field
                    name="longShortItvlMs"
                    label="Long Short Itvl"
                    component={FormInterval}
                    disabled={!longShortEnabled}
                  />
                </PiTwoColRowGrid>

                <PiOneColRowGrid>
                  <Field
                    name="longShortAutoDeleverageEnabled"
                    label="Auto Deleverage Enabled"
                    component={FormCheckbox}
                    disabled={!longShortEnabled}
                  />
                </PiOneColRowGrid>

                <LongShortDeleverageTargetViews
                  values={values}
                  accCfgA={accCfgA}
                  accCfgB={accCfgB}
                  setFieldValue={setFieldValue}
                  disabledAllFields={disabledAllFields}
                />

                <Grid
                  item
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                >
                  <Divider orientation="horizontal" className={classes.divider}/>
                </Grid>

                <PiTwoColRowGrid>
                  <Field
                    name="spammingEnabled"
                    label="Spamming Enabled"
                    component={FormCheckbox}
                  />
                </PiTwoColRowGrid>

                <PiTwoColRowGrid>
                  <Field
                    name="spammingAccName"
                    label="Spamming Account Name"
                    options={accountOptions}
                    component={FormSelect}
                    disabled={!spammingEnabled}
                  />
                </PiTwoColRowGrid>
                <SpammingTargetViews
                  values={values}
                  accountPairsMap={accountPairsMap}
                  setFieldValue={setFieldValue}
                  accountOptions={accountOptions}
                  disabledAllFields={disabledAllFields}
                />
                <DefaultGridItem>
                  <Field
                    name="spammingItvlMs"
                    label="Spamming Itvl"
                    component={FormInterval}
                  />
                </DefaultGridItem>

                <DefaultGridItem>
                  {startStopButton}
                </DefaultGridItem>
              </Grid>
            </Grid>
          </PiCardContent>
        </Card>
      </Box>
    </form>
  )
}

LongShortFormInner.propTypes = {
  active: PropTypes.bool.isRequired,
  onStopLongShort: PropTypes.func.isRequired,
  accCfgA: longShortAccCfgPropTypes,
  accCfgB: longShortAccCfgPropTypes
}

export default LongShortFormInner
