import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import {actions as singleActions} from '../../../features/single/singleSlice'
import {
  Box,
  Typography,
  Divider,
  Drawer,
  List,
  makeStyles,
  Button
} from '@material-ui/core'
import {
  BarChart as BarChartIcon,
  Settings as SettingsIcon,
  ShoppingBag as ShoppingBagIcon,
  User as UserIcon,
} from 'react-feather'
import { BugReportOutlined as BugReportIcon } from '@material-ui/icons'
import NavItem from './NavItem'
import {PATH_SESSIONS, PATH_DEBUGZONE, PATH_ACCOUNTS} from '../../../constants'
import { isDev } from '../../../config'
import lodash from 'lodash'
import {isUserAdmin, mapDispatch, trimEmail} from '../../../utils/utils'
import connect from 'react-redux/es/connect/connect'
import ConfirmDialog from '../../../components/ConfirmDialog'


const createItems = (isAdmin) => {
  const items = [
    {
      href: PATH_SESSIONS,
      icon: ShoppingBagIcon,
      title: 'Sessions'
    },
  ]

  if (isAdmin) {
    items.push(
      {
        href: PATH_ACCOUNTS,
        icon: UserIcon,
        title: 'Accounts'
      }
    )
  }
  items.push(
    {
      href: '/settings',
      icon: SettingsIcon,
      title: 'Settings'
    }
  )
  if(isDev()) {
    items.push(...[
      {
        href: '/dashboard',
        icon: BarChartIcon,
        title: 'Dashboard'
      },
      {
        href: '/products',
        icon: ShoppingBagIcon,
        title: 'Products'
      },
    ])

    items.push({
      href: PATH_DEBUGZONE,
      icon: BugReportIcon,
      title: 'Debug Zone'
    })
  }
  return items
}

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 180
  },
  desktopDrawer: {
    width: 180,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
  username: {
    paddingTop: 18,
    paddingBottom: 18,
  }
}))

const NavBar = ({ onMobileClose, logout, roles, openMobile, username }) => {
  const classes = useStyles()
  const location = useLocation()
  const isAdmin = isUserAdmin(roles)
  const items = createItems(isAdmin)

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false)
  const showLogoutDialog = () => {
    setLogoutDialogOpen(true)
  }

  const onLogoutConfirm = () => {
    setLogoutDialogOpen(false)
    logout()
  }

  const onLogoutCancel = () => {
    setLogoutDialogOpen(false)
  }

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        p={2}
      >
        <Typography
          className={classes.username}
          variant="h6"
        >
          {trimEmail(username)}
        </Typography>
      </Box>
      <Divider />
      <Box p={2}>
        <List>
          {items.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List>
      </Box>

      <Box flexGrow={1} />
      <Box
        p={2}
        m={2}
      >
        <Box
          display="flex"
          justifyContent="center"
          mt={2}
        >
          <Button
            color="primary"
            variant="outlined"
            onClick={showLogoutDialog}
          >
            Logout
          </Button>
        </Box>
      </Box>
    </Box>
  )

  return (
    <>
      <Drawer
        anchor="left"
        classes={{ paper: classes.mobileDrawer }}
        onClose={onMobileClose}
        open={openMobile}
        variant="temporary"
      >
        {content}
      </Drawer>
      <ConfirmDialog
        dialogOpen={logoutDialogOpen}
        dialogTitle=''
        dialogContent='Are you sure you want to logout now?'
        dialogCancelText='Cancel'
        dialogConfirmButton='Logout Now'
        dialogOnCancel={onLogoutCancel}
        dialogOnConfirm={onLogoutConfirm}
      />
    </>
  )
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
  username: PropTypes.string,
  logout: PropTypes.func.isRequired,
}

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
}

const mapStateToProps = (state, ownProps)=> {
  const username = lodash.get(state, ['single', 'profile', 'username'])
  const roles = lodash.get(state, ['single', 'profile', 'roles'])
  return {
    username,
    roles
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return mapDispatch(dispatch)({...singleActions})
}

export default connect(mapStateToProps, mapDispatchToProps)(NavBar)

