import React, { useEffect } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import {
  makeStyles,
  Box, Grid
} from '@material-ui/core'
import { mapDispatch } from '../../../../utils/utils'
import connect from 'react-redux/es/connect/connect'
import TakerAlgoForm from './DexTakerAlgoForm'
import DexPlaceOrderForm from './DexPlaceOrderForm'
import BasicInfoView from './DexTakerBasicInfoView'
import DexTakerApproveInfo from './DexTakerApproveInfo'
import { processLogs } from '../../../ui/LoggerView'
import MakerTakerActiveOrdersView from './DexTakerTxsView'

import {
  CMD_GET_FULL_STATE,
  CMD_LOG_UPDATE,
  CMD_DEX_TAKER_BROADCAST_TX_INFOS,
  CMD_DEX_TAKER_BROADCAST_BASIC_INFO,
  CMD_DEX_TAKER_BROADCAST_SPENDING_INFOS,
  CMD_DEX_TAKER_BROADCAST_STARTED,
  CMD_DEX_TAKER_BROADCAST_STOPPED
} from '../../../../protocolConstants'
import { useRefFieldsPrevious, useRunOnce, useStateReqView } from '../../../../utils/hooks'
import lodash from 'lodash'
import MakerTakerLoggerView from './DexTakerLoggerView'
import { actions as singleActions } from '../../../single/singleSlice'
import { addEventListener, removeEventListener } from '../../../../utils/eventBus'
import { LOG_LINES_START_TO_TRIM, LOG_LINES_TRIM_TO } from '../../../../constants'
import DexTakerGeneralInfoView from './DexTakerGeneralInfoView'
import { accountPropTypes, algorithmPropTypes, projectPropType } from '../../../../utils/propTypesUtils'
import logger from '../../../../utils/logger'


const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  leftRoot: {
    padding: theme.spacing(1)
  },
  leftTop: {
    width: '100%',
    padding: theme.spacing(1)
  },
  leftMid: {
    width: '100%',
    padding: theme.spacing(1)
  },
  leftBottom: {
    width: '100%',
    height: '300px', // it is actually the min height to be 300
    padding: theme.spacing(1),
    "overflow": "auto"
  },
  rightRoot: {
    padding: theme.spacing(1)
  },
  rightTop: {
    width: '100%',
    padding: theme.spacing(1)
  },
  rightMid: {
    width: '100%',
    padding: theme.spacing(1)
  },
  rightBottom: {
    width: '100%',
    height: '300px', // it is actually the min height to be 300
    padding: theme.spacing(1),
    "overflow": "auto"
  },
}))


const DexTakerAlgoView = ({ sessionId, sessionAccounts, project, algorithm, setCurPageData, paramDexTaker, logs, clearCurPageData, setToolbarTitle }) => {
  const classes = useStyles()
  useRunOnce(() => {
    clearCurPageData()
    if (!lodash.isEmpty(sessionAccounts)) {
      const sessionAccount = sessionAccounts[0]
      const { accId, pairs } = sessionAccount
      const exchangeAccounts = lodash.get(project, 'exchangeAccounts', [])
      const account = lodash.find(exchangeAccounts, (ea) => {
        return ea['id'] === accId
      })
      if (account) {
        let pair = ''
        if (!lodash.isEmpty(pairs)) {
          pair = pairs[0]
        }
        const toolbarTitle = `${algorithm.name} - ${account.name} - ${pair}`
        setToolbarTitle(toolbarTitle)
      }
    }
  })

  const genFullState = () => {
    return {
      cmd: CMD_GET_FULL_STATE,
      isRelay: true,
      data: {
        sessionId,
      }
    }
  }

  let { isSuccessful, receivedData, loadingView } = useStateReqView(genFullState)

  const onDexTakerTxInfosUpdate = (event) => {
    const { data: txInfos, sessionId: eventSessionId } = lodash.get(event, 'data')
    if (eventSessionId !== sessionId) {
      return
    }
    const { pendingTxs, historicalTxs } = txInfos
    setCurPageData({
      pendingTxs,
      historicalTxs
    })
  }

  const onDexTakerSpendingInfosUpdate = (event) => {
    const { data: spendingInfosData, sessionId: eventSessionId } = lodash.get(event, 'data', {})
    if (eventSessionId !== sessionId) {
      return
    }
    setCurPageData({
      spendingInfos: spendingInfosData,
    })
  }

  const onDexTakerStart = (event) => {
    const { sessionId: eventSessionId } =lodash.get(event, 'data')
    if (eventSessionId !== sessionId) {
      return
    }
    const active = true
    setCurPageData({
      paramDexTaker: {
        ...paramDexTaker,
        active
      }
    })
  }

  const onDexTakerStop = (event) => {
    const { sessionId: eventSessionId } =lodash.get(event, 'data')
    if (eventSessionId !== sessionId) {
      return
    }
    const active = false
    setCurPageData({
      paramDexTaker: {
        ...paramDexTaker,
        active
      }
    })
  }

  const onDexTakerBasicInfoUpdate = (event) => {
    const { data: basicInfoData, sessionId: eventSessionId } = lodash.get(event, 'data')
    if (eventSessionId !== sessionId) {
      return
    }
    setCurPageData({
      basicInfo: basicInfoData
    })
  }

  const onLogfileUpdate = (event) => {
    const { data: logfileData = [], sessionId: eventSessionId } = lodash.get(event, 'data')
    if (eventSessionId !== sessionId) {
      return
    }
    const addLogs = processLogs(logfileData)
    let newLogs = lodash.concat(logs, addLogs)
    if (newLogs.length > LOG_LINES_START_TO_TRIM) {
      newLogs = newLogs.slice(-LOG_LINES_TRIM_TO, newLogs.length)
    }
    setCurPageData({
      logs: newLogs
    })
  }

  useEffect(() => {
    const txInfosCallbackId = addEventListener(CMD_DEX_TAKER_BROADCAST_TX_INFOS, onDexTakerTxInfosUpdate)
    const logfileUpdateId = addEventListener(CMD_LOG_UPDATE, onLogfileUpdate)
    const dexTakerBasicUpdateId = addEventListener(CMD_DEX_TAKER_BROADCAST_BASIC_INFO, onDexTakerBasicInfoUpdate)
    const dexTakerSpendingInfoUpdatedId = addEventListener(CMD_DEX_TAKER_BROADCAST_SPENDING_INFOS, onDexTakerSpendingInfosUpdate)
    const dexTakerStarted = addEventListener(CMD_DEX_TAKER_BROADCAST_STARTED, onDexTakerStart)
    const dexTakerStopped = addEventListener(CMD_DEX_TAKER_BROADCAST_STOPPED, onDexTakerStop)
    return () => {
      removeEventListener(CMD_DEX_TAKER_BROADCAST_TX_INFOS, txInfosCallbackId)
      removeEventListener(CMD_LOG_UPDATE, logfileUpdateId)
      removeEventListener(CMD_DEX_TAKER_BROADCAST_BASIC_INFO, dexTakerBasicUpdateId)
      removeEventListener(CMD_DEX_TAKER_BROADCAST_SPENDING_INFOS, dexTakerSpendingInfoUpdatedId)
      removeEventListener(CMD_DEX_TAKER_BROADCAST_STARTED, dexTakerStarted)
      removeEventListener(CMD_DEX_TAKER_BROADCAST_STOPPED, dexTakerStopped)
    }
  })


  const prevIsSuccessful  = useRefFieldsPrevious('isSuccessful', isSuccessful)
  useEffect(() => {
    if ((!prevIsSuccessful) && isSuccessful) {
      logger.info('DexTakerAlgoView, successful, hence set cur page data')
      const logLines = lodash.get(receivedData, ['data', 'shared', 'logLines'])
      const logs = processLogs(logLines)
      const paramDexTaker = lodash.get(receivedData, ['data', 'specific', 'paramDexTaker'])
      const generalInfo = lodash.get(receivedData, ['data', 'specific', 'generalInfo'])
      const basicInfo = lodash.get(receivedData, ['data', 'specific', 'basicInfo'])
      const spendingInfos = lodash.get(receivedData, ['data', 'specific', 'spendingInfos'])
      setCurPageData({
        logs,
        paramDexTaker,
        generalInfo,
        basicInfo,
        spendingInfos
      })
    }
  })

  if(loadingView) {
    return <Box className={classes.root}>
      {loadingView}
    </Box>
  }

  return (
    <Grid
      className={clsx(classes.root)}
      container
    >
      <Grid
        item
        className={classes.leftRoot}
        xl={6}
        lg={6}
        sm={12}
        xs={12}
      >

        <Box height="100%"
             width="100%"
             display="flex"
             flexDirection="column"
        >
          <Box className={classes.leftTop}>
            <DexTakerGeneralInfoView />
          </Box>
          <Box className={classes.leftMid}>
            <BasicInfoView
              sessionId={sessionId}
            />
          </Box>
          <Box className={classes.leftMid}>
            <DexTakerApproveInfo
              sessionId={sessionId}
            />
          </Box>
          <Box className={classes.leftBottom} flexGrow={1}>
            <MakerTakerActiveOrdersView
              sessionId={sessionId}
            />
          </Box>
        </Box>
    </Grid>


    <Grid
      item
      className={classes.rightRoot}
      xl={6}
      lg={6}
      sm={12}
      xs={12}
    >
      <Box
        height="100%"
        width="100%"
        display="flex"
        flexDirection="column"
      >
        <Box className={classes.rightTop}>
          <TakerAlgoForm
            sessionId={sessionId}
          />
        </Box>

        <Box className={classes.rightMid}>
          <DexPlaceOrderForm
            sessionId={sessionId}
          />
        </Box>

        <Box className={classes.rightBottom} flexGrow={1}>
          <MakerTakerLoggerView />
        </Box>
      </Box>
    </Grid>
  </Grid>
  )
}

DexTakerAlgoView.propTypes = {
  sessionId: PropTypes.number.isRequired,
  algorithm: algorithmPropTypes, // the selected algorithm
  setCurPageData: PropTypes.func.isRequired,
  sessionAccounts: PropTypes.arrayOf(accountPropTypes),
  logs: PropTypes.array.isRequired,
  paramDexTaker: PropTypes.object,
  clearCurPageData: PropTypes.func.isRequired,
  setToolbarTitle: PropTypes.func.isRequired,
  project: projectPropType.isRequired,
}

DexTakerAlgoView.defaultProps = {
  logs: [],
}

const mapStateToProps = (state, ownProps)=> {
  const logs = lodash.get(state, ['single', 'curPageData', 'logs'], [])
  const paramDexTaker = lodash.get(state, ['single', 'curPageData', 'paramDexTaker'])
  return {
    logs,
    paramDexTaker
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return mapDispatch(dispatch)({...singleActions})
}

export default connect(mapStateToProps, mapDispatchToProps)(DexTakerAlgoView)

