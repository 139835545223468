import { ApiError } from '../../exception'
import { genUuid, getTsMs } from '../../utils/utils'
import logger from '../../utils/logger'
import { SOCKET_PING_ITV_MILLISEC, SOCKET_PING_TIMEOUT_MILLISEC } from '../../constants'
import SocketReconnectDelay from './socketReconnectDelay'
import { decreaseReconnectCountdown, updateReconnectCountdown, updateSocketStatus } from './socketSlice'
import { getApiUrl } from '../../config'
import { CMD_PING } from '../../protocolConstants'

class ReduxSocket {

  constructor (onMessage, onOpen, onClose, onError, dispatch, getReduxState) {
    this.socket = undefined

    this.onMessage = onMessage
    this.onOpen = onOpen
    this.onClose = onClose
    this.onError = onError
    this.dispatch = dispatch
    this.getReduxState = getReduxState

    this.pingItv = undefined
    this.reconnectItv = undefined

    this.lastMsgReceivedMs = getTsMs()

    this.socketReconnectDelay = new SocketReconnectDelay()
  }

  _onMessageBase = (event) => {
    this.lastMsgReceivedMs = getTsMs()
    this.onMessage(event)
  }

  _onCloseBase = (event) => {
    this._stopPingItv()
    this._startReconnectItv() // start reconnect
    this.onClose(event)
  }

  _onOpenBase = (event) => {
    logger.info('Socket connected')
    this.lastMsgReceivedMs = getTsMs()
    this._startPingItv()
    this._stopReconnectItv()
    this.socketReconnectDelay.connectSuccess()
    this.onOpen(event)
  }

  _onErrorBase = (event) => {
    logger.info(`On error`)
    this.onError(event)
  }

  connect = () => {
    this.close()
    const wsDomain = getApiUrl()
    this.socket = new WebSocket(wsDomain)
    this.socket.onopen = this._onOpenBase
    this.socket.onmessage = this._onMessageBase
    this.socket.onclose = this._onCloseBase
    this.socket.onerror = this._onErrorBase
  }

  reconnect = () => {
    this.connect()
    const socketStatus = this.getStatus()
    this.dispatch(updateSocketStatus({
      socketStatus,
    }))
  }

  close = () => {
    if(this.socket !== undefined) {
      logger.debug('Close socket is calling...')
      this.socket.close()
      // should be closing state
      const socketStatus = this.getStatus()
      this.dispatch(updateSocketStatus({
        socketStatus,
      }))
      this.socket = undefined
    }
    this._stopPingItv()
    this._stopReconnectItv()
  }

  _startPingItv = () => {
    logger.debug('Start ping interval')
    this._stopPingItv()
    this.pingItv = setInterval(() => {
      if (getTsMs() > this.lastMsgReceivedMs + SOCKET_PING_TIMEOUT_MILLISEC) {
        // pong with timeout, need to handle this
        logger.info(`Last msg received ms is: ${this.lastMsgReceivedMs}. Hence close socket`)
        this.close()
        return
      }
      try {
        this.ping()
      } catch (e) {
        logger.info('ping with error')
        logger.error(e)
      }
    }, SOCKET_PING_ITV_MILLISEC)
  }

  _stopPingItv = () => {
    if(this.pingItv !== undefined) {
      clearInterval(this.pingItv)
      this.pingItv = undefined
      logger.info('stop ping interval')
    }
  }

  _startReconnectItv = () => {
    logger.info('start reconnect interval')
    this._stopReconnectItv()
    const reconnectCountdown = this.socketReconnectDelay.getReconnectDelaySec()
    this.socketReconnectDelay.connectFailure()
    this.dispatch(updateReconnectCountdown({
      reconnectCountdown
    }))
    this.reconnectItv = setInterval(() => {
      if(this.getReduxState().socket.reconnectCountdown <= 0) {
        this.reconnect()
        return
      }
      this.dispatch(decreaseReconnectCountdown())
    }, 1000)
  }

  _stopReconnectItv = () => {
    if(this.reconnectItv !== undefined) {
      clearInterval(this.reconnectItv)
      this.reconnectItv = undefined
      logger.info('Stop reconnect interval')
    }
  }

  ping = () => {
    const rId = genUuid()
    const ts = getTsMs()
    const data = {
      rId,
      ts,
      'cmd': CMD_PING,
    }
    // logger.debug('sending ping', data)
    this.sendMessage(data)
  }

  getStatus = () => {
    if (this.socket === undefined) {
      return WebSocket.CLOSED
    }
    return this.socket.readyState
  }

  isConnected = () => {
    return this.getStatus() === WebSocket.OPEN
  }

  sendMessage = (payload) => {
    if(this.isConnected()) {
      return this.socket.send(JSON.stringify(payload))
    } else {
      throw new ApiError('Socket is disconnected')
    }
  }

}

export default ReduxSocket
