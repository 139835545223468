import { Button, makeStyles } from '@material-ui/core'
import { COLOR_BUY, COLOR_SELL, COLOR_LIGHT_BUY, COLOR_LIGHT_SELL } from '../constants'
import React from 'react'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  buttonGreen: {
    height: '100%',
    background: COLOR_BUY,
    "&:hover": {
      backgroundColor: COLOR_BUY
    },
    color: '#ffffff'
  },
  buttonLightGreen: {
    height: '100%',
    background: COLOR_LIGHT_BUY,
    "&:hover": {
      backgroundColor: COLOR_LIGHT_BUY
    },
    color: '#ffffff'
  },
  buttonRed: {
    height: '100%',
    background: COLOR_SELL,
    "&:hover": {
      backgroundColor: COLOR_SELL
    },
    color: '#ffffff'
  },
  buttonLightRed: {
    height: '100%',
    background: COLOR_LIGHT_SELL,
    "&:hover": {
      backgroundColor: COLOR_LIGHT_SELL
    },
    color: '#ffffff'
  }
}))


const ColorButton = ({ colorStyle, className, children, ...rest }) => {
  const classes = useStyles()
  let previewButtonClass

  switch (colorStyle) {
    case 'red':
      previewButtonClass = classes.buttonRed
      break
    case 'lightRed':
      previewButtonClass = classes.buttonLightRed
      break
    case 'green':
      previewButtonClass = classes.buttonGreen
      break
    case 'lightGreen':
      previewButtonClass = classes.buttonLightGreen
      break
    default:
      previewButtonClass = ''
  }
  return (
    <Button
      className={clsx(className, previewButtonClass)}
      {...rest}
    >
      {children}
    </Button>
  )
}

export default ColorButton
