import React from 'react'
import {
  makeStyles,
  Typography,
  Box,
} from '@material-ui/core'
import Page from '../../../components/Page'
import PropTypes from 'prop-types'
import { useParams} from 'react-router'
import lodash from 'lodash'
import {getInstancerServerName, mapDispatch} from '../../../utils/utils'
import { actions as socketActions } from '../../socket/socketSlice'
import { actions as singleActions } from '../../single/singleSlice'
import connect from 'react-redux/es/connect/connect'
import { algorithmArrayPropType, projectArrayPropType } from '../../../utils/propTypesUtils'
import {
  SESSION_STATUS_CLOSED,
  SESSION_STATUS_WAITING_FOR_WORKER
} from '../../../protocolConstants'
import { useStateReqView } from '../../../utils/hooks'
import SubscribeAlgoView from './subscribeAlgoView'
import MsgAlertView from '../../ui/MsgAlertView'
import DownloadAlgoView from './downloadAlgoView'
import {
  ALGO_KEY_ASSET_LIST,
  ALGO_KEY_ASSET_MOVEMENT_HISTORY,
  ALGO_KEY_PROJECT_ASSET_LIST,
  ALGO_KEY_TRADE_HISTORY,
  CMD_GET_SESSION
} from '../../../protocolConstants'
import PollingView from './pollingView'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    height: '100%',
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1)
  },
  subAlgo: {
    minHeight: '100%',
    height: '100%',
  }
}))

const isDownloadingAlgo = (algorithmKey) => {
  return [ ALGO_KEY_TRADE_HISTORY,
    ALGO_KEY_ASSET_MOVEMENT_HISTORY,
    ALGO_KEY_ASSET_LIST,
    ALGO_KEY_PROJECT_ASSET_LIST ].includes(algorithmKey)
}

const SessionView = (props) => {
  const classes = useStyles()
  const params = useParams()
  const { sessionId: sessionIdStr } = params
  const sessionId = lodash.parseInt(sessionIdStr)

  const createGetSessionData = () => {
    return {
      cmd: CMD_GET_SESSION,
      data: {
        'id': sessionId
      }
    }
  }
  const {
    loadingView,
    sendReq,
    receivedData
  } = useStateReqView(createGetSessionData)
  if(loadingView) {
    return <Page
      className={classes.root}
      title="Session View"
    >
      {loadingView}
    </Page>
  }

  const sessionData = lodash.get(receivedData, 'data')
  const { algorithmId, projectId, instancerName, id, name: sessionName = 'Session View', status } = sessionData
  const { algorithms, projects } = props
  const sessionAccounts = lodash.get(sessionData, ['data', 'accounts'])
  const serverName = getInstancerServerName(instancerName)

  const algorithm = lodash.find(algorithms, (a) => {
    return a['id'] === algorithmId
  })

  const project = lodash.find(projects, (p) => {
    return p['id'] === projectId
  })

  if (!algorithm) {
    const msg = `Algorithm with id: ${algorithmId} cannot be found`
    return <MsgAlertView
      msg={msg}
    />
  }

  const { key, name: algorithmName } = algorithm

  if (!project) {
    const msg = `Project with id: ${projectId} cannot be found`
    return <MsgAlertView
      msg={msg}
    />
  }

  if (status === SESSION_STATUS_CLOSED) {
    const msg = `This session has been closed`
    return <MsgAlertView
      msg={msg}
    />
  }

  if (status === SESSION_STATUS_WAITING_FOR_WORKER) {
    const msg = 'This session is waiting for worker. Please wait for a while. We will proceed once worker is ready.'
    const sessionExtraInfo = `Info: sessionId: ${id}, inst: ${instancerName}, serverName: ${serverName}, algo: ${algorithmName}`
    return <PollingView
      itvMs={3000}
      fn={() => sendReq(createGetSessionData())}
    >
      <MsgAlertView
        msg={<Box textAlign="center">
          <Typography>
            {msg}
          </Typography>
          <Typography>
            {sessionExtraInfo}
          </Typography>
        </Box>}
        severity='info'
      />
    </PollingView>
  }


  let content
  if (isDownloadingAlgo(key)) {
    content = <DownloadAlgoView
      sessionAccounts={sessionAccounts}
      sessionId={sessionId}
      algorithm={algorithm}
      project={project}
    />
  } else {
    content = <SubscribeAlgoView
      className={classes.subAlgo}
      sessionId={sessionId}
      algorithm={algorithm}
      sessionAccounts={sessionAccounts}
      project={project}
    />
  }

  return (
    <Page
      className={classes.root}
      title={sessionName}
    >
      {content}
    </Page>
  )
}


SessionView.propTypes = {
  projects: projectArrayPropType,
  reqInfoMap: PropTypes.object.isRequired,
  algorithms: algorithmArrayPropType,
}

SessionView.defaultProps = {
  algorithms: []
}

const mapStateToProps = (state, ownProps)=> {
  const projects = lodash.get(state, ['single', 'profile', 'projects'])
  const reqInfoMap = lodash.get(state, ['socket', 'reqInfoMap'], {})
  const algorithms = lodash.get(state, ['single', 'profile', 'algorithms'])
  return {
    projects,
    reqInfoMap,
    algorithms
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return mapDispatch(dispatch)({ ...socketActions, ...singleActions})
}

export default connect(mapStateToProps, mapDispatchToProps)(SessionView)
