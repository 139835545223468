import React, { useCallback, useContext, useEffect } from 'react'
import { makeStyles, TextField } from '@material-ui/core'
import PropTypes from 'prop-types'
import lodash from 'lodash'
import { createFieldPropTypes} from '../../utils/propTypesUtils'
import { formPropTypes, metaPropTypes } from '../../utils/propTypesUtils'
import PiInputAdornment from '../PiInputAdornment'
import clsx from 'clsx'
import logger from '../../utils/logger'
import { roundDown } from '../../utils/utils'
import Decimal from 'decimal.js'
import { PiFormContext } from '../../utils/piFormContext'

const useStyles = makeStyles(({
  disableSpin: {
    "-webkit-appearance": "none"
  },
  textField: {

  },
  textFieldDisabled: {
    backgroundColor: '#e8e8e8'
  }
}))

const FormInput = ({ isInline, size, postChange, isInteger, prec, fromDspVal, toDspVal, className, field,
                     label, placeholder, inputProps, InputProps, form, disabled, ...rest}) => {
  const { disabledAllFields } = useContext(PiFormContext)
  const classes = useStyles()
  let { value = '', name, onBlur } = field
  const { touched, errors, submitCount, setFieldValue } = form
  /**
   * the reason to check submit count is that, if field is not defined in the initValues,
   * the touched field may not set to true if we press submit button
   * */
  const fieldTouched = Boolean(lodash.get(touched, name) || submitCount > 0)
  const errMsg = lodash.get(errors, name, '')
  const error = Boolean(fieldTouched && errMsg)
  const helperText = fieldTouched && errMsg
  if (isInteger && !prec) {
    prec = '1'
  }

  /**
   * Return [roundedValue, isChanged]
   * @type {Function|*}
   */
  const roundValue = useCallback((val) => {
    if (prec) {
      let oldVal = val
      if (val === undefined || val === null || val === '') {
        return [val, false]
      }
      val = roundDown(val, prec)
      if (val.isNaN()) {
        val = new Decimal('0')
      }
      if (isInteger) {
        val = val.toNumber()
      } else {
        val = val.toFixed()
      }
      // if old val 0.0, the val is 0, then it is unchanged, since value did not change
      // in this case, we should still show 0.0 instead of 0, since use may want to input 0.01
      const changed = !(new Decimal(val).eq(oldVal))
      return [val, changed]
    } else {
      return [val, false]
    }
  }, [isInteger, prec])

  useEffect(() => {
    let [val, changed] = roundValue(value)
    if (changed) {
      setFieldValue(name, val)
      logger.debug(`FormInput update round ${name}, old val ${value}, new val ${val}`)
    }
  }, [value, name, roundValue, setFieldValue])

  const handleOnChange = (e) => {
    let targetVal = e.target.value
    let [roundVal, changed] = roundValue(targetVal)
    let val
    if (changed) {
      val = roundVal
    } else {
      val = targetVal
    }
    if(lodash.isFunction(fromDspVal)) {
      val = fromDspVal(val)
    }
    setFieldValue(name, val)
    if (lodash.isFunction(postChange)) {
      postChange(name, val)
    }
  }

  if(prec) {
    inputProps = lodash.merge({
      type: "number"
    }, inputProps)
  }

  if (lodash.get(inputProps, 'type') === 'number') {
    /**
     * in some browser, such as Ios Chrome, if no step specified, they will only allow input integer
     */
    inputProps = {
      'step': 'any',
      ...inputProps
    }
  }

  if(lodash.isFunction(toDspVal)) {
    value = toDspVal(value)
  }

  if(isInline) {
    InputProps = {
      startAdornment: <PiInputAdornment position="start" label={label}/>,
      ...InputProps
    }
    label = ''
  }

  const textFieldDisabled = disabled || disabledAllFields
  const textFieldClasses = textFieldDisabled ? classes.textFieldDisabled : classes.textField
  return (
    <TextField
      className={clsx(classes.disableSpin, textFieldClasses, className)}
      fullWidth
      error={error}
      label={label}
      placeholder={placeholder}
      onChange={handleOnChange}
      onBlur={onBlur}
      name={name}
      size='small'
      margin='dense'
      value={value}
      inputProps={inputProps}
      InputProps={InputProps}
      helperText={helperText}
      disabled={textFieldDisabled}
      {...rest}/>
  )
}

FormInput.propTypes = {
  isInline: PropTypes.bool.isRequired,
  isInteger: PropTypes.bool.isRequired,
  prec: PropTypes.string, // precision
  /**
   * fromDspVal, convert the form value to display value
   * toDspVal, convert the display value to form value,
   * These two function should be reverse function to each other
   */
  fromDspVal: PropTypes.func,
  toDspVal: PropTypes.func,
  postChange: PropTypes.func, // input will be <name, newvalue>

  field: createFieldPropTypes(),
  form: formPropTypes,
  meta: metaPropTypes,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  inputProps: PropTypes.shape({
    type: PropTypes.string
  })
}

FormInput.defaultProps = {
  isInline: true,
  isInteger: false,
  label: '',
  inputProps: {}
}

export default FormInput
