import React, { useEffect } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import lodash from 'lodash'
import {
  Card,
  Button,
  Typography,
  TableBody,
  TableHead,
  TableRow,
  makeStyles,
  TableSortLabel,
} from '@material-ui/core'
import { dexTxArrayPropTypes } from '../../../../utils/propTypesUtils'
import {
  CMD_DEX_TAKER_GET_TX_INFOS,
} from '../../../../protocolConstants'
import { getDexExplorerTxUrl, mapDispatch, trimTxId } from '../../../../utils/utils'
import connect from 'react-redux/es/connect/connect'
import PiTableCell from '../../../../components/PiTableCell'
import { FORMAT_MDHMS_SHORT, fromMs } from '../../../../utils/timeUtils'
import { useRefFieldsPrevious, useStateReq } from '../../../../utils/hooks'
import { actions as singleActions } from '../../../single/singleSlice'
import XscrollTable from '../../../ui/XscrollTable'
import PiCardContent from '../../../../components/PiCardContent'
import logger from '../../../../utils/logger'


const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  cardContent: {
    height: '100%'
  },
  refreshButtonParent: {
    textAlign: "right",
  },
  tableCellBreakword: {
    'word-wrap': 'break-word',
    width: '20%'
  },
  typography: {
    fontSize: '13px',
  },
  table: {
    "overflow-y": "scroll",
    height: '100%',
  }
}))



const SimpleTableCell = ({children, isRed, isGreen, ...rest}) => {
  const classes = useStyles()
  return (
    <PiTableCell
      {...rest}
    >
      <Typography className={classes.typography}>
        {children}
      </Typography>
    </PiTableCell>
  )
}

const HeaderTableCell = ({children, fieldName, onHeaderClick, sortedByName, isAsc, ...rest}) => {
  let sortDirection = 'asc'
  let active = false
  if (!lodash.isEmpty(fieldName) && !lodash.isEmpty(sortedByName) && fieldName === sortedByName) {
    active = true
    if (isAsc) {
      sortDirection = 'asc'
    } else {
      sortDirection = 'desc'
    }
  }
  return (
    <SimpleTableCell
      onClick={lodash.isEmpty(fieldName) ? null : onHeaderClick(fieldName)}
      {...rest}
    >
      <TableSortLabel
        active={active}
        direction={sortDirection}
      >
        {children}
      </TableSortLabel>
    </SimpleTableCell>
  )
}

const DexTakerTxsView = ({ className, historicalTxs, pendingTxs, generalInfo, sessionId, setCurPageData }) => {
  const classes = useStyles()
  if (lodash.isEmpty(historicalTxs)) {
    historicalTxs = []
  }
  if (lodash.isEmpty(pendingTxs)) {
    pendingTxs = []
  }
  const { chain } = generalInfo

  const allTxs = lodash.concat([], historicalTxs, pendingTxs)

  const {
    isSending,
    isSuccessful,
    sendReq,
    sentData,
    receivedData,
    getStateField,
    setStateField,
  } = useStateReq()

  const prevIsSuccessful = useRefFieldsPrevious('isSuccessful', isSuccessful)
  useEffect(() => {
    if (!prevIsSuccessful && isSuccessful) {
      const cmd = lodash.get(sentData, 'cmd')
      if(cmd === CMD_DEX_TAKER_GET_TX_INFOS) {
        const txInfos = lodash.get(receivedData, ['data'], {})
        const { historicalTxs = [], pendingTxs = []} = txInfos
        setCurPageData({
          historicalTxs,
          pendingTxs
        })
      } else {
        logger.info(`cmd ${cmd} is not recognized`)
      }
    }
  }, [setCurPageData, prevIsSuccessful, isSuccessful, receivedData, sentData])

  if (!generalInfo) {
    return null
  }

  const sortedByName = getStateField('sortedByName', 'time')
  const isAsc = getStateField('isAsc', false) // asc or desc
  const sortedAllTxs = lodash.orderBy(allTxs, [sortedByName], [isAsc ? 'asc' : 'desc'])

  const onHeaderClick = (fieldName) => () => {
    if (sortedByName !== fieldName) {
      setStateField('sortedByName', fieldName)
      setStateField('isAsc', true)
    } else {
      setStateField('isAsc', !Boolean(isAsc))
    }
  }

  const getTxInfos = () => {
    sendReq({
      cmd: CMD_DEX_TAKER_GET_TX_INFOS,
      isRelay: true,
      data: {
        sessionId
      }
    })
  }

  return (
    <Card
      className={clsx(classes.root, className)}
    >
      <PiCardContent className={classes.cardContent}>
        <Button
          onClick={getTxInfos}
          disabled={isSending}
          variant="contained"
        >
          Refresh
        </Button>
        <XscrollTable stickyHeader className={classes.table}>
          <TableHead>
            <TableRow>
              <HeaderTableCell
                fieldName='txId'
                onHeaderClick={onHeaderClick}
                sortedByName={sortedByName}
                isAsc={isAsc}
              >
                TxId
              </HeaderTableCell>
              <HeaderTableCell
                fieldName='time'
                onHeaderClick={onHeaderClick}
                sortedByName={sortedByName}
                isAsc={isAsc}
              >
                Time
              </HeaderTableCell>
              <HeaderTableCell
                fieldName='accName'
                onHeaderClick={onHeaderClick}
                sortedByName={sortedByName}
                isAsc={isAsc}
              >
                Acc Name
              </HeaderTableCell>
              <HeaderTableCell
                fieldName='px'
                onHeaderClick={onHeaderClick}
                sortedByName={sortedByName}
                isAsc={isAsc}
              >
                Status
              </HeaderTableCell>
              <HeaderTableCell
                fieldName='qty'
                onHeaderClick={onHeaderClick}
                sortedByName={sortedByName}
                isAsc={isAsc}
              >
                Is Pending
              </HeaderTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedAllTxs.map((tx, idx) => {
              let {
                txId,
                time,
                accName,
                status,
                isPending,
              } = tx
              return (
                <TableRow
                  hover
                  key={txId}
                >
                  <SimpleTableCell>
                    <a href={getDexExplorerTxUrl(chain, txId)}
                       target='_blank'
                       rel='noreferrer'
                    >
                    {trimTxId(txId)}
                    </a>
                  </SimpleTableCell>
                  <SimpleTableCell>
                    {fromMs(time).format(FORMAT_MDHMS_SHORT)}
                  </SimpleTableCell>
                  <SimpleTableCell>
                    {accName}
                  </SimpleTableCell>
                  <SimpleTableCell>
                    {status}
                  </SimpleTableCell>
                  <SimpleTableCell>
                    {isPending.toString()}
                  </SimpleTableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </XscrollTable>
      </PiCardContent>
    </Card>
  )
}

DexTakerTxsView.propTypes = {
  className: PropTypes.string,
  historicalTxs: dexTxArrayPropTypes,
  pendingTxs: dexTxArrayPropTypes,
  sessionId: PropTypes.number.isRequired,
  setCurPageData: PropTypes.func.isRequired
}

DexTakerTxsView.defaultProps= {
  activeOrders: []
}

const mapStateToProps = (state, ownProps)=> {
  const historicalTxs = lodash.get(state, ['single', 'curPageData', 'historicalTxs'], [])
  const pendingTxs = lodash.get(state, ['single', 'curPageData', 'pendingTxs'], [])
  const generalInfo = lodash.get(state, ['single', 'curPageData', 'generalInfo'], {})
  return {
    historicalTxs,
    pendingTxs,
    generalInfo,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return mapDispatch(dispatch)({...singleActions})
}

export default connect(mapStateToProps, mapDispatchToProps)(DexTakerTxsView)
