import React, { useCallback } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import lodash from 'lodash'
import Decimal from 'decimal.js'
import {
  Card,
  TableBody,
  TableHead,
  TableRow,
  Typography,
  makeStyles
} from '@material-ui/core'
import { orderbookPropType } from '../../utils/propTypesUtils'
import { roundDown } from '../../utils/utils'
import { useIsMobile } from '../../utils/hooks'
import XscrollTable from './XscrollTable'
import PiTableCell from '../../components/PiTableCell'
import PiCardContent from '../../components/PiCardContent'


const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
  },
  refreshButtonParent: {
    textAlign: "right",
  },
  table: {
    tableLayout: 'fixed'
  },
  tableRow: {
  },
  tableCellNoneMobile: {
    paddingTop: '1px',
    paddingBottom: '1px',
  },
  tableCell: {
    borderBottom: "none",
  },
  tableOrderbookMidCell: {
    borderBottom: "none",
    paddingBottom: '3px',
    paddingTop: '20px'
  },
  tableCellGreen: {
    color: '#0f8f62'
  },
  tableCellRed: {
    color: '#d9304f'
  }
}))

const OrderbookOnesideView = ({ isMobile, columnKeys, classes, items, isSell, onRowClick }) => {
  const renderTableRow = useCallback((item) => {
    const { px } = item
    let colorClassName
    if(isSell) {
      colorClassName = classes.tableCellRed
    } else {
      colorClassName = classes.tableCellGreen
    }
    const onClick = () => {
      if (onRowClick) {
        onRowClick(item)
      }
    }

    const tableCellNoneMobileClassName = isMobile ? undefined : classes.tableCellNoneMobile
    return (
      <TableRow
        className={classes.tableCellGreen}
        onClick={onClick}
        hover
        key={px}
      >
        {
          lodash.map(columnKeys, (k, i) => {
            const val = lodash.get(item, k)
            const className= i === 0 ? colorClassName : undefined
            return (
              <PiTableCell
                className={clsx(className, classes.tableCell, tableCellNoneMobileClassName)}
                key={k}
              >
                {val}
              </PiTableCell>
            )
          })
        }
      </TableRow>
    )
  }, [classes, columnKeys, isSell, onRowClick, isMobile])
  return (
    items.map(renderTableRow)
  )
}

const sortBidsOrAsks = (strArr, isAscending=false) => {
  return lodash.sortBy(strArr, (s) => {
    if(isAscending) {
      return parseFloat(s['px'])
    } else {
      return - parseFloat(s['px'])
    }
  })
}


const calCumQty = (items, isBids) => {
  let newItems = new Array(items.length)
  let fe
  if (isBids) {
    fe = lodash.forEach
  } else {
    fe = lodash.forEachRight
  }

  let cumQty = new Decimal(0)
  fe(items, (item) => {
    const qty = item['qty']
    cumQty = cumQty.add(qty)
    newItems.push({
      ...item,
      cumQty: cumQty.toFixed()
    })
  })

  if (!isBids) {
    newItems = lodash.reverse(newItems)
  }
  return newItems
}

const OrderbookView = ({ className, onRowClick, pxPrec, maxNum, orderbook, ...rest }) => {
  const classes = useStyles()
  const isMobile = useIsMobile()
  let asks = sortBidsOrAsks(orderbook.asks, false)
  let bids = sortBidsOrAsks(orderbook.bids, false)
  asks = asks.slice(-maxNum, asks.length)
  bids = bids.slice(0, maxNum)
  let midPx = '-'
  if(asks.length > 0 && bids.length > 0) {
    midPx = roundDown((parseFloat(asks[asks.length - 1].px) + parseFloat(bids[0].px)) / 2, pxPrec).toFixed()
  }
  asks = calCumQty(asks, false)
  bids = calCumQty(bids, true)


  const headerNames = {
    px: 'Price',
    qty: 'Quantity',
    cumQty: 'Cum Qty',
    openInQuote: 'Open in Qte',
    myOpenQty: 'My Open Qty',
    myTotalQty: 'My tot Qty'
  }

  let columnKeys
  if (isMobile) {
    columnKeys=[
      'px',
      'qty',
      'myOpenQty',
    ]
  } else {
    columnKeys=[
      'px',
      'qty',
      'cumQty',
      'openInQuote',
      'myOpenQty',
      'myTotalQty',
    ]
  }
  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <PiCardContent>
        <XscrollTable className={classes.table}>
          <TableHead>
            <TableRow>
              {columnKeys.map((key) => {
                return (
                  <PiTableCell
                    key={key}
                    className={clsx(classes.tableCell)}
                  >
                    {headerNames[key]}
                  </PiTableCell>
                )
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            <OrderbookOnesideView
              isMobile={isMobile}
              columnKeys={columnKeys}
              items={asks}
              classes={classes}
              isSell={true}
              onRowClick={onRowClick}
            />
            <TableRow>
              <PiTableCell
                className={classes.tableOrderbookMidCell}
              >
                <Typography
                  variant="body2"
                >
                  {midPx}
                </Typography>
              </PiTableCell>

              <PiTableCell
                className={classes.tableOrderbookMidCell}
              >
              </PiTableCell>

              <PiTableCell
                className={classes.tableOrderbookMidCell}
              >

              </PiTableCell>
            </TableRow>
            <OrderbookOnesideView
              isMobile={isMobile}
              columnKeys={columnKeys}
              items={bids}
              classes={classes}
              isSell={false}
              onRowClick={onRowClick}
            />
          </TableBody>
        </XscrollTable>
      </PiCardContent>
    </Card>
  )
}

OrderbookView.propTypes = {
  className: PropTypes.string,
  orderbook: orderbookPropType,
  // max number on one side
  maxNum: PropTypes.number.isRequired,
  pxPrec: PropTypes.string,
  onRowClick: PropTypes.func,
}

OrderbookView.defaultProps= {
  orderbook: {
    asks: [],
    bids: []
  },
  maxNum: 10,
  pxPrec: ''
}

export default OrderbookView
