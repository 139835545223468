import React, { useCallback, useEffect } from 'react'
import * as Yup from 'yup'
import { Field, Formik } from 'formik'
import {
  Box,
  makeStyles,
  Card,
  Grid
} from '@material-ui/core'
import { baseQuote, getQtyInQuotePrec, isValidNumber, mapDispatch } from '../../../../utils/utils'
import { actions as socketActions } from '../../../socket/socketSlice'
import { actions as singleActions } from '../../../single/singleSlice'
import connect from 'react-redux/es/connect/connect'
import PropTypes from 'prop-types'
import lodash from 'lodash'
import FormInput from '../../../../components/form/FormInput'
import ColorButton from '../../../../components/ColorButton'
import { useStateReq } from '../../../../utils/hooks'
import { BUY_SELL_OPTIONS, CMD_MAKER_TAKER_PLACE_ORDER, SIDE_BUY, SIDE_SELL } from '../../../../protocolConstants'
import FormRadio from '../../../../components/form/FormRadio'
import { pairInfoPropTypes } from '../../../../utils/propTypesUtils'
import PiInputAdornment from '../../../../components/PiInputAdornment'
import PiCardContent from '../../../../components/PiCardContent'
import logger from '../../../../utils/logger'


const useStyles = makeStyles((theme) => ({
  formBox: {
  },
  bidAskButton: {
    color: "#000000",
    height: 'auto',
  }
}))

const PlaceOrderForm = ({ sessionId, pairInfo, setCurPageData, placeOrderFormUpdatingData }) => {
  const classes = useStyles()
  const {
    sendReq,
    isSending
  } = useStateReq()
  const onNewSession = (values) => {
    sendReq({
      cmd: CMD_MAKER_TAKER_PLACE_ORDER,
      isRelay: true,
      data: {
        sessionId,
        params: {
          ...values
        }
      }
    })
  }

  if (!pairInfo) {
    return null
  }
  return (
    <Box
      className={classes.formBox}
      display="flex"
      flexDirection="column"
      justifyContent="center"
    >
      <Formik
        initialValues={{
          "buySell": SIDE_BUY
        }}
        validationSchema={Yup.object().shape({
          qty: Yup.number().required('Qty is required').moreThan(0, "Must be positive"),
          px: Yup.number().required('Price is required').moreThan(0, "Must be positive"),
          buySell: Yup.string().required('Buy sell is required'),
        })}
        onSubmit={onNewSession}
      >
        {(formProps) => {
          return (
            <PlaceOrderFormInner
              pairInfo={pairInfo}
              isSending={isSending}
              formProps={formProps}
              setCurPageData={setCurPageData}
              placeOrderFormUpdatingData={placeOrderFormUpdatingData}
            />
          )
        }}
      </Formik>
    </Box>
  )
}


PlaceOrderForm.propTypes = {
  sessionId: PropTypes.number.isRequired,
  pairInfo: pairInfoPropTypes,
  placeOrderFormUpdatingData: PropTypes.object,
}

PlaceOrderForm.defaultProps = {
  algorithms: []
}

const mapStateToProps = (state, ownProps)=> {
  const pairInfo = lodash.get(state, ['single', 'curPageData', 'pairInfo'])
  const placeOrderFormUpdatingData = lodash.get(state, ['single', 'curPageData', 'placeOrderFormUpdatingData'])
  return {
    pairInfo,
    placeOrderFormUpdatingData
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return mapDispatch(dispatch)({...socketActions, ...singleActions})
}

export default connect(mapStateToProps, mapDispatchToProps)(PlaceOrderForm)


const DefaultGridItem = ({ children }) => {
  return (
    <Grid
      item
      xl={6}
      lg={6}
      sm={6}
      xs={6}
    >
      { children }
    </Grid>
  )
}


const PlaceOrderFormInner = ({ isSending, pairInfo, formProps, setCurPageData, placeOrderFormUpdatingData }) => {
  const { values, handleSubmit, setFieldValue } = formProps
  const { buySell } = values

  const { qtyPrec, pxPrec, pair } = pairInfo
  const qtyInQuotePrec = getQtyInQuotePrec(qtyPrec, pxPrec)

  const [base, quote] = baseQuote(pair)
  let buttonLabel = 'Place Now'
  let colorStyle = ''
  if (buySell === SIDE_BUY) {
    colorStyle = 'green'
    buttonLabel = 'Buy Now'
  } else if (buySell === SIDE_SELL) {
    colorStyle = 'red'
    buttonLabel = 'Sell Now'
  }

  // either px qty, qtyInQuote changed, we will calculate the other one
  const postChangeField = useCallback((fieldName, fieldNewValue) => {
    // may need to calculate accordingly
    const newValues = {
      ...values,
      [fieldName]: fieldNewValue
    }
    let { px, qty, qtyInQuote } = newValues
    switch (fieldName) {
      case 'px':
      case 'qty':
        px = parseFloat(px)
        qty = parseFloat(qty)
        if (isValidNumber(px) && isValidNumber(qty)) {
          const newQtyInQuote = px * qty
          logger.debug(`new qty in quote set ${newQtyInQuote}`)
          setFieldValue('qtyInQuote', newQtyInQuote)
        }
        break
      case 'qtyInQuote':
        px = parseFloat(px)
        qtyInQuote = parseFloat(qtyInQuote)
        if (isValidNumber(qtyInQuote) && isValidNumber(px) && px !== 0) {
          const newQty = qtyInQuote / px
          logger.debug(`new qty in ${newQty}`)
          setFieldValue('qty', newQty)
        }
        break
      default:
        logger.error(`Unrecognized field name ${fieldName}`)
    }
  }, [values, setFieldValue])

  useEffect(() => {
    if (!lodash.isEmpty(placeOrderFormUpdatingData)) {
      setCurPageData({
        placeOrderFormUpdatingData: {} // clear the data
      })
      lodash.forEach(placeOrderFormUpdatingData, (val, key) => {
        setFieldValue(key, val)
        postChangeField(key, val)
      })
    }
  }, [setFieldValue, placeOrderFormUpdatingData, setCurPageData, postChangeField])

  return (
    <form onSubmit={handleSubmit}>
      <Box>
        <Card>
          <PiCardContent>
            <Box mx={2}>
              <Grid container spacing={3}>
                <DefaultGridItem>
                  <Field
                    name="px"
                    label="Price"
                    prec={pxPrec}
                    postChange={postChangeField}
                    inputProps = {{
                      type: "number",
                      style: {
                        textAlign: 'right'
                      }
                    }}
                    component={FormInput}
                  />
                </DefaultGridItem>

                <DefaultGridItem>
                  <Field
                    name="qty"
                    label="Quantity"
                    prec={qtyPrec}
                    postChange={postChangeField}
                    InputProps={{
                      endAdornment: <PiInputAdornment position="end" label={base}/>,
                    }}
                    inputProps = {{
                      type: "number",
                      style: {
                        textAlign: 'right'
                      }
                    }}
                    component={FormInput}
                  />
                </DefaultGridItem>

                <DefaultGridItem>
                  <Field
                    name="buySell"
                    label=""
                    options={BUY_SELL_OPTIONS}
                    component={FormRadio}
                  />
                </DefaultGridItem>

                <DefaultGridItem>
                  <Field
                    name="qtyInQuote"
                    label="Total"
                    prec={qtyInQuotePrec}
                    InputProps={{
                      endAdornment: <PiInputAdornment position="end" label={quote}/>,
                    }}
                    postChange={postChangeField}
                    inputProps = {{
                      type: "number",
                      style: {
                        textAlign: 'right'
                      }
                    }}
                    component={FormInput}
                  />
                </DefaultGridItem>

                <DefaultGridItem>
                  <ColorButton
                    fullWidth
                    size="small"
                    disabled={isSending}
                    type="submit"
                    colorStyle={colorStyle}>
                    {buttonLabel}
                  </ColorButton>
                </DefaultGridItem>
              </Grid>
            </Box>
          </PiCardContent>
        </Card>
      </Box>
    </form>
  )
}
