import React, { useState } from 'react'
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core'
import Page from '../../components/Page'
import Results from './Results'
import SendPanel from './SendPanel'
import PropTypes from 'prop-types'
import lodash from 'lodash'
import { genUuid, mapDispatch } from '../../utils/utils'
import { actions as socketActions } from '../socket/socketSlice'
import connect from 'react-redux/es/connect/connect'
import { actions as singleActions } from '../single/singleSlice'


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}))

const DebugzoneView = (props) => {
  const classes = useStyles()
  let [rIdLst, setRIdLst ] = useState([])
  const { reqInfoMap } = props

  const reqInfoLst = lodash.map(rIdLst, (rId) => {
    const reqInfo = lodash.get(reqInfoMap, rId)
    if (reqInfo === undefined) {
      return {
        rId,
        rStatus: '-',
        updateAtMs: 0,
        sentData: undefined,
        receivedData: undefined,
      }
    } else {
      return reqInfo
    }
  })

  lodash.reverse(reqInfoLst)

  const onSendMessage = (msg) => {
    try{
      const obj = JSON.parse(msg)
      const { sendMessage } = props
      const rId = genUuid()
      rIdLst.push(rId)
      setRIdLst(rIdLst)
      sendMessage({
        rId,
        ...obj
      })
    } catch (e) {
      const { showToast } = props
      showToast(`Input text is not JSON format: ${e}`, 'error')
    }
  }

  return (
    <Page
      className={classes.root}
      title="Sessions"
    >
      <Container maxWidth={false}>
        <SendPanel onSendMessage={onSendMessage}/>
        <Box mt={3}>
          <Results reqInfoLst={reqInfoLst} />
        </Box>
      </Container>
    </Page>
  )
}


DebugzoneView.propTypes = {
  reqInfoMap: PropTypes.object.isRequired,
  showToast: PropTypes.func.isRequired
}

DebugzoneView.defaultProps = {
  reqInfoMap: {}
}

const mapStateToProps = (state, ownProps)=> {
  const reqInfoMap = lodash.get(state, ['socket', 'reqInfoMap'])
  return {
    reqInfoMap
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return mapDispatch(dispatch)({ ...socketActions, ...singleActions})
}

export default connect(mapStateToProps, mapDispatchToProps)(DebugzoneView)
