import React, { useEffect } from 'react'
import clsx from 'clsx'
import {
  Card,
  makeStyles,
  Button,
  Grid,
  TableBody,
  TableRow
} from '@material-ui/core'
import lodash from 'lodash'
import { mapDispatch } from '../../../../utils/utils'
import { actions as singleActions } from '../../../single/singleSlice'
import connect from 'react-redux/es/connect/connect'
import PropTypes from 'prop-types'
import PiCardContent from '../../../../components/PiCardContent'
import { useRefFieldsPrevious, useStateReq } from '../../../../utils/hooks'
import {
  CMD_DEX_TAKER_GET_SPENDING_INFOS
} from '../../../../protocolConstants'
import XscrollTable from '../../../ui/XscrollTable'
import PiTableCell from '../../../../components/PiTableCell'


const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  cardContent: {
    height: '100%'
  },
  tableCell: {
    borderBottom: "none"
  },
  normalButton: {

  },
}))

const SmallTableCell = ({children}) => {
  const classes = useStyles()
  return <PiTableCell
    className={classes.tableCell}
  >
    {children}
  </PiTableCell>
}


const DexTakerApproveInfo = ({ generalInfo, sessionId, showToast, dialogData, setCurPageData, spendingInfos }) => {
  const classes = useStyles()
  const {
    isSending,
    sendReq,
    isSuccessful,
    receivedData,
    clearRId
  } = useStateReq()

  const tokenAAddr = lodash.get(generalInfo, ['tokenA', 'addr'], '')
  const tokenBAddr = lodash.get(generalInfo, ['tokenB', 'addr'], '')

  const tokenAAsset = lodash.get(generalInfo, ['tokenA', 'asset'], 'tokenA')
  const tokenBAsset = lodash.get(generalInfo, ['tokenB', 'asset'], 'tokenB')

  const showSpendingDetail = () => {
    const dialogOpen = true
    const dialogType = 'showSpendingDetail'
    setCurPageData({
      dialogData: {
        ...dialogData,
        dialogOpen,
        dialogType,
      }
    })
  }

  const refreshSpendingInfos = (tokenAddr) => () => {
    sendReq({
      cmd: CMD_DEX_TAKER_GET_SPENDING_INFOS,
      isRelay: true,
      data: {
        sessionId,
        params: {
          tokenAddr,
          getAll: true,
        }
      }
    })
  }

  const prevIsSending  = useRefFieldsPrevious('isSending', isSending)
  useEffect(() => {
    if (prevIsSending && !isSending && isSuccessful) {
      const cmd = lodash.get(receivedData, 'cmd')
      if (cmd === CMD_DEX_TAKER_GET_SPENDING_INFOS) {
        if (isSuccessful) {
          showToast('Spending Info updated', 'info')
        }
        clearRId()
      }
    }
  }, [clearRId, isSending, isSuccessful, prevIsSending, receivedData, showToast])

  if (tokenAAddr === '' || tokenBAddr === '') {
    return null
  }
  const { spendings = {} } = spendingInfos
  let totalAcc = 0
  let totalAApproved = 0
  let totalBApproved = 0
  lodash.forEach(spendings, (val, key) => {
    const { tokenAIsApproved, tokenBIsApproved } = val
    totalAcc = totalAcc + 1
    if (tokenAIsApproved) {
      totalAApproved = totalAApproved + 1
    }
    if (tokenBIsApproved) {
      totalBApproved = totalBApproved + 1
    }
  })

  return (
    <Card className={clsx(classes.root)}
    >
      <PiCardContent className={classes.cardContent}>

        <XscrollTable>
          <TableBody>
            <TableRow
              hover
            >
              <SmallTableCell>
                {`Token A (${tokenAAsset}) Approved`}
              </SmallTableCell>
              <SmallTableCell>
                {`${totalAApproved} / ${totalAcc}`}
              </SmallTableCell>
              <SmallTableCell>
                {`Token B (${tokenBAsset}) Approved`}
              </SmallTableCell>
              <SmallTableCell>
                {`${totalBApproved} / ${totalAcc}`}
              </SmallTableCell>
            </TableRow>
          </TableBody>
        </XscrollTable>



        <Grid container spacing={3}>
          <Grid
            item
            xl={4}
            lg={4}
            sm={4}
            xs={4}
          >
            <Button
              className={classes.normalButton}
              disabled={isSending}
              fullWidth
              size='small'
              variant='contained'
              onClick={refreshSpendingInfos(tokenAAddr)}
            >
              {`Refresh Token A (${tokenAAsset}) Spendings`}
            </Button>
          </Grid>
          <Grid
            item
            xl={4}
            lg={4}
            sm={4}
            xs={4}
          >
            <Button
              className={classes.normalButton}
              disabled={isSending}
              fullWidth
              size='small'
              variant='contained'
              onClick={refreshSpendingInfos(tokenBAddr)}
            >
              {`Refresh Token B (${tokenBAsset}) Spendings`}
            </Button>
          </Grid>
          <Grid
            item
            xl={4}
            lg={4}
            sm={4}
            xs={4}
          >
            <Button
              className={classes.normalButton}
              disabled={isSending}
              fullWidth
              size='small'
              variant='contained'
              onClick={showSpendingDetail}
            >
              Show Spending Detail
            </Button>
          </Grid>
        </Grid>
      </PiCardContent>
    </Card>
  )
}

DexTakerApproveInfo.propTypes = {
  sessionId: PropTypes.number.isRequired,
  showToast: PropTypes.func.isRequired,
  dialogData: PropTypes.object,
  spendingInfos: PropTypes.object,
}

DexTakerApproveInfo.defaultProps= {
  generalInfo: {},
  spendingInfos: {}
}

const mapStateToProps = (state, ownProps)=> {
  const generalInfo = lodash.get(state, ['single', 'curPageData', 'generalInfo'], {})
  const dialogData = lodash.get(state, ['single', 'curPageData', 'dialogData'])
  const spendingInfos = lodash.get(state, ['single', 'curPageData', 'spendingInfos'], {})
  return {
    generalInfo,
    dialogData,
    spendingInfos,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return mapDispatch(dispatch)({...singleActions})
}

export default connect(mapStateToProps, mapDispatchToProps)(DexTakerApproveInfo)

