import React, { useCallback, useEffect } from 'react'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { Box, Button, Card, Grid, makeStyles } from '@material-ui/core'
import { isValidNumber, mapDispatch } from '../../../../utils/utils'
import { actions as singleActions } from '../../../single/singleSlice'
import connect from 'react-redux/es/connect/connect'
import PropTypes from 'prop-types'
import lodash from 'lodash'
import logger from '../../../../utils/logger'
import {
  SIDE_BUY
} from '../../../../protocolConstants'
import { useRefFields, useRefFieldsPrevious, useStateReq } from '../../../../utils/hooks'
import { showToast } from '../../../single/singleSlice'
import IcebergAlgoFormDialog from './icebergAlgoForm/IcebergAlgoFormDialog'
import { icebergDialogPropType, pairInfoPropTypes } from '../../../../utils/propTypesUtils'
import PiTwoColRowGrid from '../../../../components/PiTwoColRowGrid'
import ValueErrorText from './icebergAlgoForm/ValueErrorText'
import IcebergPriceSubForm from './icebergAlgoForm/IcebergPriceSubForm'
import IcebergQtySubForm from './icebergAlgoForm/IcebergQtySubForm'
import { pctPxConv, setPctPxConvFieldsByValues } from './icebergAlgoForm/icebergUtils'
import IcebergEstimationView from './icebergAlgoForm/IcebergEstimationView'
import ColorButton from '../../../../components/ColorButton'
import { CMD_EXECUTE_ICEBERG, CMD_PREVIEW_ICEBERG, CMD_STOP_ICEBERG, SIDE_SELL } from '../../../../protocolConstants'
import PiCardContent from '../../../../components/PiCardContent'
import { PiFormContextProvider } from '../../../../utils/piFormContext'

const useStyles = makeStyles((theme) => ({
  button: {
    height: "100%"
  },
  cardSection: {
    marginBottom: 1,
  },
  rowGrid: {
  },
  rowGridInfo: {

  }
}))

const IcebergAlgoFormInner = ({ isSending, active, showStopIcebergDialog, disabledAllFields, valueErrors, pairInfo, avlbBase, avlbQuote, showActiveOrders, icebergAlgoFormUpdatingData, setCurPageData, formProps}) => {
  const { values, handleSubmit, setFieldValue } = formProps
  const classes = useStyles()
  const isBuy = values['buySell'] === SIDE_BUY
  const { tpOrderEnabled } = values

  useEffect(() => {
    if (!lodash.isEmpty(icebergAlgoFormUpdatingData)) {
      setCurPageData({
        icebergAlgoFormUpdatingData: {} // clear the data
      })

      if (disabledAllFields) {
        logger.debug(`iceberg disabled all field, do not update algo form ${icebergAlgoFormUpdatingData}`)
        return
      }

      lodash.forEach(icebergAlgoFormUpdatingData, (val, key) => {
        setFieldValue(key, val)
      })

      const newValues = {
        ...values,
        ...icebergAlgoFormUpdatingData
      }
      setPctPxConvFieldsByValues(true, newValues, setFieldValue)
    }
  }, [setFieldValue, icebergAlgoFormUpdatingData, setCurPageData, values, disabledAllFields])
  return (
    <form onSubmit={handleSubmit}>
      <Card>
        <PiCardContent>
          <Grid container spacing={1}>
            <Grid
              item
              md={8}
              xs={12}
              sm={12}
            >
              <ValueErrorText valueErrors={valueErrors}/>
              <IcebergPriceSubForm
                values={values}
                setFieldValue={setFieldValue}
                pairInfo={pairInfo}
                avlbBase={avlbBase}
                avlbQuote={avlbQuote}
              />
              <IcebergQtySubForm
                values={values}
                avlbBase={avlbBase}
                avlbQuote={avlbQuote}
                pairInfo={pairInfo}
                setFieldValue={setFieldValue}
              />
              <Box className={classes.cardSection}>
                <Box mx={1}>
                  <Grid display="flex" container spacing={0}>

                    <PiTwoColRowGrid>
                      {active
                        ? <Button
                          className={classes.button}
                          disabled={isSending}
                          fullWidth
                          variant="contained"
                          onClick={showStopIcebergDialog}
                        >
                          Stop
                        </Button>
                        :
                        <ColorButton
                          colorStyle={isBuy ? 'green': 'red'}
                          disabled={isSending}
                          fullWidth
                          type="submit"
                          variant="contained"
                        >
                          {isBuy ? 'Preview & Buy' : 'Preview & Sell'}
                        </ColorButton>
                      }
                    </PiTwoColRowGrid>

                    <PiTwoColRowGrid>
                      <Button
                        className={classes.button}
                        disabled={isSending}
                        fullWidth
                        onClick={() => showActiveOrders({
                          tpOrderEnabled
                        })}
                        variant="contained"
                      >
                        View Orders
                      </Button>
                    </PiTwoColRowGrid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
            {active
              ? null
              : <Grid
                item
                xl={4}
                lg={4}
                md={4}
                sm={12}
                xs={12}
                className={classes.rowGrid}
              >
                <IcebergEstimationView values={values}/>
              </Grid>
            }
          </Grid>
        </PiCardContent>
      </Card>
    </form>
  )
}


const calInitialValues = (params, midPx) => {
  params = {
    ...params
  }
  const { buySell } = params
  midPx = parseFloat(midPx)
  if (!isValidNumber(midPx)){
    return params
  }

  let refPx
  if (buySell === SIDE_BUY) {
    refPx = midPx * 0.9
  } else if (buySell === SIDE_SELL) {
    refPx = midPx * 1.1
  } else {
    return params
  }
  let changedValues
  if (params['bandEndPx'] === '0' && params['bandStartPx'] === '0') {
    params['bandStartPct'] = 10
    params['bandEndPct'] = 30
    changedValues = pctPxConv(true, refPx, buySell, params)
  } else {
    changedValues = pctPxConv(false, refPx, buySell, params)
  }
  changedValues['refPx'] = refPx
  return lodash.merge(params, changedValues)
}

const ALL_VSB_PCT= 100 // if vsb pct is this number, means all orders are visible, and no hidden orders

const IcebergAlgoForm = (props) => {
  let { sessionId, pairInfo, params, setCurPageData, initMidPx, avlbBase, avlbQuote, dialogData, icebergAlgoFormUpdatingData } = props
  const {
    isSending,
    isSuccessful,
    isError,
    sendReq,
    getStateField,
    setStateField,
    receivedData,
    sentData,
    clearRId
  } = useStateReq()

  const active = lodash.get(params, 'active', false)
  const valueErrors = getStateField('valueErrors', undefined)
  const showPreviewIcebergDialog = useCallback((previewDataInput, previewSentDataInput) => {
    const dialogOpen = true
    const dialogType = 'executeIceberg'
    setCurPageData({
      dialogData: {
        ...dialogData,
        dialogOpen,
        dialogType,
        previewData: previewDataInput,
        previewSentData: previewSentDataInput
      }
    })
  }, [setCurPageData, dialogData])

  const showStopIcebergDialog = () => {
    const dialogOpen = true
    const dialogType = 'stopIceberg'
    setCurPageData({
      dialogData: {
        ...dialogData,
        dialogOpen,
        dialogType
      }
    })
  }

  const [setRefField, getRefField] = useRefFields()
  const prevIsSending  = useRefFieldsPrevious('isSending', isSending)
  useEffect(() => {
    if (prevIsSending && !isSending) {
      const cmd = lodash.get(sentData, 'cmd')
      if(cmd === CMD_PREVIEW_ICEBERG) {
        if (isSuccessful) {
          const previewData = lodash.get(receivedData, 'data')
          showPreviewIcebergDialog(previewData, sentData)
        } else if (isError) {
          const valueErrors = lodash.get(receivedData, ['data', 'valueErrors'])
          const formErrors = lodash.get(receivedData, ['data', 'formErrors'])
          setStateField('valueErrors', valueErrors)
          const setErrors = getRefField('setErrors')
          if (!lodash.isEmpty(formErrors) && lodash.isFunction(setErrors)) {
            setErrors(formErrors)
          }
        }
      } else if (cmd === CMD_EXECUTE_ICEBERG) {
        if (isSuccessful) {
          showToast('Iceberg executed successfully', 'success')
          const active = true
          setCurPageData({
            // params from props, hence immutable
            params: {
              ...params,
              active
            }
          })
        } else if (isError) {
        }
      } else if (cmd === CMD_STOP_ICEBERG) {
        if (isSuccessful) {
          showToast("Iceberg stopped successfully", 'success')
          const active = false
          setCurPageData({
            // params from props, hence immutable
            params: {
              ...params,
              active
            }
          })
        } else if (isError) {

        }
      }
    }
  }, [prevIsSending, params, setCurPageData, getRefField, isSending, isSuccessful, clearRId,
    isError, receivedData, sentData, setStateField, showPreviewIcebergDialog])

  const onSubmitIcebergAlgo = (values, { setErrors }) => {
    setStateField('valueErrors', undefined)
    values = lodash.clone(values)
    // set the function into the ref
    setRefField('setErrors', setErrors)
    const stringKeys = [
      'bandStartPx',
      'bandEndPx',
      'reservePx',
      'tpOrderPx',
      'totalQty',
    ]
    lodash.forEach(stringKeys, (key) => {
      values[key] = String(values[key])
    })

    logger.info('on submit iceberg algo form')
    logger.info(values)
    sendReq({
      isRelay: true,
      cmd: CMD_PREVIEW_ICEBERG,
      data: {
        sessionId,
        params: values
      }
    })
  }

  const showActiveOrders = (activeOrderData) => {
    const dialogOpen = true
    const dialogType = 'showActiveOrders'
    setCurPageData({
      dialogData: {
        ...dialogData,
        dialogOpen,
        dialogType,
        activeOrderData
      }
    })
  }

  let initialValues = undefined
  if (params !== undefined) {
    initialValues = calInitialValues(params, initMidPx)
  }
  if (initialValues === undefined) {
    return null
  }
  return (
    <Box>
      <Formik
        initialValues={initialValues}
        validationSchema={props =>  Yup.lazy(values => {
          const pctVisiblePerLayer = values['pctVisiblePerLayer']
            return Yup.object().shape({
              numHiddenOrdersPerLayer: pctVisiblePerLayer !== ALL_VSB_PCT ? Yup.number().min(1, "Must be positive") : Yup.number().min(0, "Must be zero").max(0, "Must be zero"),
              bandStartPx: Yup.number().moreThan(0, 'Must be positive').required('Start price is required'),
              bandEndPx: Yup.number().moreThan(0, 'Must be positive').required('End price is required'),
            })
        })}
        onSubmit={onSubmitIcebergAlgo}
      >
        {(formProps) => {
          const disabledAllFields = active
          return (
            <PiFormContextProvider
              disabledAllFields={disabledAllFields}
            >
              <IcebergAlgoFormInner
                isSending={isSending}
                active={active}
                showStopIcebergDialog={showStopIcebergDialog}
                valueErrors={valueErrors}
                disabledAllFields={disabledAllFields}
                pairInfo={pairInfo}
                avlbBase={avlbBase}
                avlbQuote={avlbQuote}
                showActiveOrders ={showActiveOrders}
                icebergAlgoFormUpdatingData={icebergAlgoFormUpdatingData}
                setCurPageData={setCurPageData}
                formProps={formProps}
              />
            </PiFormContextProvider>
          )
        }}
      </Formik>
      <IcebergAlgoFormDialog
        sessionId={sessionId}
        sendReq={sendReq}
      />
    </Box>
  )
}


IcebergAlgoForm.propTypes = {
  sessionId: PropTypes.number.isRequired,
  initialValues: PropTypes.shape({
    active: PropTypes.bool.isRequired,
    bandEndPx: PropTypes.string.isRequired,
    bandStartPx: PropTypes.string.isRequired,
    buySell: PropTypes.string.isRequired,
    layers: PropTypes.number.isRequired, // integer
    numHiddenOrdersPerLayer: PropTypes.number.isRequired, // integer
    orderPxRange: PropTypes.number.isRequired,
    orderQtyRange: PropTypes.number.isRequired,
    pctVisiblePerLayer: PropTypes.number.isRequired,
    reserveEnabled: PropTypes.bool.isRequired,
    reservePx: PropTypes.string.isRequired,
    totalQty: PropTypes.string.isRequired,
    tpOrderEnabled: PropTypes.bool.isRequired,
    tpOrderPx: PropTypes.string.isRequired,
    updItvl: PropTypes.number.isRequired,
    useQuote: PropTypes.bool.isRequired
  }),
  pairInfo: pairInfoPropTypes,
  avlbBase: PropTypes.string,
  avlbQuote: PropTypes.string,
  params: PropTypes.object,
  setCurPageData: PropTypes.func.isRequired,
  dialogData: icebergDialogPropType,
}

IcebergAlgoForm.defaultProps = {
  algorithms: []
}

const mapStateToProps = (state, ownProps)=> {
  const pairInfo = lodash.get(state, ['single', 'curPageData', 'pairInfo'])
  const params = lodash.get(state, ['single', 'curPageData', 'params'])
  const initMidPx = lodash.get(state, ['single', 'curPageData', 'initMidPx'])
  const dialogData = lodash.get(state, ['single', 'curPageData', 'dialogData'])
  const icebergAlgoFormUpdatingData = lodash.get(state, ['single', 'curPageData', 'icebergAlgoFormUpdatingData'])
  const basicInfo = lodash.get(state, ['single', 'curPageData', 'basicInfo'], {})
  const { avlbBase, avlbQuote } = basicInfo
  return {
    pairInfo,
    initMidPx,
    params,
    dialogData,
    icebergAlgoFormUpdatingData,
    avlbQuote,
    avlbBase
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return mapDispatch(dispatch)(singleActions)
}

export default connect(mapStateToProps, mapDispatchToProps)(IcebergAlgoForm)
