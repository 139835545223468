import React from 'react'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { mapDispatch } from '../../../utils/utils'
import { actions as socketActions } from '../../socket/socketSlice'
import { actions as singleActions } from '../../single/singleSlice'
import connect from 'react-redux/es/connect/connect'
import PropTypes from 'prop-types'
import { useStateReq } from '../../../utils/hooks'
import { OPTION_BORROW } from '../../../protocolConstants'
import { optionArrayPropType } from '../../../utils/propTypesUtils'
import { BorrowRepayFormInner } from './borrow_repay_form_inner'
import { accNameAssetOptionArrayPropTypes, accBorrowRepayLmtPropTypes } from './utils'

const BorrowRepayForm = ({ sessionId, borrowRepayCmd, isEmptyPair, isEmptyAccName, accNameAssetOptions, accNameAssetBorrowRepayLmt, accNameOptions }) => {
  const {
    sendReq,
    isSending
  } = useStateReq()
  const onBorrowRepay = (values) => {
    sendReq({
      cmd: borrowRepayCmd,
      isRelay: true,
      data: {
        sessionId,
        params: {
          ...values
        }
      }
    })
  }
  const schema = {
    qty: Yup.number().required('Qty is required').moreThan(0, "Must be positive"),
    asset: Yup.string().required('Asset is required'),
    borrowRepay: Yup.string().required('Borrow Repay is required'),
  }

  if (!isEmptyPair) {
    schema['pair'] = Yup.string().required('Pair is required')
  }
  if (!isEmptyAccName) {
    schema['accName'] = Yup.string().required('Account Name is required')
  }

  return (
    <Formik
      initialValues={{
        'borrowRepay': OPTION_BORROW,
        'asset': '',
        'pair': '',
        'accName': ''
      }}
      validationSchema={Yup.object().shape(schema)}
      onSubmit={onBorrowRepay}
    >
      {(formProps) => {
        return (
          <BorrowRepayFormInner
            accNameAssetOptions={accNameAssetOptions}
            accNameOptions={accNameOptions}
            accNameAssetBorrowRepayLmt={accNameAssetBorrowRepayLmt}
            isSending={isSending}
            formProps={formProps}
          />
        )
      }}
    </Formik>
  )
}


BorrowRepayForm.propTypes = {
  sessionId: PropTypes.number.isRequired,
  borrowRepayCmd: PropTypes.string.isRequired,
  accNameOptions: optionArrayPropType,
  accNameAssetOptions: accNameAssetOptionArrayPropTypes,
  accNameAssetBorrowRepayLmt: accBorrowRepayLmtPropTypes,

  // config
  isEmptyPair: PropTypes.bool.isRequired,
  isEmptyAccName: PropTypes.bool.isRequired,
}

BorrowRepayForm.defaultProps = {
  isEmptyPair: true,
  isEmptyAccName: false,
  accNameAssetOptions: [],
  accNameOptions: [],
  accNameAssetBorrowRepayLmt: {}
}

const mapStateToProps = (state, ownProps)=> {
  return {
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return mapDispatch(dispatch)({...socketActions, ...singleActions})
}

export default connect(mapStateToProps, mapDispatchToProps)(BorrowRepayForm)



