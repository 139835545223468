import React, { useCallback } from 'react'
import lodash from 'lodash'
import {baseQuoteExtraParams} from '../../../../../utils/utils'
import { Field } from 'formik'
import FormSelect from '../../../../../components/form/FormSelect'
import logger from '../../../../../utils/logger'
import {
  SIDE_BUY,
  SIDE_SELL
} from '../../../../../protocolConstants'
import PiInputAdornment from '../../../../../components/PiInputAdornment'
import FormInput from '../../../../../components/form/FormInput'
import { IconButton } from '@material-ui/core'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'
import TargetItem from './TargetItem'
import { longShortAccCfgPropTypes } from '../../../../../utils/propTypesUtils'
import {accStatePropTypes, createEmptyLongShortTarget} from '../longShortUtils'
import Decimal from "decimal.js"


const BUY_SELL_A_OPTIONS = [{
  value: SIDE_BUY,
  label: 'Buy A'
},{
  value: SIDE_SELL,
  label: 'Sell A',
}]

const AddRemoveButton = ({ gridSize, disabled, hideAdd, hideRemove, onAdd, onRemove }) => {
  return (
    <TargetItem gridSize={gridSize}>
      {hideRemove ? null : <IconButton onClick={onRemove} disabled={disabled}>
        <RemoveCircleIcon color="secondary"/>
      </IconButton>}
      {hideAdd ? null : <IconButton onClick={onAdd} disabled={disabled}>
        <AddCircleIcon color="primary"/>
      </IconButton>}
    </TargetItem>
  )
}

const LongShortTargetViews = ({values, accCfgA, accStateA, setFieldValue, disabledAllFields}) => {
  const longShortTargetsView = []
  const { longShortEnabled } = values
  const { pairs, pairInfos } = accCfgA
  const pairsOptions = lodash.map(pairs, (p) => {
    return {
      value: p,
      label: p,
    }
  })

  const longShortTargetsFieldName = 'longShortTargets'
  let longShortTargetsValues = lodash.get(values, longShortTargetsFieldName, [])
  if (!longShortTargetsValues) {
    longShortTargetsValues = []
  }

  const onAddPath = () => {
    if (longShortTargetsValues.length >= pairsOptions.length) {
      return
    }
    const targetsClone = lodash.clone(longShortTargetsValues)
    targetsClone.push(createEmptyLongShortTarget())
    setFieldValue(longShortTargetsFieldName, targetsClone)
  }

  const onRemovePathAtIdx = useCallback((idx) => () => {
    // at least need 2 items to remove
    if (longShortTargetsValues.length <= 1) {
      return
    }
    if (longShortTargetsValues.length <= idx) {
      return
    }
    const pathValuesClone = []
    for (let i =0; i < longShortTargetsValues.length; i ++) {
      if (i === idx) {
        continue
      }
      pathValuesClone.push(longShortTargetsValues[i])
    }
    setFieldValue(longShortTargetsFieldName, pathValuesClone)
  }, [longShortTargetsValues, setFieldValue])

  const pairStatesA = lodash.get(accStateA, 'pairStates', {})

  const onChangePairOrTgtQty = (idx) => (name, value) => {
    const lsTgtVal = lodash.get(longShortTargetsValues, idx, null)
    if (lsTgtVal === null) {
      return
    }
    logger.debug("on change pair or tgt, and updating sideA")
    const ns = name.split('.')
    const nameShort = ns[ns.length - 1]
    let { pairA, targetQtyA } = lsTgtVal
    if (nameShort === 'pairA') {
      pairA = value
    } else if (nameShort === 'targetQtyA') {
      targetQtyA = value
    }
    const openQtyStateA = lodash.get(pairStatesA, [pairA, 'openQty'])
    const sideAFieldName = `${longShortTargetsFieldName}.${idx}.sideA`
    try {
      const openQtyADec = new Decimal(openQtyStateA)
      const tgtQtyADec = new Decimal(targetQtyA)
      if (tgtQtyADec.gt(openQtyADec)) {
        // need to buy
        setFieldValue(sideAFieldName, SIDE_BUY)
      } else {
        setFieldValue(sideAFieldName, SIDE_SELL)
      }
    } catch (e) {
      setFieldValue(sideAFieldName, '')
    }
  }

  const pairGridSize = 2
  const initDiffGridSize = 2
  const targetGridSize = 2
  const orderGridSize = 2
  const sideGridSize = 2
  const buttonGridSize = 2
  const longShortDisabled = !longShortEnabled || disabledAllFields
  lodash.forEach(longShortTargetsValues, (target, idx) => {
    const { pairA } = target

    const isLast = idx === longShortTargetsValues.length - 1

    const qtyPrec = lodash.get(pairInfos, [pairA, 'qtyPrec'], null)
    const pair = lodash.get(target, 'pairA') || ''
    const base = baseQuoteExtraParams(pair)[0]

    // pairA
    longShortTargetsView.push(
      <TargetItem gridSize={pairGridSize} key={`targetitem_${idx}_pairA`}>
        <Field
          disabled={!longShortEnabled}
          name={`${longShortTargetsFieldName}.${idx}.pairA`}
          placeholder="PairA"
          options={pairsOptions}
          component={FormSelect}
          postChange={onChangePairOrTgtQty(idx)}
        />
      </TargetItem>
    )

    longShortTargetsView.push(
      <TargetItem gridSize={initDiffGridSize} key={`targetitem_${idx}_init_asubb_diff`}>
        <Field
          name={`${longShortTargetsFieldName}.${idx}.initABSum`}
          label="Tgt AB Sum"
          prec={qtyPrec}
          disabled={!longShortEnabled}
          InputProps={{
            endAdornment: <PiInputAdornment position="end" label={base}/>,
          }}
          inputProps={{
            type: 'number',
            style: {
              textAlign: 'right'
            }
          }}
          component={FormInput}
        />
      </TargetItem>
    )

    longShortTargetsView.push(
      <TargetItem gridSize={targetGridSize} key={`targetitem_${idx}_targetqty`}>
        <Field
          name={`${longShortTargetsFieldName}.${idx}.targetQtyA`}
          label="Tgt Qty A"
          prec={qtyPrec}
          disabled={!longShortEnabled}
          InputProps={{
            endAdornment: <PiInputAdornment position="end" label={base}/>,
          }}
          inputProps={{
            type: 'number',
            style: {
              textAlign: 'right'
            }
          }}
          component={FormInput}
          postChange={onChangePairOrTgtQty(idx)}
        />
      </TargetItem>
    )

    longShortTargetsView.push(
      <TargetItem gridSize={orderGridSize} key={`targetitem_${idx}_orderqty`}>
        <Field
          name={`${longShortTargetsFieldName}.${idx}.orderQtyA`}
          label="Ord.Q"
          prec={qtyPrec}
          disabled={!longShortEnabled}
          InputProps={{
            endAdornment: <PiInputAdornment position="end" label={base}/>,
          }}
          inputProps={{
            type: 'number',
            style: {
              textAlign: 'right'
            }
          }}
          component={FormInput}
        />
      </TargetItem>
    )

    longShortTargetsView.push(
      <TargetItem gridSize={sideGridSize} key={`targetitem_${idx}_sideA`}>
        <Field
          name={`${longShortTargetsFieldName}.${idx}.sideA`}
          placeholder="Side A"
          options={BUY_SELL_A_OPTIONS}
          disabled={!longShortEnabled}
          autoCompleteRest={{
          }}
          component={FormSelect}
        />
      </TargetItem>
    )

    const hideAdd = !isLast || longShortTargetsValues.length >= pairsOptions.length
    longShortTargetsView.push(
      <AddRemoveButton
        gridSize={buttonGridSize}
        key={`addRemoveButtons_${idx}`}
        disabled={longShortDisabled}
        onAdd={onAddPath}
        hideAdd={hideAdd}
        onRemove={onRemovePathAtIdx(idx)}
      />
    )
  })

  if (longShortTargetsValues.length === 0){
    longShortTargetsView.push(
      <AddRemoveButton
        gridSize={buttonGridSize}
        key='addRemoveButtonsExtra'
        disabled={longShortDisabled}
        onAdd={onAddPath}
        hideRemove={true}
      />
    )
  }
  return longShortTargetsView
}

LongShortTargetViews.propTypes = {
  accCfgA: longShortAccCfgPropTypes,
  accStateA: accStatePropTypes,
}

export default LongShortTargetViews
