import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText, makeStyles
} from '@material-ui/core'
import lodash from 'lodash'
import DialogTitle from '@material-ui/core/DialogTitle'
import { PiContext } from '../utils/piContext'

const useStyles = makeStyles((theme) => ({
  dialogNodeContentMobile: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
}))

const ConfirmDialog = (props) => {
  const classes = useStyles()
  const piValues = useContext(PiContext)
  const { isMobile } = piValues
  const {
    dialogOpen,
    dialogTitle,
    dialogContent,
    dialogCancelText,
    dialogConfirmButton,
    dialogOnCancel,
    dialogOnConfirm,
    fullWidth,
    maxWidth,
    fullScreen,
  } = props
  const dialogNodeContentClasses = isMobile && !lodash.isString(dialogContent) ? classes.dialogNodeContentMobile: undefined
  const handleCancel = () => {
    if (lodash.isFunction(dialogOnCancel)) {
      dialogOnCancel()
    }
  }

  const handleConfirm = () => {
    if (lodash.isFunction(dialogOnConfirm)) {
      dialogOnConfirm()
    }
  }
  let confirmButton = null

  if (lodash.isEmpty(dialogConfirmButton)) {
    confirmButton = null
  } else if (lodash.isString(dialogConfirmButton)) {
    confirmButton = <Button onClick={handleConfirm} color="primary">
      {dialogConfirmButton}
    </Button>
  } else{
    confirmButton = dialogConfirmButton
  }
  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={dialogOpen}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {dialogTitle}
      </DialogTitle>
      <DialogContent className={dialogNodeContentClasses}>
        {lodash.isString(dialogContent)
          ? <DialogContentText>
            {dialogContent}
          </DialogContentText>
          : dialogContent
        }
      </DialogContent>
      <DialogActions>
        {lodash.isEmpty(dialogCancelText)
          ? null
          : <Button onClick={handleCancel}>
            {dialogCancelText}
            </Button>
        }
        {confirmButton}
      </DialogActions>
    </Dialog>
  )
}

ConfirmDialog.propTypes = {
  dialogOpen: PropTypes.bool.isRequired,
  dialogTitle: PropTypes.string.isRequired,
  dialogContent: PropTypes.oneOfType([PropTypes.node, PropTypes.string.isRequired]).isRequired,
  dialogCancelText: PropTypes.string,
  dialogConfirmButton: PropTypes.oneOfType([PropTypes.node, PropTypes.string.isRequired]).isRequired,
  dialogOnCancel: PropTypes.func,
  dialogOnConfirm: PropTypes.func,
  maxWidth: PropTypes.oneOf([
    'xs', 'sm', 'md', 'lg', 'xl', false
  ]),
  fullWidth: PropTypes.bool,
  fullScreen: PropTypes.bool,
}

ConfirmDialog.defaultProps = {
  dialogOpen: false,
  dialogTitle: '',
  dialogContent: '',
  dialogCancelText: '',
  dialogConfirmButton: '',
  fullScreen: false
}

export default ConfirmDialog
