import React from 'react'
import PropTypes from 'prop-types'
import lodash from 'lodash'
import {
  Typography,
  TableBody,
  TableHead,
  TableRow,
  makeStyles,
} from '@material-ui/core'
import { icebergOrderArrayPropTypes} from '../../../../utils/propTypesUtils'
import { ICEBERG_ROW_STATUS_DONE_TEXT, INFINITE_NUM, SIDE_BUY } from '../../../../protocolConstants'
import { mapDispatch } from '../../../../utils/utils'
import connect from 'react-redux/es/connect/connect'
import XscrollTable from '../../../ui/XscrollTable'
import { useIsMobile } from '../../../../utils/hooks'
import PiTableCell from '../../../../components/PiTableCell'
import { INFINITE_SYMBOL } from '../../../../constants'


const useStyles = makeStyles((theme) => ({
  root: {
  },
  tableCellGreen: {
    color: '#0f8f62',
  },
  tableCellRed: {
    color: '#d9304f',
  },
  typography: {
    fontSize: '13px'
  },
}))


const SimpleTableCell = ({children, isMobile=false, isRed, isGreen, ...rest}) => {
  const classes = useStyles()
  let className = undefined
  if (isRed) {
    className = classes.tableCellRed
  } else if (isGreen) {
    className = classes.tableCellGreen
  }
  return (
    <PiTableCell
      className={className}
      {...rest}
    >
      { isMobile ?
        children : <Typography className={classes.typography}>
          {children}
        </Typography>
      }
    </PiTableCell>
  )
}

const IcebergActiveOrdersView = ({ className, tpOrderEnabled, activeOrders }) => {
  const isMobile = useIsMobile()
  if (lodash.isEmpty(activeOrders)) {
    activeOrders = []
  }
  const hideTp = isMobile && !tpOrderEnabled
  return (

    <XscrollTable>
      <TableHead>
        <TableRow>
          {
            isMobile ? null
              : <SimpleTableCell>
                Eid
              </SimpleTableCell>
          }
          <SimpleTableCell>
            Price
          </SimpleTableCell>
          <SimpleTableCell>
            QtyOpen / Qty
          </SimpleTableCell>
          <SimpleTableCell>
            Hid. Qty
          </SimpleTableCell>
          <SimpleTableCell>
            Hid. Idx / Num
          </SimpleTableCell>
          {hideTp ? null
            : <SimpleTableCell>
              Tp Px
            </SimpleTableCell>}
          {hideTp ? null
            : <SimpleTableCell >
            Tp Qty
            </SimpleTableCell>
          }
          <SimpleTableCell>
            Status
          </SimpleTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {activeOrders.map((order, idx) => {
          const {
            hiddenEid,
            hiddenOrderIdx,
            perOrderHiddenQty,
            px,
            qty,
            qtyOpen,
            side,
            status,
            tpEid,
            tpQty,
            tpPx,
            visibleEid
          } = order

          let {
            numHiddenOrders,
          } = order
          let eid = lodash.find([tpEid, hiddenEid, visibleEid], (eid) => {
            return !lodash.isEmpty(eid)
          })
          if (lodash.isEmpty(eid)) {
            eid = '-'
          }
          let isBuy = side === SIDE_BUY
          if (tpEid !== '') {
            isBuy = !isBuy
          }


          let hidNumDis = ''
          if (numHiddenOrders === INFINITE_NUM) {
            hidNumDis = INFINITE_SYMBOL
          } else {
            hidNumDis = numHiddenOrders - (hiddenOrderIdx === 0 ? 0 : hiddenOrderIdx - 1)
            if (status === ICEBERG_ROW_STATUS_DONE_TEXT) {
              hidNumDis = 0
            }
          }

          if (numHiddenOrders === INFINITE_NUM) {
            numHiddenOrders = INFINITE_SYMBOL
          }
          return (
            <TableRow
              hover
              key={idx}
            >
              {
                isMobile ?
                  null: <SimpleTableCell>
                    {eid}
                  </SimpleTableCell>
              }
              <SimpleTableCell
                isRed={!isBuy}
                isGreen={isBuy}
              >
                {px}
              </SimpleTableCell>
              <SimpleTableCell>
                {`${qtyOpen} / ${qty}`}
              </SimpleTableCell>
              <SimpleTableCell>
                {`${perOrderHiddenQty} * ${hidNumDis}`}
              </SimpleTableCell>
              <SimpleTableCell>
                {`${hiddenOrderIdx} / ${numHiddenOrders}`}
              </SimpleTableCell>
              { hideTp ? null
              : <SimpleTableCell>
                {tpPx}
              </SimpleTableCell>}
              { hideTp ? null
              : <SimpleTableCell>
                {tpQty}
              </SimpleTableCell>}
              <SimpleTableCell>
                {status}
              </SimpleTableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </XscrollTable>
  )
}

IcebergActiveOrdersView.propTypes = {
  className: PropTypes.string,
  activeOrders: icebergOrderArrayPropTypes,
  tpOrderEnabled: PropTypes.bool.isRequired,
}

IcebergActiveOrdersView.defaultProps= {
  activeOrders: [],
  tpOrderEnabled: true,
}

const mapStateToProps = (state, ownProps)=> {
  const activeOrders = lodash.get(state, ['single', 'curPageData', 'activeOrders'])
  return {
    activeOrders
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return mapDispatch(dispatch)({})
}

export default connect(mapStateToProps, mapDispatchToProps)(IcebergActiveOrdersView)
