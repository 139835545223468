import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import logger from '../../../utils/logger'


const PollingView = ({ children, fn, itvMs }) => {
  useEffect(() => {
    const itv = setInterval(() => {
      logger.debug('polling called')
      fn()
    }, itvMs)
    return () => {
      clearInterval(itv)
    }
  })

  if (children) {
    return children
  } else {
    return <div>
      Checking
    </div>
  }
}

PollingView.propTypes = {
  itvMs: PropTypes.number.isRequired,
  fn: PropTypes.func.isRequired,
}

PollingView.defaultProps = {
}

export default PollingView
