import lodash from 'lodash'
import { genUuid } from './utils'

const cmdListenersMap = {}

const addEventListener = (cmd, callback) => {
  let listeners = lodash.get(cmdListenersMap, cmd)
  if (lodash.isEmpty(listeners)) {
    listeners = {}
    cmdListenersMap[cmd] = listeners
  }
  const callbackId = genUuid()
  listeners[callbackId] = callback
  return callbackId
}

const removeEventListener = (cmd, callbackId) => {
  let listeners = lodash.get(cmdListenersMap, cmd, [])
  if (lodash.isEmpty(listeners)) {
    return
  }
  delete listeners[callbackId]
}

const notifyEvent = (cmd, e) => {
  const listeners = lodash.get(cmdListenersMap, cmd, {})
  lodash.forEach(listeners, (callback, callbackId) => {
    callback(e)
  })
}


export {
  addEventListener,
  removeEventListener,
  notifyEvent
}
