import React, { useEffect } from 'react'
import clsx from 'clsx'
import {
  Card,
  TableBody,
  TableRow,
  makeStyles,
  Button, Grid
} from '@material-ui/core'
import lodash from 'lodash'
import { mapDispatch } from '../../../../utils/utils'
import { actions as singleActions } from '../../../single/singleSlice'
import connect from 'react-redux/es/connect/connect'
import PropTypes from 'prop-types'
import XscrollTable from '../../../ui/XscrollTable'
import PiTableCell from '../../../../components/PiTableCell'
import PiCardContent from '../../../../components/PiCardContent'
import { useRefFieldsPrevious, useStateReq } from '../../../../utils/hooks'
import {
  CMD_DEX_TAKER_GET_BASIC_INFO,
} from '../../../../protocolConstants'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  cardContent: {
    height: '100%'
  },
  tableCell: {
    borderBottom: "none"
  },
  refreshButton: {

  },
  viewDetailsButton: {

  }
}))

const SmallTableCell = ({children}) => {
  const classes = useStyles()
  return <PiTableCell
    className={classes.tableCell}
  >
    {children}
  </PiTableCell>
}

const DexTakerBaiscInfoView = ({ basicInfo, generalInfo, dialogData, sessionId, setCurPageData }) => {
  const classes = useStyles()
  const {
    isSending,
    isSuccessful,
    sendReq,
    sentData,
    receivedData,
  } = useStateReq()
  const handleRefreshBasicInfo = () => {
    sendReq({
      cmd: CMD_DEX_TAKER_GET_BASIC_INFO,
      isRelay: true,
      data: {
        sessionId
      }
    })
  }
  const handleViewDetails = () => {
    const dialogOpen = true
    const dialogType = 'showBasicInfoDetail'
    setCurPageData({
      dialogData: {
        ...dialogData,
        dialogOpen,
        dialogType,
      }
    })
  }

  const prevIsSuccessful = useRefFieldsPrevious('isSuccessful', isSuccessful)
  useEffect(() => {
    if (!prevIsSuccessful && isSuccessful) {
      const cmd = lodash.get(sentData, 'cmd')
      if(cmd === CMD_DEX_TAKER_GET_BASIC_INFO) {
        const receivedBasicInfo = lodash.get(receivedData, ['data'], {})
        setCurPageData({
          basicInfo: receivedBasicInfo
        })
      }
    }
  }, [setCurPageData, prevIsSuccessful, isSuccessful, receivedData, sentData])

  if (!basicInfo) {
    return null
  }

  const tokenAAsset = lodash.get(generalInfo, ['tokenA', 'asset'], 'tokenA')
  const tokenBAsset = lodash.get(generalInfo, ['tokenB', 'asset'], 'tokenB')
  const tokenGasAsset = lodash.get(generalInfo, ['tokenGas', 'asset'], 'tokenGas')

  const { avlbTokenASum = '-', avlbTokenBSum = '-', avlbTokenGasSum = '-'} = basicInfo
  return (
    <Card className={clsx(classes.root)}
    >
      <PiCardContent className={classes.cardContent}>
        <XscrollTable>
          <TableBody>
            <TableRow
              hover
            >
              <SmallTableCell>
                {`Sum of Avlb TokenA (${tokenAAsset})`}
              </SmallTableCell>
              <SmallTableCell>
                {avlbTokenASum}
              </SmallTableCell>
              <SmallTableCell>
                {`Sum of Avlb TokenB (${tokenBAsset})`}
              </SmallTableCell>
              <SmallTableCell>
                {avlbTokenBSum}
              </SmallTableCell>
            </TableRow>

            <TableRow
              hover
            >
              <SmallTableCell>
                {`Sum of Avlb Token Gas (${tokenGasAsset})`}
              </SmallTableCell>
              <SmallTableCell>
                {avlbTokenGasSum}
              </SmallTableCell>
            </TableRow>
          </TableBody>
        </XscrollTable>

        <Grid container spacing={3}>
          <Grid
            item
            xl={6}
            lg={6}
            sm={6}
            xs={6}
          >
            <Button
              className={classes.refreshButton}
              disabled={isSending}
              fullWidth
              variant='contained'
              size='small'
              onClick={handleRefreshBasicInfo}
            >
              Refresh Info
            </Button>
          </Grid>

          <Grid
            item
            xl={6}
            lg={6}
            sm={6}
            xs={6}
          >
            <Button
              className={classes.viewDetailsButton}
              disabled={isSending}
              fullWidth
              size='small'
              variant='contained'
              onClick={handleViewDetails}
            >
              View Details
            </Button>
          </Grid>
        </Grid>
      </PiCardContent>
    </Card>
  )
}

DexTakerBaiscInfoView.propTypes = {
  basicInfo: PropTypes.shape({
    serverTime: PropTypes.number.isRequired,
    avlbTokenASum: PropTypes.string.isRequired,
    avlbTokenBSum: PropTypes.string.isRequired,
    avlbTokenGasSum: PropTypes.string.isRequired,
    assetInfos: PropTypes.arrayOf(PropTypes.shape({
      accName: PropTypes.string.isRequired,
      avlbTokenA: PropTypes.string.isRequired,
      avlbTokenB: PropTypes.string.isRequired,
      avlbTokenGas: PropTypes.string.isRequired,
    }).isRequired).isRequired
  }),
  sessionId: PropTypes.number.isRequired,
}

DexTakerBaiscInfoView.defaultProps= {
  generalInfo: []
}

const mapStateToProps = (state, ownProps)=> {
  const basicInfo = lodash.get(state, ['single', 'curPageData', 'basicInfo'])
  const generalInfo = lodash.get(state, ['single', 'curPageData', 'generalInfo'], {})
  const dialogData = lodash.get(state, ['single', 'curPageData', 'dialogData'])
  return {
    basicInfo,
    generalInfo,
    dialogData,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return mapDispatch(dispatch)({...singleActions})
}

export default connect(mapStateToProps, mapDispatchToProps)(DexTakerBaiscInfoView)

