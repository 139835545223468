import React, { useEffect } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import {
  makeStyles,
  Box, Grid
} from '@material-ui/core'
import { mapDispatch } from '../../../../utils/utils'
import connect from 'react-redux/es/connect/connect'
import TakerAlgoForm from './TakerAlgoForm'
import PlaceOrderForm from './PlaceOrderForm'
import BasicInfoView from './MakerTakerBasicInfoView'
import { processLogs } from '../../../ui/LoggerView'
import MakerTakerActiveOrdersView from './MakerTakerActiveOrdersView'
import {
  CMD_MAKER_TAKER_BROADCAST_ORDERBOOK,
  CMD_MAKER_TAKER_BROADCAST_BASICINFO,
  CMD_MAKER_TAKER_BROADCAST_ACTIVE_ORDERS,
  CMD_MAKER_TAKER_BROADCAST_STARTED,
  CMD_MAKER_TAKER_BROADCAST_STOPPED,
  CMD_GET_FULL_STATE,
  CMD_LOG_UPDATE
} from '../../../../protocolConstants'
import { useRefFieldsPrevious, useRunOnce, useStateReqView } from '../../../../utils/hooks'
import lodash from 'lodash'
import MakerTakerLoggerView from './MakerTakerLoggerView'
import { actions as singleActions } from '../../../single/singleSlice'
import { addEventListener, removeEventListener } from '../../../../utils/eventBus'
import { LOG_LINES_START_TO_TRIM, LOG_LINES_TRIM_TO } from '../../../../constants'
import TakerMakerOrderbook from './TakerMakerOrderbook'
import { accountPropTypes, algorithmPropTypes, projectPropType } from '../../../../utils/propTypesUtils'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  leftRoot: {
    padding: theme.spacing(1)
  },
  leftTop: {
    width: '100%',
    padding: theme.spacing(1)
  },
  leftBottom: {
    width: '100%',
    height: '300px', // it is actually the min height to be 300
    padding: theme.spacing(1),
    "overflow": "auto"
  },
  rightRoot: {
    padding: theme.spacing(1)
  },
  rightTop: {
    width: '100%',
    padding: theme.spacing(1)
  },
  rightMid: {
    width: '100%',
    padding: theme.spacing(1)
  },
  rightBottom: {
    width: '100%',
    height: '300px', // it is actually the min height to be 300
    padding: theme.spacing(1),
    "overflow": "auto"
  },
}))


const MakerTakerAlgoView = ({ sessionId, sessionAccounts, project, algorithm, setCurPageData, paramTaker, logs, clearCurPageData, setToolbarTitle }) => {
  const classes = useStyles()

  useRunOnce(() => {
    clearCurPageData()
    if (!lodash.isEmpty(sessionAccounts)) {
      const sessionAccount = sessionAccounts[0]
      const { accId, pairs } = sessionAccount
      const exchangeAccounts = lodash.get(project, 'exchangeAccounts', [])
      const account = lodash.find(exchangeAccounts, (ea) => {
        return ea['id'] === accId
      })
      if (account) {
        let pair = ''
        if (!lodash.isEmpty(pairs)) {
          pair = pairs[0]
        }
        const toolbarTitle = `${algorithm.name} - ${account.name} - ${pair}`
        setToolbarTitle(toolbarTitle)
      }
    }
  })

  const genFullState = () => {
    return {
      cmd: CMD_GET_FULL_STATE,
      isRelay: true,
      data: {
        sessionId,
      }
    }
  }

  let { isSuccessful, receivedData, loadingView } = useStateReqView(genFullState)

  const onMakerTakerBasicInfoUpdate = (event) => {
    const { data: basicInfoData, sessionId: eventSessionId } = lodash.get(event, 'data')
    if (eventSessionId !== sessionId) {
      return
    }
    setCurPageData({
      basicInfo: basicInfoData
    })
  }

  const onMakerTakerOrderbookUpdate = (event) => {
    const { data: orderbookData, sessionId: eventSessionId } =lodash.get(event, 'data')
    if(eventSessionId !== sessionId) {
      return
    }
    setCurPageData({
      orderbook: orderbookData,
    })
  }

  const onMakerTakerStarted = (event) => {
    const { sessionId: eventSessionId } =lodash.get(event, 'data')
    if (eventSessionId !== sessionId) {
      return
    }
    const active = true
    setCurPageData({
      paramTaker: {
        ...paramTaker,
        active
      }
    })
  }

  const onMakerTakerStopped = (event) => {
    const { sessionId: eventSessionId } =lodash.get(event, 'data')
    if (eventSessionId !== sessionId) {
      return
    }
    const active = false
    setCurPageData({
      paramTaker: {
        ...paramTaker,
        active
      }
    })
  }

  const onActiveOrders = (event) => {
    const { data: activeOrders, sessionId: eventSessionId } =lodash.get(event, 'data')
    if (eventSessionId !== sessionId) {
      return
    }
    setCurPageData({
      activeOrders,
    })
  }

  const onLogfileUpdate = (event) => {
    const { data: logfileData = [], sessionId: eventSessionId } = lodash.get(event, 'data')
    if (eventSessionId !== sessionId) {
      return
    }
    const addLogs = processLogs(logfileData)
    let newLogs = lodash.concat(logs, addLogs)
    if (newLogs.length > LOG_LINES_START_TO_TRIM) {
      newLogs = newLogs.slice(-LOG_LINES_TRIM_TO, newLogs.length)
    }
    setCurPageData({
      logs: newLogs
    })
  }

  useEffect(() => {
    const basicInfoCallbackId = addEventListener(CMD_MAKER_TAKER_BROADCAST_BASICINFO, onMakerTakerBasicInfoUpdate)
    const orderbookCallbackId = addEventListener(CMD_MAKER_TAKER_BROADCAST_ORDERBOOK, onMakerTakerOrderbookUpdate)
    const icebergStarted = addEventListener(CMD_MAKER_TAKER_BROADCAST_STARTED, onMakerTakerStarted)
    const icebergStopped = addEventListener(CMD_MAKER_TAKER_BROADCAST_STOPPED, onMakerTakerStopped)
    const activeOrderCallbackId = addEventListener(CMD_MAKER_TAKER_BROADCAST_ACTIVE_ORDERS, onActiveOrders)
    const logfileUpdateId = addEventListener(CMD_LOG_UPDATE, onLogfileUpdate)
    return () => {
      removeEventListener(CMD_MAKER_TAKER_BROADCAST_BASICINFO, basicInfoCallbackId)
      removeEventListener(CMD_MAKER_TAKER_BROADCAST_ORDERBOOK, orderbookCallbackId)
      removeEventListener(CMD_MAKER_TAKER_BROADCAST_STARTED, icebergStarted)
      removeEventListener(CMD_MAKER_TAKER_BROADCAST_STOPPED, icebergStopped)
      removeEventListener(CMD_MAKER_TAKER_BROADCAST_ACTIVE_ORDERS, activeOrderCallbackId)
      removeEventListener(CMD_LOG_UPDATE, logfileUpdateId)
    }
  })


  const prevIsSuccessful  = useRefFieldsPrevious('isSuccessful', isSuccessful)
  useEffect(() => {
    if ((!prevIsSuccessful) && isSuccessful) {
      const logLines = lodash.get(receivedData, ['data', 'shared', 'logLines'])
      const logs = processLogs(logLines)
      const activeOrders = lodash.get(receivedData, ['data', 'specific', 'activeOrders'], [])
      const orderbook = lodash.get(receivedData, ['data', 'specific', 'orderbook'])
      const paramTaker = lodash.get(receivedData, ['data', 'specific', 'paramTaker'])
      const pairInfo = lodash.get(receivedData, ['data', 'specific', 'pairInfo'])
      const basicInfo = lodash.get(receivedData, ['data', 'specific', 'basicInfo'])
      setCurPageData({
        logs,
        activeOrders,
        orderbook,
        paramTaker,
        pairInfo,
        basicInfo
      })
    }
  })

  if(loadingView) {
    return <Box className={classes.root}>
      {loadingView}
    </Box>
  }

  return (
    <Grid
      className={clsx(classes.root)}
      container
    >
      <Grid
        item
        className={classes.leftRoot}
        xl={6}
        lg={6}
        sm={12}
        xs={12}
      >

        <Box height="100%"
             width="100%"
             display="flex"
             flexDirection="column"
        >
          <Box className={classes.leftTop}>
            <TakerMakerOrderbook/>
          </Box>
          <Box className={classes.leftBottom} flexGrow={1}>
            <MakerTakerActiveOrdersView
              sessionId={sessionId}
            />
          </Box>
        </Box>
    </Grid>


    <Grid
      item
      className={classes.rightRoot}
      xl={6}
      lg={6}
      sm={12}
      xs={12}
    >
      <Box
        height="100%"
        width="100%"
        display="flex"
        flexDirection="column"
      >
        <Box className={classes.rightTop}>
          <BasicInfoView />
        </Box>

        <Box className={classes.rightTop}>
          <TakerAlgoForm
            sessionId={sessionId}
          />
        </Box>

        <Box className={classes.rightMid}>
          <PlaceOrderForm
            sessionId={sessionId}
          />
        </Box>

        <Box className={classes.rightBottom} flexGrow={1}>
          <MakerTakerLoggerView />
        </Box>
      </Box>
    </Grid>
  </Grid>
  )
}

MakerTakerAlgoView.propTypes = {
  sessionId: PropTypes.number.isRequired,
  algorithm: algorithmPropTypes, // the selected algorithm
  setCurPageData: PropTypes.func.isRequired,
  sessionAccounts: PropTypes.arrayOf(accountPropTypes),
  logs: PropTypes.array.isRequired,
  paramTaker: PropTypes.object,
  clearCurPageData: PropTypes.func.isRequired,
  setToolbarTitle: PropTypes.func.isRequired,
  project: projectPropType.isRequired,
}

MakerTakerAlgoView.defaultProps = {
  logs: [],
}

const mapStateToProps = (state, ownProps)=> {
  const logs = lodash.get(state, ['single', 'curPageData', 'logs'], [])
  const paramTaker = lodash.get(state, ['single', 'curPageData', 'paramTaker'])
  return {
    logs,
    paramTaker
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return mapDispatch(dispatch)({...singleActions})
}

export default connect(mapStateToProps, mapDispatchToProps)(MakerTakerAlgoView)

