import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  rowGrid: {
    paddingRight: theme.spacing(1) / 2,
    paddingLeft: theme.spacing(1) / 2
  }
}))

const PiOneColRowGrid = ({children, ...rest}) => {
  const classes = useStyles()
  return (
    <Grid
      item
      xl={12}
      lg={12}
      md={12}
      sm={12}
      xs={12}
      className={classes.rowGrid}
      {...rest}
    >
      {children}
    </Grid>
  )
}

export default PiOneColRowGrid
