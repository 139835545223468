import lodash from 'lodash'
import { processLogs } from '../../../ui/LoggerView'
import { LOG_LINES_START_TO_TRIM, LOG_LINES_TRIM_TO } from '../../../../constants'

// hard coded key is activeOrders
const createOnActiveOrderUpdate = (setCurPageData, sessionId) => (event) => {
  const eventData = lodash.get(event, 'data')
  const { data: activeOrders, sessionId: eventSessionId } = eventData
  if (eventSessionId !== sessionId) {
    return
  }
  setCurPageData({
    activeOrders,
  })
}

// hard coded key is logs
const createOnLogfileUpdate = (setCurPageData, sessionId, logs) => (event) => {
  const { data: logfileData = [], sessionId: eventSessionId } = lodash.get(event, 'data')
  if (eventSessionId !== sessionId) {
    return
  }
  const addLogs = processLogs(logfileData)
  let newLogs = lodash.concat(logs, addLogs)
  if (newLogs.length > LOG_LINES_START_TO_TRIM) {
    newLogs = newLogs.slice(-LOG_LINES_TRIM_TO, newLogs.length)
  }
  setCurPageData({
    logs: newLogs
  })
}

// hard coded key is basicInfo
const createOnBasicInfoUpdate = (setCurPageData, sessionId) => (event) => {
  const { data: basicInfoData, sessionId: eventSessionId } = lodash.get(event, 'data')
  if (eventSessionId !== sessionId) {
    return
  }
  setCurPageData({
    basicInfo: basicInfoData
  })
}

const createOnAlgoActiveUpdate = (setCurPageData, sessionId, active, paramKey, paramData) => (event) => {
  const { sessionId: eventSessionId } =lodash.get(event, 'data')
  if (eventSessionId !== sessionId) {
    return
  }
  setCurPageData({
    [paramKey]: {
      ...paramData,
      active
    }
  })
}

export {
  createOnActiveOrderUpdate,
  createOnLogfileUpdate,
  createOnBasicInfoUpdate,
  createOnAlgoActiveUpdate,
}
