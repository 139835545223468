import React from 'react'
import {
  Box,
  makeStyles,
} from '@material-ui/core'
import { mapDispatch } from '../../../../utils/utils'
import { actions as socketActions } from '../../../socket/socketSlice'
import { actions as singleActions } from '../../../single/singleSlice'
import connect from 'react-redux/es/connect/connect'
import PropTypes from 'prop-types'
import lodash from 'lodash'
import {
  CMD_LONG_SHORT_UPDATE_NOTE,
} from '../../../../protocolConstants'
import NoteForm from '../../../units/note'
import { noteParamPropTypes } from '../../../units/note/utils'


const useStyles = makeStyles((theme) => ({
  formBox: {
  },
}))

const LongShortPlaceOrderForm = ({ sessionId, note }) => {
  const classes = useStyles()
  if (note === null) {
    // usually means the note content not yet fetched from server, ignore the ui first
    return null
  }
  const content = lodash.get(note, 'content', '')
  return (
    <Box
      className={classes.formBox}
      display="flex"
      flexDirection="column"
      justifyContent="center"
    >
      <NoteForm
        updateNoteCmd={CMD_LONG_SHORT_UPDATE_NOTE}
        initContent={content}
        sessionId={sessionId}
      />
    </Box>
  )
}


LongShortPlaceOrderForm.propTypes = {
  sessionId: PropTypes.number.isRequired,
  note: noteParamPropTypes,
}

LongShortPlaceOrderForm.defaultProps = {
  note: null
}

const mapStateToProps = (state, ownProps)=> {
  const note = lodash.get(state, ['single', 'curPageData', 'note'])
  return {
    note
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return mapDispatch(dispatch)({...socketActions, ...singleActions})
}

export default connect(mapStateToProps, mapDispatchToProps)(LongShortPlaceOrderForm)

