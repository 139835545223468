import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { createLogger } from 'redux-logger'
import singleReducer from '../features/single/singleSlice'
import socketReducer from '../features/socket/socketSlice'
import { createSocketMiddleware } from '../features/socket/socketMiddleware'
import { isDev } from '../config'

const defaultMiddlewares = getDefaultMiddleware()

const MIDDLEWARES = [...defaultMiddlewares, createSocketMiddleware()]

if (isDev()) {
  // need to disable the logger in production
  const logger = createLogger({
    // stateTransformer: (state) => {
    //   if (Iterable.isIterable(state)) {
    //     return state.toJS();
    //   } else {
    //     return state;
    //   }
    // }
  })
  MIDDLEWARES.push(logger)
}

export default configureStore({
  reducer: {
    single: singleReducer,
    socket: socketReducer
  },
  middleware: MIDDLEWARES,
})
