import React from 'react'
import { makeStyles, TableBody, TableRow, Typography } from '@material-ui/core'
import ConfirmDialog from '../../../../components/ConfirmDialog'
import {
  CMD_SPOOF_SNIPER_EXECUTE,
  CMD_SPOOF_SNIPER_STOP, OPTION_FALSE,
  SIDE_BUY
} from '../../../../protocolConstants'
import ColorButton from '../../../../components/ColorButton'
import PropTypes from 'prop-types'
import { spoofSniperDialogPropType } from '../../../../utils/propTypesUtils'
import lodash from 'lodash'
import { mapDispatch } from '../../../../utils/utils'
import { actions as singleActions } from '../../../single/singleSlice'
import connect from 'react-redux/es/connect/connect'
import { SIDE_SELL } from '../../../../protocolConstants'
import XscrollTable from '../../../ui/XscrollTable'
import PiTableCell from '../../../../components/PiTableCell'

const useStyles = makeStyles((theme) => ({
  lineThrough: {
    'text-decoration': 'line-through',
  },
}))


const TableRowNameVal = ({name, value, lineThrough, isSniperText}) => {
  const classes = useStyles()
  let className = ''
  if (lineThrough) {
    className = classes.lineThrough
  }

  return (
    <TableRow hover selected={isSniperText}>
      <PiTableCell
        variant='head'
      >
        <Typography className={className}>
          {name}
        </Typography>

      </PiTableCell>
      <PiTableCell className={className}>
        {value}
      </PiTableCell>
    </TableRow>
  )
}
const SpoofSniperDialogContent = ({previewData}) => {
  const { itvlMs, spoofEnabled, spoofOrderQty, spoofLayerNum, spoofLayerGap, spoofMinSpread, spoofRemainingQty,
    spoofRemainingAsset, spoofLmtPx, spoofOutrangeSniper, spoofAfterSniperOnly, spoofAfterSniperItvlMs,
    sniperBuySell, sniperPct, sniperMinOrderQty, sniperMaxOrderQty, sniperRemainingQty, sniperRemainingAsset,
    sniperLmtPx, sniperPxMaxGap, sniperMinConsItvlMs, sniperLayerMinConsItvlMs, sniperCounterOrderQty, sniperCounterOrderEnabled } = previewData

  const isSpoofDisabled = spoofEnabled === OPTION_FALSE
  return (
    <XscrollTable>
      <TableBody>
        <TableRowNameVal
          name="Spoof Enabled"
          value={spoofEnabled}
        />

        <TableRowNameVal
          name="Spoof Order Qty"
          lineThrough={isSpoofDisabled}
          value={spoofOrderQty}
        />

        <TableRowNameVal
          name="Spoof Lmt Px"
          lineThrough={isSpoofDisabled}
          value={spoofLmtPx}
        />

        <TableRowNameVal
          name="Spoof Layer Num"
          lineThrough={isSpoofDisabled}
          value={spoofLayerNum}
        />

        <TableRowNameVal
          name="Spoof Layer Gap"
          lineThrough={isSpoofDisabled}
          value={spoofLayerGap}
        />
        <TableRowNameVal
          name="Spoof Min Spread"
          lineThrough={isSpoofDisabled}
          value={spoofMinSpread}
        />
        <TableRowNameVal
          name="Spoof Remaining Asset"
          lineThrough={isSpoofDisabled}
          value={`${spoofRemainingQty} (${spoofRemainingAsset})`}
        />
        <TableRowNameVal
          name="Spoof Outrange Sniper"
          lineThrough={isSpoofDisabled}
          value={spoofOutrangeSniper}
        />

        <TableRowNameVal
          name="Spoof After Sniper Only"
          lineThrough={isSpoofDisabled}
          value={spoofAfterSniperOnly}
        />

        <TableRowNameVal
          name="Spoof After Sniper Itvl (second)"
          lineThrough={isSpoofDisabled}
          value={parseInt(spoofAfterSniperItvlMs / 1000)}
        />

        <TableRowNameVal
          name="Sniper Min Order Qty"
          isSniperText
          value={sniperMinOrderQty}
        />
        <TableRowNameVal
          name="Sniper Max Order Qty"
          isSniperText
          value={sniperMaxOrderQty}
        />
        <TableRowNameVal
          name="Sniper Pct"
          isSniperText
          value={sniperPct}
        />
        <TableRowNameVal
          name="Sniper Remaining Asset"
          isSniperText
          value={`${sniperRemainingQty} (${sniperRemainingAsset})`}
        />
        <TableRowNameVal
          name="Sniper Lmt Px"
          isSniperText
          value={sniperLmtPx}
        />

        <TableRowNameVal
          name="Snpier Px Max Gap"
          isSniperText
          value={sniperPxMaxGap}
        />

        <TableRowNameVal
          name="Snpier Counter Order Enabled"
          isSniperText
          value={sniperCounterOrderEnabled}
        />

        <TableRowNameVal
          name="Snpier Counter Order Qty"
          isSniperText
          value={sniperCounterOrderQty}
        />

        <TableRowNameVal
          name="Sniper Side"
          isSniperText
          value={sniperBuySell}
        />
        <TableRowNameVal
          name="Interval (sec)"
          isSniperText
          value={parseInt(itvlMs / 1000)}
        />

        <TableRowNameVal
          name="Snpier Cons Itvl (sec)"
          isSniperText
          value={parseInt(sniperMinConsItvlMs / 1000)}
        />

        <TableRowNameVal
          name="Snpier Layer Cons Itvl (sec)"
          isSniperText
          value={parseInt(sniperLayerMinConsItvlMs / 1000)}
        />
      </TableBody>
    </XscrollTable>
  )
}


const SpoofSniperAlgoFormDialog = ({dialogData, setCurPageData, sendReq, sessionId}) => {
  if (!dialogData) {
    return null
  }
  const { dialogOpen, dialogType, previewData, previewSentData} = dialogData
  const hideDialog = () => {
    setCurPageData({
      dialogData: {
        ...dialogData,
        dialogOpen: false,
      }
    })
  }

  if (dialogType === 'executeSpoofSniperAlgo') {
    const executeSpoofSniper = () => {
      sendReq({
        cmd: CMD_SPOOF_SNIPER_EXECUTE,
        isRelay: true,
        data: lodash.get(previewSentData, 'data')
      })
      hideDialog()
    }
    const { sniperBuySell } = previewData
    let confirmButton
    if (sniperBuySell === SIDE_BUY) {
      confirmButton = <ColorButton onClick={executeSpoofSniper} colorStyle='green'>
        Execute Sniper Buy
      </ColorButton>
    } else if (sniperBuySell === SIDE_SELL) {
      confirmButton = <ColorButton onClick={executeSpoofSniper} colorStyle='red'>
        Execute Sniper Sell
      </ColorButton>
    } else {
      // something wrong, hide the button
      confirmButton = null
    }
    return (
      <ConfirmDialog
        dialogOpen={dialogOpen}
        dialogTitle='Are you sure you want to execute Spoof Sniper algo?'
        dialogContent={<SpoofSniperDialogContent previewData={previewData}/>}
        dialogCancelText='Dismiss'
        dialogConfirmButton={confirmButton}
        dialogOnCancel={hideDialog}
      />
    )
  } else if (dialogType === 'stopSpoofSniperAlgo') {
    const stopTaker = () => {
      sendReq({
        cmd: CMD_SPOOF_SNIPER_STOP,
        isRelay: true,
        data: {
          sessionId
        }
      })
      hideDialog()
    }
    return (
      <ConfirmDialog
        dialogOpen={dialogOpen}
        dialogTitle='Are you sure you want to stop Spoof Sniper algo?'
        dialogContent=''
        dialogCancelText='Dismiss'
        dialogConfirmButton='Stop now'
        dialogOnCancel={hideDialog}
        dialogOnConfirm={stopTaker}
      />
    )
  } else {
    return null
  }
}


SpoofSniperAlgoFormDialog.propTypes = {
  dialogData: spoofSniperDialogPropType,
  setCurPageData: PropTypes.func.isRequired,
  sendReq: PropTypes.func.isRequired,
  sessionId: PropTypes.number.isRequired
}

SpoofSniperAlgoFormDialog.defaultProps= {

}

const mapStateToProps = (state, ownProps)=> {
  const dialogData = lodash.get(state, ['single', 'curPageData', 'dialogData'])
  return {
    dialogData
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return mapDispatch(dispatch)(singleActions)
}

export default connect(mapStateToProps, mapDispatchToProps)(SpoofSniperAlgoFormDialog)
