import React from 'react'
import {
  Container,
  makeStyles
} from '@material-ui/core'
import Page from '../../components/Page'
import LocalSettingsForm from './LocalSettingsForm'
import { getStorageLocalSettings } from '../../utils/miscUtils'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}))

const SettingsView = () => {
  const classes = useStyles()
  const initValues = getStorageLocalSettings()
  return (
    <Page
      className={classes.root}
      title="Settings"
    >
      <Container maxWidth="lg">
        <LocalSettingsForm
          initValues={initValues}
        />
      </Container>
    </Page>
  )
}

export default SettingsView
