import React, { useCallback } from 'react'
import clsx from 'clsx'
import lodash from 'lodash'
import PropTypes from 'prop-types'
import {
  Card,
  makeStyles,
  Box,
  Grid
} from '@material-ui/core'
import { Field } from 'formik'
import { optionArrayPropType } from '../../../utils/propTypesUtils'
import { isValidNumber } from '../../../utils/utils'
import FormDatePicker from '../../../components/form/FormDatePicker'
import { dayjsEndOfDay, dayjsNow, dayjsStartOfDay, dayjsToMs, fromMs } from '../../../utils/timeUtils'
import PiCardContent from '../../../components/PiCardContent'

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(3),
  },
  startEnd: {
    paddingBottom: theme.spacing(2),
  },
  accLabel: {
    paddingBottom: theme.spacing(1),
  }
}))

const StartEndFields = ({ values, className, pairCountMin, pairCountMax, startDateFieldName, endDateFieldName, accountLabel, accountIdOptions, pairsOptions, fieldNamePrefix, ...rest }) => {
  const classes = useStyles()

  const validateStartDate = useCallback((val) => {
    let err
    if (!isValidNumber(val) || val <= 0) {
      err = 'Please select an account'
    }
    return err
  }, [])

  const validateEndDate = useCallback((val) => {
    let err
    if (!isValidNumber(val) || val <= 0) {
      err = 'Please select an account'
    } else {
      const startDate = lodash.get(values, startDateFieldName)
      if (isValidNumber(startDate)) {
        if (val <= startDate) {
          err = 'Start Date should smaller than end date'
        }
      }
    }
    return err
  }, [startDateFieldName, values])

  const startDayjsToVal = useCallback((dt) => {
    if (dt) {
      dt = dayjsStartOfDay(dt)
      return dayjsToMs(dt)
    } else {
      return 0
    }
  }, [])

  const endDayjsToVal = useCallback((dt) => {
    if (dt) {
      dt = dayjsEndOfDay(dt)
      return dayjsToMs(dt)
    } else {
      return 0
    }
  }, [])

  const valToDayjs = useCallback((ms) => {
    if (isValidNumber(ms) && ms > 0) {
      return fromMs(ms)
    } else {
      return null
    }
  }, [])

  // always set the initial value to be:
  // start empty Value: 30 days ago
  // end empty value: end of today
  const startEmptyValue = dayjsToMs(dayjsStartOfDay(dayjsNow().subtract(30, 'day')))
  const endEmptyValue = dayjsToMs(dayjsEndOfDay(dayjsNow()))

  return (
    <Box mt={3} className={clsx(classes.root, className)} {...rest}>
      <Card>
        <PiCardContent
          disablePiStyle
        >
          <Grid container spacing={2}
          >
            <Grid
              item
              xl={6}
              lg={6}
              md={6}
              sm={6}
              xs={6}
            >
              <Field
                key="start"
                className={classes.startEnd}
                validate={validateStartDate}
                name={startDateFieldName}
                dayjsToVal={startDayjsToVal}
                valToDayjs={valToDayjs}
                label="Start"
                defaultEmptyValue={startEmptyValue}
                options={accountIdOptions}
                component={FormDatePicker}
              />
            </Grid>

            <Grid
              item
              xl={6}
              lg={6}
              md={6}
              sm={6}
              xs={6}
            >
              <Field
                key="end"
                className={classes.startEnd}
                validate={validateEndDate}
                name={endDateFieldName}
                dayjsToVal={endDayjsToVal}
                valToDayjs={valToDayjs}
                defaultEmptyValue={endEmptyValue}
                label="End"
                options={accountIdOptions}
                component={FormDatePicker}
              />
            </Grid>
          </Grid>
        </PiCardContent>
      </Card>
    </Box>
  )
}

StartEndFields.propTypes = {
  className: PropTypes.string,
  startDateFieldName: PropTypes.string.isRequired,
  endDateFieldName: PropTypes.string.isRequired,

  accountLabel: PropTypes.string.isRequired,
  pairCountMin: PropTypes.number,
  pairCountMax: PropTypes.number,
  accounts: PropTypes.arrayOf(PropTypes.shape({
    cat: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    name: PropTypes.number.isRequired
  })),
  accountOptions: optionArrayPropType,
  pairsOptions: optionArrayPropType,
  values: PropTypes.object.isRequired,
}

StartEndFields.defaultProps = {
  accountLabel: '',
  accountIdOptions: [],
  pairsOptions: []
}

export default StartEndFields
