import React from 'react'
import { useIsMobile } from './hooks'

/**
 * Store global values, that may need to shared by vast of childrens
 */
const PiContext = React.createContext({})

const PiContextProvider = ({ children }) => {
  const isMobile = useIsMobile()
  const value = {
    isMobile
  }
  return (
    <PiContext.Provider
      value={value}
    >
      {children}
    </PiContext.Provider>
  )
}


export {
  PiContextProvider,
  PiContext
}
